import React from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PropTyes from 'prop-types';
import { checkUniqueEmail } from '../../containers/Profile/PersonalDetails';
import Button from '../../ui-core/Button/Button';
import LogoutButton from '../../ui-core/LogoutButton';
import InputField from '../../ui-core/InputField';
import ModalBox from '../../ui-core/Modal';
import { doUpdateUserProfieRequest } from '../../store/action/user';
import { doToggleEmailModel } from '../../store/action/common';
import { SEGMENT_EVENTS, trackEvent } from '../../utils/Segment';
import { extractJSON } from '../../services/jsonUtil';
import { LOCAL_STORAGE_CONSTANT } from '../../constants/app';
import { getPageName } from '../../utils/helpers';

import './style.css';

const validate = async values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

const EmailModal = ({ path }) => {
  const dispatch = useDispatch();
  const { openEmailModal } = useSelector(state => state.common);
  const { isLoading } = useSelector(state => state.user);
  const formik = useFormik({
    initialValues: { email: '' },
    validate,
    onSubmit: async ({ email }) => {
      const error = await checkUniqueEmail(email);
      if (!error) {
        dispatch(
          doUpdateUserProfieRequest({
            data: {
              email,
            },
            callback: () => {
              const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
              trackEvent(SEGMENT_EVENTS.PROFILE_UPDATED, {
                user_id: user?.id,
                user_email: user?.attributes?.email,
                user_gender: user?.attributes?.gender,
                user_name: user?.attributes?.name,
                user_phone: user?.attributes?.mobile,
                free_trial_active: user?.attributes?.free_trial || false,
                subscription_active:
                  user?.attributes?.subscription_details?.subscribed || false,
              });
              dispatch(doToggleEmailModel(false));
            },
          }),
        );
      } else {
        formik.setErrors({
          ...formik.errors,
          email: error,
        });
      }
    },
  });

  const handleClick = () => {
    const page = getPageName(path);
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      page,
      section_title: 'Email Modal',
      button_title: 'UPDATE NOW',
    });
    formik.handleSubmit();
  };

  const handleKeyDown = event => {
    if (
      event.keyCode === 13 &&
      (formik.values.email.trim() || formik.isValid)
    ) {
      handleClick();
    }
  };

  return (
    <ModalBox show={openEmailModal} dialogClassName={'email-modal'}>
      <div
        className="p-4 text-center position-relative"
        onKeyDown={handleKeyDown}
      >
        <LogoutButton className="logout-icon" />
        <h2>You need to update your Email Address</h2>
        <InputField
          className="email-address"
          placeholder="Enter Email Address"
          name="email"
          value={formik.values.email}
          handleChange={formik.handleChange}
          errorMessage={formik.errors.email}
        />
        <Button
          class="folder-save-btn w-100 d-flex align-items-center justify-content-around crt-fldr"
          isDisabled={!formik.values.email.trim() || !formik.isValid}
          isLoading={isLoading}
          onClick={handleClick}
          text={'UPDATE NOW'}
          onKeyDown={handleKeyDown}
        />
      </div>
    </ModalBox>
  );
};

EmailModal.propTypes = {
  path: PropTyes.string.isRequired,
};

export default EmailModal;
