export const PRODUCTS_SET_IS_LOADING = 'PRODUCTS_SET_IS_LOADING';

export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN';

export const DO_FETCH_PRODUCTS_REQUEST = 'DO_FETCH_PRODUCTS_REQUEST';

export const DO_FETCH_PRODUCTS_SUCCESS = 'DO_FETCH_PRODUCTS_SUCCESS';

export const DO_FETCH_PRODUCTS_FAILURE = 'DO_FETCH_PRODUCTS_FAILURE';

export const DO_PAYMENT_INITIATION_REQUEST = 'DO_PAYMENT_INITIATION_REQUEST';

export const DO_UPDATE_PAYMENT_METHOD_REQUEST =
  'DO_UPDATE_PAYMENT_METHOD_REQUEST';
