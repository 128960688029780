import { axiosInstance, overrideHeader } from './index';
import { isNotNullOrUndefined } from '../utils/helpers';

/**
 * API request
 * */

// Accept: 'application/vnd.docjenfit.com; version=1',

export const curatedPlansGetRequest = (planId, options) => {
  overrideHeader();
  const queryParams = new URLSearchParams();
  Object.entries(options).forEach(([key, val]) => {
    if (isNotNullOrUndefined(val)) queryParams.append(key, val);
  });
  return axiosInstance.get(`/plans/${planId}?${queryParams.toString()}`, {
    headers: {
      Accept: 'application/vnd.docjenfit.com; version=2',
    },
  });
};
