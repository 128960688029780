import React from 'react';
import parser from 'html-react-parser';
import PropTypes from 'prop-types';

import LazyImage from '../../../../ui-core/Image';
import { ShouldRender } from '../../../../ui-core/ShouldRender';

import './style.css';

export const CircularImages = ({ headerText, items = [] }) => {
  return (
    <React.Fragment>
      <ShouldRender check={!!headerText}>
        <h3 className="mb-5">{headerText}</h3>
      </ShouldRender>
      {items.map((item, index) => (
        <div
          className="circular-images-section-row"
          key={`${headerText || 'circular-images'}-section-item-${index + 1}`}
        >
          <div className="d-flex">
            <LazyImage
              alt={`myth-${index + 1}`}
              className="mx-auto img-fluid"
              errorSrc={item.png}
              placeholderSrc={item.jpg}
              src={item.webp}
            />
          </div>
          <div className="circular-images-text-block">
            {parser(item.content)}
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

CircularImages.propTypes = {
  headerText: PropTypes.string,
  items: PropTypes.array.isRequired,
};
