import React, { useState } from 'react';
import clsx from 'clsx';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import '../ReadMore/style.css';

const ReadMore = props => {
  const text = props.text ?? '';
  const [isReadMore, setIsReadMore] = useState(text.length > props.textLimit);
  const toggleReadMore = () => {
    if (props.onClick) {
      props.onClick();
    } else {
      setIsReadMore(!isReadMore);
    }
  };
  const screenText = (
    <p className="desc-list">
      <ul
        className={clsx(props.className, 'plan_top_p')}
        style={{ display: 'inline' }}
      >
        {parse(
          isReadMore ? text.slice(0, props.textLimit) + '...' : text + ' ',
        )}
      </ul>
      {text.length > props.textLimit ? (
        <span
          onClick={toggleReadMore}
          className={clsx('read-hide-ref mb-4', !isReadMore && 'me-2')}
        >
          {isReadMore ? props.readMoreText : props.readLessText}
        </span>
      ) : null}
    </p>
  );
  return screenText;
};

ReadMore.propTypes = {
  className: PropTypes.string,
  readLessText: PropTypes.string,
  readMoreText: PropTypes.string,
  text: PropTypes.string,
  textLimit: PropTypes.number,
  onClick: PropTypes.func,
};

export default ReadMore;
