import {
  COLLECTION_SET_IS_LOADING,
  DO_GET_COLLECTION_DETAILS_FAILURE,
  DO_GET_COLLECTION_DETAILS_REQUEST,
  DO_GET_COLLECTION_DETAILS_SUCCESS,
  DO_GET_COLLECTION_FAILURE,
  DO_GET_COLLECTION_REQUEST,
  DO_GET_COLLECTION_SUCCESS,
  RESET_COLLECTION_DETAILS,
} from '../../constant/collection';

export const setIsLoading = payload => ({
  type: COLLECTION_SET_IS_LOADING,
  payload,
});

export const doGetCollectionsRequest = () => ({
  type: DO_GET_COLLECTION_REQUEST,
});

export const doGetCollectionsSuccess = payload => ({
  type: DO_GET_COLLECTION_SUCCESS,
  payload,
});

export const doGetCollectionsFailure = () => ({
  type: DO_GET_COLLECTION_FAILURE,
});

export const doGetCollectionDetailsRequest = payload => ({
  type: DO_GET_COLLECTION_DETAILS_REQUEST,
  payload,
});

export const doGetCollectionDetailsSuccess = payload => ({
  type: DO_GET_COLLECTION_DETAILS_SUCCESS,
  payload,
});

export const doGetCollectionDetailsFailure = () => ({
  type: DO_GET_COLLECTION_DETAILS_FAILURE,
});

export const resetCollectionDetails = () => ({
  type: RESET_COLLECTION_DETAILS,
});
