import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import TimeCounter from '../../../../ui-core/TimeCounter';
import Button from '../../../../ui-core/Button/Button';

import { TEXT_CONSTANT } from '../../../../constants/app';
import {
  createNotification,
  NOTIFICATION_TYPE,
  SUCCESS_MESSAGES,
} from '../../../../services/Notification';

import './style.css';

export const JoinNow = props => {
  const endDate = useMemo(() => {
    return dayjs(props.endDate).format('YYYY-MM-DD');
  }, []);

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(TEXT_CONSTANT.JOIN_NOW, 'isSignup', 'top bar');
    }
  };

  const handleCopy = async () => {
    if (navigator && navigator.clipboard) {
      await navigator.clipboard.writeText(props.coupon);
      createNotification({
        type: NOTIFICATION_TYPE.SUCCESS,
        message: SUCCESS_MESSAGES.TEXT_COPY_SUCCESS,
      });
    }
  };

  return (
    <div className="join-now-wrapper">
      <div className="d-flex container justify-content-between">
        <div className="d-flex mobility-div">
          <div className="mobility-coupon" onClick={handleCopy}>
            {/* <img alt="coupon" src={coupon} /> */}
            <div className="coupon-code">
              <p>Use Code</p>
              <h5>{props.coupon}</h5>
            </div>
          </div>
          <div className="coupon-info">{props.text}</div>
        </div>
        <div className="timer-block">
          <TimeCounter endDate={endDate} />
          <Button
            class={'join-now-button'}
            isDisabled={props.loading}
            isLoading={props.loading}
            text={TEXT_CONSTANT.JOIN_NOW}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

JoinNow.propTypes = {
  coupon: PropTypes.string,
  endDate: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

JoinNow.defaultProps = {
  coupon: 'MOBILITY',
  endDate: '01/01/2023',
  text: 'For 20% OFF For Your First Month!',
};
