import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { DO_SELF_ASSESSMENT_REQUEST } from '../../constant/selfAssessment';
import {
  doSelfAssessmentFailure,
  doSelfAssessmentSuccess,
  setIsLoading,
} from '../../action/selfAssessment';
import { selfAssessmentGetRequest } from '../../../api/selfAssessment';
import { createNotification } from '../../../services/Notification';
import { getFiltersParams } from '../../../services/api';
import { errorHandler } from '../../../utils/helpers';

function* selfAssessmentRequest() {
  const { selectedFilters } = yield select(state => state.common);
  const params = getFiltersParams(selectedFilters);
  yield put(setIsLoading(true));
  try {
    const response = yield call(selfAssessmentGetRequest, params);
    yield put(doSelfAssessmentSuccess(response.data.response.data));
  } catch (error) {
    yield put(doSelfAssessmentFailure());
    createNotification(errorHandler(error));
  }
  yield put(setIsLoading(false));
}

/**
 * Watch selfAssessment actions
 * */
export default function* selfAssessmentSaga() {
  yield all([takeLatest(DO_SELF_ASSESSMENT_REQUEST, selfAssessmentRequest)]);
}
