import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { APP_ROUTES } from '../../route';
import { IMAGE_CONSTANT, TOUR_TARGET_ELEMENT } from '../../constants/app';
import { useSelector } from 'react-redux';
import { getDisplayType, getUserAttribute } from '../../utils/helpers';
import useWindowWidth from '../../hooks/useWindowWidth';
import LazyImage from '../../ui-core/Image';

import '../NavFooter/style.css';

const NavFooter = ({ activeSection, history }) => {
  const isDesktopScreen = getDisplayType(useWindowWidth()) === 'desktop';
  const { user } = useSelector(state => state.user);
  if (isDesktopScreen) return null;
  const path = history.location.pathname;
  const userImage = user?.attributes?.profile_pic
    ? user.attributes.profile_pic
    : getUserAttribute('profile_pic') || IMAGE_CONSTANT.defaultProfileIcon;

  const footerNavLinks = [
    {
      title: 'Home',
      active: IMAGE_CONSTANT.homeActiveImg,
      inactive: IMAGE_CONSTANT.homeImg,
      route: APP_ROUTES.HOME,
      className: 'mobile-footer-img',
    },
    {
      title: 'Discover',
      active: IMAGE_CONSTANT.discoverIcon,
      inactive: IMAGE_CONSTANT.discoverInactive,
      route: APP_ROUTES.DISCOVER,
      className: 'mobile-footer-img',
    },
    // {
    //   title: 'Explore',
    //   active: IMAGE_CONSTANT.searchActiveIcon,
    //   inactive: IMAGE_CONSTANT.searchIcon,
    //   route: APP_ROUTES.COLLECTION,
    //   className: 'mobile-footer-img',
    // },
    {
      title: 'My Folders',
      active: IMAGE_CONSTANT.foldersActiveImg,
      inactive: IMAGE_CONSTANT.foldersImg,
      route: APP_ROUTES.FOLDERS,
      className: 'mobile-footer-img',
      dataTourId: TOUR_TARGET_ELEMENT.folders,
    },
    {
      title: 'Profile',
      inactive: userImage,
      route: APP_ROUTES.PROFILE,
      placeholderSrc: IMAGE_CONSTANT.defaultProfileIcon,
      className: 'mobile-footer-img profile-pic',
      dataTourId: TOUR_TARGET_ELEMENT.profile,
    },
  ];

  const getImageSource = (item, isActive) => {
    switch (item.title) {
      case 'Profile':
        return item.inactive;
      default:
        return isActive ? item.active : item.inactive;
    }
  };

  const isActive = (title, route) => {
    switch (title) {
      case 'Home':
        return path === APP_ROUTES.HOME;
      case 'Explore':
        return path === APP_ROUTES.COLLECTION || activeSection === 'Explore';
      default:
        return path === route;
    }
  };

  const handleClick = route => {
    if (route.includes('home')) {
      history.push(APP_ROUTES.HOME);
    } else {
      history.push(route);
    }
  };

  const renderNavLinks = () => {
    const navLinksOutput = footerNavLinks.map(item => (
      <Nav.Link
        key={item.title}
        className={isActive(item.title, item.route) ? 'active' : undefined}
        onClick={() => handleClick(item.route)}
        data-tour-id={item.dataTourId}
      >
        <LazyImage
          errorSrc={IMAGE_CONSTANT.defaultProfileIcon}
          placeholderSrc={IMAGE_CONSTANT.personIcon}
          src={getImageSource(item, isActive(item.title, item.route))}
          className={item.className}
        />
        <span>{item.title}</span>
      </Nav.Link>
    ));
    return navLinksOutput;
  };

  return (
    <Navbar bg="" expand="sm" className="bg-white mobile-navbar">
      <Container>
        <Nav className="d-flex justify-content-around w-100">
          {renderNavLinks()}
        </Nav>
      </Container>
    </Navbar>
  );
};

NavFooter.propTypes = {
  activeSection: PropTypes.string,
  history: PropTypes.object,
};

export default NavFooter;
