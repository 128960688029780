import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const SeoTags = props => {
  const { title, metaDescription, canonicalLink, ogImgUrl, twitterImgUrl } =
    props;
  return (
    <Helmet>
      <title>{title}</title>
      <meta
        data-react-helmet="true"
        name="description"
        content={metaDescription}
      />
      <meta data-react-helmet="true" name="title" content={title} />

      {/* canonical tag link */}
      {/* <link rel="canonical" href={canonicalLink} /> */}

      {/* Og tags */}
      <meta data-react-helmet="true" property="og:type" content="website" />
      <meta
        data-react-helmet="true"
        property="og:url"
        content={canonicalLink}
      />
      <meta data-react-helmet="true" property="og:title" content={title} />
      <meta
        data-react-helmet="true"
        property="og:site_name"
        content="Jen Health"
      />
      <meta
        data-react-helmet="true"
        property="og:description"
        content={metaDescription}
      />
      <meta data-react-helmet="true" property="og:image" content={ogImgUrl} />

      {/* Twiiter tag */}
      <meta
        data-react-helmet="true"
        name="twitter:card"
        content="summary_large_image"
      ></meta>
      <meta
        data-react-helmet="true"
        name="twitter:url"
        content={canonicalLink}
      />
      <meta data-react-helmet="true" name="twitter:title" content={title} />
      <meta
        data-react-helmet="true"
        name="twitter:description"
        content={metaDescription}
      />
      <meta
        data-react-helmet="true"
        name="twitter:image"
        content={twitterImgUrl}
      />
      <meta data-react-helmet="true" name="twitter:site" content="Jen Health" />

      {/* Hreflang Tags  */}
      {/* <link rel="alternate" hrefLang="en-gb" href={canonicalLink} /> */}
    </Helmet>
  );
};

SeoTags.propTypes = {
  title: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
  canonicalLink: PropTypes.string.isRequired,
  ogImgUrl: PropTypes.string,
  twitterImgUrl: PropTypes.string,
};

export default SeoTags;
