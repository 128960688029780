import { getItem, setItem } from '../utils/cache';

export const isValidJson = str => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const convertToJSON = json => {
  let convertedJson = {};
  if (typeof json === 'object') {
    return json;
  }
  convertedJson = JSON.parse(json);
  return convertedJson;
};

export const cloneJSON = json => {
  const object = typeof json !== 'string' ? JSON.stringify(json) : json;
  const isValid = isValidJson(object);
  if (isValid) {
    return convertToJSON(json);
  } else {
    return {};
  }
};

export const extractJSON = key => {
  const value = getItem(key);
  if (value) {
    return cloneJSON(value);
  } else {
    return value;
  }
};

export const saveJSON = (key, json) => setItem(key, JSON.stringify(json));
