export const QUESTIONNAIRES_SET_IS_LOADING = 'QUESTIONNAIRES_SET_IS_LOADING';

export const DO_GET_QUESTIONNAIRES_REQUEST = 'DO_GET_QUESTIONNAIRES_REQUEST';
export const DO_GET_QUESTIONNAIRES_SUCCESS = 'DO_GET_QUESTIONNAIRES_SUCCESS';
export const DO_GET_QUESTIONNAIRES_FAILURE = 'DO_GET_QUESTIONNAIRES_FAILURE';

export const DO_POST_QUESTIONNAIRES_ANSWERS_REQUEST =
  'DO_POST_QUESTIONNAIRES_ANSWERS_REQUEST';
export const DO_POST_QUESTIONNAIRES_ANSWERS_SUCCESS =
  'DO_POST_QUESTIONNAIRES_ANSWERS_SUCCESS';

export const TOGGLE_POSTING_RESPOSNE = 'TOGGLE_POSTING_RESPOSNE';
export const SET_SHOW_QUESTIONNAIRE_OVERLAY = 'SET_SHOW_QUESTIONNAIRE_OVERLAY';

export const SET_ACTIVE_QUESTION = 'SET_ACTIVE_QUESTION';
export const SET_FILLED_ANSWERS = 'SET_FILLED_ANSWERS';
export const TRIGGER_FILL_ANSWER_FOR_QUESTION =
  'TRIGGER_FILL_ANSWER_FOR_QUESTION';
export const SET_SHOW_SUB_QUESTION_MODAL = 'SET_SHOW_SUB_QUESTION_MODAL';
export const SET_ACTIVITY_INPUT_COUNTS = 'SET_ACTIVITY_INPUT_COUNTS';

export const DO_SUBMIT_QUESTIONNAIRE_ANSWER_REQUEST =
  'DO_SUBMIT_QUESTIONNAIRE_ANSWER_REQUEST';
export const RESET_QUESTIONNAIRE_STATE = 'RESET_QUESTIONNAIRE_STATE';

export const SET_QUESTIONNAIRE_IS_ALREADY_ANSWERED =
  'SET_QUESTIONNAIRE_IS_ALREADY_ANSWERED';
