import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { TEXT_CONSTANT, IMAGE_CONSTANT } from '../../../constants/app';
import { withRouter } from 'react-router-dom';
import { APP_ROUTES } from '../../../route';
import HamburgerMenu from '../../../components/HamburgerMenu';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { getDisplayType } from '../../../utils/helpers';

const Header = props => {
  const handleRedirection = page => {
    if (page === 'jen') {
      props.history.push(APP_ROUTES.ROOT);
    } else if (page === 'podcast') {
      props.history.push(APP_ROUTES.PODCASTS);
    } else {
      props.history.push(APP_ROUTES.BLOGS);
    }
  };
  const displayType = getDisplayType(useWindowWidth());
  const isMobileScreen = displayType === 'mobile';
  return (
    <Navbar
      id="header-section"
      bg=""
      expand="lg"
      className="bg-white jen-navbar"
    >
      <Container fluid>
        <div className="d-flex justify-content-between position-relative new-home-header px-lg-5 px-md-3 align-items-center w-100">
          <div className="d-flex logo-header mb-0">
            {isMobileScreen && (
              <HamburgerMenu
                blogClass={props.blogClass}
                podcastClass={props.podcastClass}
                onFreeTrialClick={() => {
                  props.onClick(
                    TEXT_CONSTANT.GET_STARTED_FOR_FREE,
                    'isSignup',
                    'navigation header',
                  );
                }}
                onLoginClick={() => {
                  props.onClick('LOGIN', 'isLogin', 'navigation header');
                }}
              />
            )}
            <h2
              className="logo-text mb-0"
              onClick={() => handleRedirection('jen')}
            >
              Jen
            </h2>
            {/* <div> */}
            {!isMobileScreen && (
              <>
                <span
                  className={`nav-item ${props.podcastClass}`}
                  onClick={() => handleRedirection('podcast')}
                >
                  <img alt="PodcastIcon" src={IMAGE_CONSTANT.MicMenu} />
                  Podcasts
                </span>
                <span
                  className={`nav-item ${props.blogClass}`}
                  onClick={() => handleRedirection('blog')}
                >
                  <img alt="BlogIcon" src={IMAGE_CONSTANT.NoteMenu} />
                  Blog
                </span>
              </>
            )}
            {/* </div> */}
          </div>
          <div>
            {!isMobileScreen && (
              <button
                className="join"
                onClick={() =>
                  props.onClick(
                    TEXT_CONSTANT.GET_STARTED_FOR_FREE,
                    'isSignup',
                    'navigation header',
                  )
                }
              >
                {props.freeTrialPage
                  ? TEXT_CONSTANT.START_FREE_TRAIL
                  : TEXT_CONSTANT.GET_STARTED_FOR_FREE}
              </button>
            )}
            {!props.freeTrialPage && (
              <button
                className="join"
                onClick={() =>
                  props.onClick('LOGIN', 'isLogin', 'navigation header')
                }
              >
                {TEXT_CONSTANT.LOGIN}
              </button>
            )}
          </div>
        </div>
      </Container>
    </Navbar>
  );
};

Header.propTypes = {
  history: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  freeTrialPage: PropTypes.bool,
  podcastClass: PropTypes.string,
  blogClass: PropTypes.string,
};

export default withRouter(Header);
