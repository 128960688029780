import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Button from '../../../ui-core/Button/Button';
import {
  IMAGE_CONSTANT,
  TEXT_CONSTANT,
  VIDEO_CONSTANT,
} from '../../../constants/app';
import { SEGMENT_EVENTS, trackEvent } from '../../../utils/Segment';

const Display = props => {
  const handleIosClick = () => {
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      button_title: 'App Store Icon',
      page: 'Home Page',
      section_title: 'Improve your mobility, reduce pain & build strength',
    });
  };
  return (
    <section className="banner-section position-relative">
      <Container>
        <Row className="px-lg-5">
          <Col xl={5} className="d-flex align-items-center banner-left">
            <div className="left-div">
              <h1 className="">{TEXT_CONSTANT.DISPLAY_HEADER_TEXT}</h1>
              <p>{TEXT_CONSTANT.DISPLAY_SUB_TEXT}</p>
              <div className="ipad-view">
                <Button
                  onClick={() =>
                    props.onClick(
                      TEXT_CONSTANT.GET_STARTED_FOR_FREE,
                      'isSignup',
                      'Improve your mobility, reduce pain & build strength',
                    )
                  }
                  text={TEXT_CONSTANT.GET_STARTED_FOR_FREE}
                  class="sign-up-button-new d-block"
                  isDisabled={false}
                />
                <Button
                  onClick={() =>
                    props.onClick(
                      'LOGIN',
                      'isLogin',
                      'Improve your mobility, reduce pain & build strength',
                    )
                  }
                  text="LOGIN"
                  class="sign-up-button-new d-block login-in-button"
                  isDisabled={false}
                />
                <div className="d-flex gap-3">
                  <a
                    className="app-store-btn"
                    href="https://apps.apple.com/in/app/jen/id1601368941"
                    target="_blank"
                    onClick={handleIosClick}
                    rel="noopener noreferrer"
                  >
                    <img
                      src={IMAGE_CONSTANT.appStoreIcon}
                      className="img-fluid"
                      alt="app"
                    />
                  </a>

                  <a
                    className="app-store-btn"
                    href="https://play.google.com/store/apps/details?id=com.mobilitymethod.jen"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={IMAGE_CONSTANT.playStoreIcon}
                      className="img-fluid"
                      alt="app"
                    />
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col className="p-0" xl={7}>
            <div className="screen-wrap p-0 m-0">
              <video className="w-100" loop muted autoPlay playsInline>
                <source
                  src={VIDEO_CONSTANT.bannerVideoWebm}
                  type="video/webm"
                />
                <source src={VIDEO_CONSTANT.bannerVideoMp4} type="video/mp4" />
              </video>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

Display.propTypes = {
  onClick: PropTypes.func.isRequired,
  redirectToPaymentPage: PropTypes.func.isRequired,
};

export default Display;
