import React from 'react';
import PropTypes from 'prop-types';
import parser from 'html-react-parser';

import LazyImage from '../../../../ui-core/Image';

import './style.css';

export const SquareFullImages = ({ items = [] }) => {
  return (
    <React.Fragment>
      {items.map((item, index) => (
        <div className="lead-section-row" key={`section-row-${index + 1}`}>
          <div className="lead-text-block">
            <div>{parser(item.content)}</div>
          </div>
          <div>
            <LazyImage
              alt={`pain-${index + 1}`}
              className="w-100 h-100 img-fluid"
              placeholderSrc={item.jpg}
              src={item.webp}
              errorSrc={item.png}
            />
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

SquareFullImages.propTypes = {
  items: PropTypes.array.isRequired,
};
