import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SubscriptionSelector from '../../../components/PaymentModal/SubscriptionSelector';
import {
  TEXT_CONSTANT,
  VIDEO_CONSTANT,
  IMAGE_CONSTANT,
} from '../../../constants/app';
import StartFreeTrail from '../../StartFreeTrial';

const Display = props => {
  return (
    <section className="banner-section position-relative">
      <Container>
        <Row className="px-lg-5">
          <Col xl={6} className="d-flex align-items-center banner-left">
            <div className="left-div">
              <h1 className="">{TEXT_CONSTANT.DISPLAY_HEADER_TEXT}</h1>
              <p>{TEXT_CONSTANT.DISPLAY_SUB_TEXT}</p>
              <div className="ipad-view">
                <SubscriptionSelector />
                <StartFreeTrail {...props} display={true} />
                <div className="d-flex gap-3">
                  <a
                    className="app-store-btn"
                    href="https://apps.apple.com/in/app/jen/id1601368941"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={IMAGE_CONSTANT.appStoreIcon}
                      className="img-fluid"
                      alt="app"
                    />
                  </a>

                  <a
                    className="app-store-btn"
                    href="https://play.google.com/store/apps/details?id=com.mobilitymethod.jen"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={IMAGE_CONSTANT.playStoreIcon}
                      className="img-fluid"
                      alt="app"
                    />
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col className="p-0" xl={6}>
            <div className="screen-wrap p-0 m-0">
              <video className="w-100" loop muted autoPlay playsInline>
                <source
                  src={VIDEO_CONSTANT.bannerVideoWebm}
                  type="video/webm"
                />
                <source src={VIDEO_CONSTANT.bannerVideoMp4} type="video/mp4" />
              </video>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

Display.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Display;
