import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { doOtpResendRequest } from '../../../../store/action/auth';
import Ref from '../../../../ui-core/Ref';
import { SEGMENT_EVENTS, trackEvent } from '../../../../utils/Segment';
import { grecaptcha } from '../../../../services/recaptcha';

const Counter = props => {
  const { postData, setOtp } = props;
  const dispatch = useDispatch();

  const handleOtpResend = e => {
    e.preventDefault();
    grecaptcha.execute(token => {
      trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
        // eslint-disable-line
        button_title: 'Resend Verification Code',
        page: props.page || 'Home Page',
        section_title: 'Enter Code Modal',
      });
      dispatch(
        doOtpResendRequest({
          data: { ...postData, token },
          page: props.page,
          callback: () => {
            setCounter(30);
            setOtp(new Array(4).fill(''));
          },
        }),
      );
    });
  };
  const [counter, setCounter] = useState(30);

  useEffect(() => {
    let timeout = null;
    if (+counter > 0) {
      timeout = setTimeout(
        () => setCounter(`${counter - 1}`.padStart(2, '0')),
        1000,
      );
    } else {
      setCounter();
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  });

  return (
    <div className="text-center counter">
      {' '}
      {counter ? (
        <div className="">
          <p>
            Resend Verification Code in <span>00:{counter}</span>
            {/* style={{ color: '#13BFB8' }} */}
          </p>
        </div>
      ) : (
        <Ref
          class="Resend-otp"
          text="Resend Verification Code"
          onClick={handleOtpResend}
        />
      )}{' '}
    </div>
  );
};

Counter.propTypes = {
  page: PropTypes.string,
  postData: PropTypes.shape({
    mobile: PropTypes.string,
    country_code: PropTypes.string,
    otp: PropTypes.string,
  }),
  otp: PropTypes.array,
  setOtp: PropTypes.func,
};

export default Counter;
