import React from 'react';
import { IMAGE_CONSTANT, TEXT_CONSTANT } from '../../constants/app';
import SubscriptionSelector from './SubscriptionSelector';

const SubscriptionPlans = () => {
  const subscriptionPoints = [
    TEXT_CONSTANT.SUBSCRIPTION_POINT_1,
    TEXT_CONSTANT.SUBSCRIPTION_POINT_2,
    TEXT_CONSTANT.SUBSCRIPTION_POINT_3,
  ];
  return (
    <>
      <div className="subscription-block__">
        <h3 className="">{TEXT_CONSTANT.SUBSCRIPTION_HEADER_TEXT}</h3>
        <SubscriptionSelector />
      </div>
      <div className="maxWidth526">
        {subscriptionPoints.map((point, index) => (
          <div className="d-flex mb-2" key={`subscription-point-${index}`}>
            <div>
              <img
                className="me-3 mb-auto"
                src={IMAGE_CONSTANT.checkboxIcon}
                alt={'check'}
              />
            </div>
            <p className="mb-0 text-start color-black-russian dm-sans">
              {point}
            </p>
          </div>
        ))}
      </div>
    </>
  );
};

export default SubscriptionPlans;
