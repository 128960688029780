import {
  identifyUser,
  SEGMENT_EVENTS,
  trackEvent,
} from '../../../utils/Segment';
import {
  DO_SIGNUP_REQUEST,
  DO_SIGNUP_SUCCESS,
  DO_SIGNUP_FAILED,
  DO_LOGIN_REQUEST,
  DO_LOGIN_SUCCESS,
  DO_LOGIN_FAILED,
  DO_LOGOUT_REQUEST,
  DO_SURVEY_SUCCESS,
  DO_SURVEY_REQUEST,
  DO_WAITLIST_SURVEY_SUCCESS,
  DO_WAITLIST_SURVEY_REQUEST,
  VERIFY_OTP_REQUESTED,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  DO_OTP_RESEND_REQUEST,
  DO_OTP_RESEND_SUCCESS,
  DO_APPLE_SIGNIN_REQUEST,
  DO_APPLE_SIGNIN_SUCCESS,
} from '../../constant/auth';
import { cloneJSON } from '../../../services/jsonUtil';

// import Cohere from 'cohere-js';

/**
 * Request tournament banner details
 * */
export const doSignupRequest = payload => {
  return {
    type: DO_SIGNUP_REQUEST,
    payload,
  };
};

/**
 * Get tournament banner details success
 * @param {Object} payload
 * */
export const doSignupSuccess = payload => {
  const { data, page } = payload;
  identifyUser(data?.id, {
    phone_number_verified: data?.attributes?.verified,
    country_code: data?.attributes?.country_code,
    phone: data?.attributes?.mobile,
    hasAccess: data?.attributes?.has_access,
  });
  trackEvent(SEGMENT_EVENTS.REQUESTED_VERIFICATION_CODE, {
    // eslint-disable-line
    page: page || 'Home Page',
    action: 'Signup',
    user_id: `${data?.id}`,
    country_code: `${data?.attributes?.country_code}`,
    phone: `${data?.attributes?.mobile}`,
    // attributes: `${data?.attributes}`,
    section_title: 'Signup Modal',
  });
  return {
    type: DO_SIGNUP_SUCCESS,
    payload: data,
  };
};

export const doSignupFailed = payload => {
  const { error, page } = payload;
  const requestData = cloneJSON(error?.config?.data);
  trackEvent(SEGMENT_EVENTS.ERROR_SENDING_VERIFICATION_CODE, {
    // eslint-disable-line
    page: page || 'Home Page',
    error_status_text: error?.response?.statusText,
    error_code: error?.response?.status,
    error_message:
      error?.response?.data?.response?.errors[0] || 'Something Went Wrong',
    country_code: requestData?.country_code,
    phone: requestData?.mobile,
    section_title: 'Signup Modal',
  });
  return {
    type: DO_SIGNUP_FAILED,
    payload,
  };
};

export const doLoginRequest = payload => {
  return {
    type: DO_LOGIN_REQUEST,
    payload,
  };
};

export const doLoginSuccess = payload => {
  const { data, page } = payload;
  identifyUser(data?.id, {
    phone_number_verified: data?.attributes?.verified,
    country_code: data?.attributes?.country_code,
    phone: data?.attributes?.mobile,
    hasAccess: data?.attributes?.has_access,
  });
  trackEvent(SEGMENT_EVENTS.REQUESTED_VERIFICATION_CODE, {
    // eslint-disable-line
    phone: `${data?.attributes?.mobile}`,
    country_code: `${data?.attributes?.country_code}`,
    page: page || 'Home Page',
    // action: 'Login',
    // user_id: `${payload?.id}`,
    // section_title: 'Login Modal',
  });
  return {
    type: DO_LOGIN_SUCCESS,
    payload: data,
  };
};

export const doLoginFailed = payload => {
  const { error, page } = payload;
  const requestData = cloneJSON(error?.config?.data);
  trackEvent(SEGMENT_EVENTS.ERROR_SENDING_VERIFICATION_CODE, {
    // eslint-disable-line
    page: page || 'Home Page',
    error_code: error?.response?.status,
    // error_status_text: error?.response?.statusText,
    error_message:
      error?.response?.data?.response?.errors[0] || 'Something Went Wrong',
    phone: requestData?.mobile,
    country_code: requestData?.country_code,
    // section_title: 'Login Modal',
  });
  return {
    type: DO_LOGIN_FAILED,
    payload,
  };
};

export const doLogoutRequest = () => {
  return {
    type: DO_LOGOUT_REQUEST,
  };
};

export const doOtpRequest = payload => {
  trackEvent(SEGMENT_EVENTS.VERIFY_CODE, {
    // eslint-disable-line
    phone: payload?.data?.mobile,
    country_code: payload?.data?.country_code,
    verification_code: payload?.data?.otp,
    page: payload.page || 'Home Page',
    // section_title: 'Enter Code Modal',
  });
  return {
    type: VERIFY_OTP_REQUESTED,
    payload,
  };
};

export const verifyOtpSuccess = payload => {
  const mobileNumber =
    payload?.attributes?.country_code + payload?.attributes?.mobile;
  window?.Cohere?.identify(`${payload?.id}`, {
    displayName: payload?.id,
    mobileNumber: mobileNumber,
  });

  identifyUser(payload?.id, {
    phone_number_verified: payload?.attributes?.verified,
    country_code: payload?.attributes?.country_code,
    phone: payload?.attributes?.mobile,
  });
  return {
    type: VERIFY_OTP_SUCCESS,
    payload,
  };
};

export const verifyOtpFailed = payload => {
  const { error, page } = payload;
  const requestData = cloneJSON(error?.config?.data);
  trackEvent(SEGMENT_EVENTS.ERROR_VERIFYING_CODE, {
    // eslint-disable-line
    page: page || 'Home Page',
    error_status_text: error?.response?.statusText,
    error_code: error?.response?.status,
    error_message:
      error?.response?.data?.response?.errors[0] || 'Something Went Wrong',
    phone: requestData?.mobile,
    country_code: requestData?.country_code,
    verification_code_entered: requestData?.otp,
    // section_title: 'Send Verification Code Modal',
  });
  return {
    type: VERIFY_OTP_FAILED,
    payload,
  };
};

export const doOtpResendRequest = payload => {
  trackEvent(SEGMENT_EVENTS.RESEND_VERIFICATION_CODE, {
    // eslint-disable-line
    phone: `${payload?.data?.mobile}`,
    country_code: `${payload?.data?.country_code}`,
    page: payload.page || 'Home Page',
  });
  return {
    type: DO_OTP_RESEND_REQUEST,
    payload,
  };
};

export const doOtpResendSuccess = payload => {
  return {
    type: DO_OTP_RESEND_SUCCESS,
    payload,
  };
};

export const doSurveyRequest = payload => {
  trackEvent(SEGMENT_EVENTS.WAITLIST_SURVEY_SUBMITTED, {
    // eslint-disable-line
    page: 'Survey Page',
    action: 'Submit',
    section_title: 'Survey Page',
    email: payload?.data?.email,
    name: payload?.data?.name,
    dob: payload?.data?.dob,
    what_brought_you_to_jen_platform: payload?.data?.how_jen,
    exercise_frequency: payload?.data?.exercise_frequency,
    exercise_plans_interested: payload?.data?.interested_plans,
    time_committed_per_day: payload?.data?.time_per_day,
    devices_use: payload?.data?.devices_used,
  });
  identifyUser(payload?.user?.id, {
    // eslint-disable-line
    email: payload?.data?.email,
    name: payload?.data?.name,
    dob: payload?.data?.dob,
    what_brought_you_to_jen_platform: payload?.data?.how_jen,
    exercise_frequency: payload?.data?.exercise_frequency,
    exercise_plans_interested: payload?.data?.interested_plans,
    time_committed_per_day: payload?.data?.time_per_day,
    devices_use: payload?.data?.devices_used,
  });
  return {
    type: DO_SURVEY_REQUEST,
    payload,
  };
};

export const doSurveySuccess = payload => {
  return {
    type: DO_SURVEY_SUCCESS,
    payload,
  };
};

export const doWaitlistSurveyRequest = payload => {
  return {
    type: DO_WAITLIST_SURVEY_REQUEST,
    payload,
  };
};

export const doWaitlistSurveySuccess = payload => ({
  type: DO_WAITLIST_SURVEY_SUCCESS,
  payload,
});

export const doAppleSignupRequest = payload => ({
  type: DO_APPLE_SIGNIN_REQUEST,
  payload,
});

export const doAppleSignupSuccess = payload => ({
  type: DO_APPLE_SIGNIN_SUCCESS,
  payload,
});
