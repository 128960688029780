import React, { useRef } from 'react';
import PropsTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFolderModal } from '../../store/action/folders';
import ModalBox from '../../ui-core/Modal';
import {
  CreateFolder,
  DeleteFolder,
  Footer,
  EditFolder,
  SelectFolder,
} from './sections';
import '../FolderModal/style.css';

const FolderModal = () => {
  const dispatch = useDispatch();
  const fromSelectFolderModal = useRef(false);
  const { selectedModal, showFolderModal: show } = useSelector(
    state => state.folder,
  );
  const selectLast = useRef(false);

  const handleClose = () => {
    if (fromSelectFolderModal.current) fromSelectFolderModal.current = false;
    if (selectLast.current) selectLast.current = false;
    dispatch(
      toggleFolderModal({
        showFolderModal: false,
        selectedModal: null,
        folderModalOptions: {},
      }),
    );
  };

  const handleCreateFolder = () => {
    fromSelectFolderModal.current = true;
    dispatch(
      toggleFolderModal({
        showFolderModal: true,
        selectedModal: 'create',
      }),
    );
  };

  const renderModalBody = () => {
    switch (selectedModal) {
      case 'select':
        return (
          <SelectFolder
            handleCreateFolder={handleCreateFolder}
            selectLast={selectLast.current}
          />
        );
      case 'create':
      case 'create-folder':
        return <CreateFolder handleGoBack={handleGoBack} />;
      case 'edit':
        return <EditFolder handleClose={handleClose} />;
      case 'delete':
      case 'remove-video':
        return <DeleteFolder selectedModal={selectedModal} />;
      default:
        return null;
    }
  };

  const head = (() => {
    switch (selectedModal) {
      case 'select':
        return (
          <div className="select-folder-wrapper d-flex justify-content-center">
            <span className="select-header">Select Folder</span>
            {/* {isDesktopScreen && (
              <Ref
                onClick={handleCreateFolder}
                class="new-folder ms-5"
                text="Create New Folder"
              />
            )} */}
          </div>
        );
      case 'create':
      case 'create-folder':
        return 'Create Folder';
      case 'edit':
        return 'Edit Name';
      case 'remove-video':
        return 'Remove Video';
      case 'delete':
        return 'Delete Folder';
      default:
        return '';
    }
  })();

  const handleGoBack = () => {
    switch (selectedModal) {
      case 'create':
      case 'create-folder':
        if (fromSelectFolderModal.current) {
          selectLast.current = true;
          dispatch(
            toggleFolderModal({
              selectedModal: 'select',
            }),
          );
        } else {
          dispatch(
            toggleFolderModal({
              showFolderModal: false,
              selectedModal: null,
              folderModalOptions: {},
            }),
          );
        }
        break;
      case 'edit':
        dispatch(
          toggleFolderModal({
            showFolderModal: false,
            selectedModal: null,
            folderModalOptions: {},
          }),
        );
        break;
      default:
        break;
    }
  };

  return (
    <ModalBox
      show={show}
      handleClose={handleClose}
      dialogClassName={'create-folder-model 333'}
      closeButton={!(selectedModal === 'delete')}
      head={head}
      handleGoBack={handleGoBack}
      footer={
        <Footer selectedModal={selectedModal} handleClose={handleClose} />
      }
      backButton={selectedModal === 'create'}
    >
      {renderModalBody()}
    </ModalBox>
  );
};

FolderModal.propTypes = {
  closeButton: PropsTypes.bool,
};

export default FolderModal;
