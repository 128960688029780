import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { Image, Overlay, Popover, PopoverContent } from 'react-bootstrap';
import { SEGMENT_EVENTS, trackSubmittedEvent } from '../../../utils/Segment';

import { encodeURLParams } from '../../../utils/url';
import { APP_ROUTES, getRoute } from '../../../route';
import { IMAGE_CONSTANT } from '../../../constants/app';
import { toggleFolderModal } from '../../../store/action/folders';

import './style.css';

const FolderBlocks = props => {
  const { history } = props;
  const imageClickRef = useRef(false);
  const [show, setShow] = useState(false);
  const targetRef = useRef(null);
  const dispatch = useDispatch();

  const handleBlockClick = (folderId, folderName) => {
    trackSubmittedEvent(SEGMENT_EVENTS.FOLDER_BLOCK_CLICKED, {
      page: 'Folder Page',
      section_title: 'Folder blocks section',
      folderId: folderId,
      folder_title: folderName,
    });
    if (!imageClickRef.current) {
      detailedFoldersPage(folderId, folderName);
    }
  };

  const handleOpen = type => {
    dispatch(
      toggleFolderModal({
        showFolderModal: true,
        selectedModal: type,
        folderModalOptions: { folderId: props.id, folderName: props.text },
      }),
    );
    setShow(false);
  };

  const detailedFoldersPage = (folderId, folderName) => {
    const params = {
      name: folderName,
    };
    const url = getRoute(APP_ROUTES.DETAILED_FOLDER, {
      pathParam: { id: folderId },
      searchParam: {
        params: encodeURLParams(params),
      },
    });
    history.push(url);
  };

  const handleImageClick = () => {
    if (!show) {
      imageClickRef.current = true;
      setShow(true);
    }
  };

  const onHide = () => {
    imageClickRef.current = false;
    setShow(false);
  };

  return (
    <div
      className="folder-block cursor-pointer d-flex"
      onClick={() => handleBlockClick(props.id, props.text)}
    >
      <div className="folder-side-strip"></div>
      <div className="d-flex justify-content-between align-items-center content  w-100">
        <div className="info-block">
          <h3>{props.text} </h3>
          <p> {props.video} </p>
        </div>
        <div className="dots-div d-flex justify-content-between align-items-center">
          <Image
            id={`three-dots-menu-${props}`}
            src={IMAGE_CONSTANT.threeDotIcon}
            onClick={handleImageClick}
            alt="three-dots"
            className="p-3"
            ref={targetRef}
          />
        </div>
        <Overlay
          onHide={onHide}
          placement="bottom"
          rootClose
          transition={false}
          show={show}
          target={targetRef.current}
        >
          <Popover bsPrefix="folders-popover">
            <PopoverContent>
              <div
                className="d-flex justify-content-start mb-2 cursor-pointer"
                onClick={() => handleOpen('edit')}
              >
                <Image src={IMAGE_CONSTANT.editIcon} alt="edit-icon" />
                <span>Edit Name</span>
              </div>
              <div
                className="d-flex justify-content-start mb-2 cursor-pointer"
                onClick={() => handleOpen('delete')}
              >
                <Image src={IMAGE_CONSTANT.trashIcon} alt="edit-icon" />
                <span style={{ color: '#FF0000' }}>Delete Folder</span>
              </div>
            </PopoverContent>
          </Popover>
        </Overlay>
      </div>
    </div>
  );
};

FolderBlocks.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  video: PropTypes.string,
  history: PropTypes.object,
};

export default withRouter(FolderBlocks);
