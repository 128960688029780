import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  DO_GET_ALL_CHALLENGES_COURSES_REQUEST,
  DO_GET_ALL_COURSES_REQUEST,
  DO_GET_CHALLENGES_LIST_REQUEST,
  DO_GET_CHALLENGE_DETAILS_REQUEST,
  DO_GET_COURSE_DETAILS_REQUEST,
  DO_GET_PUBLIC_PLANS_REQUEST,
  DO_FETCH_PUBLIC_COURSES_REQUEST,
} from '../../constant/challengesAndCourses';
import { getChallengeDetail, getChallengeList } from '../../../api/challenges';
import {
  doGetAllCoursesSuccess,
  doGetChallengeDetailFailure,
  doGetChallengeDetailSuccess,
  doGetChallengesListSuccess,
  doGetCourseDetailFailure,
  doGetCourseDetailSuccess,
  doGetPublicPlansSuccess,
  setIsLoading,
  doGetPublicCoursesSuccess,
} from '../../action/challengesAndCourses';
import {
  getAllCourses,
  getCourseDetail,
  getAllCoursesPublic,
} from '../../../api/courses';
import { createNotification } from '../../../services/Notification';
import {
  fetchProductsDetailRequest,
  fetchProductsRequest,
} from '../../../api/user';
import { publicPlansGetRequest } from '../../../api/plans';
import { LOCAL_STORAGE_CONSTANT } from '../../../constants/app';
import { errorHandler } from '../../../utils/helpers';
import { doFetchProductsSuccess, setSelectedPlan } from '../../action/products';
import { extractJSON, saveJSON } from '../../../services/jsonUtil';

async function fetchProductDetail(product_id) {
  try {
    // const queryParamsData = {};
    // const userRef = getItem(LOCAL_STORAGE_CONSTANT.USER_REF_ATTRIBUTE);
    // const utmSource = getItem(LOCAL_STORAGE_CONSTANT.UTM_SOURCE_ATTRIBUTE);
    // const utmMedium = getItem(LOCAL_STORAGE_CONSTANT.UTM_MEDIUM_ATTRIBUTE);
    // const utmCampaign = getItem(LOCAL_STORAGE_CONSTANT.UTM_CAMPAIGN_ATTRIBUTE);
    // const adId = getItem(LOCAL_STORAGE_CONSTANT.UTM_AD_ID);
    // const pixelId = getItem(LOCAL_STORAGE_CONSTANT.UTM_PIXEL_ID);
    // const utmContent = getItem(LOCAL_STORAGE_CONSTANT.UTM_CONTENT_ATTRIBUTE);
    // const fbClid = getItem(LOCAL_STORAGE_CONSTANT.FB_CLID);
    // if (userRef) {
    //   queryParamsData.ref = userRef;
    //   // removeItem(LOCAL_STORAGE_CONSTANT.USER_REF_ATTRIBUTE);
    // }
    // if (utmSource) {
    //   queryParamsData.utm_source = utmSource;
    //   // removeItem(LOCAL_STORAGE_CONSTANT.UTM_SOURCE_ATTRIBUTE);
    // }
    // if (utmMedium) {
    //   queryParamsData.utm_medium = utmMedium;
    //   // removeItem(LOCAL_STORAGE_CONSTANT.UTM_MEDIUM_ATTRIBUTE);
    // }
    // if (utmCampaign) {
    //   queryParamsData.utm_campaign = utmCampaign;
    //   // removeItem(LOCAL_STORAGE_CONSTANT.UTM_CAMPAIGN_ATTRIBUTE);
    // }
    // if (utmContent) {
    //   queryParamsData.utm_content = utmContent;
    //   // removeItem(LOCAL_STORAGE_CONSTANT.UTM_CONTENT_ATTRIBUTE);
    // }
    // if (fbClid) {
    //   queryParamsData.fbclid = fbClid;
    //   // removeItem(LOCAL_STORAGE_CONSTANT.FB_CLID);
    // }
    // if (adId) {
    //   queryParamsData.ad_id = adId;
    //   // removeItem(LOCAL_STORAGE_CONSTANT.UTM_AD_ID);
    // }
    // if (pixelId) {
    //   queryParamsData.pixel = pixelId;
    //   // removeItem(LOCAL_STORAGE_CONSTANT.UTM_PIXEL_ID);
    // }
    const response = await fetchProductsDetailRequest(product_id);
    return response?.data?.response?.data || {};
  } catch (error) {
    console.log('ERROR:', { error });
    return {};
  }
}

async function fetchChallenges() {
  try {
    const response = await getChallengeList();
    const challenges = (response?.data?.response?.data || []).filter(
      item => !!item?.attributes?.product_id,
    );
    const finalOutput = await Promise.all(
      challenges.map(async challenge => {
        const productDetail = await fetchProductDetail(
          challenge.attributes.product_id,
        );
        return {
          ...challenge,
          attributes: {
            ...(challenge?.attributes || {}),
            discount: productDetail?.attributes?.discount,
          },
        };
      }),
    );
    return finalOutput;
  } catch (error) {
    createNotification(errorHandler(error));
    return [];
  }
}

async function fetchCourses() {
  try {
    const response = await getAllCourses();
    const courses = (response?.data?.response?.data || []).filter(
      item => !!item?.attributes?.product_id,
    );
    const finalOutput = await Promise.all(
      courses.map(async course => {
        const productDetail = await fetchProductDetail(
          course.attributes.product_id,
        );
        return {
          ...course,
          attributes: {
            ...(course?.attributes || {}),
            discount: productDetail?.attributes?.discount,
          },
        };
      }),
    );
    return finalOutput;
  } catch (error) {
    createNotification(errorHandler(error));
    return [];
  }
}

export async function getPublicPlans(selectedProduct) {
  const newTimestamp = new Date().getTime();
  const sessionTime = 60 * 1000; // 1 minute
  try {
    const cachedData = extractJSON(LOCAL_STORAGE_CONSTANT.PUBLIC_PLANS_CACHE);
    const { data, timestamp } = cachedData || {};
    const isTimestampExpired = newTimestamp - timestamp > sessionTime;

    if (data?.length && !isTimestampExpired) {
      return data;
    } else {
      const response = await publicPlansGetRequest();
      const plans = response?.data?.response?.data || [];
      const finalOutput = await Promise.all(
        plans.map(async plan => {
          const productDetail =
            plan?.attributes?.product_id &&
            +plan?.attributes?.product_id !== +selectedProduct?.id
              ? await fetchProductDetail(plan?.attributes?.product_id)
              : selectedProduct;
          return {
            ...plan,
            attributes: {
              ...(plan?.attributes || {}),
              discount: productDetail?.attributes?.discount,
              amount: productDetail?.attributes?.amount_in_cents,
              currency: productDetail?.attributes?.currency,
            },
          };
        }),
      );
      saveJSON(LOCAL_STORAGE_CONSTANT.PUBLIC_PLANS_CACHE, {
        data: finalOutput,
        timestamp: newTimestamp,
      });
      return finalOutput;
    }
  } catch (error) {
    createNotification(errorHandler(error));
    return [];
  }
}

function* getChalleges({ payload }) {
  yield put(setIsLoading(true));
  const response = yield call(fetchChallenges);
  yield put(doGetChallengesListSuccess(response));
  payload?.callback && payload?.callback(response);
  yield put(setIsLoading(false));
}

function* getChallengeDetails({ payload }) {
  const { id, selectedDay, callback, errorCallback } = payload || {};
  yield put(setIsLoading(true));
  try {
    const options = {
      day: selectedDay,
    };
    const response = yield call(getChallengeDetail, id, options);
    const data = response?.data?.response?.data || {};
    yield put(doGetChallengeDetailSuccess(data));
    if (callback) callback(data);
  } catch (error) {
    createNotification(errorHandler(error));
    yield put(doGetChallengeDetailFailure({}));
    if (callback) callback();
    if (errorCallback) errorCallback();
  }
  yield put(setIsLoading(false));
}

async function fetchAllChallengesAndCourses() {
  return await Promise.all([fetchChallenges(), fetchCourses()]);
}

function* getChallegesAndCourses({ payload }) {
  try {
    const [challenges, courses] = yield call(fetchAllChallengesAndCourses);
    yield put(doGetChallengesListSuccess(challenges));
    yield put(doGetAllCoursesSuccess(courses));
    yield put(setIsLoading(false));
  } catch (e) {
    yield put(setIsLoading(false));
  }
  payload?.callback && payload?.callback();
}

function* getAllCoursesRequest({ payload }) {
  yield put(setIsLoading(true));
  const response = yield call(fetchCourses);
  yield put(doGetAllCoursesSuccess(response));
  payload?.callback && payload?.callback(response);
  yield put(setIsLoading(false));
}

function* getCourseDetailsRequest({ payload }) {
  const { id, selectedDay, callback, errorCallback } = payload || {};
  yield put(setIsLoading(true));
  try {
    const options = {
      day: selectedDay,
    };
    const response = yield call(getCourseDetail, id, options);
    const data = response?.data?.response?.data || {};
    yield put(doGetCourseDetailSuccess(data));
    if (callback) callback(data);
  } catch (error) {
    createNotification(errorHandler(error));
    yield put(doGetCourseDetailFailure({}));
    if (callback) callback();
    if (errorCallback) errorCallback();
  }
  yield put(setIsLoading(false));
}

function* getPublicPlansRequest({ payload }) {
  const { interval, callback } = payload;
  const response = yield call(fetchProductsRequest);
  const products = (response?.data?.response?.data || []).reverse();
  yield put(doFetchProductsSuccess(products));
  const selectedProduct =
    products?.find(product => product?.attributes?.interval === interval) ||
    products?.[0];
  yield put(setSelectedPlan(selectedProduct));
  const plans = yield call(getPublicPlans, selectedProduct);
  yield put(doGetPublicPlansSuccess(plans));
  callback && callback(plans);
}

function* getAllPublicCourses() {
  yield put(setIsLoading(true));
  try {
    const response = yield call(getAllCoursesPublic);
    const data = response?.data?.response?.data || {};
    yield put(doGetPublicCoursesSuccess(data));
  } catch (error) {
    // createNotification(errorHandler(error));
  }
  yield put(setIsLoading(false));
}

export default function* challengesAndCourses() {
  yield all([
    takeLatest(DO_GET_CHALLENGES_LIST_REQUEST, getChalleges),
    takeLatest(DO_GET_CHALLENGE_DETAILS_REQUEST, getChallengeDetails),
    takeLatest(DO_GET_ALL_CHALLENGES_COURSES_REQUEST, getChallegesAndCourses),
    takeLatest(DO_GET_ALL_COURSES_REQUEST, getAllCoursesRequest),
    takeLatest(DO_GET_COURSE_DETAILS_REQUEST, getCourseDetailsRequest),
    takeLatest(DO_GET_PUBLIC_PLANS_REQUEST, getPublicPlansRequest),
    takeLatest(DO_FETCH_PUBLIC_COURSES_REQUEST, getAllPublicCourses),
  ]);
}
