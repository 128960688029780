/*
 *
 * auth constants
 *
 * */

export const DO_SIGNUP_REQUEST = 'DO_SIGNUP_REQUEST';
export const DO_SIGNUP_SUCCESS = 'DO_SIGNUP_SUCCESS';
export const DO_SIGNUP_FAILED = 'DO_SIGNUP_FAILED';

export const DO_LOGIN_REQUEST = 'DO_LOGIN_REQUEST';
export const DO_LOGIN_SUCCESS = 'DO_LOGIN_SUCCESS';
export const DO_LOGIN_FAILED = 'DO_LOGIN_FAILED';

export const VERIFY_OTP_REQUESTED = 'VERIFY_OTP_REQUESTED';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILED = 'VERIFY_OTP_FAILED';

export const DO_OTP_RESEND_REQUEST = 'DO_OTP_RESEND_REQUEST';
export const DO_OTP_RESEND_SUCCESS = 'DO_OTP_RESEND_SUCCESS';

export const DO_SURVEY_REQUEST = 'DO_SURVEY_REQUEST';
export const DO_SURVEY_SUCCESS = 'DO_SURVEY_SUCCESS';

export const DO_WAITLIST_SURVEY_REQUEST = 'DO_WAITLIST_SURVEY_REQUEST';
export const DO_WAITLIST_SURVEY_SUCCESS = 'DO_WAITLIST_SURVEY_SUCCESS';

export const DO_LOGOUT_REQUEST = 'DO_LOGOUT_REQUEST';

export const DO_APPLE_SIGNIN_REQUEST = 'DO_APPLE_SIGNIN_REQUEST';
export const DO_APPLE_SIGNIN_SUCCESS = 'DO_APPLE_SIGNIN_SUCCESS';
