import { axiosInstance, overrideHeader } from './index';

/**
 * API request
 * */

export const playbackUpdateRequest = async data => {
  overrideHeader();
  return axiosInstance.post('/playbacks', data);
};

export const playbackHistoryRequest = async (count = 1, type) => {
  overrideHeader();
  let endPoint = `/playbacks/history?count=${count}`;
  if (type) endPoint += `type=${type}`;
  return axiosInstance.get(endPoint);
};

export const playbackHistoryAllDataRequest = async () => {
  overrideHeader();
  const endPoint = `/playbacks/history`;
  return axiosInstance.get(endPoint);
};
