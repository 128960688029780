import { useEffect, useState } from 'react';

const defaultOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0,
};

const useInView = (target, options = defaultOptions, disconnect = true) => {
  const [inView, setInView] = useState(!('IntersectionObserver' in window));
  useEffect(() => {
    const observer = new IntersectionObserver(entry => {
      setInView(prev => {
        const newValue = disconnect
          ? prev || entry[0].isIntersecting
          : entry[0].isIntersecting;
        if (newValue && disconnect) {
          observer.disconnect();
        }
        return newValue;
      });
    }, options);
    if (target) {
      observer.observe(target);
    }
  }, [target]);

  return inView;
};

export default useInView;
