import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import { IMAGE_CONSTANT } from '../../constants/app';

const FiltersView = ({ selectedFilters, toggleFiltersModel }) => {
  // const filtersCount = React.useMemo(
  //   () => selectedFilters.length,
  //   [selectedFilters],
  // );
  const renderSelectedFilters = () => {
    const selectedFiltersOutput = selectedFilters.map(item => (
      <span
        key={`filter-${item.id}`}
        id={`filter-${item.name.toLowerCase()}`}
        className="result d-block"
      >
        {item.name}
      </span>
    ));
    return (
      <div className="d-flex options-wrapper">{selectedFiltersOutput}</div>
    );
  };

  // function hideOverflowingFilters() {
  //   const parentElement = document.getElementById('filters-wrap');
  //   selectedFilters.forEach(item => {
  //     const childElement = document.getElementById(
  //       `filter-${item.name.toLowerCase()}`,
  //     );
  //     if (childElement && parentElement) {
  //       const { x: childX, width: childWidth } =
  //         childElement.getBoundingClientRect();
  //       const { x: parentX, width: parentWidth } =
  //         parentElement.getBoundingClientRect();
  //       const childEnd = childWidth + childX;
  //       const parentEnd = parentWidth + parentX;
  //       if (childEnd > parentEnd) {
  //         childElement.classList.add('d-none');
  //       } else {
  //         childElement.classList.remove('d-none');
  //       }
  //     }
  //   });
  // }

  // useEffect(() => {
  //   hideOverflowingFilters();
  // }, [selectedFilters]);

  return (
    <div
      id="filters-wrap"
      className={'d-flex align-items-center filters-result-container'}
    >
      {/* <Button
        onClick={toggleFiltersModel}
        className="filter-notification p-0 bg-transparent border-0 position-relative me-2"
      >
        <span className="d-inline-block me-2">
          <img src={IMAGE_CONSTANT.funnelIcon} alt="" className="img-fluid" />
        </span>
        <Badge>{filtersCount}</Badge>
      </Button> */}
      <Button className={'filter-btn bg-white'} onClick={toggleFiltersModel}>
        <span className="d-inline-block me-2">
          <img src={IMAGE_CONSTANT.filterIcon} alt="" className="img-fluid" />
        </span>
        {/* {selectedFilters.length ? 'Body Parts' : 'Filter by Body Parts'} */}
        Body Parts
      </Button>
      {selectedFilters.length ? <div className="mx-3 sap"></div> : null}
      <div className="d-flex align-items-center filters-wrap">
        {/* <span className="d-block fw-bold">Showing results for:</span> */}
        {renderSelectedFilters()}
      </div>
    </div>
  );
};

FiltersView.propTypes = {
  selectedFilters: PropTypes.array,
  toggleFiltersModel: PropTypes.func,
};

export default FiltersView;
