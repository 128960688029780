import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { saveFiltersRequest } from '../../../api/filter';
import { onboardingQuestionsRequest } from '../../../api/fitness';
import { fetchPaymentDetailsRequest } from '../../../api/payment';
import {
  playbackHistoryAllDataRequest,
  playbackHistoryRequest,
} from '../../../api/playback';
import { LOCAL_STORAGE_CONSTANT } from '../../../constants/app';
import { createNotification } from '../../../services/Notification';
import { extractJSON } from '../../../services/jsonUtil';
import { removeItem, setItem } from '../../../utils/cache';
import { errorHandler, logOutput } from '../../../utils/helpers';
import { doGetPublicPlansSuccess } from '../../action/challengesAndCourses';
import {
  doFetchOnboardingQuestionFailure,
  doFetchOnboardingQuestionSuccess,
  setLoadingState,
} from '../../action/common';
import { setPaymentDetail } from '../../action/user';
import {
  CHECK_FOR_VALUES_CHANGE,
  FETCH_ONBOARDING_QUESTIONS_REQUEST,
  GET_PLAYBACK_HISTORY,
  SET_SELECTED_FITLERS,
} from '../../constant/common';
import { getPublicPlans } from '../challengesAndCourses';
import { doTherapyPlansReset } from '../../action/plan';

function* saveFilters({ payload }) {
  const { selectedFilters, skipSave, callback } = payload || {};
  if (skipSave) {
    return;
  }
  const bodyParts = (selectedFilters ?? []).map(item => item.id);
  try {
    const response = yield call(saveFiltersRequest, bodyParts);
    // console.log(response);
    const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
    if (user?.attributes?.filters) {
      user.attributes.filters.body_parts = bodyParts;
      setItem(LOCAL_STORAGE_CONSTANT.USER, JSON.stringify(user));
      removeItem(LOCAL_STORAGE_CONSTANT.PUBLIC_PLANS_CACHE);
      yield put(doTherapyPlansReset());
    }
    const newRecommendations =
      response?.data?.response?.data?.new_recommendations;
    if (callback) callback(newRecommendations);
  } catch (error) {
    createNotification(errorHandler(error));
  }
}

function* requestPlaybackHistory({ payload }) {
  const { callback, getAllData } = payload || {};
  try {
    const apiCall = getAllData
      ? playbackHistoryAllDataRequest
      : playbackHistoryRequest;
    const result = yield call(apiCall);
    const data = result?.data?.response?.data ?? [];
    const playbackHistory = data[0];
    const { plans } = yield select(state => state.plan);
    const { publicPlans } = yield select(state => state.challengesAndCourses);
    let plansData = plans.data || publicPlans || [];
    if (
      playbackHistory?.attributes?.source?.schedule_id &&
      !publicPlans?.length
    ) {
      const response = yield call(getPublicPlans);
      if (response?.data?.response?.data) {
        plansData = response.data.response.data;
        yield put(doGetPublicPlansSuccess(plansData));
      }
    }
    yield put(setLoadingState(false));
    if (callback) {
      const response = yield call(fetchPaymentDetailsRequest);
      yield put(setPaymentDetail(response.data.response.data));
      callback({
        playbackHistory,
        plans: plansData,
        payment: response.data.response.data,
        playbackHistoryList: data,
      });
    }
  } catch (error) {
    if (callback) callback();
    yield put(setLoadingState(false));
    logOutput('ERROR:', { error });
  }
}

function* doCheckForChange({ payload }) {
  const { callback } = payload || {};
  const { valuesChanged } = yield select(state => state.common);
  if (callback) callback(valuesChanged);
}

function* fetchOnboardingQuestions() {
  try {
    const response = yield call(onboardingQuestionsRequest);
    yield put(doFetchOnboardingQuestionSuccess(response.data.response.data));
  } catch (error) {
    createNotification(errorHandler(error));
    yield put(doFetchOnboardingQuestionFailure());
  }
}

/**
 * Watch selfAssessment actions
 * */
export default function* commonSaga() {
  yield all([
    takeEvery(SET_SELECTED_FITLERS, saveFilters),
    takeLatest(GET_PLAYBACK_HISTORY, requestPlaybackHistory),
    takeLatest(CHECK_FOR_VALUES_CHANGE, doCheckForChange),
    takeLatest(FETCH_ONBOARDING_QUESTIONS_REQUEST, fetchOnboardingQuestions),
  ]);
}
