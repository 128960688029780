import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Navbar, Container, Nav, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { APP_ROUTES } from '../../route';
import {
  IMAGE_CONSTANT,
  LOCAL_STORAGE_CONSTANT,
  TOUR_TARGET_ELEMENT,
} from '../../constants/app';
import { getDisplayType, getUserAttribute } from '../../utils/helpers';
import useWindowWidth from '../../hooks/useWindowWidth';
import { capitalCase } from 'capital-case';

import { extractJSON } from '../../services/jsonUtil';
import { doGetUserDataRequest } from '../../store/action/user';
import LazyImage from '../../ui-core/Image';
import ProfileImage from '../ProfileImage';
// import SearchBar from '../SearchBar';
import CheckoutCart from '../CheckoutCart';

import '../Header/style.css';
import HamburgerMenu from '../HamburgerMenu';

const Header = props => {
  const { history, handleGoBack } = props;
  const { user } = useSelector(state => state.user);
  const displayType = getDisplayType(useWindowWidth());
  const isMobileScreen = displayType === 'mobile';
  const isIpadScreen = displayType === 'ipad';
  const isDesktopScreen = !isMobileScreen && !isIpadScreen;
  const userImage = user?.attributes?.profile_pic
    ? user.attributes.profile_pic
    : getUserAttribute('profile_pic') || IMAGE_CONSTANT.defaultProfileIcon;
  const showNav = getUserAttribute('onboarding_completed');
  const dispatch = useDispatch();

  const getUserData = () => {
    const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
    if (user?.id) {
      const userId = user.id;
      dispatch(doGetUserDataRequest({ userId }));
    }
  };

  const renderNavigationBar = () => {
    const navigationBarOutput = (
      <React.Fragment>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="">
          <Nav className="me-auto">
            <Nav.Link onClick={handleLogoClick} className={props.homeClass}>
              <span className="nav-text">Home</span>
            </Nav.Link>
            <Nav.Link
              className={props.discoverClass}
              onClick={() => history.push(APP_ROUTES.DISCOVER)}
            >
              <span className="nav-text">Discover</span>
            </Nav.Link>
            {/* <Nav.Link
              className={props.challengeClass}
              onClick={() => history.push(APP_ROUTES.COURSES)}
            >
              <span className="nav-text">Courses</span>
            </Nav.Link> */}
            <Nav.Link
              onClick={() => history.push(APP_ROUTES.FOLDERS)}
              data-tour-id={TOUR_TARGET_ELEMENT.folders}
              className={props.foldersClass}
            >
              <span className="nav-text">My Folders</span>
            </Nav.Link>
            <Nav.Link
              onClick={() => history.push(APP_ROUTES.PODCASTS)}
              data-tour-id={TOUR_TARGET_ELEMENT.podcast}
              className={`podcasts ${props.podcastClass}`}
            >
              <span className="nav-text"> Podcasts</span>
            </Nav.Link>
            <Nav.Link
              onClick={() => history.push(APP_ROUTES.BLOGS)}
              data-tour-id={TOUR_TARGET_ELEMENT.blog}
              className={`blogs ${props.blogClass}`}
            >
              <span className="nav-text">Blogs</span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </React.Fragment>
    );
    return navigationBarOutput;
  };

  const getPageTitle = () => {
    const path = history.location.pathname.split('/');
    return path[path.length - 1];
  };

  const headerTitleForIpad = props.iPadHeaderText
    ? props.iPadHeaderText
    : getPageTitle();

  const hideBackButtonForIpad = (title => {
    switch (title) {
      case 'dashboard':
        return true;
      default:
        return false;
    }
  })(headerTitleForIpad);

  const renderSearchBar = () => (
    <div className="d-flex align-items-center pr45 gap-4">
      {/* <SearchBar redirectToSearch={!haltRedirectToSearch} /> */}
      <div
        className="d-flex align-items-center avator bg-transparent p-0 border-0"
        onClick={() => history.push(APP_ROUTES.PROFILE)}
        data-tour-id={TOUR_TARGET_ELEMENT.profile}
      >
        <LazyImage
          alt="face"
          className="img-fluid user-avatar-icon"
          errorSrc={IMAGE_CONSTANT.defaultProfileIcon}
          placeholderSrc={IMAGE_CONSTANT.personIcon}
          src={userImage}
          onError={getUserData}
        />
      </div>
      <CheckoutCart history={history} />
    </div>
  );

  const handleLogoClick = () => {
    if (showNav) {
      history.push(APP_ROUTES.HOME);
    } else {
      history.push(APP_ROUTES.SELF_ASSESSMENT);
    }
  };

  if (props.hideHeader) {
    return null;
  }

  return (
    <Navbar
      id="header-section"
      bg=""
      expand="lg"
      className="bg-white jen-navbar"
    >
      <Container fluid>
        <div
          className={clsx(
            'd-flex align-items-center pl45 w-100',
            isIpadScreen && 'w-100',
          )}
        >
          {isIpadScreen ? (
            <>
              {' '}
              <HamburgerMenu
                blogClass={props.blogClass}
                podcastClass={props.podcastClass}
              />
              <h1 className="text-center list-page-title mt-4 mb-4 d-flex justify-content-between align-items-center w-100">
                {!hideBackButtonForIpad && !props.hideBackButton && (
                  <Image
                    onClick={handleGoBack}
                    className="image-fluid goback-btn cursor-pointer"
                    src={IMAGE_CONSTANT.backIcon}
                  />
                )}
                <span className="text-center list-page-title align-items-center w-100 ps-4 ps-md-5">
                  {capitalCase(headerTitleForIpad)}
                </span>
                <div className="d-flex">
                  {!showNav && (
                    <ProfileImage className="cursor-pointer profile-image-icon" />
                  )}
                  <CheckoutCart history={history} />
                </div>
              </h1>
            </>
          ) : isMobileScreen && props.showBackHeader ? (
            <>
              {' '}
              <HamburgerMenu
                blogClass={props.blogClass}
                podcastClass={props.podcastClass}
              />
              <h1 className="text-center list-page-title mt-3 mb-3 d-flex justify-content-between align-items-center w-100">
                {!props.hideBackButton && (
                  <Image
                    onClick={handleGoBack}
                    className="image-fluid goback-btn cursor-pointer back-btn"
                    src={IMAGE_CONSTANT.backIcon}
                  />
                )}
                <span className="text-center list-page-title align-items-center w-100 ps-4 ps-md-5">
                  {props.mobileHeaderText
                    ? `${props.mobileHeaderText}`
                    : capitalCase(headerTitleForIpad)}
                </span>
                <div className="d-flex">
                  {!showNav && (
                    <ProfileImage className="cursor-pointer profile-image-icon" />
                  )}
                  <CheckoutCart history={history} />
                </div>
              </h1>
            </>
          ) : (
            <Navbar.Brand className="logo me-lg-4 me-0 p-0">
              <h2 className="logo-text" onClick={handleLogoClick}>
                Jen
              </h2>
            </Navbar.Brand>
          )}
          {isDesktopScreen && showNav && renderNavigationBar()}
        </div>
        {isDesktopScreen && !showNav && (
          <ProfileImage className="cursor-pointer profile-image-icon" />
        )}
        {isDesktopScreen && showNav && renderSearchBar()}
      </Container>
    </Navbar>
  );
};

Header.propTypes = {
  history: PropTypes.object,
  homeClass: PropTypes.string,
  challengeClass: PropTypes.string,
  discoverClass: PropTypes.string,
  foldersClass: PropTypes.string,
  blogClass: PropTypes.string,
  podcastClass: PropTypes.string,
  hideHeader: PropTypes.bool,
  showBackHeader: PropTypes.bool,
  hideBackButton: PropTypes.bool,
  mobileHeaderText: PropTypes.string,
  iPadHeaderText: PropTypes.string,
  handleGoBack: PropTypes.func,
  // haltRedirectToSearch: PropTypes.bool,
};

export default withRouter(Header);
