import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const Dropdown = props => {
  const {
    className,
    disabled,
    helperText,
    label,
    name,
    noOptionsText,
    onChange,
    options,
    required,
    value,
    placeholder,
  } = props;
  return (
    <Form.Group className={className} controlId="formBasicName">
      <Form.Label className="label-text">{label}</Form.Label>
      {required && (
        <Form.Label className="label-text text-danger">*</Form.Label>
      )}
      {options.length ? (
        <Form.Control
          onChange={onChange}
          disabled={disabled}
          as="select"
          name={name}
          size="lg"
          className="folder-form-control"
          value={value}
        >
          <option key="blankChoice" hidden value={''}>
            {placeholder}
          </option>
          {options?.map((item, index) => (
            <option key={`option-${index}`} value={item.value}>
              {item.label}
            </option>
          ))}
        </Form.Control>
      ) : (
        <Form.Control
          onChange={onChange}
          disabled={disabled}
          size="lg"
          className="folder-form-control"
          value={noOptionsText}
        />
      )}
      {helperText && <div className="mt-2">{helperText}</div>}
    </Form.Group>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  noOptionsText: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  required: PropTypes.bool,
  value: PropTypes.any,
};

export default Dropdown;
