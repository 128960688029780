import { axiosInstance, overrideHeader } from '.';

export const getCollections = () => {
  overrideHeader();
  return axiosInstance.get('/collections');
};

export const getCollectionDetails = id => {
  overrideHeader();
  return axiosInstance.get('/collections/' + id);
};
