import {
  DO_SIGNUP_SUCCESS,
  DO_LOGIN_SUCCESS,
  DO_SURVEY_SUCCESS,
  DO_WAITLIST_SURVEY_SUCCESS,
} from '../../constant/auth';
import { RESET_STORE } from '../../constant/common';

const initialState = {
  user: null,
  survey: null,
  waitlistSurvey: {},
  video: {},
};

export const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DO_SIGNUP_SUCCESS:
      return { ...state, user: payload };
    case DO_LOGIN_SUCCESS:
      return { ...state, user: payload };
    case DO_SURVEY_SUCCESS:
      return { ...state, survey: payload };
    case DO_WAITLIST_SURVEY_SUCCESS:
      return { ...state, waitlistSurvey: payload };
    case RESET_STORE:
      return { ...initialState };
    default:
      return state;
  }
};
