import { RESET_STORE } from '../../constant/common';
import {
  DO_FETCH_PRODUCTS_FAILURE,
  DO_FETCH_PRODUCTS_SUCCESS,
  PRODUCTS_SET_IS_LOADING,
  SET_SELECTED_PLAN,
} from '../../constant/products';

const initialState = {
  isLoading: false,
  selectedPlan: null,
  products: null,
};

export const productsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DO_FETCH_PRODUCTS_SUCCESS:
      return { ...state, products: payload };
    case DO_FETCH_PRODUCTS_FAILURE:
      return { ...state, products: [] };
    case PRODUCTS_SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case SET_SELECTED_PLAN:
      return { ...state, selectedPlan: payload };
    case RESET_STORE:
      return { ...initialState };
    default:
      return state;
  }
};
