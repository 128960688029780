import { cloneJSON } from '../services/jsonUtil';

export function decodeBase64Value(value) {
  if (!value) return value;
  try {
    return window.atob(value);
  } catch {
    return value;
  }
}

export function encodeBase64Value(value) {
  if (!value) return value;
  try {
    return window.btoa(unescape(encodeURIComponent(value)));
  } catch {
    return value;
  }
}

export const encodeURLParams = params => {
  const stringifiedParams = JSON.stringify(params);
  const encodedParams = encodeBase64Value(stringifiedParams);
  return encodedParams;
};

export const decodeURLParams = params => {
  const decodedParams = decodeBase64Value(params);
  return cloneJSON(decodedParams);
};
