import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { getItem, removeItem } from '../../utils/cache';
import axios from 'axios';
import Button from '../../ui-core/Button/Button';
import Login from './components/Login';
import SignUp from './components/SignUp';
import OtpModal from './components/OtpModal';
import WaitList from './components/WaitList';
import SuccessModal from './components/SuccessModal';
import Testimonial from './components/testimonial';
import Calender from './components/Calender';
import Card from './components/Card';
import Community from './components/Community';
import Display from './components/Display';
import Header from './components/Header';
import SeoTags from '../../components/SeoComponent';

import {
  SURVEY_STATUS,
  TEXT_CONSTANT,
  LOCAL_STORAGE_CONSTANT,
  MODAL_TYPE,
  IMAGE_CONSTANT,
} from '../../constants/app';
import WhyJen from './components/WhyJen';
import Steps from './components/Steps';
import Offer from './components/Offer';
import Modal from './components/Modal';
import { getDisplayType, logOutput, scrollToTop } from '../../utils/helpers';
import useWindowWidth from '../../hooks/useWindowWidth';
import NeckPain from './components/NeckPain';
import { APP_ROUTES } from '../../route';
import {
  analyticsReset,
  SEGMENT_EVENTS,
  trackEvent,
} from '../../utils/Segment';
import { getCanonicalLink, SeoTexts } from '../../services/SEO';

import '../NewHome/style.css';

export const Wrapper = props => {
  const displayType = getDisplayType(useWindowWidth());
  const isMobileScreen = displayType === 'mobile';
  if (!isMobileScreen) return props.children;

  const text = {
    [MODAL_TYPE.SIGNUP]: {
      heading: 'SignUp',
      subHeading: 'Signup with your mobile number',
    },
    [MODAL_TYPE.LOGIN]: {
      heading: 'Login',
      subHeading: 'Login with your mobile number',
    },
    [MODAL_TYPE.OTP]: {
      heading: 'Enter Code',
      subHeading: 'Just one last step to verify your account with us',
    },
  };

  return (
    <div className="wrapper">
      <div className="wrapper-header position-relative">
        <div onClick={props.handleGoBack} className="go-back-block">
          <img src={IMAGE_CONSTANT.arrowLeft} alt="back" />
        </div>
        <h1>{text[props.type].heading}</h1>
        <p>{text[props.type].subHeading}</p>
      </div>
      <div className="wrapper-body">{props.children}</div>
    </div>
  );
};

const NewHome = ({ history }) => {
  const [countryCode, setCountryCode] = useState('us');
  const [modalState, setModalState] = useState(null);
  const handleModalState = modalData => setModalState(modalData);
  const displayType = getDisplayType(useWindowWidth());
  const isMobileScreen = displayType === 'mobile';

  const handleModalEvent = modalType => {
    setModalState(modalType);
  };
  useEffect(() => {
    scrollToTop();
    if (
      getItem(LOCAL_STORAGE_CONSTANT.SURVEY_STATUS) === `${SURVEY_STATUS.DONE}`
    ) {
      setModalState(MODAL_TYPE.SUCCESS);
      analyticsReset();
    }
    removeItem(LOCAL_STORAGE_CONSTANT.SURVEY_SESSION);
    removeItem(LOCAL_STORAGE_CONSTANT.SURVEY_STATUS);
    axios
      .get('https://ipapi.co/json/')
      .then(response => {
        const data = response.data;
        setCountryCode(data.country_code.toLowerCase());
      })
      .catch(error => {
        logOutput(error);
      });
  }, []);

  const handleAuthFlow = (buttonTitle, eventType, section) => {
    handleModalEvent(eventType);
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      button_title: buttonTitle,
      page: 'Home Page',
      section_title: `${section}`,
    });
    trackEvent(SEGMENT_EVENTS.AUTH_FLOW_INITIATED, {
      page: 'Home Page',
      section_title: `${section}`,
    });
    if (isMobileScreen) {
      scrollToTop();
    }
    // trackDisplayedEvent(`Display ${event} modal`, {
    //   page: 'Home Page',
    //   section_title: `${event} Modal`,
    // });
  };

  const redirectToPaymentPage = section => {
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      button_title: TEXT_CONSTANT.GET_STARTED_FOR_FREE,
      page: 'Home Page',
      section_title: section,
    });
    history.push(APP_ROUTES.FREE_TRAIL);
  };

  const renderHomePage = () => (
    <React.Fragment>
      <Header onClick={handleAuthFlow} />
      <Display
        onClick={handleAuthFlow}
        redirectToPaymentPage={redirectToPaymentPage}
      />
      <Steps />
      <WhyJen
        onClick={() =>
          handleAuthFlow('GET STARTED FOR FREE', 'isSignup', 'Why Jen ?')
        }
      />
      <Card />
      <Offer
        onClick={() =>
          handleAuthFlow(
            'GET STARTED FOR FREE',
            'isSignup',
            TEXT_CONSTANT.EXCERCISE_PLAN_MADE_FOR_YOU,
          )
        }
      />
      <Calender />
      <Community
        onClick={() =>
          handleAuthFlow(
            'JOIN NOW',
            'isLogin',
            'Be part of a community that supports you',
          )
        }
      />
      <NeckPain />
      <Testimonial />
      <footer className="bg-OysterBay text-center px-3 home-footer">
        <h3>{TEXT_CONSTANT.FOOTER_HEADER_TEXT}</h3>
        <p>{TEXT_CONSTANT.FOOTER_SUB_TEXT}</p>
        <Button
          onClick={() =>
            handleAuthFlow(
              'GET STARTED FOR FREE',
              'isSignup',
              TEXT_CONSTANT.FOOTER_HEADER_TEXT,
            )
          }
          text="GET STARTED FOR FREE"
          class="sign-up-button-white d-inline-block footer-btn"
          isDisabled={false}
        />
      </footer>
    </React.Fragment>
  );

  const renderModalContent = () => {
    switch (modalState) {
      case MODAL_TYPE.LOGIN:
        return (
          <Wrapper type={modalState} handleGoBack={() => setModalState(null)}>
            <Login
              countryCode={countryCode}
              handleModalState={handleModalState}
              handleModalEvent={handleModalEvent}
            />
          </Wrapper>
        );
      case MODAL_TYPE.OTP:
        return (
          <Wrapper type={modalState} handleGoBack={() => setModalState(null)}>
            <OtpModal
              handleModalState={handleModalState}
              handleModalEvent={handleModalEvent}
            />
          </Wrapper>
        );
      case MODAL_TYPE.SIGNUP:
        return (
          <Wrapper type={modalState} handleGoBack={() => setModalState(null)}>
            <SignUp
              countryCode={countryCode}
              handleModalState={handleModalState}
              handleModalEvent={handleModalEvent}
            />
          </Wrapper>
        );
      case MODAL_TYPE.SUCCESS:
        return <SuccessModal />;
      case MODAL_TYPE.WAITLIST:
        return <WaitList />;
      default:
        return null;
    }
  };

  const dialogClassName = (() => {
    switch (modalState) {
      case MODAL_TYPE.SUCCESS:
        return 'success-modal';
      case MODAL_TYPE.WAITLIST:
        return 'survey-modal';
      default:
        return undefined;
    }
  })();
  const handleClose = () => {
    removeItem(LOCAL_STORAGE_CONSTANT.SURVEY_SESSION);
    removeItem(LOCAL_STORAGE_CONSTANT.SURVEY_STATUS);
    setModalState(false);
  };

  const renderContentMobile = () => {
    if (modalState) {
      return renderModalContent();
    } else {
      return renderHomePage();
    }
  };

  return (
    <div className={clsx('main', isMobileScreen && modalState && 'h-100')}>
      {isMobileScreen &&
      ![MODAL_TYPE.SUCCESS, MODAL_TYPE.WAITLIST].includes(modalState)
        ? renderContentMobile()
        : renderHomePage()}
      {isMobileScreen &&
        [MODAL_TYPE.SUCCESS, MODAL_TYPE.WAITLIST].includes(modalState) && (
          <Modal
            dialogClassName={dialogClassName}
            show={Boolean(modalState)}
            handleClose={handleClose}
          >
            {renderModalContent()}
          </Modal>
        )}
      {!isMobileScreen && (
        <Modal
          dialogClassName={dialogClassName}
          show={Boolean(modalState)}
          handleClose={handleClose}
        >
          {renderModalContent()}
        </Modal>
      )}
      <SeoTags
        title={SeoTexts.JEN_WEB_TITLE}
        metaDescription={SeoTexts.JEN_WEB_DESCRIPTION}
        canonicalLink={getCanonicalLink()}
        ogImgUrl={SeoTexts.JEN_WEB_OG_IMAGE_URL}
        twitterImgUrl={SeoTexts.JEN_WEB_TWITTER_IMAGE_URL}
      />
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  handleGoBack: PropTypes.func,
};

NewHome.propTypes = {
  history: PropTypes.object,
};

export default NewHome;
