import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import FiltersSkeletons from '../../ui-core/Skeletons/FiltersSkeletons';
import FilterMobile from '../FilterSection/FiltersMobile';
import { doFitnesOptionsRequest } from '../../store/action/plan';
import useWindowWidth from '../../hooks/useWindowWidth';
import {
  getDisplayType,
  getUserAttribute,
  isTouchEnabled,
} from '../../utils/helpers';
import { LOCAL_STORAGE_CONSTANT, IMAGE_CONSTANT } from '../../constants/app';
import { SEGMENT_EVENTS, trackSubmittedEvent } from '../../utils/Segment';
import { removeItem, setItem } from '../../utils/cache';
import { setSelectedFilters } from '../../store/action/common';
import CustomButtom from '../../ui-core/Button/Button';
import FiltersView from './FiltersView';

import '../FilterSection/style.css';

const FilterSection = props => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { fitnessOptions } = useSelector(state => state.plan);
  const { selectedFilters, isLoading } = useSelector(state => state.common);
  const [selectedOptions, setSelectedOptions] = useState(selectedFilters || []);
  const displayType = getDisplayType(useWindowWidth());
  const isMobileScreen = displayType === 'mobile';
  const isTouchDevice = isTouchEnabled();
  const onboardingCompleted = getUserAttribute('onboarding_completed');

  useEffect(() => {
    dispatch(doFitnesOptionsRequest());
  }, []);

  // hides over-flow from app and page container when filter overlay is visible
  useEffect(() => {
    const appElement = document.getElementById('app');
    const mainElement = document.getElementById('main-body');
    const firstChild = mainElement?.firstChild;
    if (show) {
      if (appElement) appElement.classList.add('overflow-hidden');
      if (appElement) appElement.classList.add('scp');
      if (firstChild) firstChild.classList.add('overflow-hidden');
    }
    return () => {
      if (appElement) appElement.classList.remove('overflow-hidden');
      if (firstChild) firstChild.classList.remove('overflow-hidden');
      if (appElement) appElement.classList.remove('scp');
    };
  }, [show]);

  useEffect(() => {
    if (selectedFilters.length) setSelectedOptions(selectedFilters);
  }, [selectedFilters]);

  const handleSelectedOptions = option => {
    option = {
      id: option.id,
      name: option.attributes.name,
    };
    let newOptions = [...selectedOptions];
    if (newOptions.length) {
      if (newOptions.find(item => item.id === option.id)) {
        newOptions = [];
      } else {
        newOptions[0] = option;
      }
    } else {
      newOptions.push(option);
    }
    setSelectedOptions(newOptions);
  };

  const handleReset = () => {
    setSelectedOptions([]);
    removeItem(LOCAL_STORAGE_CONSTANT.SELECTED_FILTERS);
    dispatch(setSelectedFilters({ selectedFilters: [] }));
  };

  const handleFilterApply = () => {
    trackSubmittedEvent(SEGMENT_EVENTS.BODY_PART_SELECTED, {
      page: props.pageName,
      // section_title: 'Filter section',
      onboarding_complete: onboardingCompleted || false,
      body_part: selectedOptions[0].name,
    });
    setItem(
      LOCAL_STORAGE_CONSTANT.SELECTED_FILTERS,
      JSON.stringify(selectedOptions),
    );
    dispatch(setSelectedFilters({ selectedFilters: selectedOptions }));
    closeModel();
  };

  const handleCancel = () => {
    setSelectedOptions(selectedFilters);
    closeModel();
  };

  const renderBodyPart = () => {
    const bodyPartList = fitnessOptions.map((option, index) => (
      <CustomButtom
        text={option.attributes.name}
        class={clsx(
          'body-part-options',
          Boolean(selectedOptions.find(item => item.id === option.id)) &&
            'active',
          !isTouchDevice && 'body-part-options-no-touch',
        )}
        id={option.id}
        type={'button'}
        key={`inline-checkbox-${index}`}
        onClick={() => handleSelectedOptions(option)}
      />
    ));
    return (
      <div key={'inline-checkbox'} className="mb-3">
        {bodyPartList}
      </div>
    );
  };
  const renderFilterOptionsSkeletons = () => {
    const filterskeletons = Array.from({ length: 20 }).map((_, index) => (
      <FiltersSkeletons key={`filter-options-skeleton-${index + 1}`} />
    ));
    return (
      <div key={'inline-checkbox'} className="mb-4 d-flex flex-wrap">
        {filterskeletons}
      </div>
    );
  };

  const openModel = () => {
    if (!show) {
      setShow(true);
      const elem = document.getElementById('body-filter-model');
      if (elem) {
        elem.classList.add('body-filter-model-open');
      }
    }
  };

  const closeModel = () => {
    const elem = document.getElementById('body-filter-model');
    if (elem) {
      elem.classList.add('body-filter-model-close');
      setTimeout(() => {
        elem.classList.remove('body-filter-model-open');
        setShow(false);
        elem.classList.remove('body-filter-model-close');
      }, 500);
    }
  };

  const toggleFiltersModel = () => {
    if (show && selectedOptions.length) {
      closeModel();
    } else {
      openModel();
    }
  };

  return (
    <React.Fragment>
      <div
        className={clsx(props.class, 'filter-section-bg position-relative ')}
        style={props.style}
      >
        <div className="max-width-1013">
          <div className={'d-flex align-items-center'}>
            {/* <Button
              className={clsx(
                'filter-btn bg-white',
                selectedFilters.length && 'd-none',
              )}
              onClick={toggleFiltersModel}
            >
              <span className="d-inline-block me-2">
                <img
                  src={IMAGE_CONSTANT.filterIcon}
                  alt=""
                  className="img-fluid"
                />
              </span>
              Filter by Body Parts
            </Button> */}
            <FiltersView
              selectedFilters={selectedOptions}
              toggleFiltersModel={toggleFiltersModel}
            />
          </div>
        </div>
        {!isMobileScreen ? (
          <div className="col-breadcrumb">
            <div className="max-width-1013">
              <div className="position-relative">
                {show && (
                  <div onClick={handleCancel} className="back-drop"></div>
                )}
                <div id="body-filter-model" className="position-absolute w-100">
                  {show ? (
                    <React.Fragment>
                      <div className="filter-options-wrapper">
                        <div className=" p-3 p-md-4">
                          <div className="d-flex justify-content-between align-items-center">
                            <div></div>
                            <button
                              className="close-btn"
                              onClick={handleCancel}
                              disabled={selectedOptions.length === 0}
                            >
                              <img
                                src={IMAGE_CONSTANT.closeIcon}
                                alt=""
                                className="img-fluid"
                              />
                            </button>
                          </div>
                          <div className="options-checkbox">
                            {fitnessOptions.length
                              ? renderBodyPart()
                              : !isLoading
                              ? renderFilterOptionsSkeletons()
                              : null}
                          </div>
                          <div className="">
                            {/* <Button
                              onClick={handleReset}
                              className="reset me-3 text-uppercase"
                            >
                              Clear All
                            </Button> */}
                            <Button
                              onClick={handleFilterApply}
                              disabled={!selectedOptions.length}
                              className="apply text-uppercase mt-2"
                            >
                              Apply Filter
                            </Button>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <FilterMobile
            show={show}
            selectedOptions={selectedOptions}
            handleClear={handleReset}
            renderBodyPart={renderBodyPart}
            handleFilterApply={handleFilterApply}
            handleCancel={handleCancel}
            renderFilterOptionsSkeletons={renderFilterOptionsSkeletons}
          />
        )}
      </div>
    </React.Fragment>
  );
};

FilterSection.propTypes = {
  class: PropTypes.string,
  style: PropTypes.any,
  pageName: PropTypes.string,
};

export default FilterSection;
