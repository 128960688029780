import { axiosInstance, overrideHeader } from '.';
import { isNotNullOrUndefined } from '../utils/helpers';

export const getAllCourses = () => {
  overrideHeader();
  return axiosInstance.get('/courses');
};

export const getAllCoursesPublic = () => {
  overrideHeader();
  return axiosInstance.get('/courses/public');
};

export const getCourseDetail = (id, options) => {
  overrideHeader();
  const queryParams = new URLSearchParams();
  Object.entries(options).forEach(([key, val]) => {
    if (isNotNullOrUndefined(val)) queryParams.append(key, val);
  });
  return axiosInstance.get(`/courses/${id}?${queryParams.toString()}`);
};
