import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import {
  DO_CREATE_FOLDER_REQUEST,
  DO_FOLDERS_REQUEST,
  DO_ADD_VIDEO_TO_FOLDER_REQUEST,
  DO_ADD_VIDEO_TO_FOLDER_SUCCESS,
  DO_REMOVE_VIDEO_FROM_FOLDER_REQUEST,
  DO_REMOVE_VIDEO_FROM_FOLDER_SUCCESS,
  DO_FOLDER_DETAIL_REQUEST,
  DO_FOLDER_DELETE_REQUEST,
  DO_FOLDER_RENAME_REQUEST,
  DO_FETCH_ALL_FOLDERS_REQUEST,
} from '../../constant/folders';
import {
  doCreateFolderSuccess,
  doFolderDetailsSuccess,
  doFoldersSuccess,
  doFolderRenameSuccess,
  doFolderDeleteSuccess,
  doAddVideoToFolderSuccess,
  toggleFolderModal,
  setIsLoading,
  doRemoveVideoFromFolderSuccess,
  doFoldersRequest,
  doFetchAllFoldersSuccess,
} from '../../action/folders';
import {
  addVideoToFolderRequest,
  createfolderRequest,
  deleteFolderRequest,
  foldersGetRequest,
  getFolderDetailsRequest,
  renameFolderRequest,
  removedVideoFromFoldersRequest,
  allFoldersGetRequest,
} from '../../../api/folder';
import {
  createNotification,
  NOTIFICATION_TYPE,
  SUCCESS_MESSAGES,
} from '../../../services/Notification';
import { getItem, removeItem, setItem } from '../../../utils/cache';
import { IMAGE_CONSTANT, LOCAL_STORAGE_CONSTANT } from '../../../constants/app';
import { updateCachedPages } from '../../../services/Cache';
import { setActivePage } from '../../action/common';
import { errorHandler } from '../../../utils/helpers';

function* foldersRequest({ payload }) {
  const { noFolderCallBack } = payload || {};
  yield put(setIsLoading(true));
  try {
    const folders = getItem(LOCAL_STORAGE_CONSTANT.FOLDER_PAGES)
      ? JSON.parse(getItem(LOCAL_STORAGE_CONSTANT.FOLDER_PAGES))
      : {};
    const pageNumber = yield select(state => state.common.activePage);
    const pageIndex = pageNumber ?? 1;
    if (folders[pageIndex]) {
      const output = folders[pageIndex];
      yield put(doFoldersSuccess(output));
    } else {
      const response = yield call(foldersGetRequest, pageNumber);
      const output = {
        data: response.data.response.data,
        paging: response.data.paging,
      };
      yield put(doFoldersSuccess(output));
      folders[pageIndex] = output;
      setItem(LOCAL_STORAGE_CONSTANT.FOLDER_PAGES, JSON.stringify(folders));
      if (output.data?.length === 0 && noFolderCallBack) {
        noFolderCallBack();
      }
    }
  } catch (e) {
    createNotification(errorHandler(e));
  }
  yield put(setIsLoading(false));
}

function* createFolder({ payload }) {
  const { name, callBack } = payload || {};
  yield put(setIsLoading(true));
  try {
    const response = yield call(createfolderRequest, name);
    const folder = response.data.response.data;
    const { folders } = yield select(state => state.folder);
    if (folders.data) {
      folders.data.push(folder);
    }
    if (folders?.paging?.total_items) {
      folders.paging.total_items += 1;
    }
    if (callBack) {
      const value = {
        value: folder.id,
        label: name,
      };
      callBack(value);
    }
    yield put(doCreateFolderSuccess({ ...folders }));
    createNotification({
      type: NOTIFICATION_TYPE.SUCCESS,
      message: SUCCESS_MESSAGES.FOLDER_CREATE_SUCCESS,
    });
  } catch (e) {
    createNotification(errorHandler(e));
  }
  yield put(setIsLoading(false));
}

function* addVideoToFolder({ payload }) {
  const { callback, folderId, videoId } = payload || {};
  yield put(setIsLoading(true));
  try {
    yield call(addVideoToFolderRequest, folderId, videoId);
    yield put(doAddVideoToFolderSuccess());
    const { curatedPlans } = yield select(state => state.plan);
    callback && callback(curatedPlans);
  } catch (e) {
    createNotification(errorHandler(e));
  }
  yield put(setIsLoading(false));
}

function* addVideoToFolderSuccess() {
  const { videoId } = yield select(state => state.folder.folderModalOptions);
  const ele = document.getElementById(`star-id-${videoId}`);
  if (ele) {
    ele.src = IMAGE_CONSTANT.starIconFilled;
  }
  createNotification({
    type: NOTIFICATION_TYPE.SUCCESS,
    message: SUCCESS_MESSAGES.VIDEO_ADD_SUCCESS,
  });
  yield put(
    toggleFolderModal({
      showFolderModal: false,
      selectedModal: null,
      folderModalOptions: {},
    }),
  );
}

function* removeVideoFromFolder({ payload }) {
  const { callback, folderIds, videoId } = payload || {};
  yield put(setIsLoading(true));
  try {
    yield call(removedVideoFromFoldersRequest, folderIds, videoId);
    yield put(doRemoveVideoFromFolderSuccess({ videoId }));
    if (callback) callback();
  } catch (e) {
    createNotification(errorHandler(e));
  }
  yield put(setIsLoading(false));
}

function* removeVideoFromFolderSuccess({ payload }) {
  const { videoId } = payload || {};
  const { selectedFolder } = yield select(state => state.folder);
  if (selectedFolder?.attributes?.videos?.data) {
    let data = selectedFolder.attributes.videos.data ?? [];
    data = data.filter(item => item.id !== videoId);
    selectedFolder.attributes.videos.data = data;
  }
  yield put(doFolderDetailsSuccess({ ...selectedFolder }));
  createNotification({
    type: NOTIFICATION_TYPE.SUCCESS,
    message: SUCCESS_MESSAGES.VIDEO_REMOVED_SUCCESS,
  });
  yield put(
    toggleFolderModal({
      showFolderModal: false,
      selectedModal: null,
      folderModalOptions: {},
    }),
  );
}

function* getFolderDetails({ payload }) {
  const { folderId } = payload || {};
  yield put(setIsLoading(true));
  try {
    const response = yield call(getFolderDetailsRequest, folderId);
    const result = response.data.response.data;
    yield put(doFolderDetailsSuccess(result));
  } catch (e) {
    createNotification(errorHandler(e));
  }
  yield put(setIsLoading(false));
}

function* deleteFolder({ payload }) {
  const { folderId } = payload || {};
  yield put(setIsLoading(true));
  try {
    yield call(deleteFolderRequest, folderId);
    const { folders } = yield select(state => state.folder);
    const updatedFolders = [...folders.data].filter(
      item => item.id !== folderId,
    );
    folders.paging.total_items -= 1;
    folders.data = updatedFolders;
    updateCachedPages(LOCAL_STORAGE_CONSTANT.FOLDER_PAGES, folderId, folders);
    yield put(doFolderDeleteSuccess({ ...folders }));
    yield put(
      toggleFolderModal({
        showFolderModal: false,
        selectedModal: null,
        folderModalOptions: {},
      }),
    );
    removeItem(LOCAL_STORAGE_CONSTANT.FOLDER_PAGES);
    const wasLastFolder = updatedFolders.length === 0;
    if (wasLastFolder) {
      const { activePage } = yield select(state => state.common);
      yield put(setActivePage(activePage === 1 ? undefined : activePage - 1));
    }
    yield put(doFoldersRequest());
    createNotification({
      type: NOTIFICATION_TYPE.SUCCESS,
      message: SUCCESS_MESSAGES.FOLDER_DELETE_SUCCESS,
    });
  } catch (e) {
    createNotification(errorHandler(e));
  }
  yield put(setIsLoading(false));
}

function* renameFolder({ payload }) {
  const { folderId, name } = payload || {};
  yield put(setIsLoading(true));
  try {
    const response = yield call(renameFolderRequest, folderId, name);
    const result = response?.data?.response?.data ?? {};
    const { folders } = yield select(state => state.folder);
    const index = folders.data.findIndex(item => item.id === result.id);
    if (index !== -1) {
      folders.data[index].attributes.name = name;
      updateCachedPages(LOCAL_STORAGE_CONSTANT.FOLDER_PAGES, folderId, folders);
      yield put(doFolderRenameSuccess(folders));
    }
    createNotification({
      type: NOTIFICATION_TYPE.SUCCESS,
      message: SUCCESS_MESSAGES.FOLDER_RENAME_SUCCESS,
    });
    yield put(
      toggleFolderModal({
        showFolderModal: false,
        selectedModal: null,
        folderModalOptions: {},
      }),
    );
  } catch (e) {
    createNotification(errorHandler(e));
  }
  yield put(setIsLoading(false));
}

function* doFetchAllFolders() {
  yield put(setIsLoading(true));
  try {
    const response = yield call(allFoldersGetRequest);
    const allFolders = response?.data?.response?.data || [];
    yield put(doFetchAllFoldersSuccess(allFolders));
  } catch (e) {
    createNotification(errorHandler(e));
  }
  yield put(setIsLoading(false));
}

/**
 * Watch folders actions
 * */
export default function* foldersSaga() {
  yield all([
    takeLatest(DO_FOLDERS_REQUEST, foldersRequest),
    takeLatest(DO_CREATE_FOLDER_REQUEST, createFolder),
    takeLatest(DO_ADD_VIDEO_TO_FOLDER_REQUEST, addVideoToFolder),
    takeLatest(DO_ADD_VIDEO_TO_FOLDER_SUCCESS, addVideoToFolderSuccess),
    takeLatest(DO_REMOVE_VIDEO_FROM_FOLDER_REQUEST, removeVideoFromFolder),
    takeLatest(
      DO_REMOVE_VIDEO_FROM_FOLDER_SUCCESS,
      removeVideoFromFolderSuccess,
    ),
    takeLatest(DO_FOLDER_DETAIL_REQUEST, getFolderDetails),
    takeLatest(DO_FOLDER_DELETE_REQUEST, deleteFolder),
    takeLatest(DO_FOLDER_RENAME_REQUEST, renameFolder),
    takeLatest(DO_FETCH_ALL_FOLDERS_REQUEST, doFetchAllFolders),
  ]);
}
