export const SEARCH_INPUT_CHANGE = 'SEARCH_INPUT_CHANGE';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';

export const DO_SEARCH_REQUEST = 'DO_SEARCH_REQUEST';

export const DO_SEARCH_REQUEST_FOR_NEXT_PAGE =
  'DO_SEARCH_REQUEST_FOR_NEXT_PAGE';

export const SEARCH_SET_IS_LOADING = 'SEARCH_SET_IS_LOADING';
