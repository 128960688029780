import {
  DO_SIGNUP_REQUEST,
  DO_SIGNUP_SUCCESS,
  DO_SIGNUP_FAILED,
  DO_LOGIN_REQUEST,
  DO_LOGIN_SUCCESS,
  DO_LOGIN_FAILED,
  VERIFY_OTP_REQUESTED,
  VERIFY_OTP_FAILED,
  DO_SURVEY_REQUEST,
  DO_SURVEY_SUCCESS,
  DO_OTP_RESEND_REQUEST,
  DO_OTP_RESEND_SUCCESS,
  DO_WAITLIST_SURVEY_REQUEST,
  DO_WAITLIST_SURVEY_SUCCESS,
} from '../../constant/auth';
import {
  SET_LOADING_STATE,
  SET_SELECTED_FITLERS,
  SET_ACTIVE_PAGE,
  RESET_STORE,
  TOGGLE_TRAIL_MODAL,
  SET_ARE_VALUES_CHANGED,
  FETCH_ONBOARDING_QUESTIONS_FAILURE,
  FETCH_ONBOARDING_QUESTIONS_SUCCESS,
  TOGGLE_EMAIL_MODEL,
  SET_PAYMENT_IS_IN_PROGRESS,
} from '../../constant/common';
import { DO_FOLDERS_REQUEST, DO_FOLDERS_SUCCESS } from '../../constant/folders';

const initialState = {
  isLoading: false,
  selectedFilters: [],
  openTrialModal: { open: false, viewAll: false, page: '' },
  valuesChanged: false,
  onBoardingQuestions: null,
  openEmailModal: false,
  isPaymentInProgress: false,
};

export const commonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING_STATE:
      return { ...state, isLoading: payload };
    case DO_SIGNUP_REQUEST:
    case DO_LOGIN_REQUEST:
    case DO_SURVEY_REQUEST:
    case VERIFY_OTP_REQUESTED:
    case DO_OTP_RESEND_REQUEST:
    case DO_WAITLIST_SURVEY_REQUEST:
    case DO_FOLDERS_REQUEST:
      return { ...state, isLoading: true };
    case VERIFY_OTP_FAILED:
    case DO_SIGNUP_SUCCESS:
    case DO_SIGNUP_FAILED:
    case DO_LOGIN_SUCCESS:
    case DO_LOGIN_FAILED:
    case DO_SURVEY_SUCCESS:
    case DO_OTP_RESEND_SUCCESS:
    case DO_WAITLIST_SURVEY_SUCCESS:
    case DO_FOLDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case SET_SELECTED_FITLERS:
      return {
        ...state,
        selectedFilters: payload.selectedFilters,
      };
    case SET_ACTIVE_PAGE:
      return {
        ...state,
        activePage: payload,
      };
    case RESET_STORE:
      return { ...initialState };
    case TOGGLE_TRAIL_MODAL:
      return {
        ...state,
        openTrialModal: payload,
      };
    case SET_ARE_VALUES_CHANGED:
      return {
        ...state,
        valuesChanged: payload,
      };
    case FETCH_ONBOARDING_QUESTIONS_SUCCESS:
      return {
        ...state,
        onBoardingQuestions: payload,
      };
    case FETCH_ONBOARDING_QUESTIONS_FAILURE:
      return {
        ...state,
        onBoardingQuestions: [],
      };
    case TOGGLE_EMAIL_MODEL:
      return {
        ...state,
        openEmailModal: payload,
      };
    case SET_PAYMENT_IS_IN_PROGRESS:
      return {
        ...state,
        isPaymentInProgress: payload,
      };
    default:
      return state;
  }
};
