import {
  DO_CREATE_FOLDER_REQUEST,
  DO_CREATE_FOLDER_SUCCESS,
  DO_FOLDERS_REQUEST,
  DO_FOLDERS_SUCCESS,
  DO_ADD_VIDEO_TO_FOLDER_REQUEST,
  DO_ADD_VIDEO_TO_FOLDER_SUCCESS,
  DO_REMOVE_VIDEO_FROM_FOLDER_REQUEST,
  DO_REMOVE_VIDEO_FROM_FOLDER_SUCCESS,
  DO_FOLDER_DETAIL_REQUEST,
  DO_FOLDER_DETAIL_SUCCESS,
  TOGGLE_FOLDER_OVERLAY,
  DO_FOLDER_DELETE_REQUEST,
  DO_FOLDER_DELETE_SUCCESS,
  DO_FOLDER_RENAME_REQUEST,
  DO_FOLDER_RENAME_SUCCESS,
  FOLDERS_SET_IS_LOADING,
  RESET_FOLDERS,
  RESET_DETAILED_FOLDER,
  DO_FETCH_ALL_FOLDERS_REQUEST,
  DO_FETCH_ALL_FOLDERS_SUCCESS,
} from '../../constant/folders';

export const doFoldersRequest = payload => {
  return {
    type: DO_FOLDERS_REQUEST,
    payload,
  };
};

export const doFoldersSuccess = payload => ({
  type: DO_FOLDERS_SUCCESS,
  payload,
});

export const doCreateFolderRequest = payload => {
  return {
    type: DO_CREATE_FOLDER_REQUEST,
    payload,
  };
};

export const doCreateFolderSuccess = payload => ({
  type: DO_CREATE_FOLDER_SUCCESS,
  payload,
});

export const doAddVideoToFolderRequest = payload => {
  return {
    type: DO_ADD_VIDEO_TO_FOLDER_REQUEST,
    payload,
  };
};

export const doAddVideoToFolderSuccess = payload => ({
  type: DO_ADD_VIDEO_TO_FOLDER_SUCCESS,
  payload,
});

export const doRemoveVideoFromFolderRequest = payload => {
  return {
    type: DO_REMOVE_VIDEO_FROM_FOLDER_REQUEST,
    payload,
  };
};

export const doRemoveVideoFromFolderSuccess = payload => ({
  type: DO_REMOVE_VIDEO_FROM_FOLDER_SUCCESS,
  payload,
});

export const doFolderDetailsRequest = payload => {
  return {
    type: DO_FOLDER_DETAIL_REQUEST,
    payload,
  };
};

export const doFolderDetailsSuccess = payload => ({
  type: DO_FOLDER_DETAIL_SUCCESS,
  payload,
});

export const doFolderDeleteRequest = payload => ({
  type: DO_FOLDER_DELETE_REQUEST,
  payload,
});

export const doFolderDeleteSuccess = payload => ({
  type: DO_FOLDER_DELETE_SUCCESS,
  payload,
});

export const doFolderRenameRequest = payload => ({
  type: DO_FOLDER_RENAME_REQUEST,
  payload,
});

export const doFolderRenameSuccess = payload => ({
  type: DO_FOLDER_RENAME_SUCCESS,
  payload,
});

export const toggleFolderModal = payload => ({
  type: TOGGLE_FOLDER_OVERLAY,
  payload,
});

export const setIsLoading = payload => ({
  type: FOLDERS_SET_IS_LOADING,
  payload,
});

export const resetFolders = () => ({
  type: RESET_FOLDERS,
});

export const resetDetailedFolder = () => ({
  type: RESET_DETAILED_FOLDER,
});

export const doFetchAllFoldersRequest = () => ({
  type: DO_FETCH_ALL_FOLDERS_REQUEST,
});

export const doFetchAllFoldersSuccess = payload => ({
  type: DO_FETCH_ALL_FOLDERS_SUCCESS,
  payload,
});
