import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import {
  CAROUSEL_BREAKPOINT,
  IMAGE_CONSTANT,
  TEXT_CONSTANT,
} from '../../../constants/app';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { getDisplayType } from '../../../utils/helpers';

const Card = () => {
  const { Avatar1, Avatar2, Avatar3, Avatar1WebP, Avatar2WebP, Avatar3WebP } =
    IMAGE_CONSTANT;
  const displayType = getDisplayType(useWindowWidth());
  const isMobileScreen = displayType === 'mobile';
  const publicOpinion = [
    {
      name: 'Virgie Hatley',
      opinion: TEXT_CONSTANT.PUBLIC_OPINION_1,
      image: Avatar1,
      imageWebP: Avatar1WebP,
      color: '#56729C',
      backgroundColor: '#9EAFC9 ',
    },
    {
      name: 'Joe Sanchez',
      opinion: TEXT_CONSTANT.PUBLIC_OPINION_3,
      image: Avatar3,
      imageWebP: Avatar3WebP,
      color: '#C05A6F',
      backgroundColor: '#E89A7D',
    },
    {
      name: 'Lynlee Copenhaver',
      opinion: TEXT_CONSTANT.PUBLIC_OPINION_2,
      image: Avatar2,
      imageWebP: Avatar2WebP,
      color: '#926C0D',
      backgroundColor: '#D9B355',
    },
  ];

  const renderOpinionCards = () => {
    const opinionsOutput = publicOpinion.map((item, index) => (
      <div key={`${index} ${item.name}`} className="block- position-relative">
        <div
          className="avator-img position-absolute rounded-circle"
          style={{ backgroundColor: item.backgroundColor }}
        >
          <picture>
            <source type="image/webp" srcSet={item.imageWebP} />
            <img src={item.image} className="img-fluid" alt="user avatar" />
          </picture>
        </div>
        <div className="content  pt-5">
          <p className="section-para">{item.opinion}</p>

          <h3 style={{ color: item.color }}>{item.name}</h3>
        </div>
      </div>
    ));
    return opinionsOutput;
  };

  return (
    <section className="card-section">
      <Container>
        <Row>
          <Col>
            <h2 className="section-title-home text-center">
              What people say about Jen
            </h2>
          </Col>
        </Row>
        <Row className="px-lg-5">
          <Col sm={12}>
            {isMobileScreen ? (
              <div>{renderOpinionCards()}</div>
            ) : (
              <Carousel
                responsive={CAROUSEL_BREAKPOINT}
                className="testimonial-home  payment-cards"
                showDots={true}
                swipeable={true}
              >
                {renderOpinionCards()}
              </Carousel>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Card;
