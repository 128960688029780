import { axiosInstance, overrideHeader } from './index';

/**
 * API request
 * */

export const fitnessOptionsGetRequest = () => {
  overrideHeader();
  return axiosInstance.get('/constants/parts');
};

export const onboardingQuestionsRequest = () => {
  overrideHeader();
  return axiosInstance.get('/configs/onboarding');
};
