import React from 'react';
import PropTypes from 'prop-types';
import SkeletonElements from '.';

const FolderSkeleton = ({ theme }) => {
  const themeClass = theme || 'light';
  return (
    <div className={`folder-skeleton-wrapper ${themeClass}`}>
      <SkeletonElements
        type="folders-side-strip skeleton"
        component="folder-skeleton"
      />
      <SkeletonElements
        type="folders-title skeleton"
        component="folder-skeleton"
      />
      <SkeletonElements
        type="folder-video-text skeleton"
        component="folder-skeleton"
      />
    </div>
  );
};

FolderSkeleton.propTypes = {
  theme: PropTypes.string,
};

export default FolderSkeleton;
