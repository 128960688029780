import React from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import { Form } from 'react-bootstrap';
import './style.css';

const SearchableDropdown = props => {
  const {
    className,
    disabled,
    isMulti,
    label,
    onChange,
    onCreateOption,
    options,
    placeholder,
    required,
    value,
  } = props;
  return (
    <Form.Group className={className} controlId="formBasicName">
      <Form.Label className="label-text">{label}</Form.Label>
      {required && (
        <Form.Label className="label-text text-danger">*</Form.Label>
      )}
      <CreatableSelect
        classNamePrefix="custom-dropdown-select"
        value={value}
        isMulti={isMulti}
        isDisabled={disabled}
        isClearable={false}
        isSearchable={true}
        onChange={onChange}
        onCreateOption={onCreateOption}
        placeholder={placeholder}
        options={options}
        // minMenuHeight={true}
        // menuIsOpen={true}
      />
    </Form.Group>
  );
};

SearchableDropdown.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onCreateOption: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.any,
};

export default SearchableDropdown;
