import React, { useEffect } from 'react';
import { LOCAL_STORAGE_CONSTANT } from '../../constants/app';
import { extractJSON } from '../../services/jsonUtil';
import { getPromptLink } from '../../utils/helpers';

const HelpAndSupport = () => {
  useEffect(() => {
    const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
    if (window.Intercom) {
      const properties = {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      };
      if (user?.id) {
        properties.name = user.attributes.name;
        properties.email = user.attributes.email;
        properties.user_id = user.id;
        properties.country_code = user.attributes.country_code;
        properties.phone = user.attributes.mobile;
      }
      window.Intercom('boot', properties);
      window.Intercom('show');
    }
    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, []);
  return (
    <div className=" help-support-wrapper">
      <div className="d-flex flex-column h-100 help-support">
        <h4>Got Stuck?</h4>
        <p className="m-0">
          Need any help or support from us? Feel free to write us on{' '}
        </p>
        <a data-rel="external" href={getPromptLink('jen@jen.health')}>
          jen@jen.health
        </a>
      </div>
    </div>
  );
};

export default HelpAndSupport;
