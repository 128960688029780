import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import {
  DO_GET_QUESTIONNAIRES_REQUEST,
  DO_POST_QUESTIONNAIRES_ANSWERS_REQUEST,
  DO_SUBMIT_QUESTIONNAIRE_ANSWER_REQUEST,
  TRIGGER_FILL_ANSWER_FOR_QUESTION,
} from '../../constant/questionnaires';

import { createNotification } from '../../../services/Notification';
import { errorHandler } from '../../../utils/helpers';
import {
  doGetQuestionnairesSuccess,
  doGetQuestionnairesFailure,
  setIsLoading,
  setPostingResponse,
  setFilledAnswers,
  doPostQuestionnairesAnswersRequest,
  setActiveQuestion,
  setShowQuestionnaireOverlay,
  setShowSubQuestionModal,
  resetQuestionnaireState,
  setactivityInputs,
  setQuestionnairealreadyAnsweredQuestions,
} from '../../action/questionnaires';
import {
  getQuestionnaireRequest,
  postQuestionnaireAnswerRequest,
} from '../../../api/questionnaire';

function* getQuestionnaires({ payload }) {
  const { id } = payload || {};
  yield put(setIsLoading(true));
  try {
    const response = yield call(getQuestionnaireRequest, id);
    const questionnaire = response?.data?.response?.data || {};
    const questions = questionnaire?.attributes?.questions || [];
    const alreadyAnsweredQuestions = {};
    const answers = questions.reduce((obj, question) => {
      obj[question?.id] = question?.answer;
      alreadyAnsweredQuestions[question?.id] = !!question?.answer;
      return obj;
    }, {});
    const activityInputs = questions.reduce((obj, question) => {
      if (question?.question_type === 'text') {
        const value = Array.from({
          length: question?.answer?.length,
        });
        obj[question?.id] = value.length ? value : [undefined];
      }
      return obj;
    }, {});
    yield put(
      setQuestionnairealreadyAnsweredQuestions(alreadyAnsweredQuestions),
    );
    yield put(setactivityInputs(activityInputs));
    yield put(setFilledAnswers(answers));
    yield put(doGetQuestionnairesSuccess(response?.data?.response?.data));
  } catch (error) {
    yield put(doGetQuestionnairesFailure({}));
    createNotification(errorHandler(error));
  }
  yield put(setIsLoading(false));
}

function* postQuestionnairesAnswers({ payload }) {
  const { activeQuestion, questionnaires } = yield select(
    state => state.questionnaires,
  );
  const questions = questionnaires?.attributes?.questions || [];
  const totalQuestions = questions.length - 1;
  const { id, data } = payload || {};
  yield put(setPostingResponse(true));
  try {
    yield call(postQuestionnaireAnswerRequest, id, data);
    if (activeQuestion === totalQuestions) {
      yield put(setShowQuestionnaireOverlay(false));
      yield put(resetQuestionnaireState());
    } else {
      yield put(setActiveQuestion(activeQuestion + 1));
      yield put(setShowSubQuestionModal(false));
    }
  } catch (error) {
    createNotification(errorHandler(error));
  } finally {
    yield put(setPostingResponse(false));
  }
}

function updateRadioButtonValue(
  question,
  filledAnswers,
  showSubQuestionModal,
  value,
) {
  const updatedFilledAnswers = { ...filledAnswers };
  if (!updatedFilledAnswers[question?.id]) {
    updatedFilledAnswers[question?.id] = {};
  }
  if (showSubQuestionModal) {
    updatedFilledAnswers[question?.id].rateable_infos = value;
  } else {
    updatedFilledAnswers[question?.id] = {
      ...value,
    };
  }
  return updatedFilledAnswers;
}

function updateValueForActivityInput(question, filledAnswers, payload) {
  const updatedFilledAnswers = { ...filledAnswers };
  updatedFilledAnswers[question?.id] = payload;
  return updatedFilledAnswers;
}

function updateValueForActivityRating(question, filledAnswers, payload) {
  const updatedFilledAnswers = { ...filledAnswers };
  updatedFilledAnswers[question?.id] = { ...payload };
  return updatedFilledAnswers;
}

function updateValueForActivityCheckbox(question, filledAnswers, payload) {
  const updatedFilledAnswers = { ...filledAnswers };
  updatedFilledAnswers[question?.id] = payload;
  return updatedFilledAnswers;
}

function* fillAnswer({ payload }) {
  const {
    activeQuestion,
    filledAnswers,
    questionnaires,
    showSubQuestionModal,
  } = yield select(state => state.questionnaires);
  const questions = questionnaires?.attributes?.questions || [];
  const currentQuestion = questions[activeQuestion];
  let updatedFilledAnswers;
  switch (currentQuestion?.question_type) {
    case 'radiobutton':
      updatedFilledAnswers = updateRadioButtonValue(
        currentQuestion,
        filledAnswers,
        showSubQuestionModal,
        payload,
      );
      break;
    case 'text':
      updatedFilledAnswers = updateValueForActivityInput(
        currentQuestion,
        filledAnswers,
        payload,
      );
      break;
    case 'rating':
      updatedFilledAnswers = updateValueForActivityRating(
        currentQuestion,
        filledAnswers,
        payload,
      );
      break;
    case 'checkbox':
      updatedFilledAnswers = updateValueForActivityCheckbox(
        currentQuestion,
        filledAnswers,
        payload,
      );
      break;
    default:
      updatedFilledAnswers = { ...filledAnswers };
      break;
  }
  yield put(setFilledAnswers(updatedFilledAnswers));
}

function* submitQuestionnaireAnswer({ payload }) {
  const { planId, scheduleId } = payload || {};
  const { activeQuestion, filledAnswers, questionnaires } = yield select(
    state => state.questionnaires,
  );
  const questions = questionnaires?.attributes?.questions || [];
  const currentQuestion = questions[activeQuestion];
  const currentAnswer = filledAnswers[currentQuestion?.id];
  let data;
  if (currentQuestion?.question_type === 'radiobutton') {
    data = {
      plan_id: planId,
      question_id: currentQuestion?.id,
      schedule_id: scheduleId,
      answer: {
        option_id: currentAnswer.id,
        ratings: currentAnswer.rateable_infos.map(item => ({
          rateable_question_id: item.id,
          rating: item.value,
        })),
      },
    };
  } else if (currentQuestion?.question_type === 'text') {
    data = {
      plan_id: planId,
      question_id: currentQuestion?.id,
      schedule_id: scheduleId,
      answer: currentAnswer.map(item => ({
        value: item.value,
        ratings: item.rateable_infos.map(rating => ({
          rateable_question_id: rating.id,
          rating: rating.value,
        })),
      })),
    };
  } else if (currentQuestion?.question_type === 'rating') {
    data = {
      plan_id: planId,
      question_id: currentQuestion?.id,
      schedule_id: scheduleId,
      answer: {
        ratings: currentAnswer.rateable_infos.map(rating => ({
          rateable_question_id: rating.id,
          rating: rating.value,
        })),
      },
    };
  } else if (currentQuestion?.question_type === 'checkbox') {
    data = {
      plan_id: planId,
      question_id: currentQuestion?.id,
      schedule_id: scheduleId,
      answer: currentAnswer.map(item => ({
        option_id: item.id,
        ratings: item.rateable_infos.map(rating => ({
          rateable_question_id: rating.id,
          rating: rating.value,
        })),
      })),
    };
  }
  if (data) {
    yield put(
      doPostQuestionnairesAnswersRequest({
        id: questionnaires.id,
        data,
      }),
    );
  }
}

/**
 * Watch questionnaires actions
 * */
export default function* questionnairesSaga() {
  yield all([
    takeLatest(DO_GET_QUESTIONNAIRES_REQUEST, getQuestionnaires),
    takeLatest(
      DO_POST_QUESTIONNAIRES_ANSWERS_REQUEST,
      postQuestionnairesAnswers,
    ),
    takeLatest(TRIGGER_FILL_ANSWER_FOR_QUESTION, fillAnswer),
    takeLatest(
      DO_SUBMIT_QUESTIONNAIRE_ANSWER_REQUEST,
      submitQuestionnaireAnswer,
    ),
  ]);
}
