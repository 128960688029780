import { errorHandler } from '../utils/helpers';
import { createNotification } from './Notification';

function execute(callback) {
  if (window.grecaptcha) {
    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise
        .execute(process.env.REACT_APP_GRECAPTCHA_ID, {
          action: 'login',
        })
        .then(token => {
          callback(token);
        })
        .catch(e => {
          createNotification(errorHandler(e));
        });
    });
  } else {
    callback();
  }
}

export const grecaptcha = {
  execute,
};
