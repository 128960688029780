import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import NavFooter from '../components/NavFooter';
import Footer from '../components/Footer';
import FolderModal from '../components/FolderModal';
import PaymentModal from '../components/PaymentModal';
import SkipSelfAssessment from '../components/SkipSelfAssessment';
import { APP_ROUTES } from '../route';
import { getUserAttribute, isLoggedIn } from '../utils/helpers';
import { doToggleEmailModel, setSelectedFilters } from '../store/action/common';
import { LOCAL_STORAGE_CONSTANT } from '../constants/app';
import { extractJSON } from '../services/jsonUtil';
import Tour from '../components/Tour';
import EmailModal from '../components/EmailModal';
import SeoTags from '../components/SeoComponent';
import { getCanonicalLink, SeoTexts } from '../services/SEO';
import { encodeBase64Value } from '../utils/url';

const PrivateRoute = props => {
  let {
    activeSection,
    component: Component,
    enableAutoPlay,
    hideFooter,
    ...rest
  } = props;
  const dispatch = useDispatch();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const orderParam = searchParams.get('order');
  const onBoardingCompleted = getUserAttribute('onboarding_completed');
  const showSelfAssessmentBtn =
    rest.path.includes(APP_ROUTES.SELF_ASSESSMENT) && !onBoardingCompleted;

  if (!onBoardingCompleted) {
    hideFooter = true;
  }

  useEffect(() => {
    const retreivedFilters =
      extractJSON(LOCAL_STORAGE_CONSTANT.SELECTED_FILTERS) || [];
    if (retreivedFilters?.length) {
      dispatch(
        setSelectedFilters({
          selectedFilters: retreivedFilters,
          skipSave: true,
        }),
      );
    }
    const email = getUserAttribute('email');
    if (!email && onBoardingCompleted) {
      dispatch(doToggleEmailModel(true));
    }
  }, []);

  const inOnBoardingFlow = (() => {
    switch (rest.path) {
      case APP_ROUTES.SELF_ASSESSMENT:
      case APP_ROUTES.FITNESS:
      case APP_ROUTES.PROFILE:
      case APP_ROUTES.SELF_ASSESSMENT_DETAILED_VIDEO:
        return true;
      default:
        return false;
    }
  })();
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn() ? (
          !onBoardingCompleted && !inOnBoardingFlow && !orderParam ? (
            <Redirect
              to={{
                pathname: APP_ROUTES.FITNESS,
                search: props.location.search
                  ? `?page=${encodeBase64Value(
                      props.location.pathname + props.location.search,
                    )}`
                  : undefined,
                state: { from: props.location },
              }}
            />
          ) : (
            <Tour>
              <div
                id="main-body"
                className={clsx(
                  'main-body',
                  hideFooter && !showSelfAssessmentBtn && 'h-100 mh-100',
                )}
              >
                <Component enableAutoPlay={enableAutoPlay} {...props} />
                {showSelfAssessmentBtn && (
                  <SkipSelfAssessment forDisplays={['ipad', 'mobile']} />
                )}
                {!hideFooter && (
                  <NavFooter {...props} activeSection={activeSection} />
                )}
                <FolderModal />
              </div>
              {showSelfAssessmentBtn && (
                <SkipSelfAssessment forDisplays={['desktop']} />
              )}
              {!hideFooter && <Footer />}
              <PaymentModal />
              <EmailModal path={rest.path} />
              <SeoTags
                title={SeoTexts.JEN_WEB_TITLE}
                canonicalLink={getCanonicalLink()}
                metaDescription={SeoTexts.JEN_WEB_DESCRIPTION}
                ogImgUrl={SeoTexts.JEN_WEB_OG_IMAGE_URL}
                twitterImgUrl={SeoTexts.JEN_WEB_TWITTER_IMAGE_URL}
              />
            </Tour>
          )
        ) : (
          <Redirect
            to={{
              pathname: APP_ROUTES.ROOT,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  activeSection: PropTypes.string,
  component: PropTypes.any,
  enableAutoPlay: PropTypes.bool,
  location: PropTypes.object,
  hideFooter: PropTypes.bool,
  rest: PropTypes.shape({
    exact: PropTypes.bool,
    path: PropTypes.string,
    location: PropTypes.object,
  }),
};

export default PrivateRoute;
