import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import clsx from 'clsx';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { getDisplayType } from '../../../utils/helpers';
import LazyImage from '../../../ui-core/Image';
import { IMAGE_CONSTANT } from '../../../constants/app';
import '../style.css';

const Steps = ({ freeTrialPage }) => {
  const displayType = getDisplayType(useWindowWidth());
  const isMobileScreen = displayType === 'mobile';
  const testimonials = [
    {
      src: IMAGE_CONSTANT.Step1,
      title: 'Take a quick assessment',
      description: 'to understand your baseline',
      freetrial: 'Take a quick assessment to help us understand your baseline',
    },
    {
      src: IMAGE_CONSTANT.Step2,
      title: 'We bring exercise plans',
      description:
        'for you specific to your needs led by the best doctors of physical therapy',
      freetrial:
        'Get exercise plans specific to your needs led by the best doctors of physical therapy',
    },
    {
      src: IMAGE_CONSTANT.Step3,
      title: 'Monthly Progress updates',
      description:
        'We meet with you monthly to discuss your progress and support you in your journey',
      freetrial:
        'We meet with you monthly to discuss your progress and bring you expert guidance',
    },
    {
      src: IMAGE_CONSTANT.Step4,
      title: 'Build long term health',
      description: 'Become pain free and build longterm health',
      freetrial:
        'Become pain free, improve mobility and build long term health',
    },
  ];
  const renderTestimonials = () => {
    const testimonialsOutput = testimonials?.map((item, index) => (
      <Col sm={12} md={3} key={`step-${index + 1}`}>
        <p className={clsx('mb-4', isMobileScreen && 'mt-3')}>
          {'Step ' + (index + 1)}
        </p>
        <LazyImage
          alt={`step-${index + 1}`}
          className="img-fluid steps-images mb-3"
          errorSrc={IMAGE_CONSTANT.placeholderImage}
          placeholderSrc={IMAGE_CONSTANT.placeholderImage}
          src={item.src}
        />
        <h5 className="steps-h5 mb-3">{item.freetrial}</h5>
        {/* {!freeTrialPage && <p className="steps-para">{item.description}</p>} */}
      </Col>
    ));
    return testimonialsOutput;
  };
  return (
    <Container className={freeTrialPage && 'free-trial-how-works'}>
      <Row className="steps-row">
        <Col>
          <h1
            className={clsx(
              'section-title text-center mb-5',
              isMobileScreen && 'mt-5',
            )}
          >
            How It Works
          </h1>
        </Col>
      </Row>
      <Row className="text-center pb-5 pt-sm-0 pb-sm-5 row">
        {renderTestimonials()}
      </Row>
    </Container>
  );
};

Steps.propTypes = {
  freeTrialPage: PropTypes.bool,
};

export default Steps;
