import cookies from 'js-cookie';

class Cookies {
  static get(key) {
    return JSON.parse(cookies.get(key) || null);
  }

  static set(key, value) {
    return cookies.set(key, JSON.stringify(value));
  }

  static remove(key) {
    cookies.remove(key);
  }
}

export default Cookies;
