import { getItem, setItem } from '../utils/cache';

export const updateCachedPages = (key, id, newValue) => {
  const cachedItem = getItem(key) ? JSON.parse(getItem(key)) : {};
  let pageIndex = -1;
  Object.keys(cachedItem).forEach(page => {
    const data = cachedItem[page].data ?? [];
    const isItemPresent = data.find(item => item.id === id);
    if (isItemPresent) {
      pageIndex = +page;
      return null;
    }
  });
  if (pageIndex !== -1) {
    cachedItem[pageIndex] = newValue;
  }
  setItem(key, JSON.stringify(cachedItem));
};
