import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import clsx from 'clsx';
import Carousel from 'react-multi-carousel';

import QuoteIcon from '../../../ui-core/Quote';
import ReadMore from '../../../components/ReadMore';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { getDisplayType } from '../../../utils/helpers';
import { CAROUSEL_BREAKPOINT } from '../../../constants/app';

import './style.css';

const Testimonial = ({ headerText, testimonials, isCarouselAlways }) => {
  const displayType = getDisplayType(useWindowWidth());
  const isMobileScreen = displayType === 'mobile';
  const isIpadScreen = displayType === 'ipad';

  const renderTestimonials = () => {
    const testimonialsOutput = testimonials?.map((item, index) => (
      <div
        key={`testimonial-${index + 1}`}
        className="item postion-relative d-flex flex-column justify-content-between"
      >
        {isMobileScreen ? (
          Array.from({ length: 4 }).map((_, index) => (
            <div
              style={{
                right: '40px',
                top: '-15px',
              }}
              className="position-absolute"
              key={`testimonial-item-${index + 1}`}
            >
              <QuoteIcon key={`quote-icon-${index + 1}`} />
            </div>
          ))
        ) : (
          <div
            style={{
              right: '40px',
              top: '-15px',
            }}
            className="position-absolute"
          >
            <QuoteIcon />
          </div>
        )}
        <ReadMore
          text={item.testimonial}
          readMoreText="Read More"
          readLessText="Less"
          textLimit={209}
        />
        <p className="name-para">{item.name}</p>
      </div>
    ));
    return testimonialsOutput;
  };

  return (
    <section
      className={clsx(
        !isMobileScreen
          ? 'home-testimonial full-body-testimonials'
          : 'pt-5 home-testimonial full-body-testimonials',
      )}
    >
      <div className="container">
        <Row>
          <Col>
            <h3 className="text-center h-3 ms-5 me-5 sec-head">{headerText}</h3>
          </Col>
        </Row>
        <Row className={clsx('px-lg-5', isMobileScreen && 'mt-3')}>
          <Col className="position-relative">
            {!isCarouselAlways && isMobileScreen ? (
              <div className="doctors-advice d_c">{renderTestimonials()}</div>
            ) : (
              <Carousel
                responsive={CAROUSEL_BREAKPOINT}
                className="doctors-advice d_c"
                showDots={true}
                swipeable={isMobileScreen || isIpadScreen}
                draggable={isMobileScreen || isIpadScreen}
              >
                {renderTestimonials()}
              </Carousel>
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
};

Testimonial.propTypes = {
  headerText: PropTypes.string,
  testimonials: PropTypes.array.isRequired,
  isCarouselAlways: PropTypes.bool,
};

Testimonial.defaultProps = {
  headerText: 'Testimonials',
  isCarouselAlways: false,
};

export default Testimonial;
