import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useWindowWidth from '../../hooks/useWindowWidth';
import { IMAGE_CONSTANT, TEXT_CONSTANT } from '../../constants/app';
import {
  formatCurrency,
  getSubscriptionChargeDate,
  getDisplayType,
  getMonthName,
  getPercentageSaved,
} from '../../utils/helpers';
import dayjs from 'dayjs';

const StartFreeTrial = props => {
  const { products, selectedPlan } = useSelector(state => state.product);
  const displayType = getDisplayType(useWindowWidth());
  const isDesktopScreen = displayType === 'desktop';
  const isMobileScreen = displayType === 'mobile';
  const isIpadScreen = displayType === 'ipad';

  const subscriptionChargeDate = getSubscriptionChargeDate(7);
  const getEndDate =
    getMonthName(subscriptionChargeDate) +
    ' ' +
    subscriptionChargeDate.getDate();
  const currentDate = dayjs();
  const discountEndDate = dayjs('2053-11-27T23:59:59');
  const steps = [
    {
      title: 'Today - Free Access',
      description: 'Get instant access & see how it can change your life.',
      icon: IMAGE_CONSTANT.lockIcon,
      alt: 'lock',
      backgroundIconColor: '#B8E2E0',
    },
    {
      title: 'Day 5',
      description:
        'We’ll remind you with an email or notification that your trial is ending',
      icon: IMAGE_CONSTANT.bellIconSvg,
      alt: 'bell',
    },
    {
      title: 'Day 7',
      description: `You’ll be charged on ${getEndDate}, cancel anytime before`,
      icon: IMAGE_CONSTANT.cardIcon,
      alt: 'card',
    },
  ];
  const offValue = getPercentageSaved(products);

  return (
    <>
      <div className="start-free-trail">
        {steps.map((step, index) => (
          <div className="trial-steps" key={`trial-steps-${index}`}>
            <div
              className="step-icons"
              style={{ backgroundColor: step.backgroundIconColor ?? '#fff' }}
            >
              <img src={step.icon} alt={step.alt} />
            </div>
            <div className="step-text">
              <h5>{step.title}</h5>
              <p>{step.description}</p>
            </div>
          </div>
        ))}
        {currentDate < discountEndDate && (
          <div className="off-tag-ribbon">
            <span>
              <i className="left"></i>
              {offValue}% off
              <i className="right"></i>
            </span>
          </div>
        )}
        {selectedPlan && (
          <h5 className="free-trail-text">
            {TEXT_CONSTANT.FREE_TRIAL_TEXT}
            {currentDate < discountEndDate && (
              <span className="cut-price-main">
                {formatCurrency(300, selectedPlan.attributes.currency, 2)}
              </span>
            )}{' '}
            {
              <span className="offer-price">
                {formatCurrency(
                  +selectedPlan?.attributes?.amount_in_cents / 100,
                  selectedPlan?.attributes?.currency,
                )}
              </span>
            }
            {
              <span className="per-year">
                {formatCurrency(' per ' + selectedPlan?.attributes?.interval)}
              </span>
            }
          </h5>
        )}
        <p onClick={props.toggleViewAll} className="view-all-plans">
          {TEXT_CONSTANT.VIEW_ALL_PLANS}
        </p>
        {isDesktopScreen && (
          <div>
            <p className="h-t-c">{TEXT_CONSTANT.HOW_TO_CANCEL}</p>
            <p className="c-p-t">{TEXT_CONSTANT.CANCEL_PROCESS_TEXT}</p>
          </div>
        )}
      </div>
      {(isMobileScreen || isIpadScreen) && !props.showSubscription && (
        <div className="mt-3">
          <div className="ms-auto me-auto">
            <p className="h-t-c  px-lg-0 px-3">{TEXT_CONSTANT.HOW_TO_CANCEL}</p>
            <p className="c-p-t  px-lg-0 px-3">
              {TEXT_CONSTANT.CANCEL_PROCESS_TEXT}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

StartFreeTrial.propTypes = {
  toggleViewAll: PropTypes.func.isRequired,
  showSubscription: PropTypes.bool,
};

export default StartFreeTrial;
