import React, { useRef } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Badge } from 'react-bootstrap';
import {
  IMAGE_CONSTANT,
  STATUS,
  TOUR_TARGET_ELEMENT,
} from '../../constants/app';
import { toggleFolderModal } from '../../store/action/folders';
import { getUserAttribute } from '../../utils/helpers';
import { getDuration, getProgressBarWidth } from '../../services/String';
import LazyImage from '../../ui-core/Image';
import StatusBadge from '../StatusBadge';
import { toggleTrialModal } from '../../store/action/common';
import { SEGMENT_EVENTS, trackEvent } from '../../utils/Segment';

import '../SelfAssessmentBlock/style.css';

const SelfAssessmentBlock = props => {
  const dispatch = useDispatch();
  const imageClickRef = useRef(false);
  const minHeight = props.bodyText ? '360px' : '314px';
  const marginBottom = props.bodyText ? undefined : '16px';
  const height = props.bodyText ? '168px' : '170px';
  const onBoardingCompleted = getUserAttribute('onboarding_completed');

  const handleClick = () => {
    if (!imageClickRef.current) {
      let properties = {
        page: props.page,
        video_id: props.id,
        video_name: props.head3,
        video_type: props.type || 'video',
        video_status: props.status,
      };
      if (props.extraTrackingInfo) {
        properties = {
          ...properties,
          ...props.extraTrackingInfo,
        };
      }
      trackEvent(SEGMENT_EVENTS.VIDEO_CLICKED, properties);
      if (!props.can_view) {
        if (props.handlePurchase) {
          props.handlePurchase();
        } else {
          const page = encodeURI(
            window.location.pathname + window.location.search,
          );
          dispatch(toggleTrialModal({ open: true, page }));
        }
      } else {
        props.onClick && props.onClick();
      }
    } else {
      imageClickRef.current = false;
    }
  };
  const progressBarWidth =
    props.status === STATUS.IN_PROGRESS
      ? getProgressBarWidth(props.playbackEndedAt, props.duration)
      : props.status === STATUS.COMPLETED
      ? '100%'
      : '0%';

  const openFolderModal = () => {
    const reduxKey = props.reduxKey ?? 'selfAssessment';
    !props.saved &&
      dispatch(
        toggleFolderModal({
          showFolderModal: true,
          selectedModal: 'select',
          folderModalOptions: {
            videoId: props.id,
            videoTitle: props.head3,
            videoType: props.type || 'video',
            videoStatus: props.status,
            page: props.page,
            reduxKey,
          },
        }),
      );
  };

  const handleStarClick = () => {
    imageClickRef.current = true;
    if (!props.can_view) {
      if (props.handlePurchase) {
        props.handlePurchase();
      } else {
        trackEvent('Free_Trial_Modal', {
          page_name: props.page,
          video_id: props.id,
          video_name: props.head3,
        });
        dispatch(toggleTrialModal({ open: true }));
      }
    } else {
      openFolderModal();
    }
  };
  return (
    <div
      className="selfAssessment-block"
      data-tour-id={props.dataTourId}
      onClick={handleClick}
      style={{ minHeight: minHeight }}
    >
      <div className="video-box bg-wgite">
        <div className="video position-relative" style={{ height: height }}>
          <img
            className="videoPlay position-absolute rounded-circle"
            src={IMAGE_CONSTANT.playIcon}
            alt="play"
          />
          <div className="d-flex justify-content-between position-absolute over-content-top w-100 p-3">
            <div>
              {props.status && props.status !== 'not_started' && (
                <StatusBadge status={props.status} />
              )}
            </div>
            {onBoardingCompleted && (
              <div className="px-2" onClick={handleStarClick}>
                <img
                  id={`star-id-${props.id}`}
                  data-tour-id={TOUR_TARGET_ELEMENT.save_video}
                  src={
                    props.can_view
                      ? props.saved
                        ? IMAGE_CONSTANT.starIconFilled
                        : IMAGE_CONSTANT.starIcon
                      : IMAGE_CONSTANT.lockIconNew
                  }
                  alt="star"
                />
              </div>
            )}
          </div>
          <LazyImage
            alt="poster"
            className="img-fluid"
            errorSrc={IMAGE_CONSTANT.videoThumbnail}
            placeholderSrc={IMAGE_CONSTANT.placeholderImage}
            src={props.src ? props.src : IMAGE_CONSTANT.videoThumbnail}
          />
          <div className="time position-absolute text-white">
            {props.duration ? getDuration(props.duration) : '00:00'}
          </div>
          <div
            className={`progressbar position-absolute ${props.status}`}
            style={{ width: progressBarWidth }}
          ></div>
        </div>
        <div className="content">
          <h3 style={{ marginBottom: marginBottom }}>
            {props.head3 ? props.head3 : null}
          </h3>
          {props?.body && props?.body?.length !== 0 && props.bodyText && (
            <div className="best">
              <p className="m-0">
                <span className="org">
                  <strong className="me-2">{props.bodyText}</strong>
                  {props?.body?.length !== 0 ? (
                    <span className="d-inline">{props?.body?.join(', ')}</span>
                  ) : (
                    'No Data'
                  )}
                </span>
                {props?.body?.join(', ').length > 67 &&
                props.body.length > 3 ? (
                  <Badge className="self-badge">
                    +{props?.body?.length - 3}
                  </Badge>
                ) : null}
              </p>
            </div>
          )}
          {props?.equip && props?.equip?.length !== 0 && props.equipText && (
            <div className="best2 mb-0">
              <p className="m-0">
                <span className="org">
                  <strong className="me-2">{props.equipText}</strong>
                  {props?.equip?.length !== 0 ? (
                    <span className="d-inline">{props?.equip.join(', ')}</span>
                  ) : (
                    'No Data'
                  )}
                </span>
                {props?.equip?.join(', ').length > 70 &&
                props.equip.length > 3 ? (
                  <Badge className="self-badge">
                    +{props?.equip?.length - 3}
                  </Badge>
                ) : null}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SelfAssessmentBlock.propTypes = {
  src: PropTypes.string,
  head3: PropTypes.string,
  body: PropTypes.array,
  phase: PropTypes.string,
  equip: PropTypes.array,
  duration: PropTypes.string,
  history: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  onStarClick: PropTypes.func,
  playbackEndedAt: PropTypes.string,
  bodyText: PropTypes.string,
  level: PropTypes.string,
  equipText: PropTypes.string,
  extraTrackingInfo: PropTypes.object,
  saved: PropTypes.bool,
  status: PropTypes.string,
  section: PropTypes.string,
  reduxKey: PropTypes.string,
  can_view: PropTypes.bool.isRequired,
  page: PropTypes.string,
  type: PropTypes.string,
  dataTourId: PropTypes.string,
  handlePurchase: PropTypes.func,
};

export default withRouter(SelfAssessmentBlock);
