import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Video from '../../../ui-core/Video';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { getDisplayType } from '../../../utils/helpers';
import { VIDEO_CONSTANT } from '../../../constants/app';

const NeckPain = () => {
  const displayType = getDisplayType(useWindowWidth());
  const isMobileScreen = displayType === 'mobile';
  return (
    <section className="why-jen-neck">
      <Container>
        <Row className="px-lg-5 px-2 justify-content-center">
          <Col lg={12}>
            <Row className="align-items-center">
              <Col md={6} xs={isMobileScreen && { order: 'last' }}>
                <h4 className="h-3 sec-head">
                  Does your neck hurt after staring at the screen too long? Back
                  hurts after sitting too long?
                </h4>
                {/* <h5 className="mb-4">Back hurts after sitting too long?</h5> */}
                <ul className="list-unstyled w-j-p pe-lg-2">
                  <li>
                    <p>
                      We bring you <b>quick tips</b> that bring you relief in
                      under 1 minute!
                    </p>
                  </li>
                  <li>
                    <p>
                      Trusted by <b>millions</b> of people worldwide
                    </p>
                  </li>
                  {/* <li>
                    <p>
                      Improve <b>mobility</b>, find relief from <b>pain</b> and
                      maintain <b>long term health.</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      Gain <b>knowledge for life</b> and discover what works for
                      your body.
                    </p>
                  </li>
                  <li>
                    <p>
                      Save up to <b>thousands of dollars annually</b> in overall
                      healthcare costs
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>No more waiting for weeks&nbsp;</b>
                      to get the guidance you need.
                    </p>
                  </li> */}
                </ul>
              </Col>
              <Col md={6}>
                <Video
                  mp4={VIDEO_CONSTANT.neckPainVideoMp4}
                  removeIntersectionObserver
                  webm={VIDEO_CONSTANT.neckPainVideoWebm}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NeckPain;
