import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { removeItem, setItem } from '../../../utils/cache';
import {
  DO_SIGNUP_REQUEST,
  DO_LOGIN_REQUEST,
  DO_LOGOUT_REQUEST,
  VERIFY_OTP_REQUESTED,
  DO_OTP_RESEND_REQUEST,
  DO_WAITLIST_SURVEY_REQUEST,
  DO_SURVEY_REQUEST,
  DO_APPLE_SIGNIN_REQUEST,
} from '../../constant/auth';
import {
  doSignupSuccess,
  doSignupFailed,
  verifyOtpSuccess,
  verifyOtpFailed,
  doLoginSuccess,
  doLoginFailed,
  doOtpResendSuccess,
  doSurveySuccess,
  doWaitlistSurveySuccess,
} from '../../action/auth';
import { doPlaybackHistoryRequest, setLoadingState } from '../../action/common';
import {
  loginPostRequest,
  signupPostRequest,
  otpPostRequest,
  otpResendPostRequest,
  surveyPostRequest,
  appleSignInRequest,
} from '../../../api/auth';
import { waitlistSurveyGetRequest } from '../../../api/waitlistSurvey';
import {
  createNotification,
  NOTIFICATION_TYPE,
} from '../../../services/Notification';
import { SURVEY_STATUS, LOCAL_STORAGE_CONSTANT } from '../../../constants/app';
import { errorHandler } from '../../../utils/helpers';

// select Was also used in 1st import

/**
 * Get tournament details by Id Request
 * */
function* signupRequest({ payload: { data, callback, page } }) {
  try {
    const response = yield call(signupPostRequest, data);
    yield put(doSignupSuccess({ data: response?.data?.response?.data, page }));
    if (callback) {
      callback();
    }
    createNotification({
      type: NOTIFICATION_TYPE.SUCCESS,
      message: 'Verification Code Sent Successfully !',
    });
  } catch (e) {
    doSignupFailed({
      error: e,
      page,
    });
    createNotification(errorHandler(e));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* loginRequest({ payload: { data, callback, page } }) {
  try {
    const response = yield call(loginPostRequest, data);
    yield put(
      doLoginSuccess({
        data: response?.data?.response?.data,
        page,
      }),
    );
    if (callback) {
      callback();
    }
    createNotification({
      type: NOTIFICATION_TYPE.SUCCESS,
      message: 'Verification Code Sent Successfully !',
    });
  } catch (e) {
    doLoginFailed({
      error: e,
      page,
    });
    createNotification(errorHandler(e));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* logoutRequest() {
  try {
    removeItem(LOCAL_STORAGE_CONSTANT.USER);
    removeItem(LOCAL_STORAGE_CONSTANT.SESSION);
  } catch (e) {
    createNotification(errorHandler(e));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* verifyOTPRequest({ payload: { data, callback, page, plan_id } }) {
  const { user } = yield select(state => state.auth);
  const newUser = user?.attributes?.new_user || false;
  data.new_user = newUser;
  try {
    const response = yield call(otpPostRequest, data, plan_id);
    const user = response?.data?.response?.data;
    // user.attributes.onboarding_completed = false;
    if (user) {
      setItem(LOCAL_STORAGE_CONSTANT.USER, JSON.stringify(user));
      setItem(
        LOCAL_STORAGE_CONSTANT.SESSION,
        JSON.stringify(response?.headers),
      );
      const onboardingCompleted = user?.attributes?.onboarding_completed;
      if (onboardingCompleted) {
        yield put(doPlaybackHistoryRequest({ callback }));
      } else {
        callback();
      }
    }
    yield put(verifyOtpSuccess(user));
    createNotification({
      type: NOTIFICATION_TYPE.SUCCESS,
      message: 'Verification Complete !',
    });
  } catch (e) {
    yield put(verifyOtpFailed({ error: e, page }));
    createNotification(errorHandler(e));
  }
}

function* resendOTPRequest({ payload: { data, callback } }) {
  try {
    const response = yield call(otpResendPostRequest, data);
    yield put(doOtpResendSuccess(response?.data?.response?.data));
    if (response?.data?.response?.data?.attributes?.has_access) {
      setItem(
        LOCAL_STORAGE_CONSTANT.USER,
        JSON.stringify(response?.data?.response?.data),
      );
      setItem(
        LOCAL_STORAGE_CONSTANT.SESSION,
        JSON.stringify(response?.headers),
      );
    }
    createNotification({
      type: NOTIFICATION_TYPE.SUCCESS,
      message: 'Verification Code Sent Successfully !',
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    createNotification(errorHandler(e));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* surveyRequest({ payload: { data, callback } }) {
  try {
    const response = yield call(surveyPostRequest, data);
    yield put(doSurveySuccess(response?.data?.response?.data));
    createNotification({
      type: NOTIFICATION_TYPE.SUCCESS,
      message: 'Survey Submitted Successfully !',
    });
    setItem(LOCAL_STORAGE_CONSTANT.SURVEY_STATUS, `${SURVEY_STATUS.DONE}`);
    if (callback) {
      callback();
    }
  } catch (e) {
    yield put(doSurveySuccess({}));
    createNotification(errorHandler(e));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* waitlistSurveyRequest() {
  try {
    const response = yield call(waitlistSurveyGetRequest);
    yield put(doWaitlistSurveySuccess(response.data.response.data));
  } catch (e) {
    if (e?.response?.status !== 404) {
      createNotification(errorHandler(e));
    }
  } finally {
    yield put(setLoadingState(false));
  }
}

function* singupWithApple({ payload: { response, callback } }) {
  const { code, id_token } = response?.authorization || {};
  const name = response?.user?.name
    ? response?.user?.name?.firstName + ' ' + response?.user?.name?.lastName
    : '';
  try {
    const res = yield call(appleSignInRequest, code, id_token, name);
    console.log({ res });
    const user = res?.data?.response?.data;
    // user.attributes.onboarding_completed = false;
    if (user) {
      setItem(LOCAL_STORAGE_CONSTANT.USER, JSON.stringify(user));
      setItem(LOCAL_STORAGE_CONSTANT.SESSION, JSON.stringify(res?.headers));
      const onboardingCompleted = user?.attributes?.onboarding_completed;
      if (onboardingCompleted) {
        yield put(doPlaybackHistoryRequest({ callback }));
      } else {
        callback();
      }
    }
  } catch (error) {
    createNotification(errorHandler(error));
  }
}

/**
 * Watch auth actions
 * */
export default function* authSaga() {
  yield all([
    takeLatest(DO_SIGNUP_REQUEST, signupRequest),
    takeLatest(DO_LOGIN_REQUEST, loginRequest),
    takeLatest(DO_LOGOUT_REQUEST, logoutRequest),
    takeLatest(VERIFY_OTP_REQUESTED, verifyOTPRequest),
    takeLatest(DO_OTP_RESEND_REQUEST, resendOTPRequest),
    takeLatest(DO_SURVEY_REQUEST, surveyRequest),
    takeLatest(DO_WAITLIST_SURVEY_REQUEST, waitlistSurveyRequest),
    takeLatest(DO_APPLE_SIGNIN_REQUEST, singupWithApple),
  ]);
}
