import React, { useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import SubscriptionSelector from '../../../../components/PaymentModal/SubscriptionSelector';
import { OfferWithButton } from '../OfferWithButton';
import { ShouldRender } from '../../../../ui-core/ShouldRender';
import { formatCurrency } from '../../../../utils/helpers';

export const SubscriptionAndOffer = ({
  btnText,
  coupon,
  couponFirst,
  courseDetail,
  hideSubscriptionSelector,
  loading,
  onClick,
  sectionTitle,
  text,
}) => {
  const currentPrice = useMemo(() => {
    return courseDetail?.attributes?.amount
      ? +courseDetail?.attributes?.amount / 100
      : 0;
  }, [courseDetail?.attributes?.amount]);

  const originalPrice = useMemo(() => {
    return currentPrice + (courseDetail?.attributes?.discount || 0);
  }, [currentPrice, courseDetail?.attributes?.discount]);

  return (
    <div className="d-flex align-items-center justify-content-center subscription-section">
      <div className="my-4">
        <ShouldRender check={!courseDetail}>
          {!hideSubscriptionSelector && <SubscriptionSelector />}
        </ShouldRender>
        <ShouldRender check={!!courseDetail}>
          <div className="d-flex align-items-center justify-content-center">
            {Boolean(courseDetail?.attributes?.discount) && (
              <p className="jen-health-price-cut text-dark">
                {formatCurrency(
                  originalPrice,
                  courseDetail?.attributes?.currency,
                  2,
                )}
              </p>
            )}
            <p className="jen-health-price text-dark">
              {formatCurrency(
                currentPrice,
                courseDetail?.attributes?.currency,
                2,
              )}
            </p>
            <p className="jen-health-para text-dark">
              {courseDetail?.attributes?.discount > 0
                ? 'For a limited time'
                : 'only'}
            </p>
          </div>
        </ShouldRender>
        <div className={clsx(!hideSubscriptionSelector && 'my-5')}>
          <OfferWithButton
            btnText={btnText}
            coupon={coupon}
            couponFirst={couponFirst}
            loading={loading}
            onClick={onClick}
            sectionTitle={sectionTitle}
            text={text}
          />
        </div>
      </div>
    </div>
  );
};

SubscriptionAndOffer.propTypes = {
  btnText: PropTypes.string.isRequired,
  coupon: PropTypes.string.isRequired,
  couponFirst: PropTypes.bool,
  courseDetail: PropTypes.object,
  hideSubscriptionSelector: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  sectionTitle: PropTypes.string.isRequired,
  text: PropTypes.string,
};

SubscriptionAndOffer.defaultProps = {
  hideSubscriptionSelector: false,
};
