import React, { useEffect, useState } from 'react';
import PropsTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Image, Modal } from 'react-bootstrap';
import Button from '../../../ui-core/Button/Button';
import InputField from '../../../ui-core/InputField';
import {
  doAddVideoToFolderRequest,
  doCreateFolderRequest,
  doFolderRenameRequest,
  doFolderDeleteRequest,
  doRemoveVideoFromFolderRequest,
  resetFolders,
  doFetchAllFoldersRequest,
} from '../../../store/action/folders';
// import { getDisplayType } from '../../../utils/helpers';
// import useWindowWidth from '../../../hooks/useWindowWidth';
import { IMAGE_CONSTANT, LOCAL_STORAGE_CONSTANT } from '../../../constants/app';
import Ref from '../../../ui-core/Ref';
import Dropdown from '../../../ui-core/CreatableDropdown';
import { removeItem } from '../../../utils/cache';
import {
  SEGMENT_EVENTS,
  trackEvent,
  trackSubmittedEvent,
} from '../../../utils/Segment';

import '../style.css';

export const CreateFolder = ({ handleGoBack }) => {
  const { folderModalOptions, isLoading } = useSelector(state => state.folder);
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const handleChange = event => setName(event.target.value);
  const successCallback = folder => {
    trackEvent(SEGMENT_EVENTS.FOLDER_CREATED, {
      page: folderModalOptions.page || 'Folder Page',
      folder_name: folder?.label,
      folder_id: folder?.value,
    });
    handleGoBack();
  };
  const handleClick = () => {
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      page: folderModalOptions.page || 'Folder Page',
      section_title: 'Create Folder Modal',
      button_title: 'DONE',
    });
    dispatch(doCreateFolderRequest({ name, callBack: successCallback }));
  };
  const handleKeyDown = event => {
    if (event.keyCode === 13 && name.trim()) {
      handleClick();
    }
  };

  return (
    <div>
      <div className="folder-select mb" onKeyDown={handleKeyDown}>
        <InputField
          handleChange={handleChange}
          label="Add Folder Name"
          placeholder="Add Name"
          autocomplete={'off'}
        />
      </div>
      <Button
        class="folder-save-btn mx-auto d-flex align-items-center justify-content-around crt-fldr"
        isDisabled={!name.trim()}
        isLoading={isLoading}
        onClick={handleClick}
        text={
          <>
            <span>CREATE FOLDER</span>{' '}
            <Image src={IMAGE_CONSTANT.checkIcon} alt="check-icon" />
          </>
        }
      />
    </div>
  );
};

export const EditFolder = ({ handleClose }) => {
  const dispatch = useDispatch();
  const { folderModalOptions, isLoading } = useSelector(state => state.folder);
  const [name, setName] = useState(folderModalOptions.folderName);
  const history = useHistory();
  const path = history.location.pathname.split('/');
  const handleChange = event => setName(event.target.value);

  const handleClick = () => {
    trackSubmittedEvent(SEGMENT_EVENTS.FOLDER_EDITED, {
      page: `${path} Page`,
      section_title: 'Edit Folder Modal',
      button_title: 'DONE',
    });
    if (name === folderModalOptions.folderName) {
      handleClose && handleClose();
    } else {
      dispatch(
        doFolderRenameRequest({ folderId: folderModalOptions.folderId, name }),
      );
    }
  };
  const handleKeyDown = event => {
    if (event.keyCode === 13 && name.trim()) {
      handleClick();
    }
  };

  return (
    <div className="add-folder">
      <div className="folder-select mb" onKeyDown={handleKeyDown}>
        <InputField
          value={name}
          handleChange={handleChange}
          label="Rename Folder"
        />
      </div>
      <Button
        class="folder-save-btn mx-auto d-flex align-items-center justify-content-around done"
        isDisabled={!name.trim()}
        isLoading={isLoading}
        onClick={handleClick}
        text={
          <>
            <span>DONE</span>{' '}
            <Image src={IMAGE_CONSTANT.checkIcon} alt="check-icon" />
          </>
        }
      />
    </div>
  );
};

export const DeleteFolder = ({ selectedModal }) => {
  const { folderModalOptions } = useSelector(state => state.folder);
  const renderHeaderText = () => {
    if (selectedModal === 'delete') {
      return `Are you sure you want to delete the folder ${folderModalOptions.folderName}?`;
    } else {
      return `Are you sure you want to remove the video ${folderModalOptions.videoName} from ${folderModalOptions.folderName}?`;
    }
  };
  return (
    <div className="delete-folder">
      <h6 className="text-center delete-para">{renderHeaderText()}</h6>
    </div>
  );
};

export const SelectFolder = ({ handleCreateFolder, selectLast }) => {
  const { allFolders, folderModalOptions, isLoading } = useSelector(
    state => state.folder,
  );
  const { reduxKey, videoId } = folderModalOptions;
  const [selectedFolderId, setSelectedFolderId] = useState();
  const options = allFolders.map(folder => ({
    value: String(folder.id),
    label: folder.name,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (allFolders.length && selectLast) {
      setSelectedFolderId(options[allFolders.length - 1]);
    }
  }, [allFolders]);

  useEffect(() => {
    dispatch(doFetchAllFoldersRequest());
    return () => {
      dispatch(resetFolders());
      removeItem(LOCAL_STORAGE_CONSTANT.FOLDER_PAGES);
    };
  }, []);

  const handleChange = value => setSelectedFolderId(value);

  const handleSave = () => {
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      page: folderModalOptions.page,
      section_title: 'Select Folder Modal',
      button_title: 'SAVE',
    });
    selectedFolderId?.value &&
      dispatch(
        doAddVideoToFolderRequest({
          folderId: [selectedFolderId.value],
          videoId: videoId,
          reduxKey,
          callback: plan => {
            const properties = {
              page: folderModalOptions.page,
              section_title: 'Select Folder Modal',
              button_title: 'SAVE',
              folder_name: selectedFolderId?.label,
              folder_id: selectedFolderId?.value,
              video_id: folderModalOptions.videoId,
              video_type: folderModalOptions.videoType,
              video_name: folderModalOptions.videoTitle,
              video_status: folderModalOptions.videoStatus,
            };
            if (plan) {
              properties.day_id = plan.id;
              properties.plan_id = plan?.attributes?.title;
              properties.plan_name = plan?.attributes?.active_day?.id;
            }
            trackEvent(SEGMENT_EVENTS.VIDEO_SAVED, properties);
          },
        }),
      );
  };

  const handleOptionSelect = newValue => {
    setSelectedFolderId(newValue);
  };

  const handleCreateOption = inputValue =>
    dispatch(
      doCreateFolderRequest({ name: inputValue, callBack: handleOptionSelect }),
    );

  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      handleSave();
    }
  };

  return (
    <div className="select-folder">
      <div className="folder-select" onKeyDown={handleKeyDown}>
        <Dropdown
          disabled={isLoading}
          options={options}
          onChange={handleChange}
          onCreateOption={handleCreateOption}
          placeholder="Choose Folder"
          value={selectedFolderId}
        />
      </div>
      <div className="">
        <Ref
          onClick={handleCreateFolder}
          class="new-folder"
          text="Create New Folder +"
        />
      </div>
      <Button
        class="folder-save-btn mx-auto d-flex align-items-center justify-content-around"
        isDisabled={isLoading || !selectedFolderId?.value || !videoId}
        isLoading={isLoading}
        onClick={handleSave}
        text={
          <>
            <span>SAVE VIDEO</span>{' '}
            <Image src={IMAGE_CONSTANT.checkIcon} alt="check-icon" />
          </>
        }
      />
    </div>
  );
};

export const Footer = ({ handleClose, selectedModal }) => {
  const dispatch = useDispatch();
  const { folderModalOptions, isLoading } = useSelector(state => state.folder);
  if (selectedModal !== 'delete' && selectedModal !== 'remove-video') {
    return null;
  }
  const handleClick = () => {
    if (selectedModal === 'delete') {
      trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
        page: 'Folder Page',
        section_title: 'Delete Folder Modal',
        button_title: 'DELETE',
      });
      dispatch(
        doFolderDeleteRequest({ folderId: folderModalOptions.folderId }),
      );
    } else {
      const { folderId, folderName, videoId, videoName, videoType } =
        folderModalOptions;
      trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
        page: 'Folder Page',
        section_title: 'Unsave Video from Folder Modal',
        button_title: 'REMOVE',
      });
      const payload = {
        folderIds: [folderId],
        videoId,
        callback: () => {
          trackEvent(SEGMENT_EVENTS.VIDEO_UNSAVED, {
            page: `Detailed Folder Page`,
            video_id: videoId,
            video_type: videoType,
            video_name: videoName,
            folder_id: folderId,
            folder_name: folderName,
          });
        },
      };
      dispatch(doRemoveVideoFromFolderRequest(payload));
    }
  };
  return (
    <Modal.Footer>
      <div className="d-flex justify-content-around w-100">
        <Button
          text="CANCEL"
          class="folder-cancel-btn align-items-center"
          onClick={handleClose}
        />
        <Button
          text={selectedModal === 'delete' ? 'DELETE' : 'REMOVE'}
          class="folder-delete-btn align-items-center"
          isLoading={isLoading}
          onClick={handleClick}
        />
      </div>
    </Modal.Footer>
  );
};

CreateFolder.propTypes = {
  fromSelectFolderModal: PropsTypes.bool,
  handleClose: PropsTypes.func,
  handleGoBack: PropsTypes.func,
};

EditFolder.propTypes = {
  handleClose: PropsTypes.func,
};

DeleteFolder.propTypes = {
  selectedModal: PropsTypes.string,
};

SelectFolder.propTypes = {
  handleCreateFolder: PropsTypes.func,
  selectLast: PropsTypes.bool,
};

Footer.propTypes = {
  selectedModal: PropsTypes.string,
  handleClose: PropsTypes.func,
};
