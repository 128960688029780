import {
  DO_FETCH_PRODUCTS_FAILURE,
  DO_FETCH_PRODUCTS_REQUEST,
  DO_FETCH_PRODUCTS_SUCCESS,
  DO_PAYMENT_INITIATION_REQUEST,
  DO_UPDATE_PAYMENT_METHOD_REQUEST,
  PRODUCTS_SET_IS_LOADING,
  SET_SELECTED_PLAN,
} from '../../constant/products';

export const setIsLoading = payload => ({
  type: PRODUCTS_SET_IS_LOADING,
  payload,
});

export const setSelectedPlan = payload => ({
  type: SET_SELECTED_PLAN,
  payload,
});

export const doFetchProductsRequest = payload => ({
  type: DO_FETCH_PRODUCTS_REQUEST,
  payload,
});

export const doFetchProductsSuccess = payload => ({
  type: DO_FETCH_PRODUCTS_SUCCESS,
  payload,
});

export const doFetchProductsFailure = () => ({
  type: DO_FETCH_PRODUCTS_FAILURE,
});

export const doPaymentInitiationRequest = payload => ({
  type: DO_PAYMENT_INITIATION_REQUEST,
  payload,
});

export const doUpdatePaymentMethodRequest = payload => ({
  type: DO_UPDATE_PAYMENT_METHOD_REQUEST,
  payload,
});
