import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '../../ui-core/Button/Button';
import { doHandleCreateExercisePlan } from '../../store/action/user';
import { getDisplayType, getUserAttribute } from '../../utils/helpers';
import useWindowWidth from '../../hooks/useWindowWidth';
import { SEGMENT_EVENTS, trackEvent } from '../../utils/Segment';

import './style.css';

const SkipSelfAssessment = ({ forDisplays, history, match }) => {
  const dispatch = useDispatch();
  const isVideoDetailPage = match.params.videoTitle;
  const text = isVideoDetailPage ? 'Show Exercise Plans' : 'Skip Assessment';
  const page = isVideoDetailPage
    ? 'Detailed Video Page'
    : 'Self Assessment Page';
  const displayType = getDisplayType(useWindowWidth());
  const desktopScreen = displayType === 'desktop';

  const callback = (selectedFilters, video) => {
    const onBoardingCompleted =
      getUserAttribute('onboarding_completed') || false;
    const bodyPart = selectedFilters[0]?.name;
    if (isVideoDetailPage) {
      trackEvent(SEGMENT_EVENTS.SHOW_EXCERCISE_PLANS, {
        onboarding_complete: onBoardingCompleted,
        body_part: bodyPart,
        page,
        video_id: video.id,
        video_name: video?.attributes?.title,
        video_type: video.type,
      });
    } else {
      trackEvent(SEGMENT_EVENTS.SKIP_ASSESSMENT, {
        onboarding_complete: onBoardingCompleted,
        body_part: bodyPart,
        page,
      });
    }
  };
  const handleClick = () => {
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      button_title: text,
      section_title: 'Skip Self Assessments',
      page,
    });
    dispatch(doHandleCreateExercisePlan({ history, callback, page }));
  };
  if (!forDisplays.includes(displayType)) return null;
  if (!desktopScreen) {
    return (
      <div className="skip-step-wrapper-mobile">
        <Button
          text={text}
          class="folder-save-btn text-uppercase text-center m-auto"
          onClick={handleClick}
        />
      </div>
    );
  }
  return (
    <div className="pt-4 overflow-hidden">
      <div className="skip-btn-shadow">
        <div
          className={
            isVideoDetailPage ? 'w-856 px-lg-1 px-0' : 'body-col-2 px-3'
          }
        >
          <Button
            text={text}
            class="folder-save-btn d-block w-100 text-uppercase text-center"
            onClick={() =>
              dispatch(
                doHandleCreateExercisePlan({
                  history,
                  callback,
                  page,
                  trackEvent,
                }),
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

SkipSelfAssessment.propTypes = {
  forDisplays: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(SkipSelfAssessment);
