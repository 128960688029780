import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  IMAGE_CONSTANT,
  LOCAL_STORAGE_CONSTANT,
  TOUR_DESCRIPTION_OBJECT,
} from '../../constants/app';
import { APP_ROUTES, getRoute } from '../../route';
import { TourProvider } from '@reactour/tour';
import { getUserAttribute } from '../../utils/helpers';
import { SEGMENT_EVENTS, trackEvent } from '../../utils/Segment';
import { extractJSON } from '../../services/jsonUtil';
import Button from '../../ui-core/Button/Button';

export const getTourSteps = searchData => {
  const data = [];
  const quickTipsIndex = searchData.findIndex(
    item => item.name === 'Quick Tips',
  );
  searchData.forEach(item => {
    if (TOUR_DESCRIPTION_OBJECT[item.name]) {
      data.push(TOUR_DESCRIPTION_OBJECT[item.name]);
    }
  });
  if (quickTipsIndex !== -1) {
    data.push(TOUR_DESCRIPTION_OBJECT.Save_Video);
  }
  data.push(TOUR_DESCRIPTION_OBJECT.Folders, TOUR_DESCRIPTION_OBJECT.Profile);
  return data;
};

const CustomPopover = props => {
  const { currentStep, steps, setCurrentStep, setIsOpen, onClickClose } = props;
  const nextStep = currentStep + 1;
  const isLastStep = currentStep === steps.length - 1;
  const handleDismiss = () => {
    setIsOpen(false);
    onClickClose();
  };
  const handleClick = () => {
    if (isLastStep) {
      handleDismiss();
    } else {
      setCurrentStep(nextStep);
    }
  };
  return (
    <div className="d-flex flex-column">
      <Button
        class="p-0 ms-auto close-button mb-2"
        onClick={handleDismiss}
        text={
          <img
            src={IMAGE_CONSTANT.closeIcon}
            alt="close"
            className="img-fluid"
          />
        }
      />
      <p>{steps[currentStep].content}</p>
      <Button
        class="folder-save-btn mx-auto d-flex align-items-center justify-content-around crt-fldr"
        onClick={handleClick}
        text={isLastStep ? 'Finish' : 'Next'}
      />
    </div>
  );
};

const Tour = props => {
  const { history } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const { searchInput, searchResults } = useSelector(state => state.search);
  const keywordRef = useRef('');

  useEffect(() => {
    if (searchInput) {
      keywordRef.current = searchInput;
    }
  }, [searchInput]);

  const steps = useMemo(() => {
    const searchData = searchResults?.data || [];
    const data = getTourSteps(searchData);
    return data;
  });

  const trackStep = useCallback(step_number => {
    const freeTrial = getUserAttribute('free_trial');
    const subscriptionDetails = getUserAttribute('subscription_details');
    const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
    if (user?.id) {
      trackEvent(SEGMENT_EVENTS.PRODUCT_TOUR_STEP_COMPLETED, {
        user_id: user.id,
        step: step_number,
        free_trial_active: freeTrial || false,
        subscription_active: subscriptionDetails?.subscribed || false,
      });
    }
  }, []);

  const doBeforeStartActions = useCallback(
    (action = {}) => {
      if (action.type === 'ROUTE_TO') {
        history.push(action.payload);
      } else if (action.type === 'SECTION_CHANGE') {
        const { pathname } = history.location;
        const section = encodeURI(action.payload);
        const searchParam = {
          section,
        };
        const url = getRoute(pathname, {
          searchParam,
        });
        history.push(url);
      }
    },
    [history],
  );

  const moveToNextStep = useCallback(
    nextStep => {
      doBeforeStartActions(steps[nextStep].action);
      trackStep(nextStep);
      setTimeout(() => {
        setCurrentStep(nextStep);
      }, 0);
    },
    [doBeforeStartActions, currentStep, steps],
  );

  const onClickClose = useCallback(() => {
    const url = getRoute(APP_ROUTES.SEARCH, {
      pathParam: {
        keyword: encodeURIComponent(
          keywordRef.current.replace(/[^a-zA-Z0-9 ]/g, ''),
        ), // regular expression to remove special characters
      },
    });
    history.push(url);
  }, [history]);

  return (
    <TourProvider
      className="tour-popover"
      steps={steps}
      currentStep={currentStep}
      setCurrentStep={moveToNextStep}
      ContentComponent={CustomPopover}
      onClickClose={onClickClose}
    >
      {props.children}
    </TourProvider>
  );
};

CustomPopover.propTypes = {
  currentStep: PropTypes.number,
  steps: PropTypes.array,
  setCurrentStep: PropTypes.func,
  setIsOpen: PropTypes.func,
  onClickClose: PropTypes.func,
};

Tour.propTypes = {
  children: PropTypes.node,
  history: PropTypes.object,
};

export default withRouter(Tour);
