import {
  COLLECTION_SET_IS_LOADING,
  DO_GET_COLLECTION_DETAILS_FAILURE,
  DO_GET_COLLECTION_DETAILS_SUCCESS,
  DO_GET_COLLECTION_FAILURE,
  DO_GET_COLLECTION_SUCCESS,
  RESET_COLLECTION_DETAILS,
} from '../../constant/collection';
import { RESET_STORE } from '../../constant/common';

const initialState = {
  isLoading: false,
  collections: null,
  collectionDetail: null,
};

export const collectionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case COLLECTION_SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case DO_GET_COLLECTION_SUCCESS:
      return { ...state, collections: payload };
    case DO_GET_COLLECTION_FAILURE:
      return { ...state, collections: {} };
    case DO_GET_COLLECTION_DETAILS_SUCCESS:
      return { ...state, collectionDetail: payload };
    case DO_GET_COLLECTION_DETAILS_FAILURE:
      return { ...state, collectionDetail: {} };
    case RESET_COLLECTION_DETAILS:
      return { ...state, collectionDetail: null };
    case RESET_STORE:
      return { ...initialState };
    default:
      return state;
  }
};
