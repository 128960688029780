import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { doVideoFailure, doVideoSuccess } from '../../action/video';
import {
  DO_TRACK_VIDEO_EVENT_REQUEST,
  DO_VIDEO_REQUEST,
  UPDATE_PLAYBACK_TIME,
} from '../../constant/video';
import { extractJSON } from '../../../services/jsonUtil';
import { doGetUserDataRequest } from '../../action/user';
import { formatPlayBackTime } from '../../../services/String';
import { trackEvent } from '../../../utils/Segment';
import { videoGetRequest } from '../../../api/video';
import { playbackUpdateRequest } from '../../../api/playback';
import { LOCAL_STORAGE_CONSTANT } from '../../../constants/app';
import { createNotification } from '../../../services/Notification';
import { errorHandler, logOutput } from '../../../utils/helpers';

function* videoRequest({ payload }) {
  const { callback, videoId, plan_id } = payload;
  try {
    const response = yield call(videoGetRequest, videoId, plan_id);
    const video = response.data.response.data;
    if (!video?.attributes?.can_view) {
      if (callback) {
        const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
        if (user?.id) {
          const userId = user.id;
          yield put(doGetUserDataRequest({ userId }));
        }
        callback(video?.id, video?.attributes?.title, user);
      }
    } else {
      yield put(doVideoSuccess(video));
    }
  } catch (error) {
    createNotification(errorHandler(error));
    yield put(doVideoFailure());
  }
}

function* trackVideoEvent({ payload }) {
  const { additionalProps, eventType } = payload || {};
  const { video } = yield select(state => state.video);
  const { curatedPlans } = yield select(state => state.plan);
  const { selectedFolder } = yield select(state => state.folder);
  const playbackStartedAt = formatPlayBackTime(
    additionalProps.playback_started_at,
  );

  const props = {
    video_id: video.id,
    video_name: video?.attributes?.title,
  };
  if (curatedPlans?.id) {
    props.plan_id = curatedPlans?.id;
    props.plan_name = curatedPlans?.title;
    props.day_id = curatedPlans?.active_phase?.active_day?.id;
    props.phase_id = curatedPlans?.active_phase?.id;
  } else if (selectedFolder?.id) {
    props.folder_id = selectedFolder.id;
    props.folder_name = selectedFolder?.attributes?.name;
  }
  if (additionalProps.playback_ended_at) {
    const playbackTime =
      additionalProps.playback_ended_at - additionalProps.playback_started_at;
    const playbackEndedAt = formatPlayBackTime(
      additionalProps.playback_ended_at,
    );
    additionalProps.playback_time = playbackTime;
    additionalProps.playback_ended_at = playbackEndedAt;
  }
  trackEvent(eventType, {
    ...props,
    ...additionalProps,
    playback_started_at: playbackStartedAt,
  });
}

function* updatePlaybackTime({ payload }) {
  const { callback, endTime, source, startTime, videoId, videoFileId } =
    payload || {};
  const playbackTime = endTime - startTime;
  const playbackStartedAt = formatPlayBackTime(startTime);
  const playbackEndedAt = formatPlayBackTime(endTime);
  const data = {
    playback_ended_at: playbackEndedAt,
    playback_started_at: playbackStartedAt,
    playback_time: playbackTime,
    source,
    video_file_id: videoFileId,
    video_id: videoId,
  };
  try {
    const response = yield call(playbackUpdateRequest, data);
    if (callback) {
      callback(response?.data?.response?.data);
    }
  } catch (error) {
    logOutput('ERROR:', { error });
    if (callback) callback();
  }
}

/**
 * Watch video actions
 * */
export default function* videoSaga() {
  yield all([
    takeLatest(DO_VIDEO_REQUEST, videoRequest),
    takeLatest(DO_TRACK_VIDEO_EVENT_REQUEST, trackVideoEvent),
    takeEvery(UPDATE_PLAYBACK_TIME, updatePlaybackTime),
  ]);
}
