import {
  DO_FETCH_DETAIL_PODCAST_REQUEST,
  DO_FETCH_DETAIL_PODCAST_REQUEST_SUCCESS,
  DO_FETCH_PODCAST_BY_CATEGORY_REQUEST,
  DO_FETCH_PODCAST_BY_CATEGORY_REQUEST_SUCCESS,
  DO_FETCH_PODCAST_CATEGORIES_META_REQUEST_SUCCESS,
  DO_FETCH_PODCAST_CATEGORY_REQUEST,
  DO_FETCH_PODCAST_CATEGORY_REQUEST_SUCCESS,
  DO_FETCH_PODCAST_META_REQUEST_SUCCESS,
  DO_FETCH_PODCAST_REQUEST,
  DO_FETCH_PODCAST_REQUEST_SUCCESS,
  DO_FETCH_PODCAST_SPOTLIGHT_REQUEST_SUCCESS,
  PODCAST_BY_CATEGORY_IS_LOADING,
  PODCAST_DETAIL_SET_IS_LOADING,
  PODCAST_SET_IS_LOADING,
  SEARCH_PODCAST_STRING_REQUEST,
  SEARCH_PODCAST_STRING_REQUEST_SUCCESS,
} from '../../constant/podcast';

export const setIsLoading = payload => {
  return {
    type: PODCAST_SET_IS_LOADING,
    payload,
  };
};

export const setPodcastByCategoryIsLoading = payload => {
  return {
    type: PODCAST_BY_CATEGORY_IS_LOADING,
    payload,
  };
};

export const setPodcastDetailIsLoading = payload => {
  return {
    type: PODCAST_DETAIL_SET_IS_LOADING,
    payload,
  };
};

export const doFetchAllPodcastRequest = payload => {
  return {
    type: DO_FETCH_PODCAST_REQUEST,
    payload,
  };
};

export const doFetchAllPodcastSuccess = payload => {
  return {
    type: DO_FETCH_PODCAST_REQUEST_SUCCESS,
    payload,
  };
};

export const doFetchAllPodcastMetaSuccess = payload => {
  return {
    type: DO_FETCH_PODCAST_META_REQUEST_SUCCESS,
    payload,
  };
};

export const doFetchAllPodcastMetaCategoriesSuccess = payload => {
  return {
    type: DO_FETCH_PODCAST_CATEGORIES_META_REQUEST_SUCCESS,
    payload,
  };
};

export const doFetchAllSpotlightPodcastSuccess = payload => {
  return {
    type: DO_FETCH_PODCAST_SPOTLIGHT_REQUEST_SUCCESS,
    payload,
  };
};

export const doFetchAllPodcastCategoryRequest = payload => {
  return {
    type: DO_FETCH_PODCAST_CATEGORY_REQUEST,
    payload,
  };
};

export const doFetchAllPodcastcategorySuccess = payload => {
  return {
    type: DO_FETCH_PODCAST_CATEGORY_REQUEST_SUCCESS,
    payload,
  };
};

export const doFetchDetailPodcastRequest = payload => {
  return {
    type: DO_FETCH_DETAIL_PODCAST_REQUEST,
    payload,
  };
};

export const doFetchDetailPodcastSuccess = payload => {
  return {
    type: DO_FETCH_DETAIL_PODCAST_REQUEST_SUCCESS,
    payload,
  };
};

export const doFetchPodcastsByCategoryRequest = payload => {
  // console.log({ payload });
  return {
    type: DO_FETCH_PODCAST_BY_CATEGORY_REQUEST,
    payload,
  };
};

export const doFetchPodcastsByCategorySuccess = payload => {
  // console.log({ payload });
  return {
    type: DO_FETCH_PODCAST_BY_CATEGORY_REQUEST_SUCCESS,
    payload,
  };
};

export const doSearchPodcastStringRequest = payload => {
  console.log({ payload });
  return {
    type: SEARCH_PODCAST_STRING_REQUEST,
    payload,
  };
};

export const doSearchPodcastStringRequestSuccess = payload => {
  return {
    type: SEARCH_PODCAST_STRING_REQUEST_SUCCESS,
    payload,
  };
};
