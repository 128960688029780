import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ModalBox from '../../../ui-core/Modal';
import Button from '../../../ui-core/Button/Button';
import ImageCropper from './ImageCropper';
import { IMAGE_CONSTANT } from '../../../constants/app';
import './style.css';

const PhotoUploadModel = ({ callback, handleClose, show }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const croppedImageRef = useRef(null);
  const [warning, setWarning] = useState('');
  const { isLoading } = useSelector(state => state.user);
  const inputRef = useRef(null);

  const setCroppedImageUrl = imageUrl => {
    if (croppedImageRef.current) {
      croppedImageRef.current.content = imageUrl;
    }
  };

  useEffect(() => {
    if (!show) {
      setSelectedImage(null);
      croppedImageRef.current = null;
    }
  }, [show]);

  const onImageSelect = async event => {
    const image = event.target.files[0];
    if (image) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = function () {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src);
          if (width >= 400 && height >= 300) {
            const content = reader.result;
            const filename = image.name;
            const type = image.type;
            const imageObject = {
              content,
              filename,
              type,
            };
            croppedImageRef.current = imageObject;
            setSelectedImage(imageObject);
            setWarning('');
          } else {
            setWarning('Image size too low, Min resolution 400 x 300');
            inputRef.current.value = '';
            setSelectedImage(null);
            event.preventDefault();
          }
        };
      });
      reader.readAsDataURL(image);
    }
  };
  const handleImageUpload = () => {
    const { filename, content } = croppedImageRef.current || {};
    if (callback && filename && content) {
      callback({
        filename,
        content,
      });
    }
  };
  const handleClearImage = () => {
    inputRef.current.value = '';
    setSelectedImage(null);
  };
  const header = useMemo(
    () => (
      <div className="d-flex flex-column justify-content-start w-100 py-2 px-3 image-cropper-wrap">
        <input
          ref={inputRef}
          type="file"
          name="myfile"
          accept=".png, .jpg, .jpeg"
          onChange={onImageSelect}
        />
        <Button
          onClick={handleClearImage}
          isDisabled={!selectedImage}
          text="Clear Image"
        />
        {warning && (
          <span className="error-text mt-2 text-danger text-center position-static">
            {'* ' + warning}
          </span>
        )}
      </div>
    ),
    [selectedImage, warning],
  );
  const footer = useMemo(
    () => (
      <Button
        class="folder-save-btn mb-4 mx-auto d-flex align-items-center justify-content-around"
        onClick={handleImageUpload}
        isDisabled={!selectedImage || isLoading}
        isLoading={isLoading}
        text={
          <>
            <span>DONE</span>{' '}
            <img src={IMAGE_CONSTANT.checkIcon} alt="check-icon" />
          </>
        }
      />
    ),
    [selectedImage, isLoading],
  );
  return (
    <ModalBox
      show={show}
      head={header}
      footer={footer}
      handleClose={handleClose}
      dialogClassName={'edit-photo-modal w-auto'}
    >
      <div className="gradient-border cropper-wrapper mt-2">
        {selectedImage ? (
          <ImageCropper
            imageType={selectedImage?.type}
            imageUrl={selectedImage?.content}
            setCurrentImageURL={setCroppedImageUrl}
          />
        ) : (
          <div className="position-absolute d-flex w-100 h-100 justify-content-center align-items-center">
            <h5 className="text-center">Upload Image and start cropping</h5>
          </div>
        )}
      </div>
    </ModalBox>
  );
};

PhotoUploadModel.propTypes = {
  callback: PropTypes.func,
  handleClose: PropTypes.func,
  show: PropTypes.bool,
};

export default PhotoUploadModel;
