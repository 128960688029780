import {
  DO_CATEGORIES_SLUG_FAILED,
  DO_CATEGORIES_SLUG_LOADING,
  DO_CATEGORIES_SLUG_PAGINATION_LOADING,
  DO_CATEGORIES_SLUG_PAGINATION_REQUEST,
  DO_CATEGORIES_SLUG_PAGINATION_RESET,
  DO_CATEGORIES_SLUG_PAGINATION_SUCCESS,
  DO_CATEGORIES_SLUG_REQUEST,
  DO_CATEGORIES_SLUG_SUCCESS,
  DO_DEFAULT_VIDEO_FAILED,
  DO_DEFAULT_VIDEO_LOADING,
  DO_DEFAULT_VIDEO_REQUEST,
  DO_DEFAULT_VIDEO_SUCCESS,
  DO_CLEAR_SEARCH_RECORDS,
  DO_SEARCH_V2_LOADING,
  DO_SEARCH_V2_REQUEST,
  DO_SEARCH_V2_SUCCESS,
  STORE_DISCOVER_SEARCH_TEXT,
} from '../../constant/discover';

export const setCategoriesSlugLoading = payload => {
  return {
    type: DO_CATEGORIES_SLUG_LOADING,
    payload,
  };
};

export const doCategoriesSlugRequest = payload => {
  return {
    type: DO_CATEGORIES_SLUG_REQUEST,
    payload,
  };
};

/**
 * Get tournament banner details success
 * @param {Object} payload
 * */
export const categoriesBySlugSuccess = payload => {
  const { data } = payload;
  return {
    type: DO_CATEGORIES_SLUG_SUCCESS,
    payload: data,
  };
};

export const categoriesBySlugfailed = () => ({
  type: DO_CATEGORIES_SLUG_FAILED,
});

export const setDefaultVideoLoading = payload => {
  return {
    type: DO_DEFAULT_VIDEO_LOADING,
    payload,
  };
};

export const doDefaultVideoRequest = payload => {
  return {
    type: DO_DEFAULT_VIDEO_REQUEST,
    payload,
  };
};

export const defaultVideoSuccess = payload => {
  const { data } = payload;
  return {
    type: DO_DEFAULT_VIDEO_SUCCESS,
    payload: data,
  };
};

export const defaultVideofailed = () => ({
  type: DO_DEFAULT_VIDEO_FAILED,
});

export const setV2SearchLoading = payload => {
  return {
    type: DO_SEARCH_V2_LOADING,
    payload,
  };
};

export const doV2SearchRequest = payload => {
  return {
    type: DO_SEARCH_V2_REQUEST,
    payload,
  };
};

export const doV2SearchSuccess = payload => {
  const { data } = payload;
  return {
    type: DO_SEARCH_V2_SUCCESS,
    payload: data,
  };
};

export const clearSearchRecords = () => ({
  type: DO_CLEAR_SEARCH_RECORDS,
});

export const doCatSlugPaginationRequest = payload => {
  return {
    type: DO_CATEGORIES_SLUG_PAGINATION_REQUEST,
    payload,
  };
};

export const setCatSlugPaginationLoading = payload => {
  return {
    type: DO_CATEGORIES_SLUG_PAGINATION_LOADING,
    payload,
  };
};

export const catSlugPaginationSuccess = payload => {
  const { data } = payload;
  return {
    type: DO_CATEGORIES_SLUG_PAGINATION_SUCCESS,
    payload: data,
  };
};

export const catSlugPaginationReset = () => {
  return {
    type: DO_CATEGORIES_SLUG_PAGINATION_RESET,
  };
};

export const storeDiscoverSearchText = data => {
  return {
    type: STORE_DISCOVER_SEARCH_TEXT,
    payload: data,
  };
};
