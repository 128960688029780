import { APP_ROUTES, COURSES_SLUGS } from '../route';
import { getPromptLink } from '../utils/helpers';

// image imports
import Doctor1 from '../static/images/doctor1.png';
import Doctor2 from '../static/images/doctor2.png';
import Doctor3 from '../static/images/doctor3.png';
import Doctor4 from '../static/images/doctor4.png';
import Doctor5 from '../static/images/doctor5.png';
import Doctor1WebP from '../static/images/doctor1.webp';
import Doctor2WebP from '../static/images/doctor2.webp';
import Doctor3WebP from '../static/images/doctor3.webp';
import Doctor4WebP from '../static/images/doctor4.webp';
import Doctor5WebP from '../static/images/doctor5.webp';
import Avatar1 from '../static/images/Avatar1.png';
import Avatar2 from '../static/images/Avatar2.png';
import Avatar3 from '../static/images/Avatar3.png';
import Avatar1WebP from '../static/images/Avatar1.webp';
import Avatar2WebP from '../static/images/Avatar2.webp';
import Avatar3WebP from '../static/images/Avatar3.webp';
import screens from '../static/images/screens.png';
import screensWebP from '../static/images/screens.webp';
import logoNew from '../static/images/jen-new.png';
import Vid from '../static/images/vid.png';
import VidWebP from '../static/images/vid.webp';
import Cal2 from '../static/images/30daycalendar.png';
import Cal2WebP from '../static/images/30daycalendar.webp';
import CommunityImage from '../static/images/community.png';
import CommunityImageWebP from '../static/images/community.webp';
import closeIcon from '../static/images/x.png';
import crossIcon from '../static/images/cross-black.svg';
import filterIcon from '../static/images/filter.png';
import funnelIcon from '../static/images/funnel.svg';
import searchActiveIcon from '../static/images/search-hover.png';
import searchIcon from '../static/images/search.png';
import faceIcon from '../static/images/Face.png';
import foldersActiveImg from '../static/images/folders-hover.svg';
import foldersImg from '../static/images/folders.svg';
import backIcon from '../static/images/left-arrow.png';
import streakActiveImg from '../static/images/streak-hover.svg';
import streakImg from '../static/images/streak.svg';
import homeActiveImg from '../static/images/home-hover.svg';
import homeImg from '../static/images/home.svg';
import starIcon from '../static/images/star.svg';
import videoThumbnail from '../static/images/video.png';
import shareIcon from '../static/images/share-2.svg';
import shareIconMobile from '../static/images/share.png';
import checkIcon from '../static/images/check.svg';
import threeDotIcon from '../static/images/Group 7232.svg';
import trashIcon from '../static/images/trash-2.svg';
import editIcon from '../static/images/Vector.svg';
import starIconFilled from '../static/images/yellow-star.png';
import backArrow from '../static/images/backArrow.png';
import tickGreen from '../static/images/tick-green.png';
import giftIcon from '../static/images/gift.png';
import faqIcon1 from '../static/images/faq1.png';
import appStoreIcon from '../static/images/app-store.png';
import playStoreIcon from '../static/images/play-store.png';
import facebookIcon from '../static/images/facebook.png';
import instagramIcon from '../static/images/instagram.png';
import playIcon from '../static/images/play-icon.svg';
import youtubeIcon from '../static/images/youtube.png';
import quickTipsPoster from '../static/images/qt1.png';
import diamondIcon from '../static/images/diamond.png';
import proteinShakeIcon from '../static/images/protein-shake.png';
import editIcon1 from '../static/images/edit.png';
import userIcon from '../static/images/user.png';
import crownIcon from '../static/images/crown.png';
import mailIcon from '../static/images/mail.png';
import bellIcon from '../static/images/bell.png';
import myOrdersIcon from '../static/images/shopping-cart.png';
import aboutJenIcon from '../static/images/J.png';
import unlockIcon from '../static/images/unlock.png';
import checkListIcon from '../static/images/check-list.png';
import quicktipIcon from '../static/images/quicktip-icon.png';
import allplantIcon from '../static/images/allplan-icon.png';
import sleepingIcon from '../static/images/sleeping-icon.png';
import exercisesIcon from '../static/images/exercises-icon.png';
import sittingIcon from '../static/images/sitting-icon.png';
import podcastIcon from '../static/images/podcast-icon.png';
import nextIcon from '../static/images/nextP.svg';
import prevIcon from '../static/images/prevP.svg';
import personIcon from '../static/images/person-circle.svg';
import placeholderImage from '../static/images/placeholder-image.jpg';
import placeholderImage1 from '../static/images/placeholder-image-1.jpg';
import defaultProfileIcon from '../static/images/default-profile.svg';
import powerIcon from '../static/images/power.svg';
import arrowLeft from '../static/images/arrowLeft.svg';
import bellIconSvg from '../static/images/bell.svg';
import cardIcon from '../static/images/card.svg';
import lockIcon from '../static/images/lock.svg';
import lockIconNew from '../static/images/lock-new.svg';
import checkboxIcon from '../static/images/checkbox.svg';
import lockIconWhite from '../static/images/lock-white.svg';
import leftArrow from '../static/images/Left.svg';
import rightArrow from '../static/images/Right.png';
import leftArrowSmall from '../static/images/small-left.png';
import rightArrowSmall from '../static/images/small-right.png';
import checkIconNew from '../static/images/check-new.svg';
import chevronRight from '../static/images/next.svg';
import paymentCard from '../static/images/payment.png';
import visaLogo from '../static/images/visa.png';
import alertCircle from '../static/images/alert-circle.svg';
import chevronDown from '../static/images/chevron-down.svg';
import chevronUp from '../static/images/chevron-up.svg';
import radioIcon from '../static/images/Radio.svg';
import paymentScreen2 from '../static/images/payment-screen-2.png';
import paymentScreen2Min from '../static/images/payment-screen-2-min.png';
import paymentScreen2Webp from '../static/images/payment-screen-2.webp';
import paymentScreen3 from '../static/images/payment-screen3.jpg';
import paymentScreen3Min from '../static/images/payment-screen3-min.jpg';
import paymentScreen3Webp from '../static/images/payment-screen3.webp';
import playCircle from '../static/images/play-circle.svg';
import pauseCircle from '../static/images/pause-circle.svg';
import paymentScreen4VideoMp4 from '../static/videos/payment-screen-4.mp4';
import paymentScreen4VideoWebm from '../static/videos/payment-screen-4.webm';
import paymentScreen1VideoMp4 from '../static/videos/payment-screen1.mp4';
import paymentScreen1VideoWebm from '../static/videos/payment-screen1.webm';
import elbowIcon from '../static/images/elbow.svg';
import neckIcon from '../static/images/neck.svg';
import shouldersIcon from '../static/images/shoulders.svg';
import upperBackIcon from '../static/images/upper-back.svg';
import wristIcon from '../static/images/wrist.svg';
import ankleIcon from '../static/images/Ankle.svg';
import ArtboardIcon from '../static/images/Artboard.svg';
import CoreIcon from '../static/images/Core.svg';
import FeetIcon from '../static/images/Feet.svg';
import FullbodyIcon from '../static/images/Fullbody.svg';
import GroinIcon from '../static/images/Groin.svg';
import HamstringIcon from '../static/images/Hamstring.svg';
import headIcon from '../static/images/Head.svg';
import HipsIcon from '../static/images/Hips.svg';
import itBandIcon from '../static/images/IT_Band.svg';
import lowerBackIcon from '../static/images/Lower_Back.svg';
import pelvisIcon from '../static/images/Pelvis.svg';
import shinIcon from '../static/images/Shin.svg';
import kneesIcon from '../static/images/Knees.svg';
import upperNeckIcon from '../static/images/Upper_Neck.svg';
import upperTrapeziusIcon from '../static/images/Upper_Trapezius.svg';
import lowerTrapeziusIcon from '../static/images/Lower_Trapezius.svg';
import Step1 from '../static/images/step1.png';
import Step2 from '../static/images/step2.png';
import Step3 from '../static/images/step3.png';
import Step4 from '../static/images/step4.png';
import appleIcon from '../static/images/apple-icon.png';
import googleIcon from '../static/images/google-icon.png';
import bannerAvator from '../static/images/banner_avator.png';
import bannerPrice from '../static/images/banner_price.png';
import bannerAvatorWebp from '../static/images/banner_avator.webp';
import bannerPriceWebp from '../static/images/banner_price.webp';
import bannerAvatorLow from '../static/images/banner_avator.jpg';
import bannerPriceLow from '../static/images/banner_price.jpg';
import piggyBankIcon from '../static/images/challenge/icons/piggy-bank.svg';
import ribbonIcon from '../static/images/challenge/icons/ribbon.png';
import cashPrizeIcon from '../static/images/challenge/icons/cash prize.svg';
import referFriendsIcon from '../static/images/challenge/icons/refer your friend.svg';
import shoesIcon from '../static/images/challenge/icons/shoes.svg';
import companyLogo1 from '../static/images/challenge/logos/KTLA_logo 1.svg';
import companyLogo2 from '../static/images/challenge/logos/24-life-logo-mobile-2x 1.svg';
import companyLogo3 from '../static/images/challenge/logos/SFMLogoWebBlack400px 1.svg';
import companyLogo4 from '../static/images/challenge/logos/325-3258657_good-morning-america-good-morning-america-logo 1.svg';
import companyLogo5 from '../static/images/challenge/logos/oxygen-logo.svg';
import companyLogo6 from '../static/images/challenge/logos/womenshealth.svg';
import warmupIcon from '../static/images/challenge/icons/warmup cues-01 1.svg';
import FiveMuscleActivation from '../static/images/challenge/5 Muscle Activations.png';
import FiveMuscleActivationLow from '../static/images/challenge/5 Muscle Activations.jpg';
import FiveMuscleActivationWebp from '../static/images/challenge/5 Muscle Activations.webp';
import FiveSelfAssessments from '../static/images/challenge/5 Self Assessments.png';
import FiveSelfAssessmentsLow from '../static/images/challenge/5 Self Assessments.jpg';
import FiveSelfAssessmentsWebp from '../static/images/challenge/5 Self Assessments.webp';
import SixCoreStabilityWorkouts from '../static/images/challenge/6 Core Stability Workouts.png';
import SixCoreStabilityWorkoutsLow from '../static/images/challenge/6 Core Stability Workouts.jpg';
import SixCoreStabilityWorkoutsWebp from '../static/images/challenge/6 Core Stability Workouts.webp';
import SevenEffectiveAndFunctionalHIITWorkouts from '../static/images/challenge/7 Effective & Functional HIIT Workouts.png';
import SevenEffectiveAndFunctionalHIITWorkoutsLow from '../static/images/challenge/7 Effective & Functional HIIT Workouts.jpg';
import SevenEffectiveAndFunctionalHIITWorkoutsWebp from '../static/images/challenge/7 Effective & Functional HIIT Workouts.webp';
import NineMobilityFlows from '../static/images/challenge/9 Mobility Flows.png';
import NineMobilityFlowsLow from '../static/images/challenge/9 Mobility Flows.jpg';
import NineMobilityFlowsWebp from '../static/images/challenge/9 Mobility Flows.webp';
import NoEquipmentNeeded from '../static/images/challenge/No Equipment Needed.png';
import NoEquipmentNeededLow from '../static/images/challenge/No Equipment Needed.jpg';
import NoEquipmentNeededWebp from '../static/images/challenge/No Equipment Needed.webp';
import ChallengeSample from '../static/images/challenge/challenge-sample.png';
import ChallengeSampleLow from '../static/images/challenge/challenge-sample.jpg';
import ChallengeSampleWebp from '../static/images/challenge/challenge-sample.webp';
import GratefulForMyBody from '../static/images/challenge/GratefulForMyBody2021.png';
import GratefulForMyBodyLow from '../static/images/challenge/GratefulForMyBody2021.jpg';
import GratefulForMyBodyWebp from '../static/images/challenge/GratefulForMyBody2021.webp';
import MoveToImprove from '../containers/MoveToImprove/images/move_to_improve.png';
import MoveToImproveLow from '../containers/MoveToImprove/images/move_to_improve.jpg';
import MoveToImproveWebp from '../containers/MoveToImprove/images/move_to_improve.webp';
import MoveToImproveNew from '../containers/MoveToImprove/images/MoveImproveNew.png';
import MoveToImproveNewLow from '../containers/MoveToImprove/images/MoveImproveNew.jpg';
import MoveToImproveNewWebp from '../containers/MoveToImprove/images/MoveImproveNew.webp';
import MeetDocJenFit from '../static/images/challenge/Meet DocJenFit.png';
import MeetDocJenFitLow from '../static/images/challenge/Meet DocJenFit.jpg';
import MeetDocJenFitWebp from '../static/images/challenge/Meet DocJenFit.webp';
import arrowAltCircle from '../static/images/challenge/icons/arrow-alt-circle-right.svg';
import dotPattern from '../static/images/challenge/icons/Dot Pattern 4.svg';
import tickLogo from '../static/images/challenge/icons/tick.svg';
import bannerProfile from '../static/images/banner_profile.svg';
import hero from '../static/images/full-body-low-impact/Hero 1.png';
import heroLow from '../static/images/full-body-low-impact/Hero 1.jpg';
import heroWebp from '../static/images/full-body-low-impact/Hero 1.webp';
import cashPrizeWinnerIcon from '../static/images/full-body-low-impact/icons/cash.svg';
import foamRollerIcon from '../static/images/full-body-low-impact/icons/foam-roller.svg';
import jenLowImapceIcon from '../static/images/full-body-low-impact/icons/jen-low-imapce-icon.svg';
import jenLowImapceIcon2 from '../static/images/full-body-low-impact/icons/jen-low-imapce-icon2.svg';
import bodyTherapyIcon from '../static/images/full-body-low-impact/icons/body-therapy-icon.svg';
import bodyTherapyIcon2 from '../static/images/full-body-low-impact/icons/body-therapy-icon2.svg';
import shoesIconFullBody from '../static/images/full-body-low-impact/icons/shoes.svg';
import shoesIconFullBody2 from '../static/images/full-body-low-impact/icons/shoes2.svg';
import winnerPrizes from '../static/images/full-body-low-impact/icons/win-prizes.svg';
import winnerPrizes1 from '../static/images/full-body-low-impact/icons/win-prizes-1.svg';
import winnerPrizes2 from '../static/images/full-body-low-impact/icons/win-prizes2.svg';
import winnerPrizes3 from '../static/images/full-body-low-impact/icons/win-prizes3.svg';
import raboso from '../static/images/full-body-low-impact/icons/raboso.svg';
import rabosoWebP from '../static/images/full-body-low-impact/icons/Raboso.webp';
import November1Challenge from '../static/images/full-body-low-impact/icons/nov1_challenge.svg';
import bannerImage from '../static/images/full-body-low-impact/jen-banner.png';
import bodyTherapyKitIcon from '../static/images/full-body-low-impact/icons/body-therapy-kit.svg';
import TwoFoundationalCorePelvicFloorVideos from '../static/images/full-body-low-impact/2 Foundational Core  Pelvic Floor Videos.png';
import TwoFoundationalCorePelvicFloorVideosLow from '../static/images/full-body-low-impact/2 Foundational Core  Pelvic Floor Videos.jpg';
import TwoFoundationalCorePelvicFloorVideosWebp from '../static/images/full-body-low-impact/2 Foundational Core  Pelvic Floor Videos.webp';
import SixFunctionalStrengthVideos from '../static/images/full-body-low-impact/6 Functional Strength Videos.png';
import SixFunctionalStrengthVideosLow from '../static/images/full-body-low-impact/6 Functional Strength Videos.jpg';
import SixFunctionalStrengthVideosWebp from '../static/images/full-body-low-impact/6 Functional Strength Videos.webp';
import SevenMuscleActivationAndCorrectiveExerciseVideos from '../static/images/full-body-low-impact/7 Muscle Activation & Corrective Exercise Videos.png';
import SevenMuscleActivationAndCorrectiveExerciseVideosLow from '../static/images/full-body-low-impact/7 Muscle Activation & Corrective Exercise Videos.jpg';
import SevenMuscleActivationAndCorrectiveExerciseVideosWebp from '../static/images/full-body-low-impact/7 Muscle Activation & Corrective Exercise Videos.webp';
import TwentyMobilityVideos from '../static/images/full-body-low-impact/20 Mobility Videos.png';
import TwentyMobilityVideosLow from '../static/images/full-body-low-impact/20 Mobility Videos.jpg';
import TwentyMobilityVideosWebp from '../static/images/full-body-low-impact/20 Mobility Videos.webp';

import FiveSelfAssessment from '../static/images/full-body-low-impact/5_self_assessments.png';
import FiveSelfAssessmentLow from '../static/images/full-body-low-impact/5_self_assessments.jpg';
import FiveSelfAssessmentWebp from '../static/images/full-body-low-impact/5_self_assessments.webp';
import TherteenMobilityFlows from '../static/images/full-body-low-impact/13_mobility_flows.png';
import TherteenMobilityFlowsLow from '../static/images/full-body-low-impact/13_mobility_flows.jpg';
import TherteenMobilityFlowsWebp from '../static/images/full-body-low-impact/13_mobility_flows.webp';
import FiveFlowsForPelvicFloor from '../static/images/full-body-low-impact/5_flows_for_the_pelvic_floor.png';
import FiveFlowsForPelvicFloorLow from '../static/images/full-body-low-impact/5_flows_for_the_pelvic_floor.jpg';
import FiveFlowsForPelvicFloorWebp from '../static/images/full-body-low-impact/5_flows_for_the_pelvic_floor.webp';
import FivePelvicFlowsMotivation from '../static/images/full-body-low-impact/5_pelvic_flows_motivation.png';
import FivePelvicFlowsMotivationLow from '../static/images/full-body-low-impact/5_pelvic_flows_motivation.jpg';
import FivePelvicFlowsMotivationWebp from '../static/images/full-body-low-impact/5_pelvic_flows_motivation.webp';
import FourBreathWork from '../static/images/full-body-low-impact/4_breath_work.png';
import FourBreathWorkLow from '../static/images/full-body-low-impact/4_breath_work.jpg';
import FourBreathWorkWebp from '../static/images/full-body-low-impact/4_breath_work.webp';
import FourSoftTissue from '../static/images/full-body-low-impact/4_soft_tissues.png';
import FourSoftTissueLow from '../static/images/full-body-low-impact/4_soft_tissues.jpg';
import FourSoftTissueWebp from '../static/images/full-body-low-impact/4_soft_tissues.webp';
import WeeklyMindBody from '../static/images/full-body-low-impact/weekly_mind_body.png';
import WeeklyMindBodyLow from '../static/images/full-body-low-impact/weekly_mind_body.jpg';
import WeeklyMindBodyWebp from '../static/images/full-body-low-impact/weekly_mind_body.webp';
import GratefulForMyBodyImage from '../static/images/full-body-low-impact/grateful_for_my_body.png';
import GratefulForMyBodyImageLow from '../static/images/full-body-low-impact/grateful_for_my_body.jpg';
import GratefulForMyBodyImageWebp from '../static/images/full-body-low-impact/grateful_for_my_body.webp';
import BONUSAccessTo12SelfAssessments from '../static/images/full-body-low-impact/BONUS Access to 12 Self-Assessments.png';
import BONUSAccessTo12SelfAssessmentsLow from '../static/images/full-body-low-impact/BONUS Access to 12 Self-Assessments.jpg';
import BONUSAccessTo12SelfAssessmentsWebp from '../static/images/full-body-low-impact/BONUS Access to 12 Self-Assessments.webp';
import jenMembershipImage1 from '../static/images/full-body-low-impact/icons/jen_membership1.svg';
import jenMembershipImage2 from '../static/images/full-body-low-impact/icons/jen_membership2.svg';
import jenMembershipImage3 from '../static/images/full-body-low-impact/icons/jen_membership3.svg';
import jenMembershipImage4 from '../static/images/full-body-low-impact/icons/jen_membership4.svg';
import jenMembershipImage5 from '../static/images/full-body-low-impact/icons/jen_membership5.svg';
import jenMembershipImage6 from '../static/images/full-body-low-impact/icons/jen_membership6.svg';
import bareFeetIcon from '../static/images/bare-feet.svg';
import calendarIcon from '../static/images/calendar.svg';
import dailyVideosIcon from '../static/images/daily-videos.svg';
import liveRecordedInterviewIcon from '../static/images/live-recorded-interview.svg';
import workbookIcon from '../static/images/workbook.svg';
import courseAvailability from '../static/images/bare-foot/course availability.png';
import courseAvailabilityLow from '../static/images/bare-foot/course availability.jpg';
import courseAvailabilityWebp from '../static/images/bare-foot/course availability.webp';
import knowledgeIsPowerful from '../static/images/bare-foot/knowledge is powerful.png';
import knowledgeIsPowerfulLow from '../static/images/bare-foot/knowledge is powerful.jpg';
import knowledgeIsPowerfulWebp from '../static/images/bare-foot/knowledge is powerful.webp';
import whoItsFor from '../static/images/bare-foot/who it is for.png';
import whoItsForLow from '../static/images/bare-foot/who it is for.jpg';
import whoItsForWebp from '../static/images/bare-foot/who it is for.webp';
import barefeet1 from '../static/images/bare-foot/Barefeet-1.png';
import barefeet1Low from '../static/images/bare-foot/Barefeet-1.jpg';
import barefeet1Webp from '../static/images/bare-foot/Barefeet-1.webp';
import barefeetLead1 from '../static/images/bare-foot/BarefeetLead-1.png';
import barefeetLead1Low from '../static/images/bare-foot/BarefeetLead-1.jpg';
import barefeetLead1Webp from '../static/images/bare-foot/BarefeetLead-1.webp';

import barefeet2 from '../static/images/bare-foot/Barefeet-2.png';
import barefeet2Low from '../static/images/bare-foot/Barefeet-2.jpg';
import barefeet2Webp from '../static/images/bare-foot/Barefeet-2.webp';
import barefeet3 from '../static/images/bare-foot/Barefeet-3.png';
import barefeet3Low from '../static/images/bare-foot/Barefeet-3.jpg';
import barefeet3Webp from '../static/images/bare-foot/Barefeet-3.webp';
import barefeet3Lead from '../static/images/bare-foot/BarefeetLead-3.png';
import barefeet3LeadLow from '../static/images/bare-foot/BarefeetLead-3.jpg';
import barefeet3LeadWebp from '../static/images/bare-foot/BarefeetLead-3.webp';
import barefeet4 from '../static/images/bare-foot/Barefeet-4.png';
import barefeet4Low from '../static/images/bare-foot/Barefeet-4.jpg';
import barefeet4Webp from '../static/images/bare-foot/Barefeet-4.webp';
import barefeet4Lead from '../static/images/bare-foot/BarefeetLead-4.png';
import barefeet4LeadLow from '../static/images/bare-foot/BarefeetLead-4.jpg';
import barefeet4LeadWebp from '../static/images/bare-foot/BarefeetLead-4.webp';
import barefeet5 from '../static/images/bare-foot/Barefeet-5.png';
import barefeet5Low from '../static/images/bare-foot/Barefeet-5.jpg';
import barefeet5Webp from '../static/images/bare-foot/Barefeet-5.webp';
import rebuildFootHealth from '../static/images/bare-foot/unnamed.png';
import rebuildFootHealthLow from '../static/images/bare-foot/unnamed.jpg';
import rebuildFootHealthWebp from '../static/images/bare-foot/unnamed.webp';
import challengeImg from '../static/images/challenge-hover.svg';
import backWhite from '../static/images/back-white.svg';
import challengeInactiveImg from '../static/images/challenge-inactive.svg';
import resourceThumnail from '../static/images/resource-thumbnail.png';
import JenCalenderImage from '../static/images/full-body-low-impact/grateful-calender.png';
import JenCalenderImageLow from '../static/images/full-body-low-impact/grateful-calender.jpg';
import JenCalenderImageWebp from '../static/images/full-body-low-impact/grateful-calender.webp';
import JenCalenderMobile from '../static/images/full-body-low-impact/30days-calender-mobile.png';
import JenCalenderMobileLow from '../static/images/full-body-low-impact/30days-calender-mobile.jpg';
import JenCalenderMobileWebp from '../static/images/full-body-low-impact/30days-calender-mobile.webp';
import GratefulHero from '../static/images/full-body-low-impact/grateful-banner.png';
import GratefulHeroLow from '../static/images/full-body-low-impact/grateful-banner.jpg';
import GratefulHeroWebp from '../static/images/full-body-low-impact/grateful-banner.webp';
import giftHealthBackground from '../static/images/gift-card/background.png';
import giftHealthBackgroundLow from '../static/images/gift-card/background.jpg';
import giftHealthBackgroundWebp from '../static/images/gift-card/background.webp';
import giftHealthBackgroundMobile from '../static/images/gift-card/background-mobile.png';
import giftHealthBackgroundMobileLow from '../static/images/gift-card/background-mobile.jpg';
import giftHealthBackgroundMobileWebp from '../static/images/gift-card/background-mobile.webp';
import giftCard1 from '../static/images/gift-card/icons/gift-card 1.svg';
import giftCard2 from '../static/images/gift-card/icons/gift-card 2.svg';
import giftCard3 from '../static/images/gift-card/icons/gift-card 3.svg';
import discountStar from '../static/images/gift-card/icons/discount-star.svg';
import giftOption1 from '../static/images/gift-card/Gift Annual Subscription.png';
import giftOption1Low from '../static/images/gift-card/Gift Annual Subscription.jpeg';
import giftOption1Webp from '../static/images/gift-card/Gift Annual Subscription.webp';
import calendarIconBlack from '../static/images/calendar-icon.svg';
import subscriptionBackground from '../static/images/gift-card/subscription-background.png';
import planBeings from '../static/images/full-body-mobility/plan-beings.png';
import lowImpactBanner from '../static/images/full-body-low-impact/lowimpact.png';
import lowImpactBannerLow from '../static/images/full-body-low-impact/lowimpact.jpg';
import lowImpactBannerWebp from '../static/images/full-body-low-impact/lowimpact.webp';
import removeIcon from '../static/images/minus-circle.svg';
import shoppingCartIcon from '../static/images/shopping-cart.svg';
import emptyCartIcon from '../static/images/empty-cart 1.svg';
import deleteIcon from '../static/images/trash.svg';
import errorGif from '../static/images/error.gif';
import successGif from '../static/images/green-check.gif';
import helpCircleIcon from '../static/images/help-circle.svg';
import boxIcon from '../static/images/box 1.svg';
import backIconV1 from '../static/images/back.png';
import lowBackBannerPlan from '../static/images/lowback/LowBackBanner.png';
import lowBackBannerPlanLow from '../static/images/lowback/LowBackBanner.jpeg';
import lowBackBannerPlanWebp from '../static/images/lowback/LowBackBanner.webp';
import winnerPrizesHead from '../static/images/full-body-low-impact/winner-prizes-img.png';
import optimalBodyTherapyKit from '../static/images/full-body-low-impact/therapy-kit.png';
import intelliRollFoamRoller from '../static/images/full-body-low-impact/foam-roller.png';
import jenHealthMembership from '../static/images/full-body-low-impact/laptop-membership.png';
import learnMoreImg1 from '../static/images/full-body-low-impact/learn-1.jpg';
import learnMoreImg2 from '../static/images/full-body-low-impact/learn-2.jpg';
import defaultBlogCoverLow from '../static/images/blogs/DefaultBlogCover.jpeg';
import defaultBlogCoverWebp from '../static/images/blogs/DefaultBlogCover.webp';
import defaultBlogCover from '../static/images/blogs/DefaultBlogCover.png';
import anklePainLow from '../static/images/blogs/Ankle_Pain.jpg';
import anklePain from '../static/images/blogs/Ankle_Pain.png';
import anklePainWebp from '../static/images/blogs/Ankle_Pain.webp';
import backPain from '../static/images/blogs/Back_Pain.png';
import backPainLow from '../static/images/blogs/Back_Pain.jpg';
import backPainWebp from '../static/images/blogs/Back_Pain.webp';
import buttPain from '../static/images/blogs/Butt_Pain.png';
import buttPainLow from '../static/images/blogs/Butt_Pain.jpg';
import buttPainWebp from '../static/images/blogs/Butt_Pain.webp';
import neckPainWebp from '../static/images/blogs/Neck_Pain.webp';
import neckPainLow from '../static/images/blogs/Neck_Pain.jpg';
import neckPain from '../static/images/blogs/Neck_Pain.png';
import postureWebp from '../static/images/blogs/Posture.webp';
import posture from '../static/images/blogs/Posture.png';
import postureLow from '../static/images/blogs/Posture.jpg';
import shoulderPainWebp from '../static/images/blogs/Shoulder_Pain.webp';
import shoulderPain from '../static/images/blogs/Shoulder_Pain.png';
import shoulderPainLow from '../static/images/blogs/Shoulder_Pain.jpg';
import eyeIcon from '../static/images/blogs/eye.png';
import bodyPart from '../static/images/blogs/fit.svg';
import strengthArm from '../static/images/blogs/strength.svg';
import yogaPosition from '../static/images/blogs/yoga-position.svg';
import neckInjury from '../static/images/blogs/injury.svg';
import gutHealth from '../static/images/blogs/wellness.svg';
import mobility from '../static/images/blogs/run.svg';
import debunking from '../static/images/blogs/directional-sign.svg';
import wrongPosture from '../static/images/blogs/worng_posture.svg';
import holisticHealth from '../static/images/blogs/natural.svg';
import lungs from '../static/images/blogs/lungs.svg';
import footerBackground from '../static/images/blogs/footerbg.png';
import brandNames from '../static/images/blogs/brand_names.png';
import brandNamesMob from '../static/images/blogs/brand_names_mob.png';
import newsLetterImage from '../static/images/blogs/newsLetterBackground.png';
import spotLight from '../static/images/blogs/spotlight_big.png';
import elbowPain from '../static/images/blogs/elobow_pain.png';
import legStretch from '../static/images/blogs/leg_Stretch.png';
import recent1 from '../static/images/blogs/recent1.png';
import recent2 from '../static/images/blogs/recent2.png';
import recent3 from '../static/images/blogs/recent3.png';
import podcastBanner from '../static/images/podcast/podcast-hero.png';
import podcastBannerLow from '../static/images/podcast/podcast-hero.jpg';
import podcastBannerWebp from '../static/images/podcast/podcast-hero.webp';
import optimalBody from '../static/images/podcast/optimal-body.png';
import applePodcasts from '../static/images/podcast/apple-podcasts.svg';
import soundCloud from '../static/images/podcast/soundcloud.svg';
import spotify from '../static/images/podcast/spotify.svg';
import amazonMusic from '../static/images/podcast/AmazonMusic.svg';
import iHeartRadio from '../static/images/podcast/iHeartRadio.svg';
import pandora from '../static/images/podcast/pandora.svg';
import googlePodcasts from '../static/images/podcast/google-podcasts.png';
import youtube from '../static/images/podcast/youtube.svg';
import bodyPartImg from '../static/images/podcast/body-part.png';
import strengthImg from '../static/images/podcast/strength.png';
import yogaImg from '../static/images/podcast/yoga.png';
import physicalTherapyImg from '../static/images/podcast/physical-therapy.png';
import gutHealthNutritionImg from '../static/images/podcast/gut-health-nutrition.png';
import chronicPainImg from '../static/images/podcast/chronic-pain.png';
import mobilityImg from '../static/images/podcast/mobility.png';
import debunkingImg from '../static/images/podcast/debunking.png';
import postureImg from '../static/images/podcast/posture.png';
import holisticHealthImg from '../static/images/podcast/holistic-health.png';
import breathworkImg from '../static/images/podcast/breathwork.png';
import rightAngle from '../static/images/podcast/right-angle.svg';
import ProductItem from '../static/images/podcast/shoe.png';
import gymGirl from '../static/images/podcast/girls.jpg';
import MicMenu from '../static/images/mic-icon.svg';
import NoteMenu from '../static/images/blog-icon.svg';
import userMenu from '../static/images/podcast/user-menu.svg';
import LMNTSponsor from '../static/images/podcast/LMNT-sponsor.svg';
import VivoSponsor from '../static/images/podcast/vivo-sponsor.svg';
import VitalProtiensSponsor from '../static/images/podcast/vital-sponsor.svg';
import NabosoSponsor from '../static/images/podcast/naboso.svg';
import NeededSponsor from '../static/images/podcast/needed.svg';
import prevBlkIcon from '../static/images/prev.svg';
import arrowRight from '../static/images/v2/arrow-right.svg';
import jenBanner from '../static/images/v2/jen-banner.png';
import therapyCardImg from '../static/images/v2/therapy-card-img.jpg';
import crown from '../static/images/v2/crown.svg';
import infoIcon from '../static/images/v2/info.svg';
import PreCourseImg from '../static/images/v2/pre-course-img.jpg';
import muscleActivations from '../static/images/v2/muscle-activations.jpg';
import mobilityFlows from '../static/images/v2/mobility-flows.jpg';
import deskBreakMobility from '../static/images/v2/desk-break-mobility.jpg';
import techNeckHelp from '../static/images/v2/tech-neck-help.jpg';
import pelvicFloorFoundation from '../static/images/v2/pelvic-floor-foundation.png';
import headacheRelief from '../static/images/v2/headache-relief.jpg';
import MostPopularImg from '../static/images/v2/discover/most-popular-1.jpg';
import quickTipImg from '../static/images/v2/discover/quick-tip-img.jpg';
import searchByConditions from '../static/images/v2/discover/search-by-conditions.jpg';
import rotationMobility from '../static/images/v2/discover/rotation-mobility.jpg';
import arrowRightImg from '../static/images/v2/discover/arrow-right.svg';
import zoomLive from '../static/images/v2/zoom-live.svg';
import moveImproveBanner from '../static/images/move-improve/banner-img.png';
import strongHealthJoint from '../static/images/move-improve/strong-health-joint.png';
import joinUsStarting from '../static/images/move-improve/join-us-starting.png';
import StrongFiveSelfAssesments from '../static/images/move-improve/strong 5 self assesment.png';
import StrongFiveSelfAssesmentsLow from '../static/images/move-improve/strong  5 self assesment.jpeg';
import StrongFiveSelfAssesmentsWebp from '../static/images/move-improve/strong  5 self assesment.webp';
import StrongEightMobilityFlows from '../static/images/move-improve/strong 8 mobilityflows.png';
import StrongEightMobilityFlowsLow from '../static/images/move-improve/strong 8 mobilityflows.jpg';
import StrongEightMobilityFlowsWebp from '../static/images/move-improve/strong 8 mobilityflows.webp';
import StrongSixMobilityStrengths from '../static/images/move-improve/strong 6 strength.png';
import StrongSixMobilityStrengthsLow from '../static/images/move-improve/strong 6 strength.jpg';
import StrongSixMobilityStrengthsWebp from '../static/images/move-improve/strong 6 strength.webp';
import StrongFourCoreStabilityWorkouts from '../static/images/move-improve/strong 4 core.png';
import StrongFourCoreStabilityWorkoutsLow from '../static/images/move-improve/strong 4 core.jpg';
import StrongFourCoreStabilityWorkoutsWebp from '../static/images/move-improve/strong 4 core.webp';
import StrongThreeHIIT from '../static/images/move-improve/strong 3 hiit.png';
import StrongThreeHIITLow from '../static/images/move-improve/strong-3-hiit.jpg';
import StrongThreeHIITWebp from '../static/images/move-improve/strong-3-hiit.webp';
import StrongFourLiveClass from '../static/images/move-improve/strong 4 live class.png';
import StrongFourLiveClassLow from '../static/images/move-improve/strong-4-live-class.jpg';
import StrongFourLiveClassWebp from '../static/images/move-improve/strong-4-live-class.webp';
import bareFootMiniCourse from '../static/images/v2/barefoot-mini-course.png';
import days30OfRelease from '../static/images/v2/30-days-of-release.png';
import discoverIcon from '../static/images/discover-icon.svg';
import discoverInactive from '../static/images/discover-inactive.svg';

export const IMAGE_CONSTANT = {
  StrongFiveSelfAssesments,
  StrongFiveSelfAssesmentsLow,
  StrongFiveSelfAssesmentsWebp,
  StrongEightMobilityFlows,
  StrongEightMobilityFlowsLow,
  StrongEightMobilityFlowsWebp,
  StrongSixMobilityStrengths,
  StrongSixMobilityStrengthsLow,
  StrongSixMobilityStrengthsWebp,
  StrongFourCoreStabilityWorkouts,
  StrongFourCoreStabilityWorkoutsLow,
  StrongFourCoreStabilityWorkoutsWebp,
  StrongThreeHIIT,
  StrongThreeHIITLow,
  StrongThreeHIITWebp,
  StrongFourLiveClass,
  StrongFourLiveClassLow,
  StrongFourLiveClassWebp,
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar1WebP,
  Avatar2WebP,
  Avatar3WebP,
  alertCircle,
  appStoreIcon,
  playStoreIcon,
  arrowLeft,
  backArrow,
  backWhite,
  backIcon,
  backIconV1,
  bareFeetIcon,
  bellIcon,
  bellIconSvg,
  boxIcon,
  closeIcon,
  crossIcon,
  Cal2,
  Cal2WebP,
  calendarIcon,
  calendarIconBlack,
  cardIcon,
  checkIcon,
  checkIconNew,
  checkboxIcon,
  checkListIcon,
  chevronRight,
  chevronDown,
  chevronUp,
  CommunityImage,
  CommunityImageWebP,
  crownIcon,
  dailyVideosIcon,
  defaultProfileIcon,
  deleteIcon,
  diamondIcon,
  discountStar,
  defaultBlogCoverLow,
  defaultBlogCoverWebp,
  defaultBlogCover,
  Doctor1,
  Doctor2,
  Doctor3,
  Doctor4,
  Doctor5,
  Doctor1WebP,
  Doctor2WebP,
  Doctor3WebP,
  Doctor4WebP,
  Doctor5WebP,
  editIcon,
  editIcon1,
  emptyCartIcon,
  facebookIcon,
  faceIcon,
  faqIcon1,
  filterIcon,
  foldersActiveImg,
  foldersImg,
  funnelIcon,
  giftCard1,
  giftCard2,
  giftCard3,
  giftHealthBackground,
  giftHealthBackgroundLow,
  giftHealthBackgroundWebp,
  giftHealthBackgroundMobile,
  giftHealthBackgroundMobileLow,
  giftHealthBackgroundMobileWebp,
  giftIcon,
  giftOption1,
  giftOption1Low,
  giftOption1Webp,
  helpCircleIcon,
  homeActiveImg,
  homeImg,
  challengeImg,
  challengeInactiveImg,
  instagramIcon,
  leftArrow,
  liveRecordedInterviewIcon,
  logoNew,
  lockIcon,
  lockIconNew,
  lockIconWhite,
  lowImpactBanner,
  lowImpactBannerLow,
  lowImpactBannerWebp,
  mailIcon,
  nextIcon,
  pauseCircle,
  placeholderImage,
  placeholderImage1,
  playCircle,
  playIcon,
  prevIcon,
  personIcon,
  powerIcon,
  proteinShakeIcon,
  quickTipsPoster,
  radioIcon,
  removeIcon,
  rightArrow,
  searchActiveIcon,
  searchIcon,
  screens,
  screensWebP,
  shareIcon,
  shareIconMobile,
  shoppingCartIcon,
  starIcon,
  starIconFilled,
  streakActiveImg,
  streakImg,
  threeDotIcon,
  tickGreen,
  trashIcon,
  userIcon,
  unlockIcon,
  Vid,
  videoThumbnail,
  VidWebP,
  youtubeIcon,
  quicktipIcon,
  allplantIcon,
  sleepingIcon,
  exercisesIcon,
  sittingIcon,
  podcastIcon,
  leftArrowSmall,
  rightArrowSmall,
  paymentCard,
  visaLogo,
  paymentScreen2,
  paymentScreen2Min,
  paymentScreen2Webp,
  paymentScreen3,
  paymentScreen3Min,
  paymentScreen3Webp,
  elbowIcon,
  neckIcon,
  shouldersIcon,
  upperBackIcon,
  workbookIcon,
  wristIcon,
  ankleIcon,
  ArtboardIcon,
  CoreIcon,
  FeetIcon,
  FullbodyIcon,
  GroinIcon,
  HamstringIcon,
  headIcon,
  HipsIcon,
  itBandIcon,
  kneesIcon,
  lowerBackIcon,
  pelvisIcon,
  shinIcon,
  upperNeckIcon,
  upperTrapeziusIcon,
  lowerTrapeziusIcon,
  Step1,
  Step2,
  Step3,
  Step4,
  appleIcon,
  googleIcon,
  bannerAvator,
  bannerPrice,
  bannerAvatorLow,
  bannerPriceLow,
  bannerAvatorWebp,
  bannerPriceWebp,
  piggyBankIcon,
  ribbonIcon,
  cashPrizeIcon,
  referFriendsIcon,
  shoesIcon,
  companyLogo1,
  companyLogo2,
  companyLogo3,
  companyLogo4,
  companyLogo5,
  companyLogo6,
  warmupIcon,
  FiveMuscleActivation,
  FiveMuscleActivationLow,
  FiveMuscleActivationWebp,
  FiveSelfAssessments,
  FiveSelfAssessmentsLow,
  FiveSelfAssessmentsWebp,
  SixCoreStabilityWorkouts,
  SixCoreStabilityWorkoutsLow,
  SixCoreStabilityWorkoutsWebp,
  SevenEffectiveAndFunctionalHIITWorkouts,
  SevenEffectiveAndFunctionalHIITWorkoutsLow,
  SevenEffectiveAndFunctionalHIITWorkoutsWebp,
  NineMobilityFlows,
  NineMobilityFlowsLow,
  NineMobilityFlowsWebp,
  NoEquipmentNeeded,
  NoEquipmentNeededLow,
  NoEquipmentNeededWebp,
  ChallengeSample,
  ChallengeSampleLow,
  ChallengeSampleWebp,
  GratefulForMyBody,
  GratefulForMyBodyLow,
  GratefulForMyBodyWebp,
  MoveToImprove,
  MoveToImproveLow,
  MoveToImproveWebp,
  MoveToImproveNew,
  MoveToImproveNewLow,
  MoveToImproveNewWebp,
  MeetDocJenFit,
  MeetDocJenFitLow,
  November1Challenge,
  MeetDocJenFitWebp,
  arrowAltCircle,
  dotPattern,
  tickLogo,
  bannerProfile,
  hero,
  heroLow,
  heroWebp,
  cashPrizeWinnerIcon,
  foamRollerIcon,
  bodyTherapyIcon,
  bodyTherapyIcon2,
  shoesIconFullBody,
  shoesIconFullBody2,
  jenLowImapceIcon,
  jenLowImapceIcon2,
  winnerPrizes,
  winnerPrizes1,
  winnerPrizes2,
  winnerPrizes3,
  bannerImage,
  bodyTherapyKitIcon,
  TwoFoundationalCorePelvicFloorVideos,
  TwoFoundationalCorePelvicFloorVideosLow,
  TwoFoundationalCorePelvicFloorVideosWebp,
  SixFunctionalStrengthVideos,
  SixFunctionalStrengthVideosLow,
  SixFunctionalStrengthVideosWebp,
  SevenMuscleActivationAndCorrectiveExerciseVideos,
  SevenMuscleActivationAndCorrectiveExerciseVideosLow,
  SevenMuscleActivationAndCorrectiveExerciseVideosWebp,
  TwentyMobilityVideos,
  TwentyMobilityVideosLow,
  TwentyMobilityVideosWebp,
  BONUSAccessTo12SelfAssessments,
  BONUSAccessTo12SelfAssessmentsLow,
  BONUSAccessTo12SelfAssessmentsWebp,
  jenMembershipImage1,
  jenMembershipImage2,
  jenMembershipImage3,
  jenMembershipImage4,
  jenMembershipImage5,
  jenMembershipImage6,
  courseAvailability,
  courseAvailabilityLow,
  courseAvailabilityWebp,
  knowledgeIsPowerful,
  knowledgeIsPowerfulLow,
  knowledgeIsPowerfulWebp,
  whoItsFor,
  whoItsForLow,
  whoItsForWebp,
  barefeet1,
  barefeet1Low,
  barefeet1Webp,
  barefeetLead1,
  barefeetLead1Low,
  barefeetLead1Webp,
  barefeet2,
  barefeet2Low,
  barefeet2Webp,
  barefeet3,
  barefeet3Low,
  barefeet3Webp,
  barefeet3Lead,
  barefeet3LeadWebp,
  barefeet3LeadLow,
  barefeet4,
  barefeet4Low,
  barefeet4Webp,
  barefeet4Lead,
  barefeet4LeadLow,
  barefeet4LeadWebp,
  barefeet5,
  barefeet5Low,
  barefeet5Webp,
  rebuildFootHealth,
  rebuildFootHealthLow,
  rebuildFootHealthWebp,
  resourceThumnail,
  raboso,
  rabosoWebP,
  GratefulForMyBodyImage,
  GratefulForMyBodyImageLow,
  GratefulForMyBodyImageWebp,
  JenCalenderImage,
  JenCalenderImageLow,
  JenCalenderImageWebp,
  JenCalenderMobile,
  JenCalenderMobileLow,
  JenCalenderMobileWebp,
  GratefulHero,
  GratefulHeroLow,
  GratefulHeroWebp,
  subscriptionBackground,
  planBeings,
  errorGif,
  successGif,
  aboutJenIcon,
  myOrdersIcon,
  lowBackBannerPlan,
  lowBackBannerPlanLow,
  lowBackBannerPlanWebp,
  winnerPrizesHead,
  optimalBodyTherapyKit,
  intelliRollFoamRoller,
  jenHealthMembership,
  learnMoreImg1,
  learnMoreImg2,
  shoulderPainLow,
  shoulderPain,
  shoulderPainWebp,
  postureLow,
  posture,
  postureWebp,
  neckPain,
  neckPainLow,
  neckPainWebp,
  buttPainWebp,
  buttPainLow,
  buttPain,
  backPainWebp,
  backPainLow,
  backPain,
  anklePainWebp,
  anklePain,
  anklePainLow,
  eyeIcon,
  bodyPart,
  strengthArm,
  yogaPosition,
  neckInjury,
  gutHealth,
  mobility,
  debunking,
  wrongPosture,
  holisticHealth,
  lungs,
  footerBackground,
  brandNames,
  brandNamesMob,
  newsLetterImage,
  spotLight,
  elbowPain,
  legStretch,
  recent1,
  recent2,
  recent3,
  podcastBanner,
  podcastBannerLow,
  podcastBannerWebp,
  optimalBody,
  bodyPartImg,
  strengthImg,
  yogaImg,
  physicalTherapyImg,
  gutHealthNutritionImg,
  chronicPainImg,
  mobilityImg,
  debunkingImg,
  postureImg,
  holisticHealthImg,
  breathworkImg,
  rightAngle,
  ProductItem,
  gymGirl,
  MicMenu,
  NoteMenu,
  applePodcasts,
  soundCloud,
  spotify,
  googlePodcasts,
  youtube,
  amazonMusic,
  iHeartRadio,
  pandora,
  userMenu,
  prevBlkIcon,
  LMNTSponsor,
  VivoSponsor,
  VitalProtiensSponsor,
  NabosoSponsor,
  NeededSponsor,
  arrowRight,
  jenBanner,
  therapyCardImg,
  crown,
  infoIcon,
  PreCourseImg,
  muscleActivations,
  mobilityFlows,
  deskBreakMobility,
  techNeckHelp,
  pelvicFloorFoundation,
  headacheRelief,
  MostPopularImg,
  quickTipImg,
  searchByConditions,
  arrowRightImg,
  rotationMobility,
  zoomLive,
  moveImproveBanner,
  strongHealthJoint,
  joinUsStarting,
  bareFootMiniCourse,
  days30OfRelease,
  discoverIcon,
  discoverInactive,
};

export const VIDEO_CONSTANT = {
  bannerVideoWebm:
    'https://cdn-staging.jen.health/static/Banner+Video-Hi+res.webm',
  bannerVideoMp4: 'https://cdn-staging.jen.health/static/Banner+Video.mp4',
  communityVideoWebm:
    'https://cdn-staging.jen.health/static/Community+video.webm',
  communityVideoMp4:
    'https://cdn-staging.jen.health/static/Community+Video.mp4',
  neckPainVideoWebm: 'https://cdn-staging.jen.health/static/Neck+pain.webm',
  neckPainVideoMp4: 'https://cdn-staging.jen.health/static/Neck+pain.mp4',
  stepByStepVideoWebm:
    'https://cdn-staging.jen.health/static/Step+by+step+Programs.webm',
  stepByStepVideoMp4:
    'https://cdn-staging.jen.health/static/Step+by+Step+Program.mp4',
  paymentScreen1VideoMp4,
  paymentScreen1VideoWebm,
  paymentScreen4VideoMp4,
  paymentScreen4VideoWebm,
  whyJenVideoWebm: 'https://cdn-staging.jen.health/static/Why+Jen.webm',
  whyJenVideoMp4: 'https://cdn-staging.jen.health/static/Why+Jen.mp4',
  fullBodyLowImpactMp4:
    'https://cdn-staging.jen.health/static/Low+Impact+Welcome.mp4',
  fullBodyLowImpactWebm:
    'https://cdn-staging.jen.health/static/Low+Impact+Welcome.webm',
  fullBodyBannerMp4:
    'https://cdn-staging.jen.health/static/FBLI+SILENT+VIDEO.mp4',
  fullBodyBannerWebm:
    'https://cdn-staging.jen.health/static/FBLI+SILENT+VIDEO.webm',
};

export const TEXT_CONSTANT = {
  REGISTER_FOR_PELVLIC_FLOOR: 'Register for Pelvic Floor Foundations',
  TESTIMONIAL_1:
    'Jen is a great platform that will get you results while ensuring you’re safely working with your own body. As a women’s health physician I am happy to recommend this to my patients knowing they’ll get the coaching they need to achieve their goals.',
  TESTIMONIAL_2:
    'Jen has made it her personal mission to truly reach the public, teach the public, and show folks that they can be in charge of their own health. She’s a gift to the profession, and I wholeheartedly support her work, and this app',
  TESTIMONIAL_3:
    'Jen offers an opportunity for my patients to continue learning about their body outside of our sessions. Jen is a knowledgeable and trustworthy resource, and the cues are amazing. My patients truly feel empowered in their care on Jen',
  TESTIMONIAL_4:
    'Jen Health has an approach that is universally digestible. It’s able to make things simple that many others overcomplicate. When people who aren’t clinicians/ in this field want to feel good within their bodies and move well, but don’t know where to begin, Jen’s direction is a great place.',
  TESTIMONIAL_5:
    'I refer all of my patients, friends, and family to Jen’s platform and absolutely use it myself. Before and after I exercise, other times when I feel a bit stiff and need to check in on what my body needs. It’s so easy to find the mobility flow, or set of moves I should use.',
  COMMUNITY_HEADER_TEXT:
    'We meet with you each month to discuss your progress, doubts and questions',
  COMMUNITY_SUB_TEXT:
    'People from across different age groups, genders, lifestyle which share similar interests about health, fitness and nutrition',
  CALENDAR_HEADER_TEXT:
    'Step-by-step exercise programs designed  for you to spend only 15 minutes a day',
  CALENDAR_SUB_TEXT:
    'Step-by-step programs are created by experienced doctors of physical therapy to teach you proper functional movements, pain relief routines and optimize health',
  VIDEO_HEADER_TEXT:
    'Get to know your body with guidance from our expert doctors of physical therapy',
  VIDEO_SUB_TEXT:
    'Education about the ‘why’ behind each exercise, how and why our bodies move and function, debunk the pain and fitness myths, prevent injuries and more',
  PUBLIC_OPINION_1:
    'My back pain has subsided & sleeping without waking up! I’m so motivated to learn more and breathe',
  PUBLIC_OPINION_2:
    'I have to say that lower extremity flow, unlocked more than just my hips! Transformational!',
  PUBLIC_OPINION_3:
    'My run times, distance, and recovery are definitely better since starting the program. Jen has improved my life so much.',
  DISPLAY_HEADER_TEXT: 'Improve your mobility, reduce pain & build strength',
  DISPLAY_SUB_TEXT:
    'With knowledge & exercises from experienced doctors of physical therapy',
  FULL_BODY_SUB_TEXT:
    'Workout on our effective detailed plans for your knee pain from our highly qualified experts. More than 10 curated plans created for you.',
  THIRTY_DAY_SUB_TEXT:
    'Get Ready to Improve Mobility, Relieve Aches & Pains, and Move BETTER!',
  FOOTER_HEADER_TEXT: 'Great physical therapy guidance in your pocket',
  FOOTER_INFO_TEXT:
    'Jen health helps you improve your mobility, reduce pain and build strength with knowledge & exercises from experienced doctors of physical therapy.',
  FOOTER_SUB_TEXT: 'Be the first to get started on Jen',
  PLANS_MADE_FOR_YOU: 'Plans Made For You',
  RECOMMENDATIONS_FOR_YOU: 'Recommended Plans For You',
  PLANS_MADE_FOR_YOU_SUB_TEXT:
    'Each curated plan has been carefully put together by experienced medical doctors and practitionares to help you understand the needs of your body, find solutions that works for you and get you moving forward toward aa healthier, happier and fitter self',
  QUICK_TIPS: 'Quick Tips',
  NO_PLANS_TEXT: 'No matching plans found',
  NO_MY_PLANS_TEXT: 'Start your first exercise plan',
  NO_MY_PLANS_SUB_TEXT:
    'Start your first exercise plan to see a difference in your body and on this screen',
  QUICK_TIPS_SUB_TEXT:
    'Quick Tips emphasize on functional, multi-joint movements that can be modified to any fitness level and elicit greater muscle recruitment than more traditional exercise. This gets you moving, improving and eventually grooving any way you want.',
  SELF_ASSESSMENT: 'Self Assessment',
  SELF_ASSESSMENT_TOP_TEXT:
    "Let's complete a self assesment first to understand your baseline",
  SELF_ASSESSMENT_SUB_TEXT:
    'Self assessments help you understand your body better. We have put together a series of small tests that go a long way in helping you assess movements, strength, mobility in different parts of your body. We’ve also added explanations about what might be causing certain restrictions and exercise recommendations to help you move and improve.',
  FUNCTIONAL_HIIT: 'Functional HIIT',
  FUNCTIONAL_HIIT_SUB_TEXT:
    'HIIT emphasize on functional, multi-joint movements that can be modified to any fitness level and elicit greater muscle recruitment than more traditional exercise. This gets you moving, improving and eventually grooving any way you want.',
  DETAILED_FAQ_HEADER_TEXT: 'Knock Knees? Is it causing pain?',
  DETAILED_FAQ_SUB_TEXT:
    'Lumbar spinal stenosis—a narrowing of the nerve passageways in your low back—causes pain from your lumbar spine down to your buttocks and legs. When your lower body feels weak and in pain, staying active is the last thing on your mind. But gentle stretching and exercise are among your greatest allies, as they strengthen your spine and make it less susceptible to spinal stenosis symptoms.It’s also important to consider that a sedentary lifestyle—using rest to heal your pain—may actually worsen your lumbar spinal stenosis symptoms.Exercising with lumbar spinal stenosis shouldn’t be stressful or painful. Simple stretches like the 3 demonstrated in the videos above—pelvic tilt, knee to chest, and lower trunk rotation—can easily be incorporated into your daily routine',
  NO_FOLDERS_TEXT: 'Create your first folder',
  NO_FOLDERS_SUB_TEXT:
    'Folders help you save videos and create your own exercise playlists',
  NO_SELF_ASSESSMENT_TEXT: 'No matching Self Assessments found',
  NO_RECOMMENDATION_TEXT: 'Looking for Recommended Plans?',
  NO_RECOMMENDATION_SUB_TEXT:
    'Select Your Main Pain Point and Start Your Free Week as a Jen Health Member!',
  NO_VIDEOS_TEXT: 'Add your favourite videos here',
  NO_VIDEOS_SUB_TEXT:
    'Click on the ⭐ icon to save your favourite exercises to this folder',
  NO_VIDEOS_TEXT_2: 'Today is your rest day!',
  CREATE_NEW_FOLDER: 'Create New Folder',
  ADD_VIDEO_DETAILED_FOLDER: 'VIEW EXERCISES',
  DASHBOARD_TEXT_SINGULAR: 'We recommend this exercise plan for you',
  DASHBOARD_TEXT_PLURAL: 'We recommend these exercise plans for you',
  SELECT_PLANS: 'SELECT PLANS',
  SELECT_PLAN: 'Select Plan',
  SELECT_PREFERRED_PLAN: 'Select your preferred subscription plan',
  FREE_TRIAL_TEXT: 'Unlimited free access for 7-days, then ',
  VIEW_ALL_PLANS: 'View All Plans',
  HOW_TO_CANCEL: 'How can I cancel?',
  CANCEL_PROCESS_TEXT:
    "You'll be able to cancel the subscription easily from the payments section under your profile",
  // SUBSCRIPTION_HEADER_TEXT: 'Subscribe to a year of unlimited discovery',
  SUBSCRIPTION_HEADER_TEXT:
    'Improve Your Mobility, Reduce Pain & Build Strength',
  // SUBSCRIPTION_SUBHEADER_TEXT:
  //   'Your journey for moving freely and pain free starts here at only $9.99/month!',
  SUBSCRIPTION_POINT_1:
    'Complete guided plans & videos for mobility, pain, movement and more.',
  SUBSCRIPTION_POINT_2:
    'Weekly live Q&A with doctors of physical therapy and world renowned experts about your health & body',
  SUBSCRIPTION_POINT_3:
    'Find community which supports you in your journey to explore and learn about your body.',
  START_FREE_TRIAL: 'Start My Free Week',
  SUBSCRIPTION_DETAILS: 'Subscription Details',
  FETCHING_RESULTS: 'Fetching results for "search_phrase"',
  NO_SEARCH_RESULTS: 'Your search for “search_phrase” did not find any matches',
  SUGGESTIONS: 'Suggestions',
  SUGGESTION_1: 'Try different keywords',
  SUGGESTION_2: 'Look for an exercise, Body part, plan or assessment',
  SUGGESTION_3: 'Try for Mobility, Optimal body, Pain',
  DISCARD_CHANGES: 'Are you sure you want to discard changes?',
  YES: 'Yes',
  NO: 'No',
  NEW_RECOMMENDATION_TEXT:
    'We’ve recommended a new exercise plan for your selectedFilter 🎉',
  NEW_RECOMMENDATION_SUB_TEXT: 'Let’s get you moving & feeling better!',
  NO_NEW_RECOMMENDATION_TEXT:
    'Oops...Looks like you already have an exercise plan recommended for your selectedFilter 🤭',
  NO_NEW_RECOMMENDATION_SUB_TEXT:
    'We suggest you give it a try. You’re gonna feel awesome, trust us 😊',
  OKAY_VIEW_PLANS: 'Okay view plans',
  OKAY: 'Okay',
  NO_ACTIVE_SUBSCRIPTION: 'No active subscription found',
  CHANGE_PAYMENT_METHOD: 'Change Payment Method',
  CANCEL_PLAN_CONFIRMATION_TEXT: 'Are you sure you want to cancel? ',
  CANCEL_PLAN_CONFIRMATION_SUB_TEXT:
    'You’ll lose access to all your exercises & folders',
  CHARGED_ON_TEXT: 'You will be charged on',
  PLAN_ACTIVE: 'Plan Active',
  FREE_TRAIL: 'Free Trial',
  CANCEL_FREE_TRAIL: 'Cancel Free Trial',
  CANCEL_PLAN: 'Cancel Plan',
  CANCEL_SUBSCRIPTION: 'Cancel Subscription',
  CANCEL: 'Cancel',
  CANCEL_MY_SUBSCRIPTION: 'Cancel My Subscription',
  CANCEL_MY_SUBSCRIPTION_ACCEPT: 'Yes, lose access',
  CANCEL_MY_SUBSCRIPTION_DECLINE: 'No, keep my access',
  ACTIVE_ON: 'Active on',
  CANCELLED_DATE: 'Cancelled Date',
  NEXT_BILLING_DATE: 'Next Billing Date',
  PAYMENT_MODE: 'Payment Mode',
  JOIN_MEMBERSHIP: 'JOIN MEMBERSHIP',
  START_FREE_TRAIL: 'start my free week',
  RESTART_MEMBERSHIP: 'RESTART MY MEMBERSHIP',
  REJOIN_MEMBERSHIP: 'REJOIN MEMBERSHIP',
  UPDATE_PAYMENT_METHOD: 'Update Payment Method',
  RENEW_TEXT:
    'Improve Your Mobility, Find Relief From Pain & Build Long Term Health',
  PAYMENT_FAILED_NOTE:
    'Details regarding updating your payment method will be here',
  SPEND_DAILY_FIFTEEN_MINUTES: 'Spending 15 mins each day with Jen, you can',
  SAVE_COSTS: 'Move freely, pain free',
  SAVE: 'Save',
  AVOID_INJURIES: 'Avoid Injuries',
  RELIEVE_CHRONIC_PAIN: 'Relieve chronic pain',
  LEARN_PROPER_EXCERCISE_FORM: 'Learn proper exercise form',
  MAINTAIN_LONG_TERM_HEALTH: 'Maintain long term health',
  FAQ: 'Frequently asked questions',
  HOW_FREE_TRIAL_WORKS: 'How does your free trial work?',
  GUIDANCE_TEXT: `You'll be able to cancel the subscription easily from the payments section under your profile`,
  WHY_JEN:
    'Understand the ‘Why’ behind each exercise, how your different body parts work together and how to prevent injuries with guidance from our expert doctors of physical therapy',
  GET_STARTED_FOR_FREE: 'GET STARTED FOR FREE',
  LOGIN: 'LOGIN',
  DEFAULT_ONBOARDING_QUESTIN_FOR_NAME: `What's your name?`,
  DEFAULT_ONBOARDING_QUESTIN_FOR_EMAIL: 'What’s your email?',
  DEFAULT_ONBOARDING_QUESTIN_FOR_DOB: 'Please enter your Date Of Birth',
  DEFAULT_ONBOARDING_QUESTIN_FOR_GOAL: 'What goal would you like to achieve?',
  DEFAULT_ONBOARDING_QUESTIN_FOR_EXCERCISE_FREQUENCY:
    'How consistent will you be in a week to acheive your goal?',
  DEFAULT_ONBOARDING_QUESTIN_TRIED_SOLUTIONS:
    'Which solutions have you tried in the past?',
  DEFAULT_ONBOARDING_QUESTIN_TIME_PER_DAY:
    'How much time will you commit each day for this goal?',
  DEFAULT_ONBOARDING_QUESTIN_PREFERRED_PART_OF_DAY:
    'What time of the day do you prefer to exercise?',
  JEN_OFFER_TEXT:
    'We offer various body plans Knee Plan, Hip Plan, Full Body Plan..etc., for you. Improving mobility in surrounding joints to reduce pressure and tension on the knee, while building hip/core strength, control and awareness to increase knee strength and resiliency.',
  WHAT_WE_OFFER: 'What we offer',
  EXCERCISE_PLAN_MADE_FOR_YOU: 'Exercise plans made for you',
  BAREFOOR_PRICE_INCREASE: 'Price will increase after October 2nd !',
  BAREFOOT_MINI_HURRY: 'Hurry! Register now and get started with your course!',
  EXCERCISE_PLAN_SUB_TEXT:
    'We recommend you exercise plans for different parts of your body. Each exercise plan is led by the best doctors of physical therapy. They teach you the best movements along with providing you with so much knowledge and education about the body and the ‘why’ behind each movement.',
  SIGNUP_WITH_APPLE: 'Sign up with Apple',
  SIGNUP_WITH_GOOGLE: 'Sign up with Gmail',
  SIGN_ME_UP: 'SIGN ME UP',
  LOGIN_WITH_APPLE: 'Login with Apple',
  LOGIN_WITH_GOOGLE: 'Login with Gmail',
  BANNER_OFFER: 'Annual Membership offer',
  BANNER_DISCOUNT: '60% OFF',
  BANNER_TEXT: 'Discount on your first year this week only',
  REGISTER_NOW: 'Register now and get started with your self-assessments!',
  GRATEFUL_CHALLENGE: 'Register now and get started',
  EARLY_BIRD: 'EARLY BIRD SALE ENDS FRIDAY!',
  EARLY_BIRD_DISCOUNT: 'Use code “move” for a discount! Code expires Friday',
  POST_EARLY_BIRD_MOVE: 'Register and start moving with us now!',
  JOIN_NOW: 'JOIN NOW',
  JOIN_TO_START_LEARING: 'Join here to start learning',
  I_AM_READY: "I'm Ready",
  PS_TEXT:
    "P.S. Yup... just $2/DAY!! That's a $60 investment into your health!",
  WIN_EPIC_PRIZES: 'Win Epic Prizes',
  ALL_TAKING_PLACE: 'It’s All Taking Place...',
  THIRTY_DAY_CHALLENGE:
    'This 30-Day Fitness Challenge Will Sculpt Your Body From Head To Toe',
  WHAT_YOU_GET: 'What do you get for these next 30 days?',
  WHAT_YOU_GET_FULL_BODY_PLAN:
    'What do you get in the Full Body Low Impact Plan?',
  WHAT_YOU_GET_MOBILITY_PLAN: 'What do you get in the Full Body Mobility Plan?',
  WHAT_YOU_GET_LOW_BACK_PAIN_PLAN: 'What will you learn in the Low Back Plan?',
  WHAT_YOU_GET_HIP_MOBILITY_PLAN: 'What will you learn in the Hip Plan?',
  WHAT_YOU_GET_KNEE_PLAN: 'What will you learn in the Knee Plan?',
  WHAT_YOU_GET_UPPER_BACK_AND_NECK_PLAN:
    'What Do You Get In The Neck and Upper Back Plan?',
  WHAT_YOU_GET_OVER_30: 'What Do You Get Over The Next 30 Days?',
  TWO_DOLLARS_DAY: 'All of this for only $2/day when you sign up!',
  GRATEFUL_FOR_MY_BODY_CHALLENGE: '#GratefulForMyBodyChallenge',
  SIGNUP_NOW: 'Signup Now',
  REGISTER: 'Register Now',
  I_AM_IN: 'I’M in!',
  READY_TO_EXPERIENCE_GRATEFUL_FOR_MY_BODY:
    'Ready to experience the #GratefulForMyBody2021 CHALLENGE!?',
  READY_TO_EXPERIENCE_THE: 'Ready to experience the ',
  MOVE_TO_IMPROVE: '#Move2Improve2023 CHALLENGE!?',
  PUSH_NEW_POSSIBILITIES:
    'I cannot wait to move with you and push these incredible bodies to new possibilities!',
  ASSURANCE_TEXT: "Your info is safe with me—I'll never share your email.",
  JOIN_THE_TEAM: 'Join the team now',
  JOIN_US: 'Join Us',
  LETS_DO_IT: 'Join Now! Let’s do this',
  DO_THIS_LATER: "I'll do it later",
  MEET_DOC_JENFIT: 'Meet DocJenFit',
  DOC_INFO_1:
    'Jen Fraboni, PT, DPT, is an internationally-renown physical therapist who specializes in helping people overcome chronic pain and maximize physical performance.',
  DOC_INFO_2:
    'As the founder of two transformative programs, “The Mobility Method” and “The Optimal Body,” she brings a unique, whole body approach to strength, mobility and pain-free living. In 2019, Jen was named one of the top 50 most influential healthcare professionals. Jen’s easily accessible approach has garnered her more than half a million followers on social media and millions of views of her health and fitness videos. Jen has been featured in Shape Magazine, Self Magazine, Men’s Fitness and Muscle and Fitness and in 2020, graced the cover of Oxygen Magazine. She has recently been featured on Good Morning America and NBC. Dr. Jen is the co-host with her husband, who is also a Doctor of Physical Therapy, to a popular podcast called "The Optimal Body Podcast". They live in Los Angeles, California.',
  JOIN_CHALLENGE: 'Join the 30 Day GRATEFUL FOR MY BODY CHALLENGE!',
  GET_READY_FOR_CHALLENGE:
    'Get Ready to Improve Mobility, Relieve Aches & Pains, and Move BETTER!',
  YOU_WILL_RECEIVE: 'When you plan for only $2/Day, you will receive',
  JEN_MEMBERSHIP: 'What do you get within the Jen Membership',
  MEMBERSHIP_STEP_BY_STEP: 'Step-by-step exercise programs',
  MEMEBERSHIP_EACH_MONTH:
    'Meet each month with Dr. Jen Fraboni to discuss your progress',
  MEMBERSHIP_STAR: 'Star the video to save into your folders',
  MEMEBERSHIP_FOLDERS: 'Get Recommended Plans For You',
  MEMBERSHIP_CURATED_PLANS: 'Explore through 11 different curated plans,',
  MEMBERSHIP_WORKOUT_PREFERENCE:
    'Search for any body part, pain point, or workout preference ',
  MEMBERSHIP_RECOMMENDED_PLANS: 'Get Recommended Plans For you',
  MEMBERSHIP_PARA_1:
    'Every plan has full video demonstrations to not only show you have to execute each movement properly, but to help you understand the “why” behind what they do for your body.',
  MEMBERSHIP_PARA_2:
    'Beyond the challenge, continue to meet each month with Dr. Jen Fraboni to discuss your progress, examine concerns and answer any questions about the body',
  MEMBERSHIP_PARA_3:
    'Love a video you find through this Full Body Low Impact Plan (or any other plan) you know you’ll want to come back to? Star that video to save into any folders you create!',
  MEMBERSHIP_PARA_4:
    'Each with 2-3 phases per plan to continue learning about your body from Doctors of Physical Therapy',
  MEMBERSHIP_PARA_5:
    'Find quick tips, curated plans, specific exercises or self-assessments related to exactly what you’re looking fo by typing in our search bar or exploring our movement playlists!',
  MEMBERSHIP_PARA_6:
    'Want to explore the other plans we have available after the challenge? Simply scroll to the bottom of the “For You” page on your home page and click on “Get more recommendations.”',
  HOW_IT_WORKS: 'How It Works',
  FULL_BODY_PLAN: 'Full Body Plan',
  THIRTY_DAY_PLAN: 'Join the 30 Day GRATEFUL FOR MY BODY CHALLENGE!',
  SUBSCRIBE_NOW: 'Subscribe now',
  LETS_DO_THIS: 'Join Now! Let’s do this',
  REVIEWS: 'Reviews',
  JOIN_JEN_HEALTH: 'Join our Jen Health',
  COMMUNITY_CHALLENGE_BEGINS: 'Community Challenge beginning August 1st!',
  JEN_HEALTH_CHALLENGE: '#JenHealthChallenge',
  JOIN_FULL_BODY_IMPACT_PLAN:
    'Join our Full Body Low Impact Plan on Jen Health',
  JOIN_LOW_BACK_PLAN:
    'Join the Low Back Plan on Jen Health and start feeling the difference',
  GRATEFUL_FOR_MY_BODY_CHALLENGE_H2:
    'Join the All New 30 DAY RELEASE & RECOVERY PROGRAM',
  ABOUT_JEN_HEALTH: 'About The Full Body Impact Plan',
  ABOUT_MOBILITY: 'About The 30 Day Full Body Mobility Plan',
  ABOUT_GRATEFUL_FOR_MY_BODY_CHALLENGE:
    'About The Grateful For My Body Challenge',
  ABOOUT_30_DAY: 'About 30 Days of Release & Recovery',
  LOW_BACK_DESCRIPTION:
    'Move through <b>30 Days</b> of targeted mobility and strengthening drills to <b>relieve low back pain</b> and start moving better with a Doctor of Physical Therapy to guide you!',
  FULL_BODY_PLAN_DESCRIPTION:
    'Learn how to <b>Improve Mobility, Relieve Consistent Aches & Pains,</b> and <b>Move Smarter</b> with a Doctor of Physical Therapy to Guide You!',
  GRATEFUL_FOR_MY_BODY_DESCRIPTION:
    "It's time to <b>Release Restrictions, Relieve Aches & Pains,</b> and <b>Reset the Body</b> from the Inside Out",
  IM_IN: "I'M IN",
  STARTS_AUGUST_FIRST:
    'Join our Jen Health Community Challenge beginning August 1st!',
  GET_STARTED_NOW: 'Get started now',
  GET_IN_BEFORE: 'Get in before the price increases',
  YOU_WILL_RECEIVE_ONE_DOLLAR_DAY:
    'When you plan for less than $1/Day, you will receive',
  YOU_WILL_RECEIVE_TWO_DOLLAR_DAY:
    'When you sign up for only $2/day, you will receive',
  PS_TEXT_2: "Less than $1/DAY!! That's a $25 investment into your health!",
  PS_TEXT_3:
    "P.S. Yup... only $2/DAY!! That's a $45 investment into your health!",
  CLICK_HERE_TO_SUBSCRIBE: 'Click here to subscribe',
  ELIGIBLE_TO: 'You’ll also be eligible to',
  MEET_DOC_JEN_FRABONI: 'Meet Dr. Jen Fraboni',
  DOC_INFO_3:
    'Dr. Jen Fraboni, PT, DPT, is an internationally-renown physical therapist who specializes in helping people overcome chronic pain and maximize physical performance.',
  DOC_INFO_4:
    'As the founder of the new platform and app, “Jen.Health,” she brings a unique, whole body approach to strength, mobility and pain-free living. In 2019, Jen was named one of the top 50 most influential healthcare professionals. Jen’s easily accessible approach has garnered her more than half a million followers on social media and millions of views of her health and fitness videos. Jen has been featured in Shape Magazine, Self Magazine, Men’s Fitness and Muscle and Fitness and in 2020, graced the cover of Oxygen Magazine. During the pandemic, she helped ease back pain with her feature on Good Morning America and NBC. Dr. Jen is the co-host with her husband, who is also a Doctor of Physical Therapy, to a popular podcast called "The Optimal Body Podcast.”',
  LIVE_NOW: 'Live Now',
  SWITCH_PLAN: 'Switch to',
  READY_TO_SWITCH: 'Ready to switch to',
  SWITCH_TO: 'Switch to',
  ENROLL_NOW: 'Enroll Now',
  SIGN_UP_HERE: 'Sign up here',
  ABOUT_BAREFOOT_MINI_COURSE: 'About The Barefoot Mini Course',
  JOIN_THE_COURSE: 'Join the course',
  VIEW_THE_COURSE: 'View the course',
  REBUILD_FOOT_HEALTH: 'Rebuild Foot Health to Relieve Pain',
  REBUILD_FOOT_HEALTH_DESCRIPTION:
    "Don't wait until you have <b>plantar fasciitis</b>, painful bunions, or are <b>living with orthotics</b>. Come learn how to build a <b>proper foundation</b> through assessment and exercise in quick 10 minute videos with Dr. Jen Fraboni, a Doctor of Physical Therapy",
  CHALLENGE_TEXT:
    "Don't wait until you have <b>plantar fasciitis, painful bunions</b>, or are <b>living with orthotics</b>.",
  BFM_DOCUMENT: 'Barefoot mini course',
  WHAT_YOU_WILL_LEARN: "What You'll learn",
  BARE_FOOT_MINI_COURSE:
    'The Barefoot Mini-Course is a <b>two week course</b> that will provide you with: ',
  FEET_FOUNDATION: 'Our feet are our foundation',
  LOW_BACK_RESILIENT: 'The Low Back is more resilient than we think.',
  HIPS_AXIS: 'The Hips are our axis point for movement and locomotion',
  PAY_ATTENTION_TO_FEETS:
    "Even if you're not experiencing pain or issues, this course is designed to help you understand what you could be missing within your feet. Your feet are your base of support. We use them all day long, and yet neglect paying attention to them the most!",
  DONT_MISS_OPPORTUNITY: "Don't miss out on this opportunity to learn!",
  BARE_FOOT_COURSE: 'What Do You Get Within The Barefoot Mini Course?',
  READY_TO_LEARN: 'Ready to Learn?',
  WHY_FOOT_PAIN: 'Why Are So Many People Dealing with Foot Pain?',
  NO_ACTIVE_CHALLENGES: 'No Active Challenges',
  JOIN_BAREFOOT_COURSE: 'Join the Barefoot Mini Course',
  BARE_FOOT_MINI_COURSE_DESCRIPTION:
    "Don't wait until you have plantar fasciitis, painful bunions, or are living with orthotics. Come learn how to build a proper foundation through assessment and exercise in quick 10 minute videos with Dr. Jen Fraboni, a Doctor of Physical Therapy",
  PURCHASES_PREV_BOUGHT: 'Purchased',
  CHALLENGE_SPECIAL_COURSES: 'Featured Courses',
  AVAILABLE_COURSES: 'Available Courses',
  GRATEFUL_FOR_MY_BODY: 'Join the Grateful for My Body Challenge',
  JOIN_30_DAYS: 'Join 30 Days of Release & Recovery',
  GET_IN_BEFORE_PRICE_INCREASE: 'Get in before the price increases!',
  READY_TO_EXPERIENCE: 'Ready to experience',
  GRATEFUL_CHALLENGE_2022: '#GratefulForMyBody2022 CHALLENGE!?',
  JOIN_COMMUNITY: 'join the community',
  JEN_CALENDER_PARA:
    'A $300 value at minimum and we are giving it to you for $45!',
  JEN_CALENDER_H4: 'All of this for only $2/day when you sign up!',
  GRATEFUL_ENROL_TEXT:
    'Enroll in the Grateful For My Body Challenge to have the opportunity to win one of these prizes',
  FULL_BODY_ENROLL_TEXT:
    'Join the 30 day Full Body Mobility Plan on Jen Health to have the opportunity to win one of these prizes',
  FULL_BODY_MOBILITY_DESCRIPTION:
    'Learn the tools to <b>Improve Mobility & Relieve Consistent Aches & Pains</b> in only <b>10-15 minutes/day</b>',
  WHO_IS_THIS_COURSE_FOR: 'Who is this Course for?',
  JOIN_PLAN_NOW: 'Join the Plan Now!',
  CLICK_TO_JOIN_NOW: 'click here to join now',
  ENROLL_IN_UPPER_BACK_AND_NECK_PLAN:
    'Enroll in the Neck & Upper Back Plan to have the opportunity to win one of these prizes',
  ENROLL_IN_MOVE_TO_IMPROVE_PLAN:
    'Enroll in the Move 2 Improve Challenge to have the opportunity to win one of these prizes',
  JOIN_CHALLENGE_NOW: 'Join the challenge Now!',
  NEXT_DAY: 'Next Day',
  NEXT_PHASE: 'Next Phase',
  VIEW: 'View',
  SUBMIT: 'Submit',
  NEXT: 'Next',
  GO_TO_NEXT_QUESTION: 'Go to Next Question',
  VIEW_QUESTIONNAIRE: 'View Questionnaire',
  START_QUESTIONNAIRE: 'Start Questionnaire',
  DONT_TRACK_MY_PROGRESS: "I don't want to track my progress",
  WORKOUTPREP_AND_RECOVERY: 'Workout Prep & Recovery',
  WORKOUTPREP_AND_RECOVERY_DESCRIPTION: `Building a consistent workout routine but need <b>quick, effective warm-ups</b> and <b>cool-downs</b> to better prepare your body and improve your mobility along the way? <p className='mt-3 mb-0'><b>Join Jen Health to get 14 warm-ups and cool-downs you can use for any type of workout!</b></p>`,
};

export const CAROUSEL_BREAKPOINT = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1025 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

export const CAROUSEL_V2_BREAKPOINT = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1023, min: 767 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

export const CAROUSEL_4_LAYOUT = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1023, min: 767 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

export const CAROUSEL_3_AND_HALF_LAYOUT = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3.5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3.5,
  },
  tablet: {
    breakpoint: { max: 1023, min: 767 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

export const BODY_PARTS_BREAKPOINT = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1025 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 2,
  },
};

export const SELF_CAROUSEL = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 768 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const PLANS_CAROUSEL = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 768 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const QUICK_TIPS_CAROUSEL = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 768 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const FAQS_CAROUSEL = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 768 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const DETAILED_CAROUSEL = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 768 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const MONTHS = {
  Jan: '01',
  Feb: '02',
  March: '03',
  April: '04',
  May: '05',
  June: '06',
  July: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
};

export const MONTHS_PRINT = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'Aug',
  '09': 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const SURVEY_STATUS = {
  PENDING: 'PENDING',
  DONE: 'DONE',
};

export const LOCAL_STORAGE_CONSTANT = {
  SELECTED_FILTERS: 'selectedFilters',
  SESSION: 'session',
  SURVEY_STATUS: 'survey-status',
  SURVEY_SESSION: 'survey-session',
  USER: 'jweb-user',
  FOLDER_PAGES: 'folder-pages-cache',
  PUBLIC_PLANS_CACHE: 'jweb-public-plans-cache',
  BODY_PARTS_OPTIONS: 'jweb-body-parts-options',
  LAST_VOLUME: 'videojs-last-volume',
  LAST_PLAYBACK_RATE: 'videojs-playback-rate',
  MY_PLANS: 'my-plans-cache',
  RECOMMENDED_PLANS: 'recommended-plans-cache',
  REGISTERED_WITH_JEN_HEALTH_CHALLENGE: 'jweb-jen-health-challenge',
  ACTIVE_CHALLENGES: 'active-challenges-list-cache',
  USER_REF_ATTRIBUTE: 'jweb-user-ref-attribute',
  UTM_SOURCE_ATTRIBUTE: 'jweb-utm-source-attribute',
  UTM_CAMPAIGN_ATTRIBUTE: 'jweb-utm-campaign-attribute',
  UTM_MEDIUM_ATTRIBUTE: 'jweb-utm-medium-attribute',
  UTM_CONTENT_ATTRIBUTE: 'jweb-utm-content-attribute',
  FB_CLID: 'jweb-ads-fbclid-attribute',
  UTM_AD_ID: 'jweb-ads-ad-id-attribute',
  UTM_PIXEL_ID: 'jweb-ads-pixel-id-attribute',
  ORDER_ID_TIMESTAMP: 'order_id_timestamp',
};

export const USER_ATTRIBUTES = {
  ONBOARDING_COMPLETED: 'onboarding_completed',
};

export const STATUS = {
  COMPLETED: 'completed',
  IN_PROGRESS: 'in_progress',
  NOT_STARTED: 'not_started',
  NEW: 'new',
};

export const GENDER_OPTIONS = [
  { label: 'Female', value: 'female' },
  { label: 'Male', value: 'male' },
  { label: 'Non Binary', value: 'non_binary' },
  { label: 'Rather Not Share', value: 'not_share' },
];

export const COUNTRY_CODE = {
  '+1': 'US',
  '+32': 'BE',
  '+41': 'CH',
  '+43': 'AT',
  '+54': 'AR',
  '+55': 'BR',
  '+56': 'CL',
  '+61': 'AU',
  '+86': 'CN',
  '+93': 'AF',
  '+225': 'CI',
  '+226': 'BF',
  '+229': 'BJ',
  '+236': 'CF',
  '+244': 'AO',
  '+257': 'BI',
  '+267': 'BW',
  '+297': 'AW',
  '+355': 'AL',
  '+359': 'BG',
  '+374': 'AM',
  '+375': 'BY',
  '+376': 'AD',
  '+387': 'BA',
  '+501': 'BZ',
  '+591': 'BO',
  '+673': 'BN',
  '+880': 'BD',
  '+971': 'AE',
  '+973': 'BH',
  '+975': 'BT',
  '+994': 'AZ',
  '+237': 'CM',
  '+243': 'CD',
  '+242': 'CG',
  '+682': 'CK',
  '+57': 'CO',
  '+269': 'KM',
  '+238': 'CV',
  '+506': 'CR',
  '+53': 'CU',
  '+357': 'CY',
  '+420': 'CZ',
  '+49': 'DE',
  '+253': 'DJ',
  '+767': 'DM',
  '+45': 'DK',
  '+213': 'DZ',
  '+593': 'EC',
  '+20': 'EG',
  '+291': 'ER',
  '+34': 'ES',
  '+372': 'EE',
  '+251': 'ET',
  '+358': 'FI',
  '+679': 'FJ',
  '+500': 'FK',
  '+33': 'FR',
  '+298': 'FO',
  '+691': 'FM',
  '+241': 'GA',
  '+44': 'GB',
  '+995': 'GE',
  '+233': 'GH',
  '+350': 'GI',
  '+224': 'GN',
  '+590': 'GP',
  '+220': 'GM',
  '+245': 'GW',
  '+240': 'GQ',
  '+30': 'GR',
  '+299': 'GL',
  '+502': 'GT',
  '+594': 'GF',
  '+592': 'GY',
  '+852': 'HK',
  '+504': 'HN',
  '+385': 'HR',
  '+509': 'HT',
  '+36': 'HU',
  '+62': 'ID',
  '+91': 'IN',
  '+353': 'IE',
  '+98': 'IR',
  '+964': 'IQ',
  '+354': 'IS',
  '+972': 'IL',
  '+39': 'IT',
  '+962': 'JO',
  '+81': 'JP',
  '+7': 'RU',
  '+254': 'KE',
  '+996': 'KG',
  '+855': 'KH',
  '+686': 'KI',
  '+82': 'KR',
  '+965': 'KW',
  '+856': 'LA',
  '+961': 'LB',
  '+231': 'LR',
  '+218': 'LY',
  '+423': 'LI',
  '+94': 'LK',
  '+266': 'LS',
  '+370': 'LT',
  '+352': 'LU',
  '+371': 'LV',
  '+853': 'MO',
  '+212': 'MA',
  '+377': 'MC',
  '+373': 'MD',
  '+261': 'MG',
  '+960': 'MV',
  '+52': 'MX',
  '+692': 'MH',
  '+389': 'MK',
  '+223': 'ML',
  '+356': 'MT',
  '+95': 'MM',
  '+382': 'ME',
  '+976': 'MN',
  '+258': 'MZ',
  '+222': 'MR',
  '+596': 'MQ',
  '+230': 'MU',
  '+265': 'MW',
  '+60': 'MY',
  '+262': 'RE',
  '+264': 'NA',
  '+687': 'NC',
  '+227': 'NE',
  '+672': 'NF',
  '+234': 'NG',
  '+505': 'NI',
  '+683': 'NU',
  '+31': 'NL',
  '+47': 'SJ',
  '+977': 'NP',
  '+674': 'NR',
  '+64': 'NZ',
  '+968': 'OM',
  '+92': 'PK',
  '+507': 'PA',
  '+51': 'PE',
  '+63': 'PH',
  '+680': 'PW',
  '+675': 'PG',
  '+48': 'PL',
  '+351': 'PT',
  '+595': 'PY',
  '+970': 'PS',
  '+689': 'PF',
  '+974': 'QA',
  '+40': 'RO',
  '+250': 'RW',
  '+966': 'SA',
  '+249': 'SD',
  '+211': 'SS',
  '+221': 'SN',
  '+65': 'SG',
  '+290': 'SH',
  '+677': 'SB',
  '+232': 'SL',
  '+503': 'SV',
  '+378': 'SM',
  '+252': 'SO',
  '+508': 'PM',
  '+381': 'RS',
  '+239': 'ST',
  '+597': 'SR',
  '+421': 'SK',
  '+386': 'SI',
  '+46': 'SE',
  '+248': 'SC',
  '+963': 'SY',
  '+235': 'TD',
  '+228': 'TG',
  '+66': 'TH',
  '+992': 'TJ',
  '+690': 'TK',
  '+993': 'TM',
  '+670': 'TL',
  '+676': 'TO',
  '+216': 'TN',
  '+90': 'TR',
  '+688': 'TV',
  '+886': 'TW',
  '+255': 'TZ',
  '+256': 'UG',
  '+380': 'UA',
  '+598': 'UY',
  '+998': 'UZ',
  '+58': 'VE',
  '+84': 'VN',
  '+678': 'VU',
  '+681': 'WF',
  '+685': 'WS',
  '+967': 'YE',
  '+27': 'ZA',
  '+260': 'ZM',
  '+263': 'ZW',
};

export const PERSONAL_DETAIL_FIELDS = [
  {
    name: 'name',
    label: 'Full Name',
    placeholder: 'Enter full name',
  },
  {
    name: 'email',
    label: 'Email address',
    placeholder: 'Enter email',
  },
  {
    name: 'mobile',
    label: 'Mobile Number',
  },
  {
    name: 'gender',
    label: 'Gender',
  },
];

export const MODAL_TYPE = {
  LOGIN: 'isLogin',
  OTP: 'isOtpModal',
  SIGNUP: 'isSignup',
  SUCCESS: 'isSuccessModal',
  WAITLIST: 'isWaitList',
  THANK_YOU: 'isThankYou',
};

export const SWIPE = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

export const SUBSCRIPTION_STATUSES = {
  FREE_TRAIL_ACTIVE: 'Free Trial Active',
  FREE_TRAIL_CANCELLED: 'Free Trial Cancelled',
  FREE_TRAIL_ENDED: 'Free Trial Ended',
  SUBSCRIPTION_ACTIVE: 'Subscription Active',
  SUBSCRIPTION_CANCELLED: 'Subscription Cancelled',
  SUBSCRIPTION_ENDED: 'Subscription Ended',
};

export const PAYMENT_INTERVAL = {
  YEARLY: 'year',
  MONTHLY: 'month',
};

export const GOALS_OPTIONS = [
  'Reduce Pain',
  'Improve Mobility',
  'Increase Strength',
  'Improve Posture',
];

export const TRIED_SOLUTION_OPTIONS = [
  'Visiting PT for exercises',
  'Self workout',
  'Taking online exercises',
  'I haven’t tried anything',
];

export const EXCERCISE_FREQUENCY_OPTIONS = [
  '7 Days a week',
  '3-5 Days a week',
  'Twice a week',
  'I never workout',
];

export const TIME_PER_DAY_OPTIONS = [
  '15-30 mins a day',
  '30-45 mins a day',
  '45-60 mins a day',
  'None of the above',
];

export const PREFFERED_PART_OF_DAY_OPTIONS = [
  'Morning',
  'Afternoon',
  'Evening',
  'I have no preference',
];

export const ONBOARDING_STEPS_ENUM = {
  insiprational_quote: 1,
  name: 2,
  dob: 3,
  goal: 4,
  tried_solutions: 5,
  exercise_frequency: 6,
  time_per_day: 7,
  preferred_part_of_day: 8,
  email: 9,
};

export const testimonials = [
  {
    pictureUrl: IMAGE_CONSTANT.FullbodyIcon,
    name: 'Full Body',
  },
  {
    pictureUrl: IMAGE_CONSTANT.neckIcon,
    name: 'Neck',
  },
  {
    pictureUrl: IMAGE_CONSTANT.shouldersIcon,
    name: 'Shoulders',
  },
  {
    pictureUrl: IMAGE_CONSTANT.upperBackIcon,
    name: 'Upper Back',
  },
  {
    pictureUrl: IMAGE_CONSTANT.elbowIcon,
    name: 'Elbow',
  },
  {
    pictureUrl: IMAGE_CONSTANT.wristIcon,
    name: 'Wrist',
  },
  {
    pictureUrl: IMAGE_CONSTANT.ankleIcon,
    name: 'Ankle',
  },
  {
    pictureUrl: IMAGE_CONSTANT.CoreIcon,
    name: 'Core',
  },
  {
    pictureUrl: IMAGE_CONSTANT.GroinIcon,
    name: 'Groin',
  },
  {
    pictureUrl: IMAGE_CONSTANT.HamstringIcon,
    name: 'Hamstring',
  },
  {
    pictureUrl: IMAGE_CONSTANT.headIcon,
    name: 'Head',
  },
  {
    pictureUrl: IMAGE_CONSTANT.HipsIcon,
    name: 'Hips',
  },
  {
    pictureUrl: IMAGE_CONSTANT.kneesIcon,
    name: 'Knees',
  },
  {
    pictureUrl: IMAGE_CONSTANT.lowerBackIcon,
    name: 'Lower Back',
  },
  {
    pictureUrl: IMAGE_CONSTANT.pelvisIcon,
    name: 'Pelvis',
  },
  {
    pictureUrl: IMAGE_CONSTANT.shinIcon,
    name: 'Shin',
  },
  // {
  //   pictureUrl: IMAGE_CONSTANT.ArtboardIcon,
  //   name: 'Arthboard',
  // },
  {
    pictureUrl: IMAGE_CONSTANT.itBandIcon,
    name: 'IT band',
  },
  // {
  //   pictureUrl: IMAGE_CONSTANT.lowerTrapeziusIcon,
  //   name: 'Lower Trapezius',
  // },
  // {
  //   pictureUrl: IMAGE_CONSTANT.upperTrapeziusIcon,
  //   name: 'Upper Trapezius',
  // },
];
export const TOUR_TARGET_ELEMENT = {
  assessments: 'Self Assessments',
  excercises: 'Exercises',
  folders: 'Folders',
  plans: 'Plans',
  profile: 'Profile',
  quicktips: 'Quick Tips',
  save_video: 'Save_Video',
  blog: 'blog',
  podcast: 'podcast',
  privacy_policy: 'privacy_policy',
  terms_and_conditions: 'terms_and_conditions',
  refund_policy: 'refund_policy',
};

export const TOUR_DESCRIPTION_OBJECT = {
  'Quick Tips': {
    selector: `[data-tour-id='${TOUR_TARGET_ELEMENT.quicktips}']`,
    content: 'Find quick tips to bring you immediate relief',
    // action: {
    //   type: 'SECTION_CHANGE',
    //   payload: 'Quick Tips',
    // },
  },
  Plans: {
    selector: `[data-tour-id='${TOUR_TARGET_ELEMENT.plans}']`,
    content: 'Find structured exercise plans for complete guidance',
    // action: {
    //   type: 'SECTION_CHANGE',
    //   payload: 'Plans',
    // },
  },
  // Exercises: {
  //   selector: `[data-tour-id='${TOUR_TARGET_ELEMENT.excercises}']`,
  //   content: 'Find individual exercises',
  //   action: {
  //     type: 'SECTION_CHANGE',
  //     payload: 'Exercises',
  //   },
  // },
  'Self Assessments': {
    selector: `[data-tour-id='${TOUR_TARGET_ELEMENT.assessments}']`,
    content: 'Try self assessments to understand your baseline',
    // action: {
    //   type: 'SECTION_CHANGE',
    //   payload: 'Self Assessments',
    // },
  },
  Save_Video: {
    selector: `[data-tour-id='${TOUR_TARGET_ELEMENT.save_video}']`,
    content: 'Save videos to folders by clicking this icon',
    // action: {
    //   type: 'SECTION_CHANGE',
    //   payload: 'Quick Tips',
    // },
  },
  Folders: {
    selector: `[data-tour-id='${TOUR_TARGET_ELEMENT.folders}']`,
    content: 'You will find your folders here',
    action: {
      type: 'ROUTE_TO',
      payload: APP_ROUTES.FOLDERS,
    },
  },
  Profile: {
    selector: `[data-tour-id='${TOUR_TARGET_ELEMENT.profile}']`,
    content: 'This is your profile for payments and other details',
    action: {
      type: 'ROUTE_TO',
      payload: APP_ROUTES.PROFILE,
    },
  },
};

export const CHALLENGE_FAQS = [
  {
    title: 'How Will The Challenge Work?',
    description: [
      'You will receive an email to sign into your program and create your password (if you already have an account through Thinkific from a previous program of mine, use the same email and password to make it easy!). To easily find the link again save the following link:<br /><a class="color-primary" href="https://docjenfit.thinkific.com/users/sign_in/">https://docjenfit.thinkific.com/users/sign_in/</a>',
      `If you do not see an email to sign into your account, email me at <a class='color-primary' data-rel="external" href={${getPromptLink(
        'jen@jen.health',
      )}}>jen@jen.health</a> so we can help you out!`,
      'You will receive an email with a downloadable <b>Welcome Package</b> PDF <b>immediately</b>, as well as have access to it from your program online!',
      'You will have access to the <b>five self-assessment</b> videos to be able to go through before your challenge even begins! I recommend slotting 30 minutes somewhere (or doing one assessment at a time) to learn about your your body and chart your current starting point before the challenge begins!',
      '<b>Every Sunday before the new week</b>, I will send you an email with detailed information on what to expect for the week ahead and your program will unlock the week ahead online! The first Sunday you will receive an email is on <b>July 31st</b>.',
      'Every week you will receive <b>4 mobility flows, 2 functional workouts, 2 core workouts</b> and <b>1 muscle activation</b> for the week.',
      'Yes, some of the workouts and mobility flows <b>will repeat</b> throughout the month. Remember, if we want to feel something different in the body, we must <b>maintain consistency</b> as we explore and learn new movements! The challenge will be to see how your body progresses throughout the month!',
      "And most importantly... <b>you will have access to this program for 6 months!</b> I highly encourage you to keep up with us throughout the month of November to the best of your abilities (don't worry, we all miss a day or two throughout the week &#128521;)  in order to truly experience what 30 consistent days of this type of movement can feel like in your body. And then feel free to go back into your program and repeat workouts as needed after to keep learning!",
    ],
  },
  {
    title: 'Who is this challenge for?',
    description: [],
  },
  {
    title: 'Who is this challenge NOT for?',
    description: [],
  },
  {
    title: 'How do the challenge prizes work!?',
    description: [],
  },
];

export const BODY_PARTS_FAQS = [
  {
    title: 'How will the plan Work?',
    description: [
      'You will receive an email to sign into your program and create your password (if you already have an account through Thinkific from a previous program of mine, use the same email and password to make it easy!). To easily find the link again save the following link:<br /><a class="color-primary" href="https://docjenfit.thinkific.com/users/sign_in/">https://docjenfit.thinkific.com/users/sign_in/</a>',
      `If you do not see an email to sign into your account, email me at <a class='color-primary' data-rel="external" href={${getPromptLink(
        'jen@jen.health',
      )}}>jen@jen.health</a> so we can help you out!`,
      'You will receive an email with a downloadable <b>Welcome Package</b> PDF <b>immediately</b>, as well as have access to it from your program online!',
      'You will have access to the <b>five self-assessment</b> videos to be able to go through before your challenge even begins! I recommend slotting 30 minutes somewhere (or doing one assessment at a time) to learn about your your body and chart your current starting point before the challenge begins!',
      '<b>Every Sunday before the new week</b>, I will send you an email with detailed information on what to expect for the week ahead and your program will unlock the week ahead online! The first Sunday you will receive an email is on <b>July 31st</b>.',
      'Every week you will receive <b>4 mobility flows, 2 functional workouts, 2 core workouts</b> and <b>1 muscle activation</b> for the week.',
      'Yes, some of the workouts and mobility flows <b>will repeat</b> throughout the month. Remember, if we want to feel something different in the body, we must <b>maintain consistency</b> as we explore and learn new movements! The challenge will be to see how your body progresses throughout the month!',
      "And most importantly... <b>you will have access to this program for 6 months!</b> I highly encourage you to keep up with us throughout the month of November to the best of your abilities (don't worry, we all miss a day or two throughout the week &#128521;)  in order to truly experience what 30 consistent days of this type of movement can feel like in your body. And then feel free to go back into your program and repeat workouts as needed after to keep learning!",
    ],
  },
  {
    title: 'Who is it for?',
    description: [
      '<b>#GratefulForMyBody</b> is for those who are ready to feel BETTER in their bodies. We will be sweating as we move through the week, but this guide goes far beyond exercise or workouts. It is for those ready to <b>learn proper movement patterns</b>, understand not only HOW to perform exercises better but WHY we might change body positioning. It is for those who might be ignoring aches and pains in the body or eager to finally understand how to move through them without fearing exercise.',
    ],
  },
  {
    title: 'Who should not do this plan?',
    description: [
      "<b>#GratefulForMyBody</b> is not for you if you are looking for a <b>weight loss program</b>. This program is built for you to understand proper exercise execution through expert cueing and video visuals, while understanding how to implement mobility, core and muscle activations into your workout plans. This is your opportunity to learn about your body and appreciate it's ability to adapt and reduce pain as you improve strength.",
    ],
  },
];

export const LEAD_RELEASE_FAQS = [
  {
    title: 'How will the plan Work?',
    description: [
      'You will receive an email to sign into your program and create your password (if you already have an account through Thinkific from a previous program of mine, use the same email and password to make it easy!). To easily find the link again save the following link:<br /><a class="color-primary" href="https://docjenfit.thinkific.com/users/sign_in/">https://docjenfit.thinkific.com/users/sign_in/</a>',
      `If you do not see an email to sign into your account, email me at <a class='color-primary' data-rel="external" href={${getPromptLink(
        'jen@jen.health',
      )}}>jen@jen.health</a> so we can help you out!`,
      'You will receive an email with a downloadable <b>Welcome Package</b> PDF <b>immediately</b>, as well as have access to it from your program online!',
      'You will have access to the <b>five self-assessment</b> videos to be able to go through before your challenge even begins! I recommend slotting 30 minutes somewhere (or doing one assessment at a time) to learn about your your body and chart your current starting point before the challenge begins!',
      '<b>Every Sunday before the new week</b>, I will send you an email with detailed information on what to expect for the week ahead and your program will unlock the week ahead online! The first Sunday you will receive an email is on <b>July 31st</b>.',
      'Every week you will receive <b>4 mobility flows, 2 functional workouts, 2 core workouts</b> and <b>1 muscle activation</b> for the week.',
      'Yes, some of the workouts and mobility flows <b>will repeat</b> throughout the month. Remember, if we want to feel something different in the body, we must <b>maintain consistency</b> as we explore and learn new movements! The challenge will be to see how your body progresses throughout the month!',
      "And most importantly... <b>you will have access to this program for 6 months!</b> I highly encourage you to keep up with us throughout the month of November to the best of your abilities (don't worry, we all miss a day or two throughout the week &#128521;)  in order to truly experience what 30 consistent days of this type of movement can feel like in your body. And then feel free to go back into your program and repeat workouts as needed after to keep learning!",
    ],
  },
  {
    title: 'Who is it for?',
    description: [
      '<b>#GratefulForMyBody</b> is for those who are ready to feel BETTER in their bodies. We will be sweating as we move through the week, but this guide goes far beyond exercise or workouts. It is for those ready to <b>learn proper movement patterns</b>, understand not only HOW to perform exercises better but WHY we might change body positioning. It is for those who might be ignoring aches and pains in the body or eager to finally understand how to move through them without fearing exercise.',
    ],
  },
  {
    title: 'Who should not do this plan?',
    description: [
      "<b>#GratefulForMyBody</b> is not for you if you are looking for a <b>weight loss program</b>. This program is built for you to understand proper exercise execution through expert cueing and video visuals, while understanding how to implement mobility, core and muscle activations into your workout plans. This is your opportunity to learn about your body and appreciate it's ability to adapt and reduce pain as you improve strength.",
    ],
  },
];

export const LOW_IMPACT_FAQS = [
  {
    title: 'Who is this Course for?',
    description: [
      'Our <b>30 Days of Release and Recovery</b> is for those who are ready to <b>feel BETTER</b> in their bodies and finally start addressing those <b>consistent aches and pains.</b> We will be sweating as we move through the week, but this guide goes far beyond exercise or workouts. It is for those ready to <b>learn</b> proper movement patterns, understand not only HOW to perform exercises with improved form but WHY we might change body positioning. It is for those who might be ignoring aches and pains in the body or eager to finally understand how to move through them without fearing exercise.',
      `<b>Don&apos;t have a lot of time to add an hour of yoga or mobility a week?</b> This program will teach you how to understand mobility to implement in smaller pieces throughout your day. The mobility flows each last between <b>8-15 minutes</b> and can easily be implemented as a warm-up, a cool-down or on a rest day.`,
      '<b>Been trying to hammer in core work to help your back pain?</b> The core goes way beyond just the abs. And unless we understand how to utilize the breath, work with your pelvic floor, back and entire abdominal region, we might not ever support the body in the most efficient and effective way! As a previous pilates instructor of 6 years and Doctor of Physical Therapy, I have been studying this "core concept" for a while now. Understanding this foundation can help to relieve back, hip, SI and pelvic pain and dysfunction.',
      '<b>Looking for more structure on how to ideally set up your week and not have to think about it?</b> This plan will provide a 30 day calendar that automatically checks off your days as you complete them for accountability and tracking! We will also be providing a daily check-in graphic you can use to post to your social channels and connect with our community also moving along! Beyond having day-to-day plans, you will have the accountability to show up each day with a huge community of movers all joining in with you!',
      `<b>Don&apos;t have a lot of equipment? Want to workout at home?</b> No problem! We&apos;re going to get creative using step stools, ottomans, benches, and understanding of how to change our positioning to add more load in body weight exercises! Through intention and understanding, you will be able to feel muscles burning you&apos;ve never quite noticed before!`,
    ],
  },
];

export const GRATEFUL_FOR_BODY_FAQS = [
  {
    title: 'How Will The Program Work?',
    description: [
      'The log-in you use to sign up for the program (either phone number or gmail) will be the log-in you use to access your challenge each time! Make sure to always use the same entry as you used to initially sign up. To easily find the link again, save this link in your browser: <a href="https://jen.health">https://jen.health</a>',
      `You will also receive a Welcome Email to go over more information about your program! Please check spam, junk and promotions folders. You can also search for <a data-rel="external" href="${getPromptLink(
        'jen@jen.health',
      )}">jen@jen.health</a> through your laptop or computer email service.`,
      'Your program will include a downloadable <b>Welcome Ebook PDF</b> that you can find when you click on your 30 Days of Release & Recovery inside <b>"Courses"</b> within the Jen Health Platform.',
      'You will have access to the <b>five self-assessment videos</b> that I strongly encourage you go through <b>before</b> you begin your program! I recommend slotting 30 minutes somewhere (or doing one assessment at a time) to learn about your body and chart your current starting point before the challenge begins! We have provided <b>a Guided Workbook</b> in Day 0 of your program, to track your progress and take notes on your self-assessments.',
      'Every week you will receive <b>1 pelvic floor mobility flow, 1 pelvic floor activation sequence, 3 mobility flows (lower body, upper body and full body), 1 breath work class and 1 bonus class for soft tissue mobilization</b>.',
      'Each week you will receive <b>brand new flows</b>, activations and classes to expand your educational toolbox that you can use for months and years to come! Consistency is key. These classes are all filmed in great detail so you can learn and understand the information for your continued use.',
      "And most importantly... <b>you will have access to this program for 6 months! I highly</b> encourage you to keep up with us throughout the month of November to the best of your abilities (don't worry, we all miss a day or two throughout the week 😉)  in order to truly experience what 30 consistent days of this type of movement can feel like in your body. And then feel free to go back into your program and repeat classes as needed after to keep learning!",
      'For those are are <b>Jen Health members</b> (or choose to become Jen Health members), you will have continued access to this challenge for as long as you choose to maintain your membership.',
    ],
  },
  {
    title: 'Who is this Program for?',
    description: [
      "<b>30 Days of Release & Recovery</b> is for those who are ready to feel BETTER in their bodies and get back to <b>baseline</b> within the body. This program is for those who have been feeling <b>tight or locked up</b>, are tired of <b>consistent aches and pains</b> throughout the day or after a workout. It is for those ready to <b>understand</b> how to truly use <b>mobility, breath work</b> and <b>our inner core</b> to reset how we feel on the outside. It is for those who might be ignoring aches and pains in the body and are finally ready to address these <b>underlying issues</b> so they don't turn into bigger injuries.",
      "<b>Don't have a lot of time to add an hour of yoga or mobility a week?</b> This program will teach you how to understand mobility to implement in smaller pieces throughout your day. The mobility flows each last between <b>5-15 minutes</b> and can easily be implemented as a warm-up, a cool-down or on a rest day.",
      "<b>Been trying to hammer in core work to help your back pain?</b> The core goes way beyond just the abs. And unless we understand how to utilize the breath, work with your pelvic floor, back, muscles that connect along the pelvis, and the abdominal region through dynamic movement, we might not ever support the body in the most efficient and effective way! As a previous pilates instructor of 6 years and Doctor of Physical Therapy, I have been studying this 'core concept' for awhile now! And until you can find your foundation, you might never find your abs, back or even glutes!",
      '<b>Looking for more structure on how to ideally set up your week and not have to think about it?</b> This program will provide <b>a 30 day calendar</b> that will automatically check off and keep track of your progress throughout the program. We will also have <b>daily check-in graphics</b> that you can download and use as added support and <b>accountability</b>. Beyond having day-to-day plans, you will have the accountability to show up each day with a huge community of movers all joining in with you! Need more support? Post, comment, and engage with the <b>30 Days of Release & Recovery program</b> community hashtag from people who have gone through this course in the past!',
      "<b>Don't have a lot of equipment?</b> No problem! This program does NOT require any equipment. If you would like to participate in the bonus soft tissue mobilization classes, you will need a foam roller and I recommend having the Coregeous Ball and Therapy Balls from TuneUp Fitness. I will provide links to my favorite soft tissue mobilization tools in the Welcome Ebook.",
    ],
  },
  {
    title: 'Who is this Program NOT for?',
    description: [
      "<b>30 Days of Release & Recovery</b> is <b>not</b> for you if you are looking for a <b>weight loss program</b>. This program is built for you to understand proper exercise execution through expert cueing and video visuals, while understanding how to implement mobility, core and muscle activations into your workout plans. This is your opportunity to learn about your body and appreciate it's ability to adapt and reduce pain as you relieve tension from the body.",
      '<b>This program is not for you if you are hoping to be diagnosed about a specific pain point or symptom you are currently experiencing</b>. This program is merely an educational platform built by a Doctor of Physical Therapy. Though I am a Doctor, I am not YOUR Doctor and without a proper assessment and personal evaluation, I cannot diagnose you nor prescribe an individual treatment plan.',
      '<b>Feel like you may be too advanced for this program?</b> We will be starting easier at the beginning of the month and progress as the month goes on. In my experience (yes, even me as a therapist!), there is ALWAYS more to learn. If you are hesitant about revisiting the basics, slowing things down to ensure proper engagement, or even be challenged in your current way of movement and exercise, this program may not be for you! I want to challenge your idea of mobility, core and exercise execution! I am looking to move with those who are open to exploring the body in new and different ways!',
      `<b>Uncertain you will like it?</b> Unfortunately, we do not offer refunds with this program. Since it is a one-time, low price for 6-month access to 36 educational videos, I truly built this program to work for everyone to learn at least something along the way! We understand the value of learning from a physical therapist and hope you will too! If you're not sure just yet, email us at <a data-rel="external" href="${getPromptLink(
        'jen@jen.health',
      )}">jen@jen.health</a> with any more questions!`,
      "<b>Need a diet plan with your movements?</b> There will not be a meal plan included in this program. You can learn more from my friend, Julia Glanz (registered dietitian) on <b>Instagram or her Intermittent Fasting Guide for Women Program. You can also check out Dr. Dom's Kitchen Independence Cookbook HERE!</b>",
      `Have additional questions? Email me at <a data-rel="external" href="${getPromptLink(
        'jen@jen.health',
      )}">jen@jen.health</a>`,
    ],
  },
];

export const NEXT_30_DAYS = [
  {
    name: '5 Self-Assessment Videos',
    description:
      'It\'s difficult to understand <b>where our restrictions</b> could be stemming from that could be causing <b>compensations</b> and the feeling of <b>imbalance</b> during exercise without first assessing movement. We will use <b>five specific exercise assessments</b> to begin this challenge on "Day 0" and then re-assess at the end to see (or <u>feel</u>) a difference!',
    image: IMAGE_CONSTANT.FiveSelfAssessments,
    placeholderImage: IMAGE_CONSTANT.FiveSelfAssessmentsLow,
    webpImage: IMAGE_CONSTANT.FiveSelfAssessmentsWebp,
    reverseOrder: false,
    valueText:
      'Value of 1 Physical Therapy Appointment for Assessment = <i>$75</i>',
  },
  {
    name: '8 Mobility Flows (5-10 minutes)',
    description:
      '<b>Mobility is crucial to maintain as we age.</b> It is key to providing the foundation from which your body can gain strength, power & coordinate movement. Learn how to <b>properly assess</b> your restrictions and do the mobility exercises that will help to <b>improve range of motion and relieve tension!</b> Learn how to use mobility as a warm-up or when to use it alone to relieve tension after a long day! We will be going through full body flows, upper body flows, lower body flows and flows specific for <b>posture</b>.',
    image: IMAGE_CONSTANT.NineMobilityFlows,
    placeholderImage: IMAGE_CONSTANT.NineMobilityFlowsLow,
    webpImage: IMAGE_CONSTANT.NineMobilityFlowsWebp,
    reverseOrder: true,
    valueText:
      'Value of 8 Mobility Classes by a Physical Therapist= <i>$80</i>',
  },
  {
    name: '6 Strength Workouts (25-30 minutes)',
    description:
      'Strength is imperative to <b>help protect our joints, optimize pain control, and gain overall resiliency</b> within the body. These workouts are meant for every level, beginning with exercises to activate and stimulate the muscle groups we will be using, into either a full body workout, upper body or lower body workout. I will be doing the full workout with you to maximize <b>education, cueing</b> and <b>understanding</b> throughout the workouts. Although I will be in a gym setting, I will demonstrate how to adapt exercises for home. It is recommended to have free weights available but resistance bands will work as well.',
    image: IMAGE_CONSTANT.SevenEffectiveAndFunctionalHIITWorkouts,
    placeholderImage: IMAGE_CONSTANT.SevenEffectiveAndFunctionalHIITWorkoutsLow,
    webpImage: IMAGE_CONSTANT.SevenEffectiveAndFunctionalHIITWorkoutsWebp,
    reverseOrder: false,
    valueText:
      'Value of 6 Educational Classes by a Physical Therapist = <i>$90</i>',
  },
  {
    name: '4 Core Workouts (10 minutes)',
    description:
      'The core is far more than just the 6 pack muscles we often think of. These core workouts will help you to better understand how the entire core from the <b>pelvic floor</b> to the <b>shoulder blades coordinate</b> together to <b>reduce hip flexor tension, low back tightness, SI discomfort</b> and <b>hip popping</b>. We will challenge the core from stabilization through movement and segmental spinal control to help improve true core stability.',
    image: IMAGE_CONSTANT.SixCoreStabilityWorkouts,
    placeholderImage: IMAGE_CONSTANT.SixCoreStabilityWorkoutsLow,
    webpImage: IMAGE_CONSTANT.SixCoreStabilityWorkoutsWebp,
    reverseOrder: true,
    valueText:
      'Value of 4 Core/Pelvic Floor Classes by a Physical Therapist = <i>$50</i>',
  },
  {
    name: '3 High Intensity Interval Workouts (10-20 minutes)',
    description:
      '<b>We lose power at almost twice the rate we lose strength every decade.</b> After age 40, <b>power declines 17%</b>. High Intensity Interval Training <b>stimulates bone and muscle growth</b>, preventing age related bone and muscle wasting. These workouts are <b>highly modifiable</b> for the power athlete to the 70 year old strong grandma. We will be challenging the body to move quicker under control and with <b>proper guidance</b> and <b>intention</b>. ',
    image: IMAGE_CONSTANT.FiveMuscleActivation,
    placeholderImage: IMAGE_CONSTANT.FiveMuscleActivationLow,
    webpImage: IMAGE_CONSTANT.FiveMuscleActivationWebp,
    reverseOrder: false,
    valueText:
      'Value of 3 Guided HIIT Classes by a Physical Therapist = <i>$45</i>',
  },
  {
    name: '4 Live Educational Webinars (30 minutes)',
    description:
      'We are committed to you leaving these 30 days with more knowledge beyond workouts and exercise. Each week, we will have a guest speaker to answer your personal questions. The <b>first week</b>, our guest speaker is a <b>clinical nutritionist,</b> author and mom of 3. <b>The second week</b>, we will have a <b>pelvic floor physical therapist</b> to cut through the noise of “right” & “wrong” and better understand true management of pelvic health from pregnancy, postpartum and into menopause. The <b>third week</b> will be with a well-respected <b>functional medicine practitioner</b> specializing in brain and thyroid health, lean body mass support and longevity. And the <b>last week</b> we will wrap with me, <b>Dr. Jen Fraboni</b>, to answer any lingering body health & pain questions!',
    image: IMAGE_CONSTANT.NoEquipmentNeeded,
    placeholderImage: IMAGE_CONSTANT.NoEquipmentNeededLow,
    webpImage: IMAGE_CONSTANT.NoEquipmentNeededWebp,
    reverseOrder: true,
    valueText:
      'Value of 4 Educational Webinars with Health Professionals = <i>$160</i>',
  },
];

export const STRONG_MOVE2IMPROVE_NEXT_30_DAYS = [
  {
    name: '5 Self-Assessment Videos',
    description:
      'It\'s difficult to understand <b>where our restrictions</b> could be stemming from that could be causing <b>compensations</b> and the feeling of <b>imbalance</b> during exercise without first assessing movement. We will use <b>five specific exercise assessments</b> to begin this challenge on "Day 0" and then re-assess at the end to see (or <u>feel</u>) a difference!',
    image: IMAGE_CONSTANT.StrongFiveSelfAssesments,
    placeholderImage: IMAGE_CONSTANT.StrongFiveSelfAssesmentsLow,
    webpImage: IMAGE_CONSTANT.StrongFiveSelfAssesmentsWebp,
    reverseOrder: false,
    valueText:
      'Value of 1 Physical Therapy Appointment for Assessment = <i>$75</i>',
  },
  {
    name: '8 Mobility Flows (5-10 minutes)',
    description:
      '<b>Mobility is crucial to maintain as we age.</b> It is key to providing the foundation from which your body can gain strength, power & coordinate movement. Learn how to <b>properly assess</b> your restrictions and do the mobility exercises that will help to <b>improve range of motion and relieve tension!</b> Learn how to use mobility as a warm-up or when to use it alone to relieve tension after a long day! We will be going through full body flows, upper body flows, lower body flows and flows specific for <b>posture</b>.',
    image: IMAGE_CONSTANT.StrongEightMobilityFlows,
    placeholderImage: IMAGE_CONSTANT.StrongEightMobilityFlowsLow,
    webpImage: IMAGE_CONSTANT.StrongEightMobilityFlowsWebp,
    reverseOrder: true,
    valueText:
      'Value of 8 Mobility Classes by a Physical Therapist= <i>$80</i>',
  },
  {
    name: '6 Strength Workouts (25-30 minutes)',
    description:
      'Strength is imperative to <b>help protect our joints, optimize pain control, and gain overall resiliency</b> within the body. These workouts are meant for every level, beginning with exercises to activate and stimulate the muscle groups we will be using, into either a full body workout, upper body or lower body workout. I will be doing the full workout with you to maximize <b>education, cueing</b> and <b>understanding</b> throughout the workouts. Although I will be in a gym setting, I will demonstrate how to adapt exercises for home. It is recommended to have free weights available but resistance bands will work as well.',
    image: IMAGE_CONSTANT.StrongSixMobilityStrengths,
    placeholderImage: IMAGE_CONSTANT.StrongSixMobilityStrengthsLow,
    webpImage: IMAGE_CONSTANT.StrongSixMobilityStrengthsWebp,
    reverseOrder: false,
    valueText:
      'Value of 6 Educational Classes by a Physical Therapist = <i>$90</i>',
  },
  {
    name: '4 Core Workouts (10 minutes)',
    description:
      'The core is far more than just the 6 pack muscles we often think of. These core workouts will help you to better understand how the entire core from the <b>pelvic floor</b> to the <b>shoulder blades coordinate</b> together to <b>reduce hip flexor tension, low back tightness, SI discomfort</b> and <b>hip popping</b>. We will challenge the core from stabilization through movement and segmental spinal control to help improve true core stability.',
    image: IMAGE_CONSTANT.StrongFourCoreStabilityWorkouts,
    placeholderImage: IMAGE_CONSTANT.StrongFourCoreStabilityWorkoutsLow,
    webpImage: IMAGE_CONSTANT.StrongFourCoreStabilityWorkoutsWebp,
    reverseOrder: true,
    valueText:
      'Value of 4 Core/Pelvic Floor Classes by a Physical Therapist = <i>$50</i>',
  },
  {
    name: '3 High Intensity Interval Workouts (10-20 minutes)',
    description:
      '<b>We lose power at almost twice the rate we lose strength every decade.</b> After age 40, <b>power declines 17%</b>. High Intensity Interval Training <b>stimulates bone and muscle growth</b>, preventing age related bone and muscle wasting. These workouts are <b>highly modifiable</b> for the power athlete to the 70 year old strong grandma. We will be challenging the body to move quicker under control and with <b>proper guidance</b> and <b>intention</b>. ',
    image: IMAGE_CONSTANT.StrongThreeHIIT,
    placeholderImage: IMAGE_CONSTANT.StrongThreeHIITLow,
    webpImage: IMAGE_CONSTANT.StrongThreeHIITWebp,
    reverseOrder: false,
    valueText:
      'Value of 3 Guided HIIT Classes by a Physical Therapist = <i>$45</i>',
  },
  {
    name: '4 Recorded Educational Webinars (30 minutes)',
    description:
      'We are committed to you leaving these 30 days with more knowledge beyond workouts and exercise. Each week, you will hear from a guest health and wellness expert in their field. In the <b>first week</b>, you will hear from a <b>clinical nutritionist</b>, author and mom of 3, Kelly LeVeque. <b>The second week</b>, you will hear from a <b>pelvic floor physical therapist</b> to cut through the noise of “right” & “wrong” and better understand true management of pelvic health from pregnancy, postpartum and into menopause with Dr. Mae Hughes. The <b>third week</b>, I, <b>Dr. Jen Fraboni</b>, answer questions live that have commonly popped up throughout the challenge to help reduce any extra stress or strain you might be feeling on the body & keep you moving! And the <b>last week</b> you will hear from a well-respected family medicine doctor <b>specializing in brain and thyroid health</b>, lean body mass support and longevity, <b>Dr. Gabrielle Lyons</b>.',
    image: IMAGE_CONSTANT.StrongFourLiveClass,
    placeholderImage: IMAGE_CONSTANT.StrongFourLiveClassLow,
    webpImage: IMAGE_CONSTANT.StrongFourLiveClassWebp,
    reverseOrder: true,
    valueText:
      'Value of 4 Educational Webinars with Health Professionals = <i>$160</i>',
  },
];

export const FULL_BODY_NEXT_30_DAYS = [
  {
    name: '20 Mobility Videos',
    description:
      'Mobility is crucial to a healthy body. It is key to providing the <b>foundation</b> from which your body improves upon and moves freely. Learn how to <b>properly assess</b> your restrictions and do the mobility exercises that will help to <b>improve range of motion</b> and <b>relieve tension!</b> Learn how to use mobility as a warm-up, cool-down or on its own! We will be going through various flows and individual exercises strategically placed around workouts or to cap off your week.',
    image: TwentyMobilityVideos,
    placeholderImage: TwentyMobilityVideosLow,
    webpImage: TwentyMobilityVideosWebp,
    reverseOrder: false,
  },
  {
    name: '6 Functional Strength Videos',
    description:
      "Strength is imperative to function, <b>pain control</b>, and overall <em class='font-weight-bold'>resiliency</em> within the body. These workouts are meant for every level, beginning with exercises to <em class='font-weight-bold'>activate and stimulate</em> the muscle groups we will be using, into either <b>a full body workout, upper body or lower body</b> workout. We will then end with a muscle burnout finisher. It is your choice to add weights to increase resistance or just follow along with your own body weight. <b>I will be doing the full workout</b> with you to maximize education, cueing and understanding throughout the workouts.",
    image: SixFunctionalStrengthVideos,
    placeholderImage: SixFunctionalStrengthVideosLow,
    webpImage: SixFunctionalStrengthVideosWebp,
    reverseOrder: true,
  },
  {
    name: '2 Foundational Core & Pelvic Floor Videos',
    description:
      "Core stability? It's not the \"6 pack\" you might think. It's WAY more. And if we actually want to build a <b>strong foundation</b> that supports our back, hips, knees and neck you are going to want to start learning here. I'll provide you with the tools to begin building that strong core power from the <b>pelvic floor to scapular control</b>. You’ll understand how to reduce any hip popping that is common with core exercises and maximize your overall trunk and pelvic control.",
    image: TwoFoundationalCorePelvicFloorVideos,
    placeholderImage: TwoFoundationalCorePelvicFloorVideosLow,
    webpImage: TwoFoundationalCorePelvicFloorVideosWebp,
    reverseOrder: false,
  },
  {
    name: '7 Muscle Activation & Corrective Exercise Videos',
    description:
      "What are muscle activations? Think of these as your <b>physical therapy</b> guided, <b>corrective exercises</b>. I will be demonstrating and instructing how to get into the most effective, corrective exercises so that, once and for all, you can finally <b>feel those glutes</b> fire up without any back pain or hip flexor issues. Get ready to connect to your body in ways you haven't been able to before!",
    image: SevenMuscleActivationAndCorrectiveExerciseVideos,
    placeholderImage: SevenMuscleActivationAndCorrectiveExerciseVideosLow,
    webpImage: SevenMuscleActivationAndCorrectiveExerciseVideosWebp,
    reverseOrder: true,
  },
  {
    name: 'BONUS: Access to 12 Self-Assessments!',
    description:
      'It’s difficult to know <b>where to start</b> without understanding <b>what your body needs</b> first.<br /> Take the first week before we start to go through at least 3 of these self-assessments to get to know your body better and understand what your body needs most!',
    image: BONUSAccessTo12SelfAssessments,
    placeholderImage: BONUSAccessTo12SelfAssessmentsLow,
    webpImage: BONUSAccessTo12SelfAssessmentsWebp,
    reverseOrder: false,
  },
];

export const GRATEFUL_FOR_BODY_NEXT_30_DAYS = [
  {
    name: '5 Self Assessments',
    description:
      "It's difficult to know <b>where to start</b> without understanding <b>what your body needs first</b>. We will use <b>five specific self-assessments</b> to begin this challenge and then re-assess at the end of the 30 days to see (or feel) a difference! These assessments will cover the pelvic floor, nerves in the upper and lower body, and joint rotation of the shoulder and hip. We also have a <b>workbook</b> for you to keep track of your findings!",
    image: FiveSelfAssessment,
    placeholderImage: FiveSelfAssessmentLow,
    webpImage: FiveSelfAssessmentWebp,
    reverseOrder: false,
  },
  {
    name: '13 Mobility Flows',
    description:
      "Mobility is <b>crucial</b> to a healthy body. It is key to providing the <b>foundation</b> from which your body improves upon and moves freely. Learn how to <b>properly assess</b> your restrictions and do the mobility exercises that will help to <b>improve range of motion</b> and <b>relieve tension!</b> Learn how to use mobility as a warm-up, cool-down or on it's own! We will be going through full body flows, upper body flows, and lower body flows. These flows will even focus on the health and <b>mobility of our nerves</b> and how they glide through our muscles.",
    image: TherteenMobilityFlows,
    placeholderImage: TherteenMobilityFlowsLow,
    webpImage: TherteenMobilityFlowsWebp,
    reverseOrder: true,
  },
  {
    name: '5 Flows for the Pelvic Floor ',
    description:
      "The pelvic floor often gets blamed for being too weak. We are told to do kegels or hold in a yoni egg in order to stop or prevent <b>leaking</b> or <b>incontinence</b>. However, just like any other muscle, the muscles that make up our <b>pelvic floor must be able to relax and lengthen</b> in order to contract and be strong. If we don't address the tension first, it is difficult to build appropriate strength and coordination. The muscles highly connected to the pelvic floor include the adductors, glutes, deep rotators and muscles connecting to the pelvis. It's important to address them all in order to improve pelvic floor relaxation and reduce tension.",
    image: FiveFlowsForPelvicFloor,
    placeholderImage: FiveFlowsForPelvicFloorLow,
    webpImage: FiveFlowsForPelvicFloorWebp,
    reverseOrder: false,
  },
  {
    name: '5 Pelvic Floor Activations',
    description:
      "Kegels alone <b>don't</b> improve functional control or strength of the pelvic floor through movement. We must first understand how the pelvic floor <b>coordinates</b> with the diaphragm. Then, we can start <b>retraining how the muscles around the pelvis coordinate with the pelvic floor to help improve functional strength and control of your pelvic floor!</b> We will be progressing in strength and coordination throughout the month.",
    image: FivePelvicFlowsMotivation,
    placeholderImage: FivePelvicFlowsMotivationLow,
    webpImage: FivePelvicFlowsMotivationWebp,
    reverseOrder: true,
  },
  {
    name: '4 Breath Work Classes',
    description:
      "With the intention on releasing restrictions and resetting the body, we must address the breath. Now, these classes won't be focused as much on all the different types of breath work you can do, but more on the body components needed to create <b>improved, expansive</b> breaths that reduce stress and tension from the neck and lower back. We will be improving the <b>mobility of the ribcage, upper back and mechanics of the breath.</b>",
    image: FourBreathWork,
    placeholderImage: FourBreathWorkLow,
    webpImage: FourBreathWorkWebp,
    reverseOrder: false,
  },
  {
    name: 'BONUS: 4 Soft Tissue Mobilization Classes!',
    description:
      'These are <b>completely optional</b>, as you do NOT need tools to be able to participate in this challenge. <b>Foam rolling is NOT used to release fascia or break up scar tissue</b>. That is simply not possible. However, it can be an extremely helpful tool to <b>improving mobility</b> through touch and up regulation of the parasympathetic nervous system. I will be instructing you on how to properly use tools like a foam roller or balls to positively influence the body.',
    image: FourSoftTissue,
    placeholderImage: FourSoftTissueLow,
    webpImage: FourSoftTissueWebp,
    reverseOrder: true,
  },
  {
    name: 'Weekly Mind-Body Pain Reset Tools + Daily Goals',
    description:
      "Our mind has the ability to control our muscles, fascia, movement, and pain. I'll be providing <b>tools</b> to help improve this <b>connection</b> and set you up for long-term success within your body. In addition to movement, we will have a new intention or 'focus of the week' each week. We will move and work with some mindset tools using the intention/focus.",
    image: WeeklyMindBody,
    placeholderImage: WeeklyMindBodyLow,
    webpImage: WeeklyMindBodyWebp,
    reverseOrder: false,
  },
];

export const BARE_FOOT_MINI_COURSE_FAQS = [
  {
    title: 'Who This Course is For',
    listItems: [
      'Those who have struggled with <b>achy, sore feet day</b> after day',
      'Those who have been told to rely on <b>orthotics</b> forever',
      'Those with <b>persistent</b> or <b>recurring</b> foot pain',
      'Those who struggle with shin <b>splints</b> or <b>achilles tendinitis</b>',
      'Those struggling with <b>knee</b> or <b>low back</b> pain during movement',
      'Those wanting to <b>prevent</b> future foot/ankle or knee pain!',
    ],
    description:
      "<p>Even if you're not experiencing pain or issues, this course is designed to help you understand what you could be missing within your feet. Your feet are your base of support. We use them all day long, and yet neglect paying attention to them the most!</p><h5>Don't miss out on this opportunity to learn!</h5>",
    image: IMAGE_CONSTANT.knowledgeIsPowerful,
    placeholderImage: IMAGE_CONSTANT.knowledgeIsPowerfulLow,
    webpImage: IMAGE_CONSTANT.knowledgeIsPowerfulWebp,
    type: 'ul',
  },
  {
    title: 'Knowledge is Powerful',
    header:
      '<h5>I created this course so you can feel more empowered with the tools to improve your own foot health.</h5>',
    description:
      'Orthotics should be used as temporary support as you learn to use your own foot muscles. Flat feet is not a reason to search for more arch support. No matter the height of the arch, we have the ability strengthen our intrinsic foot muscles and create a more adaptable and functional foot. We should be learning to support our own foot health rather than relying and hoping external shoes, tools, and products will do the work for us. This is why I created this course. To provide the tools, education and knowledge for you to take back control of your own foot health! ',
    image: IMAGE_CONSTANT.whoItsFor,
    placeholderImage: IMAGE_CONSTANT.whoItsForLow,
    webpImage: IMAGE_CONSTANT.whoItsForWebp,
    type: 'text',
    reverseOrder: true,
  },
  {
    title: 'Course Availability',
    header: '<h5>Access the course on your own time, at your own pace.</h5>',
    listItems: [
      'All videos are pre-recorded and available for viewing anytime on your own. The Guided Workbook will be available at the top of your course and available for download to take notes as you assess & learn.',
      'Each day will have a video, plus additional educational resources to understand the anatomy connected with each exercise.',
      'The recorded interview with Dr. Emily Splichal is already uploaded and to your course! Many people just like you were able to ask so many incredible questions about foot health, diagnosis, shoes, and so much more!',
    ],
    image: IMAGE_CONSTANT.courseAvailability,
    placeholderImage: IMAGE_CONSTANT.courseAvailabilityLow,
    webpImage: IMAGE_CONSTANT.courseAvailabilityWebp,
    type: 'ul',
  },
];

export const CHALLENGE_TYPE_ENUM = {
  CHALLENGE_LIST: 'challenge_list',
  COURSE_LIST: 'courses_list',
  COURSES: 'courses',
  COURSE: 'course',
};

export const CHALLENGE_REDIRECT_TO = {
  [APP_ROUTES.BARE_FOOT_MINI_COURSE]: COURSES_SLUGS.BARE_FOOT,
  [APP_ROUTES.FULL_BODY_LOW_IMPACT]: COURSES_SLUGS.FULL_BODY,
  [APP_ROUTES.GRATEFUL_FOR_BODY]: COURSES_SLUGS.GRATEFUL_FOR_BODY,
  [APP_ROUTES.OLD_COURSES_BARE_FOOT]: '/' + COURSES_SLUGS.BARE_FOOT,
  [APP_ROUTES.OLD_COURSES_FULL_BODY_LOW_IMPACT]: '/' + COURSES_SLUGS.FULL_BODY,
  [APP_ROUTES.OLD_COURSES_GRATEFUL_FOR_BODY]:
    '/' + COURSES_SLUGS.GRATEFUL_FOR_BODY,
  [APP_ROUTES.OLD_CHALLENGES_BARE_FOOT]: '/' + COURSES_SLUGS.BARE_FOOT,
  [APP_ROUTES.OLD_CHALLENGES_FULL_BODY_LOW_IMPACT]:
    '/' + COURSES_SLUGS.FULL_BODY,
  [APP_ROUTES.OLD_CHALLENGES_GRATEFUL_FOR_BODY]:
    '/' + COURSES_SLUGS.GRATEFUL_FOR_BODY,
  [APP_ROUTES.FULL_BODY_MOBILITY]: COURSES_SLUGS.FULL_BODY_MOBILITY,
  [APP_ROUTES.OLD_GRATEFUL_FOR_BODY]: COURSES_SLUGS.GRATEFUL_FOR_BODY,
};

export const defaultVideoPlayerOptions = {
  // lookup the options in the docs for more options
  autoplay: true,
  responsive: true,
  fluid: true,
  controls: true,
  playbackRates: [0.5, 1, 1.25, 1.5, 2],
  disablePictureInPicture: true,
  sources: [],
};

const BARE_FOOT_FITLER = [
  {
    id: '156',
    name: 'Ankle & Foot',
  },
];

const FULL_BODY_FILTER = [
  {
    id: '18',
    name: 'Full Body',
  },
];

const LOW_BACK_FILTER = [
  {
    id: '9',
    name: 'Low Back',
  },
];

export const SLUGS_TO_FILTERS = {
  [COURSES_SLUGS.BARE_FOOT]: BARE_FOOT_FITLER,
  [COURSES_SLUGS.FULL_BODY]: FULL_BODY_FILTER,
  [COURSES_SLUGS.FULL_BODY_MOBILITY]: FULL_BODY_FILTER,
  [COURSES_SLUGS.GRATEFUL_FOR_BODY]: FULL_BODY_FILTER,
  [COURSES_SLUGS.LowBack]: LOW_BACK_FILTER,
};

export const defaultWinningPrizes = [
  {
    title: 'Prizes for anyone who joins',
    items: [
      {
        title: 'The Optimal Body Therapy Kit',
        priceDisc: '($60 Value)',
        image: IMAGE_CONSTANT.bodyTherapyIcon,
      },
    ],
  },
  {
    title: 'Prizes for those who post 3x per week on their Main IG feed',
    items: [
      {
        title: '3 Winners of <br /> Intelliroll Foam Roller',
        priceDisc: '($60 Value)',
        image: IMAGE_CONSTANT.foamRollerIcon,
      },
      {
        title: 'Free 1 Year of Jen Health',
        priceDisc: '($180 Value)',
        image: IMAGE_CONSTANT.jenLowImapceIcon2,
      },
    ],
  },
];

export const PUBLIC_PRIVATE_COMBINED = [
  APP_ROUTES.THANK_YOU,
  APP_ROUTES.PODCASTS,
  APP_ROUTES.BLOGS,
  '/blog',
  '/podcast',
  '/podcast-listing',
  '/blog-listing',
  APP_ROUTES.PRIVACY_POLICY,
];
export const BLOG_LIST_TEXT_CONSTANT = [
  {
    image: { src: IMAGE_CONSTANT.backPain, altImg: IMAGE_CONSTANT.backPainLow },
    bulletPoints: ['Back Pain', '27 aug 23', '8 Mins read'],
    heading: 'Improve Thoracic Mobility with Simple Breathing Exercises',
    content:
      'Looking to improve your thoracic mobility? Having issues with the range of motion in your upper back? Whether it’s extension or rotation, we want our upper back to be able to move well, as it is absolutely vital to the health of our low back, neck...',
  },
  {
    image: { src: IMAGE_CONSTANT.buttPain, altImg: IMAGE_CONSTANT.buttPainLow },
    bulletPoints: ['Butt Pain', '27 aug 23', '3 Mins read'],
    heading: 'Piriformis Syndrome Stretches for Butt Pain Relief',
    content:
      'Piriformis syndrome is a real, well, pain in the butt! Literally! I don’t believe that the most effective way to reduce pain in the glute region is by ‘smashing’ into it. We need to work around the piriformis because the body is holistically connected...',
  },
  {
    image: {
      src: IMAGE_CONSTANT.anklePain,
      altImg: IMAGE_CONSTANT.anklePainLow,
    },
    bulletPoints: ['Ankle Pain', '27 aug 23', '8 Mins read'],
    heading: 'Ankle Dorsiflexion Exercises to Improve Deep Squats',
    content:
      'Deeper squats✨' +
      'A lot of people want to access the ability to do deep squats but not everyone has the mobility to do so. I believe that there are two issues that hold them back:',
  },
  {
    image: { src: IMAGE_CONSTANT.posture, altImg: IMAGE_CONSTANT.postureLow },
    bulletPoints: ['Posture', '27 aug 23', '3 Mins read'],
    heading: 'Work From Home Postures That Keep You Pain Free and Productive',
    content:
      'You’ve probably heard (or felt!) that working on a laptop all day can contribute to poor posture. Maybe you’ve seen diagrams about work from home postures where the keyboard and mouse should be in relation to your shoulders…',
  },
  {
    image: {
      src: IMAGE_CONSTANT.shoulderPain,
      altImg: IMAGE_CONSTANT.shoulderPainLow,
    },
    bulletPoints: ['Shoulder Pain', '27 aug 23', '8 Mins read'],
    heading: 'How to Fix Rounded Shoulders and Forward Head',
    content:
      'Piriformis syndrome is a real, well, pain in the butt! Literally! I don’t believe that the most effective way to reduce pain in the glute region is by ‘smashing’ into it. We need to work around the piriformis because the body is holistically connected...',
  },
  {
    image: { src: IMAGE_CONSTANT.neckPain, altImg: IMAGE_CONSTANT.neckPainLow },
    bulletPoints: ['DeskJob', '27 aug 23', '3 Mins read'],
    heading: '#1 Text Neck Exercise ...Just Grab A Towel',
    content:
      'When we use technology – especially handheld screens – our necks naturally come forward. Then muscles around the neck tense and tighten to protect the head and neck from falling all the way forward and find safety  and stability. The result is..',
  },
];

export const BLOG_RECENT_TEXT_CONSTANT = [
  {
    cotegory: 'back pain',
    read: '255K',
    content: 'Learn How to Reverse Your Biological Age with Chris Mirabile',
  },
  {
    cotegory: 'back pain',
    read: '255K',
    content: 'Learn How to Reverse Your Biological Age with Chris Mirabile',
  },
  {
    cotegory: 'back pain',
    read: '255K',
    content: 'Learn How to Reverse Your Biological Age with Chris Mirabile',
  },
  {
    cotegory: 'back pain',
    read: '255K',
    content: 'Learn How to Reverse Your Biological Age with Chris Mirabile',
  },
  {
    cotegory: 'back pain',
    read: '255K',
    content: 'Learn How to Reverse Your Biological Age with Chris Mirabile',
  },
];

export const BLOG_CATEGORY = [
  {
    image: bodyPart,
    categoryName: 'Body Part',
  },
  {
    image: strengthArm,
    categoryName: 'Strength',
  },
  {
    image: yogaPosition,
    categoryName: 'Yoga',
  },
  {
    image: neckInjury,
    categoryName: 'Physical Therapy',
  },
  {
    image: gutHealth,
    categoryName: 'Gut Health & Nutrition',
  },
  {
    image: neckInjury,
    categoryName: 'Chronic Pain',
  },
  {
    image: mobility,
    categoryName: 'Mobility',
  },
  {
    image: debunking,
    categoryName: 'Debunking',
  },
  {
    image: wrongPosture,
    categoryName: 'Posture',
  },
  {
    image: holisticHealth,
    categoryName: 'Holistic Health',
  },
  {
    image: lungs,
    categoryName: 'Breathwork',
  },
];

export const BLOG_LANDING_SPOTLIGHT = [
  {
    image: backPain,
    header: ['MOBILITY', '3 JULY 23'],
    title: 'Improve Thoracic mobility with simple breathing exercises',
    description:
      'Looking to improve your thoracic mobility and the range of motion...',
  },
  {
    image: elbowPain,
    header: ['ELBOW PAIN', '3 JULY 23'],
    title: 'The 2 best exercises to recover from Golfer’s Elbow',
    description: 'While the name would typically refer to an inflammation...',
  },
  {
    image: legStretch,
    header: ['MOBILITY', '3 JULY 23'],
    title: 'Piriformis syndrome stretches for butt pain relief!',
    description:
      'Piriformis syndrome is a real, well, pain in the butt! Literally!...',
  },
];

export const BLOG_LANDING_RECENT = [
  {
    image: recent1,
    heading: ['EPISODE 311', '3 JULY 23'],
    title: 'Learn How to Reverse Your Biological Age with Chris Mirabile',
    description:
      'We have Chris Mirabile on to share his brain tumour survival journey and what influences his passion for his work around...',
  },
  {
    image: recent2,
    heading: ['EPISODE 311', '3 JULY 23'],
    title: 'How to Improve Mind-Body Muscle Connection',
    description:
      'Feeling certain muscles and not others? DocJen and Dr. Dim dive into mind-body muscle connection and how to coordinate the the awareness arou...',
  },
  {
    image: recent3,
    heading: ['EPISODE 311', '26 JUne 23'],
    title: 'Tailbone (COCCYX) Pain',
    description:
      'DocJen and Dr. Dom dive into the diagnosis for tailbone pain and all the potential causes. Diving into the current research comparing surgi...',
  },
];
