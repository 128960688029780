import {
  DO_CURATED_PLANS_REQUEST,
  DO_CURATED_PLANS_SUCCESS,
  DO_FITNESS_OPTIONS_REQUEST,
  DO_FITNESS_OPTIONS_SUCCESS,
  DO_PLANS_REQUEST,
  DO_PLANS_SUCCESS,
  DO_THERAPY_PLANS_REQUEST,
  DO_THERAPY_PLANS_RESET,
  DO_THERAPY_PLANS_SUCCESS,
  PLANS_IS_LOADING,
  PLANS_SET_IS_LOADING,
  RESET_CURATED_PLAN,
  SET_SHOW_PHASE_MODAL,
} from '../../constant/plan';

export const doPlansRequest = payload => {
  return {
    type: DO_PLANS_REQUEST,
    payload,
  };
};

export const doTherapyPlansRequest = payload => {
  return {
    type: DO_THERAPY_PLANS_REQUEST,
    payload,
  };
};

export const doPlansSuccess = payload => ({
  type: DO_PLANS_SUCCESS,
  payload,
});

export const doTherapyPlansSuccess = payload => ({
  type: DO_THERAPY_PLANS_SUCCESS,
  payload,
});

export const doTherapyPlansReset = payload => ({
  type: DO_THERAPY_PLANS_RESET,
  payload,
});

export const doCuratedPlansRequest = payload => {
  return {
    type: DO_CURATED_PLANS_REQUEST,
    payload,
  };
};

export const resetCuratedPlan = () => ({
  type: RESET_CURATED_PLAN,
});

export const doCuratedPlansSuccess = payload => ({
  type: DO_CURATED_PLANS_SUCCESS,
  payload,
});

export const doFitnesOptionsRequest = payload => {
  return {
    type: DO_FITNESS_OPTIONS_REQUEST,
    payload,
  };
};

export const doFitnessOptionsSuccess = payload => ({
  type: DO_FITNESS_OPTIONS_SUCCESS,
  payload,
});

export const setIsLoading = payload => ({
  type: PLANS_SET_IS_LOADING,
  payload,
});

export const setIsPlanLoading = payload => ({
  type: PLANS_IS_LOADING,
  payload,
});

export const setShowPhaseModal = payload => ({
  type: SET_SHOW_PHASE_MODAL,
  payload,
});
