import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../ui-core/Button/Button';
import { Offer } from '../../../FullBodyMobility/components';

import './style.css';

export const OfferWithButton = ({
  btnText,
  coupon,
  couponFirst,
  loading,
  mode,
  onClick,
  sectionTitle,
  text,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick(btnText, 'isSignup', sectionTitle);
    }
  };
  return (
    <div className="offers-box">
      <Offer
        coupon={coupon}
        couponFirst={couponFirst}
        mode={mode}
        text={text}
      />
      <Button
        class="sign-up-button-new"
        isDisabled={loading}
        isLoading={loading}
        onClick={handleClick}
        text={btnText}
      />
    </div>
  );
};

OfferWithButton.propTypes = {
  btnText: PropTypes.string.isRequired,
  coupon: PropTypes.string.isRequired,
  couponFirst: PropTypes.bool,
  loading: PropTypes.bool,
  mode: PropTypes.string,
  onClick: PropTypes.func,
  sectionTitle: PropTypes.string,
  text: PropTypes.string,
};
