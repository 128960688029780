import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../ui-core/Button/Button';
import ModalBox from '../../ui-core/Modal';
import { TEXT_CONSTANT } from '../../constants/app';
import { doCancelSubcriptionRequest } from '../../store/action/user';
import { DATE_FORMATS, formatDate } from '../../services/Date';

const CancelSubscription = ({ handleClose, show }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { payment } = useSelector(state => state.user);
  const isFreeTrial = payment?.attributes?.free_trial || false;
  const subText = useMemo(() => {
    const endDate = payment?.attributes?.ends_at;
    if (!endDate) return TEXT_CONSTANT.CANCEL_PLAN_CONFIRMATION_SUB_TEXT;
    return `${TEXT_CONSTANT.CANCEL_PLAN_CONFIRMATION_SUB_TEXT} on ${formatDate(
      DATE_FORMATS.DD_MMM_YYYY,
      endDate,
    )}`;
  }, [payment]);

  const handleClick = () => {
    setLoading(true);
    dispatch(
      doCancelSubcriptionRequest({
        callback: () => {
          setLoading(false);
          handleClose && handleClose();
        },
      }),
    );
  };

  return (
    <ModalBox
      show={show}
      handleClose={handleClose}
      dialogClassName={'create-folder-model'}
      head={
        isFreeTrial
          ? TEXT_CONSTANT.CANCEL_FREE_TRAIL
          : TEXT_CONSTANT.CANCEL_MY_SUBSCRIPTION
      }
      closeButton
      footer={
        <Modal.Footer className="cancel-subscription-footer">
          <div className="d-flex justify-content-around flex-wrap w-100">
            <Button
              text={TEXT_CONSTANT.CANCEL_MY_SUBSCRIPTION_ACCEPT}
              isLoading={loading}
              isDisabled={loading}
              class="cancel-subscription-btn"
              onClick={handleClick}
            />
            <Button
              text={TEXT_CONSTANT.CANCEL_MY_SUBSCRIPTION_DECLINE}
              class="cancel-subscription-btn fw-bold"
              onClick={handleClose}
            />
          </div>
        </Modal.Footer>
      }
    >
      <div className="delete-folder">
        <div className="text-center delete-para">
          <span className="d-block">
            {TEXT_CONSTANT.CANCEL_PLAN_CONFIRMATION_TEXT}
          </span>
          <span className="d-block">{subText}.</span>
        </div>
      </div>
    </ModalBox>
  );
};

CancelSubscription.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CancelSubscription;
