import {
  CHECKOUT_SET_IS_LOADING,
  DO_ADD_TO_CART_REQUEST,
  DO_ADD_TO_CART_SUCCESS,
  DO_CREATE_ORDER_REQUEST,
  DO_CREATE_ORDER_SUCCESS,
  DO_FETCH_CART_REQUEST,
  DO_FETCH_CART_SUCCESS,
  DO_REMOVE_FROM_CART_REQUEST,
  DO_REMOVE_FROM_CART_SUCCESS,
  DO_TOGGLE_CART_MODAL,
  SET_CART_IS_LOADING,
} from '../../constant/checkout';

export const setIsLoading = payload => {
  return {
    type: CHECKOUT_SET_IS_LOADING,
    payload,
  };
};

export const setCartIsLoading = payload => {
  return {
    type: SET_CART_IS_LOADING,
    payload,
  };
};

export const doAddToCartRequest = payload => {
  return {
    type: DO_ADD_TO_CART_REQUEST,
    payload,
  };
};

export const doAddToCartSuccess = payload => {
  return {
    type: DO_ADD_TO_CART_SUCCESS,
    payload,
  };
};

export const doRemoveFromCartRequest = payload => ({
  type: DO_REMOVE_FROM_CART_REQUEST,
  payload,
});

export const doRemoveFromCartSuccess = payload => ({
  type: DO_REMOVE_FROM_CART_SUCCESS,
  payload,
});

export const doFetchCartRequest = payload => {
  return {
    type: DO_FETCH_CART_REQUEST,
    payload,
  };
};

export const doFetchCartSuccess = payload => {
  return {
    type: DO_FETCH_CART_SUCCESS,
    payload,
  };
};

export const doCreateOrderRequest = () => {
  return {
    type: DO_CREATE_ORDER_REQUEST,
  };
};

export const doCreateOrderSuccess = () => {
  return {
    type: DO_CREATE_ORDER_SUCCESS,
  };
};

export const doToggleCartModal = payload => {
  return {
    type: DO_TOGGLE_CART_MODAL,
    payload,
  };
};
