import {
  DO_FETCH_BLOG_REQUEST,
  DO_FETCH_BLOG_REQUEST_SUCCESS,
  DO_FETCH_BLOG_SPOTLIGHT_REQUEST_SUCCESS,
  DO_FETCH_BLOG_CATEGORY_REQUEST,
  DO_FETCH_BLOG_CATEGORY_REQUEST_SUCCESS,
  BLOG_SET_IS_LOADING,
  DO_FETCH_DETAIL_BLOG_REQUEST,
  DO_FETCH_DETAIL_BLOG_REQUEST_SUCCESS,
  DO_FETCH_BLOG_BY_CATEGORY_REQUEST,
  DO_FETCH_BLOG_BY_CATEGORY_REQUEST_SUCCESS,
  SEARCH_STRING_REQUEST,
  SEARCH_STRING_REQUEST_SUCCESS,
  BLOG_BY_CATEGORY_IS_LOADING,
  DO_FETCH_BLOG_META_REQUEST_SUCCESS,
  BLOG_DETAIL_SET_IS_LOADING,
} from '../../constant/blog';

export const setIsLoading = payload => {
  return {
    type: BLOG_SET_IS_LOADING,
    payload,
  };
};

export const setBlogDetailIsLoading = payload => {
  return {
    type: BLOG_DETAIL_SET_IS_LOADING,
    payload,
  };
};

export const setBlogByCategoryIsLoading = payload => {
  return {
    type: BLOG_BY_CATEGORY_IS_LOADING,
    payload,
  };
};

export const doFetchAllBlogRequest = payload => {
  return {
    type: DO_FETCH_BLOG_REQUEST,
    payload,
  };
};

export const doFetchAllBlogSuccess = payload => {
  return {
    type: DO_FETCH_BLOG_REQUEST_SUCCESS,
    payload,
  };
};

export const doFetchAllBlogMetaSuccess = payload => {
  return {
    type: DO_FETCH_BLOG_META_REQUEST_SUCCESS,
    payload,
  };
};

export const doFetchAllSpotlightBlogSuccess = payload => {
  return {
    type: DO_FETCH_BLOG_SPOTLIGHT_REQUEST_SUCCESS,
    payload,
  };
};

export const doFetchAllBlogCategoryRequest = payload => {
  return {
    type: DO_FETCH_BLOG_CATEGORY_REQUEST,
    payload,
  };
};

export const doFetchAllBlogcategorySuccess = payload => {
  return {
    type: DO_FETCH_BLOG_CATEGORY_REQUEST_SUCCESS,
    payload,
  };
};

export const doFetchDetailBlogRequest = payload => {
  return {
    type: DO_FETCH_DETAIL_BLOG_REQUEST,
    payload,
  };
};

export const doFetchDetailBlogSuccess = payload => {
  return {
    type: DO_FETCH_DETAIL_BLOG_REQUEST_SUCCESS,
    payload,
  };
};

export const doFetchBlogsByCategoryRequest = payload => {
  // console.log({ payload });
  return {
    type: DO_FETCH_BLOG_BY_CATEGORY_REQUEST,
    payload,
  };
};

export const doFetchBlogsByCategorySuccess = payload => {
  // console.log({ payload });
  return {
    type: DO_FETCH_BLOG_BY_CATEGORY_REQUEST_SUCCESS,
    payload,
  };
};

export const doSearchStringRequest = payload => {
  console.log({ payload });
  return {
    type: SEARCH_STRING_REQUEST,
    payload,
  };
};

export const doSearchStringRequestSuccess = payload => {
  return {
    type: SEARCH_STRING_REQUEST_SUCCESS,
    payload,
  };
};
