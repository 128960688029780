import axios from 'axios';
// import { setupCache } from 'axios-cache-adapter';
// import localforage from 'localforage';
import {
  LOCAL_STORAGE_CONSTANT,
  PUBLIC_PRIVATE_COMBINED,
} from '../constants/app';
import { getItem, setItem } from '../utils/cache';
import { clearDataAndRedirectToLogin } from '../utils/helpers';

// Create a localforage instance for caching
// const forageStore = localforage.createInstance({
//   driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
//   name: 'axios-cache',
// });

/**
 * Configure core axios instance with cache
 * */
export const baseURL =
  `${process.env.REACT_APP_API_BASE_URL}` || 'https://api.jen.health';

// const cache = setupCache({
//   maxAge: 1 * 60 * 1000, // Cache duration in milliseconds (e.g., 1 minutes)
//   exclude: { query: false }, // Cache requests with query parameters
//   store: forageStore, // Use localforage store
//   // debug: true, // Enable debug logging
// });

export function createAxiosInstace() {
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/vnd.docjenfit.com; version=1',
      Source: 'web',
    },
    // adapter: cache.adapter, // Use cache adapter
  });
}

export const axiosInstance = createAxiosInstace();

const errorHandler = async err => {
  const originalConfig = err.config;
  if (originalConfig.url !== '/auth/login' && err.response) {
    // Access Token was expired
    if (err.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        const instance = createAxiosInstace();
        const response = await instance.post('/auth/refresh');
        const user = response?.data?.response?.data;
        const session = response?.headers;
        setItem(LOCAL_STORAGE_CONSTANT.USER, JSON.stringify(user));
        setItem(LOCAL_STORAGE_CONSTANT.SESSION, JSON.stringify(session));
        overrideHeader();
        originalConfig.headers.uid = session.uid;
        originalConfig.headers.client = session.client;
        originalConfig.headers['access-token'] = session['access-token'];
        return axiosInstance(originalConfig);
      } catch (_error) {
        if (!PUBLIC_PRIVATE_COMBINED.includes(window.location.pathname)) {
          clearDataAndRedirectToLogin();
          return Promise.reject(_error);
        }
      }
    } else if (
      err.response.status === 401 &&
      err?.response?.config?.url !== '/auth/login' &&
      !PUBLIC_PRIVATE_COMBINED.includes(window.location.pathname)
    ) {
      clearDataAndRedirectToLogin();
      return Promise.reject(err);
    }
  }
  return Promise.reject(err);
};

axiosInstance.interceptors.response.use(res => res, errorHandler);

export const overrideHeader = (type = 'session') => {
  const session = JSON.parse(getItem(type));
  if (session) {
    axiosInstance.defaults.headers.common.uid = session.uid;
    axiosInstance.defaults.headers.common.client = session.client;
    axiosInstance.defaults.headers.common['access-token'] =
      session['access-token'];
  }
};
