export const SET_LOADING_STATE = 'AUTH_SET_LOADING_STATE';

export const SET_SELECTED_FITLERS = 'SET_SELECTED_FITLERS';

export const GET_PLAYBACK_HISTORY = 'GET_PLAYBACK_HISTORY';

export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';

export const RESET_STORE = 'RESET_STORE';

export const TOGGLE_TRAIL_MODAL = 'TOGGLE_TRAIL_MODAL';

export const SET_ARE_VALUES_CHANGED = 'SET_ARE_VALUES_CHANGED';

export const CHECK_FOR_VALUES_CHANGE = 'CHECK_FOR_VALUES_CHANGE';

export const FETCH_ONBOARDING_QUESTIONS_REQUEST =
  'FETCH_ONBOARDING_QUESTIONS_REQUEST';

export const FETCH_ONBOARDING_QUESTIONS_SUCCESS =
  'FETCH_ONBOARDING_QUESTIONS_SUCCESS';

export const FETCH_ONBOARDING_QUESTIONS_FAILURE =
  'FETCH_ONBOARDING_QUESTIONS_FAILURE';

export const TOGGLE_EMAIL_MODEL = 'TOGGLE_EMAIL_MODEL';

export const SET_PAYMENT_IS_IN_PROGRESS = 'SET_PAYMENT_IS_IN_PROGRESS';
