import { isNotNullOrUndefined } from '../utils/helpers';
import { axiosInstance, createAxiosInstace, overrideHeader } from './index';

/**
 * API request
 * */

export const plansGetRequest = async params => {
  await overrideHeader();
  let endPoint = '/plans';
  if (params) endPoint += params;
  return axiosInstance.get(endPoint);
};

export const publicPlansGetRequest = async params => {
  const axiosInstance = createAxiosInstace();
  let endPoint = '/plans/public';
  if (params) endPoint += params;
  return axiosInstance.get(endPoint);
};

export const getPlanDetail = (id, options) => {
  overrideHeader();
  const queryParams = new URLSearchParams();
  Object.entries(options).forEach(([key, val]) => {
    if (isNotNullOrUndefined(val)) queryParams.append(key, val);
  });
  return axiosInstance.get(`/plans/${id}?${queryParams.toString()}`);
};

export const getHotList = () => {
  overrideHeader();
  return axiosInstance.get(`/products/user`);
};
