import { axiosInstance, overrideHeader } from './index';

/**
 * API request
 * */

export const videoGetRequest = async (id, plan_id) => {
  overrideHeader();
  let endPoint = `/videos/${id}`;
  if (plan_id) {
    endPoint += `?plan_id=${plan_id}`;
  }
  return axiosInstance.get(endPoint);
};

export const searchVideosRequest = async (value, pageNumber) => {
  overrideHeader();
  let endPoint = '/videos/search?search=' + value;
  if (pageNumber) {
    endPoint += '&page=' + pageNumber;
  }
  return axiosInstance.get(endPoint);
};
