import {
  CHALLENGES_AND_COURSES_SET_IS_LOADING,
  DO_GET_ALL_CHALLENGES_COURSES_REQUEST,
  DO_GET_ALL_COURSES_REQUEST,
  DO_GET_ALL_COURSES_SUCCESS,
  DO_GET_CHALLENGES_LIST_REQUEST,
  DO_GET_CHALLENGES_LIST_SUCCESS,
  DO_GET_CHALLENGE_DETAILS_FAILURE,
  DO_GET_CHALLENGE_DETAILS_REQUEST,
  DO_GET_CHALLENGE_DETAILS_SUCCESS,
  DO_GET_COURSE_DETAILS_FAILURE,
  DO_GET_COURSE_DETAILS_REQUEST,
  DO_GET_COURSE_DETAILS_SUCCESS,
  DO_GET_PUBLIC_PLANS_FAILURE,
  DO_GET_PUBLIC_PLANS_REQUEST,
  DO_GET_PUBLIC_PLANS_SUCCESS,
  RESET_CHALLENGE_DETAIL,
  RESET_COURSE_DETAIL,
  DO_FETCH_PUBLIC_COURSES_REQUEST,
  DO_FETCH_PUBLIC_COURSES_SUCCESS,
} from '../../constant/challengesAndCourses';

export const setIsLoading = payload => ({
  type: CHALLENGES_AND_COURSES_SET_IS_LOADING,
  payload,
});

export const doGetChallengesListRequest = payload => {
  return {
    type: DO_GET_CHALLENGES_LIST_REQUEST,
    payload,
  };
};

export const doGetChallengesListSuccess = payload => ({
  type: DO_GET_CHALLENGES_LIST_SUCCESS,
  payload,
});

export const doGetChallengeDetailRequest = payload => {
  return {
    type: DO_GET_CHALLENGE_DETAILS_REQUEST,
    payload,
  };
};

export const doGetChallengeDetailSuccess = payload => ({
  type: DO_GET_CHALLENGE_DETAILS_SUCCESS,
  payload,
});

export const doGetChallengeDetailFailure = payload => ({
  type: DO_GET_CHALLENGE_DETAILS_FAILURE,
  payload,
});

export const resetChallengeDetail = () => ({
  type: RESET_CHALLENGE_DETAIL,
});

export const doGetAllCoursesRequest = payload => ({
  type: DO_GET_ALL_COURSES_REQUEST,
  payload,
});

export const doGetAllCoursesSuccess = payload => ({
  type: DO_GET_ALL_COURSES_SUCCESS,
  payload,
});

export const doGetCourseDetailRequest = payload => {
  return {
    type: DO_GET_COURSE_DETAILS_REQUEST,
    payload,
  };
};

export const doGetCourseDetailSuccess = payload => ({
  type: DO_GET_COURSE_DETAILS_SUCCESS,
  payload,
});

export const doGetCourseDetailFailure = payload => ({
  type: DO_GET_COURSE_DETAILS_FAILURE,
  payload,
});

export const resetCourseDetail = () => ({
  type: RESET_COURSE_DETAIL,
});

export const doGetAllChallengesAndCoursesRequest = payload => ({
  type: DO_GET_ALL_CHALLENGES_COURSES_REQUEST,
  payload,
});

export const doGetPublicPlansRequest = payload => ({
  type: DO_GET_PUBLIC_PLANS_REQUEST,
  payload,
});

export const doGetPublicPlansSuccess = payload => ({
  type: DO_GET_PUBLIC_PLANS_SUCCESS,
  payload,
});

export const doGetPublicPlansFailure = payload => ({
  type: DO_GET_PUBLIC_PLANS_FAILURE,
  payload,
});

export const doGetPublicCoursesRequest = payload => {
  return {
    type: DO_FETCH_PUBLIC_COURSES_REQUEST,
    payload,
  };
};

export const doGetPublicCoursesSuccess = payload => {
  return {
    type: DO_FETCH_PUBLIC_COURSES_SUCCESS,
    payload,
  };
};
