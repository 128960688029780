import React from 'react';
import { Row, Col } from 'react-bootstrap';
import clsx from 'clsx';
import Carousel from 'react-multi-carousel';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { getDisplayType } from '../../../utils/helpers';
import DoctorBox from '../../../components/DoctorBox';
import {
  CAROUSEL_BREAKPOINT,
  IMAGE_CONSTANT,
  TEXT_CONSTANT,
} from '../../../constants/app';

const Testimonial = () => {
  const displayType = getDisplayType(useWindowWidth());
  const isMobileScreen = displayType === 'mobile';
  const isIpadScreen = displayType === 'ipad';
  const testimonials = [
    {
      testimonial: TEXT_CONSTANT.TESTIMONIAL_1,
      profileUrl: IMAGE_CONSTANT.Doctor1,
      profileWebP: IMAGE_CONSTANT.Doctor1WebP,
      name: 'Dr. Jolene Brighten',
      qualifications: 'NMD, FABNE',
    },
    {
      testimonial: TEXT_CONSTANT.TESTIMONIAL_2,
      profileUrl: IMAGE_CONSTANT.Doctor2,
      profileWebP: IMAGE_CONSTANT.Doctor2WebP,
      name: 'Dr. Shante Cofield',
      qualifications: 'PT, DPT, OCS, CSCS, CF-L1',
    },
    {
      testimonial: TEXT_CONSTANT.TESTIMONIAL_3,
      profileUrl: IMAGE_CONSTANT.Doctor3,
      profileWebP: IMAGE_CONSTANT.Doctor3WebP,
      name: 'Dr. Breanna Welsh',
      qualifications: 'PT, DPT',
    },
    {
      testimonial: TEXT_CONSTANT.TESTIMONIAL_4,
      profileUrl: IMAGE_CONSTANT.Doctor4,
      profileWebP: IMAGE_CONSTANT.Doctor4WebP,
      name: 'Dr. Ayonna Procter',
      qualifications: 'PT, DPT',
    },
    {
      testimonial: TEXT_CONSTANT.TESTIMONIAL_5,
      profileUrl: IMAGE_CONSTANT.Doctor5,
      profileWebP: IMAGE_CONSTANT.Doctor5WebP,
      name: 'Dr Jessica Greene',
      qualifications: 'ND, DC',
    },
  ];

  const renderTestimonials = () => {
    const testimonialsOutput = testimonials?.map((item, index) => (
      <DoctorBox
        key={index}
        text={item.testimonial}
        profile={item.profileUrl}
        profileWebP={item.profileWebP}
        name={item.name}
        quali={item.qualifications}
      />
    ));
    return testimonialsOutput;
  };

  return (
    <section
      className={clsx(
        !isMobileScreen ? 'home-testimonial' : 'pt-5 home-testimonial',
      )}
    >
      <div className="container">
        <Row>
          <Col>
            <h3 className="text-center h-3 ms-5 me-5 sec-head">
              Recommended by leading doctors
            </h3>
          </Col>
        </Row>
        <Row className={clsx('px-lg-5', isMobileScreen && 'mt-3')}>
          <Col className="position-relative">
            <Carousel
              responsive={CAROUSEL_BREAKPOINT}
              className="doctors-advice d_c"
              showDots={true}
              swipeable={isMobileScreen || isIpadScreen}
              draggable={isMobileScreen || isIpadScreen}
            >
              {renderTestimonials()}
            </Carousel>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Testimonial;
