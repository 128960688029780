import React from 'react';
import { IMAGE_CONSTANT } from '../../../../constants/app';
import LazyImage from '../../../../ui-core/Image';
import './style.css';

const SuccessModal = () => {
  return (
    <div className="success-model-body">
      <div className="px-lg-2 align-items-center">
        <h3 className="text-center"> Thanks for filling the survey! </h3>
      </div>
      <div className="mt-4">
        <LazyImage
          alt="logo"
          className="survey-gif img-fluid "
          errorSrc={IMAGE_CONSTANT.placeholderImage}
          placeholderSrc={IMAGE_CONSTANT.placeholderImage}
          src="https://media.giphy.com/media/kKo2x2QSWMNfW/giphy.gif"
        />
      </div>
    </div>
  );
};

export default SuccessModal;
