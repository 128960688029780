import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactAppleLogin from 'react-apple-signin-auth';
import Button from '../../ui-core/Button/Button';
import { getPageName, getUserAttribute } from '../../utils/helpers';
import {
  IMAGE_CONSTANT,
  LOCAL_STORAGE_CONSTANT,
  TEXT_CONSTANT,
} from '../../constants/app';
import {
  SEGMENT_EVENTS,
  trackEvent,
  trackSubmittedEvent,
} from '../../utils/Segment';
import { APP_ROUTES, getRoute } from '../../route';
import { withRouter } from 'react-router-dom';
import {
  createNotification,
  NOTIFICATION_TYPE,
} from '../../services/Notification';
import { doAppleSignupRequest } from '../../store/action/auth';
import { extractJSON } from '../../services/jsonUtil';
import { useCourseDetail } from '../../hooks/useCourseDetail';
import { doPaymentInitiationRequest } from '../../store/action/products';
import { encodeBase64Value, encodeURLParams } from '../../utils/url';

const AppleLogin = ({
  history,
  isLoading,
  match,
  section,
  handleModalState,
}) => {
  const isLogin = section === 'Login';
  const dispatch = useDispatch();
  const { isLoading: loading } = useSelector(state => state.product);
  const { isLoading: challengeDetailLoading, handleCourseRedirection } =
    useCourseDetail(history, match);
  const isPayment = history.location.state?.payment || false;

  const CustomButton = ({ onClick, match, history }) => {
    const handleClick = () => {
      const isChallegnesPage = match.path === APP_ROUTES.COURSE_SLUG;
      const slug = match.params.slug;
      const page = isChallegnesPage
        ? slug
        : getPageName(history.location.pathname);
      trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
        page: page,
        section_title: section,
        button_title: 'Sign up with Apple',
      });
      if (onClick) onClick();
    };
    return (
      <Button
        text={
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={IMAGE_CONSTANT.appleIcon}
              alt={IMAGE_CONSTANT.appleIcon}
              className="img-fluid mx-2"
            />
            <span>
              {isLogin
                ? TEXT_CONSTANT.LOGIN_WITH_APPLE
                : TEXT_CONSTANT.SIGNUP_WITH_APPLE}
            </span>
          </div>
        }
        class="sign-with-button apple-btn"
        isDisabled={isLoading || loading || challengeDetailLoading}
        isLoading={isLoading || loading || challengeDetailLoading}
        onClick={handleClick}
      ></Button>
    );
  };

  const handleRedirectionForAlreadyOnboardedUser = (playbackHistory, plans) => {
    if (playbackHistory?.attributes?.source?.schedule_id) {
      const planId = playbackHistory?.attributes?.source?.id;
      const viewedPlan = plans.find(item => +item.id === +planId);
      const card_color = viewedPlan?.attributes?.card_color;
      const title = viewedPlan?.attributes?.title;
      const searchParams = {
        card_color,
        title,
      };
      if (viewedPlan) {
        if (viewedPlan?.attributes?.plan_type === 'plan') {
          const options = {
            pathParam: { id: planId },
            searchParam: {
              params: encodeURLParams(searchParams),
            },
          };
          const route = getRoute(APP_ROUTES.HOME_SUB_SECTION, options);
          history.push(route);
        } else {
          const options = {
            pathParam: { id: planId },
            searchParam: {
              params: encodeURLParams(searchParams),
            },
          };
          const route = getRoute(APP_ROUTES.PREMIUM_COURSES_DETAILS, options);
          history.push(route);
        }
      } else {
        history.push(APP_ROUTES.HOME);
      }
    } else {
      history.push(APP_ROUTES.HOME);
    }
  };

  const handleSuccess = response => {
    console.log('response', response);
    dispatch(
      doAppleSignupRequest({
        response,
        callback: response => {
          const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
          if (user?.attributes?.new_user) {
            trackSubmittedEvent(SEGMENT_EVENTS.USER_SIGNUP_SUCCESSFUL, {
              user_id: user?.id,
              has_access: user?.attributes?.has_access,
              phone: user?.attributes?.mobile,
              country_code: user?.attributes?.country_code,
            });
          } else {
            trackSubmittedEvent(SEGMENT_EVENTS.USER_LOGIN_SUCCESSFUL, {
              user_id: user?.id,
              has_access: user?.attributes?.has_access,
              phone: user?.attributes?.mobile,
              country_code: user?.attributes?.country_code,
            });
          }
          const onboardingCompleted = user?.attributes?.onboarding_completed;

          const { playbackHistory, plans, payment } = response || {};
          if (match.path === APP_ROUTES.GIFTS) {
            if (onboardingCompleted) {
              handleModalState(null);
              // dispatch(toggleShowGiftModal(true));
            } else {
              const url = getRoute(APP_ROUTES.FITNESS, {
                searchParam: {
                  page: encodeBase64Value(APP_ROUTES.GIFTS),
                },
              });
              history.push(url);
              handleModalState(null);
            }
          } else if (match.path === APP_ROUTES.COURSE_SLUG) {
            handleCourseRedirection();
          } else {
            const subscriptionDetails = getUserAttribute(
              'subscription_details',
            );
            const freeTrial = getUserAttribute('free_trial');
            const isSubscriptionExpired = subscriptionDetails?.ends_at
              ? new Date() > new Date(subscriptionDetails.ends_at)
              : false;
            const noSubscription = !subscriptionDetails?.subscribed;
            const noFreeTrails = !freeTrial;
            const noActiveSubscription = noSubscription ? noFreeTrails : false;
            const isSubscriptionCancelled =
              payment?.attributes?.provider === 'stripe' &&
              payment?.attributes?.original_status === 'cancelled' &&
              (payment?.attributes?.status === 'active' ||
                payment?.attributes?.status === 'cancelled');
            /*
            when user comes into the app through public payment page or start free trial button
            and has no active subscription / free trial or his subscription has expired
            redirect to payment gateway
          */
            if (
              isPayment &&
              (noActiveSubscription ||
                isSubscriptionExpired ||
                isSubscriptionCancelled)
            ) {
              if (!loading) {
                dispatch(
                  doPaymentInitiationRequest({
                    page: encodeBase64Value(APP_ROUTES.FREE_TRAIL),
                  }),
                );
              }
            } else if (onboardingCompleted) {
              handleRedirectionForAlreadyOnboardedUser(playbackHistory, plans);
            } else {
              history.push(APP_ROUTES.FITNESS, {
                payment: true,
              });
            }
          }
        },
      }),
    );
  };
  return (
    <ReactAppleLogin
      authOptions={{
        clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
        redirectURI:
          process.env.REACT_APP_ENV === 'production'
            ? 'https://jen.health/login/callback'
            : 'https://jweb-dev.web.app/login/callback',
        scope: 'email name',
        usePopup: true,
      }}
      uiType="dark"
      className="apple-auth-btn"
      render={({ onClick }) => (
        <CustomButton history={history} match={match} onClick={onClick} />
      )}
      onSuccess={handleSuccess}
      onError={error =>
        createNotification({
          type: NOTIFICATION_TYPE.ERROR,
          message: error,
        })
      }
    />
  );
};

AppleLogin.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  section: PropTypes.string.isRequired,
  handleModalState: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  onClick: PropTypes.func,
  plan_id: PropTypes.string,
};

export default withRouter(AppleLogin);
