import React, { useState } from 'react';
import { phone } from 'phone';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { doSignupRequest } from '../../../../store/action/auth';
import Phone from '../../../../ui-core/Phone';
import Button from '../../../../ui-core/Button/Button';
import Ref from '../../../../ui-core/Ref';
import { MODAL_TYPE } from '../../../../constants/app';
import { SEGMENT_EVENTS, trackEvent } from '../../../../utils/Segment';
// import AppleLogin from '../../../../components/AppleLogin';
import GoogleLogin from '../../../../components/GoogleLogin';
import AppleLogin from '../../../../components/AppleLogin';
import { grecaptcha } from '../../../../services/recaptcha';

import './style.css';
import { APP_ROUTES } from '../../../../route';

const SignUp = props => {
  const [postData, setPostData] = useState({
    mobile: null,
    country_code: null,
  });
  const [isValidNumber, setValidNumber] = useState(false);
  const { isLoading } = useSelector(state => state.common);
  const [error, setError] = useState({ hasError: false, message: '' });
  const [, setBtnVisible] = useState({
    isVisible: false,
  });
  const dispatch = useDispatch();

  const { handleModalState, countryCode } = props;

  const head = <h1 className="sign-in-h1 text-center">Sign up </h1>;

  const handlePhoneOnChange = (phoneNumber, countryCode, countryData) => {
    const validData = phone(
      `${countryCode}${phoneNumber?.replace(/\D+/g, '')}`,
      { country: countryData?.countryCode?.toUpperCase() },
    );
    setValidNumber(validData.isValid);
    setPostData({
      mobile: phoneNumber?.replace(/\D+/g, ''),
      country_code: countryCode,
    });
    if (phoneNumber[0] === '0') {
      setError({
        ...error,
        hasError: true,
        message: '0 cannot be the first digit of your mobile number.',
      });
    } else if (phoneNumber[0] === '(' && phoneNumber[1] === '0') {
      setError({
        ...error,
        hasError: true,
        message: '0 cannot be the first digit of your mobile number.',
      });
    } else if (!validData.isValid) {
      setError({
        ...error,
        hasError: true,
        message: 'Please enter a valid number.',
      });
    } else {
      setError({ ...error, hasError: false, message: '' });
    }
  };

  const handlePhoneOnSubmit = e => {
    e.preventDefault();
    grecaptcha.execute(token => {
      // trackSubmittedEvent('Sign up submit', {
      //   page: 'Home Page',
      //   section_title: 'Signup Modal',
      //   button_title: 'SEND VERIFICATION CODE',
      //   phone: postData?.mobile,
      //   country_code: postData?.country_code,
      // });
      dispatch(
        doSignupRequest({
          data: {
            ...postData,
            type: 'signup',
            token,
          },
          callback: () => handleModalState(MODAL_TYPE.OTP),
          page: props.page,
        }),
      );
      // trackDisplayedEvent('Display OTP Modal', {
      //   page: 'Home Page',
      //   section_title: 'OTP Modal',
      // });
    });
  };

  const handleLoginClick = () => {
    handleModalState(MODAL_TYPE.LOGIN);
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      // eslint-disable-line
      button_title: 'Login Now',
      page: props.page || 'Home Page',
      section_title: `Sign up Modal`,
    });
    // trackDisplayedEvent('Display Login Modal', {
    //   page: 'Home Page',
    //   section_title: 'Login Modal',
    // });
  };

  const handleKeyDown = event => {
    if (event.keyCode === 13 && isValidNumber) handlePhoneOnSubmit(event);
  };

  return (
    <div className="form-h">
      {head}
      <p className="sign-in-h3 text-center mb-4 mt-4 pb-3">
        Signup with your mobile number
      </p>
      <Phone
        countryCode={countryCode}
        handleOnChange={handlePhoneOnChange}
        setBtnVisible={setBtnVisible}
        onKeyDown={handleKeyDown}
      />
      <div className="position-relative">
        <span className="text-danger error-text text-center d-block w-100">
          {error.hasError ? error.message : ''}
        </span>
      </div>
      <Button
        text="SEND VERIFICATION CODE"
        // TODO: we need to add 'spinner-border text-white'
        class={`OtpButton ${isLoading && ''}`}
        isDisabled={isLoading || !isValidNumber}
        isLoading={isLoading}
        onClick={handlePhoneOnSubmit}
      />
      <p className="new-sign-in-para">or</p>
      <div className="d-flex align-items-center justify-content-center">
        {/* <AppleLogin isLoading={isLoading} section={'Signup'} /> */}
        <GoogleLogin
          isLoading={isLoading}
          section={'Signup'}
          plan_id={props.plan_id}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <AppleLogin
          isLoading={isLoading}
          section={'Signup'}
          plan_id={props.plan_id}
          handleModalState={handleModalState}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center signin-bottom-row mt-4">
        <h3 className="head3 m-0"> Already a member ? </h3>
        <Ref
          class="signup-link fz20 ms-2"
          onClick={() => handleLoginClick()}
          text="Login Now"
        />
      </div>
      <div className="sign-in-agreement d-flex align-items-center flex-wrap justify-content-center">
        <h4>By continuing, you agree to Jen&apos; s</h4>
        <div className="d-flex align-items-center mb_d">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.google.com/document/d/1d2nS643SoO95afqaKZtL7EHxClNoACPsiFeqevS7PGQ/edit"
            className="d-inline-block mx-1"
          >
            Terms of service
          </a>
          <h3>and </h3>
          <a
            className="d-inline-block mx-1"
            target="_blank"
            rel="noopener noreferrer"
            href={window.location.origin + APP_ROUTES.PRIVACY_POLICY}
          >
            Privacy policy
          </a>
        </div>
      </div>
    </div>
  );
};

SignUp.propTypes = {
  countryCode: PropTypes.string,
  handleModalState: PropTypes.func,
  page: PropTypes.string,
  plan_id: PropTypes.string,
};

export default SignUp;
