import { RESET_STORE } from '../../constant/common';
import {
  DO_GET_QUESTIONNAIRES_FAILURE,
  DO_GET_QUESTIONNAIRES_SUCCESS,
  QUESTIONNAIRES_SET_IS_LOADING,
  RESET_QUESTIONNAIRE_STATE,
  SET_ACTIVE_QUESTION,
  SET_ACTIVITY_INPUT_COUNTS,
  SET_FILLED_ANSWERS,
  SET_QUESTIONNAIRE_IS_ALREADY_ANSWERED,
  SET_SHOW_QUESTIONNAIRE_OVERLAY,
  SET_SHOW_SUB_QUESTION_MODAL,
  TOGGLE_POSTING_RESPOSNE,
} from '../../constant/questionnaires';

const initialState = {
  activeQuestion: 0,
  filledAnswers: {},
  alreadyAnsweredQuestions: {},
  isLoading: true,
  postingResponse: false,
  questionnaires: null,
  showQuestionnaireOverlay: false,
  showSubQuestionModal: false,
  activityInputs: {},
};

export const questionnairesReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case QUESTIONNAIRES_SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case DO_GET_QUESTIONNAIRES_FAILURE:
    case DO_GET_QUESTIONNAIRES_SUCCESS:
      return { ...state, isLoading: false, questionnaires: payload };
    case TOGGLE_POSTING_RESPOSNE:
      return { ...state, postingResponse: payload };
    case SET_SHOW_QUESTIONNAIRE_OVERLAY:
      return { ...state, showQuestionnaireOverlay: payload };
    case SET_ACTIVE_QUESTION:
      return { ...state, activeQuestion: payload };
    case SET_FILLED_ANSWERS:
      return { ...state, filledAnswers: payload };
    case SET_SHOW_SUB_QUESTION_MODAL:
      return { ...state, showSubQuestionModal: payload };
    case SET_ACTIVITY_INPUT_COUNTS:
      return { ...state, activityInputs: payload };
    case SET_QUESTIONNAIRE_IS_ALREADY_ANSWERED:
      return { ...state, alreadyAnsweredQuestions: payload };
    case RESET_QUESTIONNAIRE_STATE:
    case RESET_STORE:
      return { ...initialState };
    default:
      return state;
  }
};
