import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../ui-core/Button/Button';
import { IMAGE_CONSTANT, TEXT_CONSTANT } from '../../constants/app';
import { baseURL } from '../../api';
import { getPageName, openLink } from '../../utils/helpers';
import { SEGMENT_EVENTS, trackEvent } from '../../utils/Segment';
import { APP_ROUTES } from '../../route';
import LazyImage from '../../ui-core/Image';
import { withRouter } from 'react-router-dom';

const GoogleLogin = ({ history, isLoading, match, section, plan_id }) => {
  const isLogin = section === 'Login';
  const handleRedirection = () => {
    const isChallegnesPage = match.path === APP_ROUTES.COURSE_SLUG;
    const slug = match.params.slug;
    const page = isChallegnesPage
      ? slug
      : getPageName(history.location.pathname);
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      page: page,
      section_title: section,
      button_title: 'Sign up with Gmail',
    });
    openLink(
      baseURL +
        '/auth/google_oauth2?page=' +
        encodeURI(page) +
        (plan_id ? `&plan_id=${plan_id}` : ''),
      true,
    );
  };
  return (
    <Button
      text={
        <div className="d-flex justify-content-between align-items-center">
          <LazyImage
            alt={IMAGE_CONSTANT.googleIcon}
            className="img-fluid mx-2"
            errorSrc={IMAGE_CONSTANT.placeholderImage}
            placeholderSrc={IMAGE_CONSTANT.placeholderImage}
            src={IMAGE_CONSTANT.googleIcon}
          />
          <span>
            {isLogin
              ? TEXT_CONSTANT.LOGIN_WITH_GOOGLE
              : TEXT_CONSTANT.SIGNUP_WITH_GOOGLE}
          </span>
        </div>
      }
      class="sign-with-button google-btn"
      isDisabled={isLoading}
      isLoading={isLoading}
      onClick={handleRedirection}
    ></Button>
  );
};

GoogleLogin.propTypes = {
  history: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.object,
  section: PropTypes.string.isRequired,
  plan_id: PropTypes.string,
};

export default withRouter(GoogleLogin);
