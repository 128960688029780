import {
  DO_VIDEO_FAILURE,
  DO_VIDEO_REQUEST,
  DO_VIDEO_SUCCESS,
  RESET_VIDEO_STATE,
} from '../../constant/video';
import { RESET_STORE } from '../../constant/common';

const initialState = {
  isLoading: true,
  video: {},
};

export const videoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DO_VIDEO_REQUEST:
      return { ...state, isLoading: true };
    case DO_VIDEO_SUCCESS:
      return { ...state, video: payload, isLoading: false };
    case DO_VIDEO_FAILURE:
      return { ...state, isLoading: false };
    case RESET_VIDEO_STATE:
    case RESET_STORE:
      return { ...initialState };
    default:
      return state;
  }
};
