import {
  DO_FETCH_DETAIL_PODCAST_REQUEST_SUCCESS,
  DO_FETCH_PODCAST_BY_CATEGORY_REQUEST_SUCCESS,
  DO_FETCH_PODCAST_CATEGORIES_META_REQUEST_SUCCESS,
  DO_FETCH_PODCAST_CATEGORY_REQUEST_SUCCESS,
  DO_FETCH_PODCAST_META_REQUEST_SUCCESS,
  DO_FETCH_PODCAST_REQUEST_SUCCESS,
  DO_FETCH_PODCAST_SPOTLIGHT_REQUEST_SUCCESS,
  PODCAST_BY_CATEGORY_IS_LOADING,
  PODCAST_DETAIL_SET_IS_LOADING,
  PODCAST_SET_IS_LOADING,
} from '../../constant/podcast';

const initialState = {
  podcasts: null,
  podcastsListMeta: null,
  podcast: null,
  spotLight: null,
  podcastsCategory: null,
  isLoading: true,
  detailPodcast: null,
  podcastsByCategory: [],
  podcastsByCategoryIsLoading: false,
  meta: null,
  metaCategories: null,
};

export const podcastReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PODCAST_SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case PODCAST_DETAIL_SET_IS_LOADING:
      return {
        ...state,
        isPodcastDetailLoading: payload,
      };

    case DO_FETCH_PODCAST_REQUEST_SUCCESS:
      return {
        ...state,
        podcasts: payload,
      };

    case DO_FETCH_PODCAST_META_REQUEST_SUCCESS:
      return {
        ...state,
        meta: payload,
      };

    case DO_FETCH_PODCAST_CATEGORIES_META_REQUEST_SUCCESS:
      return {
        ...state,
        metaCategories: payload,
      };

    case DO_FETCH_PODCAST_CATEGORY_REQUEST_SUCCESS:
      return {
        ...state,
        podcastsCategory: payload,
      };

    case DO_FETCH_PODCAST_SPOTLIGHT_REQUEST_SUCCESS:
      return {
        ...state,
        spotLight: payload,
      };

    case DO_FETCH_DETAIL_PODCAST_REQUEST_SUCCESS:
      return {
        ...state,
        detailPodcast: payload,
      };

    case DO_FETCH_PODCAST_BY_CATEGORY_REQUEST_SUCCESS:
      return {
        ...state,
        podcastsByCategory: payload,
      };
    case PODCAST_BY_CATEGORY_IS_LOADING:
      return {
        ...state,
        podcastsByCategoryIsLoading: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
