import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
// import Loader from '../../ui-core/Loader';
import './style.css';
import { setPaymentIsInProgress } from '../../store/action/common';

const PaymentProgressIndicator = () => {
  const { isPaymentInProgress } = useSelector(state => state.common);
  const dispatch = useDispatch();
  const loaderRef = useRef();

  // if (!isPaymentInProgress) return null;
  useEffect(() => {
    dispatch(setPaymentIsInProgress(false));
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      if (loaderRef.current) {
        loaderRef.current.classList.add('d-none');
      }
    }, 4000);
  });

  return (
    <div
      ref={loaderRef}
      className={clsx('payment-progress', !isPaymentInProgress && 'd-none')}
    >
      <div className="payment-progress-quote">
        <div className="block">
          <h2>
            Good things take time, please don’t hit the back button or refresh
            this screen…..
          </h2>
          <div className="loader">
            <div></div>
          </div>
          <h1>Jen</h1>
        </div>
      </div>
    </div>
  );
};

export default PaymentProgressIndicator;
