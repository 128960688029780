import {
  DO_MY_PLANS_REQUEST,
  DO_MY_PLANS_SUCCESS,
  DO_GET_RECOMMENDATION_REQUEST,
  DO_GET_RECOMMENDATION_SUCCESS,
  USER_SET_IS_LOADING,
  DO_GET_USER_DATA_REQUEST,
  DO_GET_USER_DATA_SUCCESS,
  DO_UPDATE_USER_PROFILE_REQUEST,
  DO_HANDLE_CREATE_EXCERCISE_PLAN_REQUEST,
  DO_GET_PAYMENT_DETAIL_REQUEST,
  SET_PAYMENT_DETAIL,
  DO_CANCEL_SUBSCRIPTION_REQUEST,
  DO_PAYMENT_RESTART_REQUEST,
  DO_CHANGE_SUBSCRIPTION_PRODUCT,
  DO_REMOVE_RECOMMENDATION_REQUEST,
  DO_REMOVE_RECOMMENDATION_SUCCESS,
  DO_FETCH_ALL_CATEGORIES_REQUEST,
  DO_FETCH_ALL_CATEGORIES_SUCCESS,
  DO_FETCH_BANNER_REQUEST,
  DO_FETCH_BANNER_SUCCESS,
  BANNER_SET_IS_LOADING,
} from '../../constant/user';

export const doMyPlansRequest = payload => {
  return {
    type: DO_MY_PLANS_REQUEST,
    payload,
  };
};

export const doMyPlansSuccess = payload => ({
  type: DO_MY_PLANS_SUCCESS,
  payload,
});

export const doGetRecommendationsRequest = payload => {
  return {
    type: DO_GET_RECOMMENDATION_REQUEST,
    payload,
  };
};

export const doGetRecommendationsSuccess = payload => ({
  type: DO_GET_RECOMMENDATION_SUCCESS,
  payload,
});

export const doGetUserDataRequest = payload => ({
  type: DO_GET_USER_DATA_REQUEST,
  payload,
});

export const doGetUserDataSuccess = payload => ({
  type: DO_GET_USER_DATA_SUCCESS,
  payload,
});

export const doUpdateUserProfieRequest = payload => ({
  type: DO_UPDATE_USER_PROFILE_REQUEST,
  payload,
});

export const setIsLoading = payload => ({
  type: USER_SET_IS_LOADING,
  payload,
});

export const doHandleCreateExercisePlan = payload => ({
  type: DO_HANDLE_CREATE_EXCERCISE_PLAN_REQUEST,
  payload,
});

export const doGetPaymentDetailRequest = payload => ({
  type: DO_GET_PAYMENT_DETAIL_REQUEST,
  payload,
});

export const setPaymentDetail = payload => ({
  type: SET_PAYMENT_DETAIL,
  payload,
});

export const doCancelSubcriptionRequest = payload => ({
  type: DO_CANCEL_SUBSCRIPTION_REQUEST,
  payload,
});

export const doPaymentRestartRequest = () => ({
  type: DO_PAYMENT_RESTART_REQUEST,
});

export const doChangeSubscriptionProduct = payload => ({
  type: DO_CHANGE_SUBSCRIPTION_PRODUCT,
  payload,
});

export const doRemoveRecommendationsRequest = payload => {
  return {
    type: DO_REMOVE_RECOMMENDATION_REQUEST,
    payload,
  };
};

export const doRemoveRecommendationsSuccess = payload => ({
  type: DO_REMOVE_RECOMMENDATION_SUCCESS,
  payload,
});

export const doFetchAllCategoriesRequest = payload => {
  return {
    type: DO_FETCH_ALL_CATEGORIES_REQUEST,
    payload,
  };
};

export const doFetchCategoriesSuccess = payload => ({
  type: DO_FETCH_ALL_CATEGORIES_SUCCESS,
  payload,
});

export const doFetchBannerRequest = payload => {
  return {
    type: DO_FETCH_BANNER_REQUEST,
    payload,
  };
};

export const doFetchBannerSuccess = payload => {
  return {
    type: DO_FETCH_BANNER_SUCCESS,
    payload,
  };
};

export const setIsBannerLoading = payload => ({
  type: BANNER_SET_IS_LOADING,
  payload,
});
