import { axiosInstance, overrideHeader } from './index';

/**
 * API request
 * */

export const foldersGetRequest = async pageNumber => {
  await overrideHeader();
  let endPoint = '/folders';
  if (pageNumber) {
    endPoint += '?page=' + pageNumber;
  }
  return axiosInstance.get(endPoint);
};

export const createfolderRequest = async name => {
  await overrideHeader();
  return axiosInstance.post('/folders', { name });
};

export const addVideoToFolderRequest = async (folderIds, videoId) => {
  await overrideHeader();
  const body = {
    video_id: videoId,
    folder_ids: folderIds,
  };
  return axiosInstance.post(`/folders/add`, body);
};

export const getFolderDetailsRequest = async folderId => {
  await overrideHeader();
  return axiosInstance.get(`/folders/${folderId}`);
};

export const deleteFolderRequest = async folderId => {
  await overrideHeader();
  return axiosInstance.delete(`/folders/${folderId}`);
};

export const renameFolderRequest = async (folderId, name) => {
  await overrideHeader();
  return axiosInstance.put(`/folders/${folderId}`, { name });
};

export const removedVideoFromFoldersRequest = async (folderIds, videoId) => {
  await overrideHeader();
  const body = {
    data: {
      video_id: videoId,
      folder_ids: folderIds,
    },
  };
  return axiosInstance.delete('/folders/remove', body);
};

export const allFoldersGetRequest = async () => {
  await overrideHeader();
  return axiosInstance.get('/folders/all');
};
