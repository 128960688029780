import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const Loader = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }
  return (
    <div className={'Loader-Wrap'}>
      <div className={'Loader'}></div>
    </div>
  );
};

Loader.propTypes = {
  isLoading: PropTypes.bool,
};

export default Loader;
