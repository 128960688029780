import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import Button from '../../../../ui-core/Button/Button';
import { ShouldRender } from '../../../../ui-core/ShouldRender';

import {
  defaultWinningPrizes,
  IMAGE_CONSTANT,
  TEXT_CONSTANT,
} from '../../../../constants/app';

import './style.css';

export const WinPrizes = ({
  btnText,
  headerImage,
  loading,
  onClick,
  sections,
  title,
}) => {
  const renderFieldSet = (title, data) => {
    return (
      <fieldset className="mx-4 p-3 d-flex flex-column justify-content-center mb-4 p-block">
        <legend>{title}</legend>
        <div className="justify-content-center w-100 d-flex flex-wrap">
          {data.map(item => (
            <div
              key={item.title}
              className="d-flex flex-column align-items-center card-block"
            >
              <div className="prize-image-block">
                {item.image ? (
                  <picture>
                    <source type="image/webp" srcSet={item.imageWebP} />
                    <img src={item.image} className="h-100" alt={item.title} />
                  </picture>
                ) : (
                  <div className="empty-block"></div>
                )}
              </div>
              <div>
                <h5>{parse(item.title)}</h5>
                <span>{parse(item.priceDisc)}</span>
              </div>
            </div>
          ))}
        </div>
      </fieldset>
    );
  };

  const handleClick = () => {
    if (onClick) {
      onClick(btnText, 'isSignup', title);
    }
  };

  return (
    <div className="win-prizes">
      <div className="ribbon">
        <img src={headerImage} alt="ribbon" />
      </div>
      <p className="enroll-text">{title}</p>
      <div className="d-flex my-3 prizes-block">
        {sections.map(section => (
          <React.Fragment key={section.title}>
            {renderFieldSet(section.title, section.items)}
          </React.Fragment>
        ))}
      </div>
      <ShouldRender check={!!btnText}>
        <div>
          <Button
            class="sign-up-button-new"
            isDisabled={loading}
            isLoading={loading}
            onClick={handleClick}
            text={btnText}
          />
        </div>
      </ShouldRender>
    </div>
  );
};

WinPrizes.propTypes = {
  headerImage: PropTypes.string,
  btnText: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          priceDisc: PropTypes.string,
          image: PropTypes.string,
        }),
      ),
    }),
  ),
  title: PropTypes.string,
};

WinPrizes.defaultProps = {
  headerImage: IMAGE_CONSTANT.winnerPrizes3,
  sections: defaultWinningPrizes,
  title: TEXT_CONSTANT.FULL_BODY_ENROLL_TEXT,
};
