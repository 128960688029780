import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  IMAGE_CONSTANT,
  LOCAL_STORAGE_CONSTANT,
  TOUR_TARGET_ELEMENT,
} from '../../constants/app';
import LazyImage from '../../ui-core/Image';
import { getUserAttribute } from '../../utils/helpers';
import { APP_ROUTES } from '../../route';
import { extractJSON } from '../../services/jsonUtil';
import { doGetUserDataRequest } from '../../store/action/user';

const ProfileImage = ({ className, history }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const userImage = user?.attributes?.profile_pic
    ? user.attributes.profile_pic
    : getUserAttribute('profile_pic') || IMAGE_CONSTANT.defaultProfileIcon;

  const handleClick = () => history.push(APP_ROUTES.PROFILE);

  const handleError = () => {
    const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
    if (user?.id) {
      const userId = user.id;
      dispatch(doGetUserDataRequest({ userId }));
    }
  };

  return (
    <LazyImage
      className={className}
      dataTourId={TOUR_TARGET_ELEMENT.profile}
      errorSrc={IMAGE_CONSTANT.defaultProfileIcon}
      placeholderSrc={IMAGE_CONSTANT.personIcon}
      onClick={handleClick}
      onError={handleError}
      src={userImage}
    />
  );
};

ProfileImage.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

export default withRouter(ProfileImage);
