import React from 'react';
import PropTypes from 'prop-types';

/* 
  This component allows the conditional rendering in a more consise and readable manner by conforming to 
  react declarative style
*/
export const ShouldRender = ({ check, children }) => {
  const component = children || <React.Fragment />;
  return check ? component : <React.Fragment />;
};

ShouldRender.propTypes = {
  check: PropTypes.bool.isRequired,
  children: PropTypes.node,
};
