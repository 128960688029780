import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  doFoldersRequest,
  resetFolders,
  toggleFolderModal,
} from '../../store/action/folders';
import { getDisplayType } from '../../utils/helpers';
import useWindowWidth from '../../hooks/useWindowWidth';
import Header from '../../components/Header';
import PagiNation from '../../components/Pagination';
import FolderBlocks from './FolderBlocks';
import Button from '../../ui-core/Button/Button';
import { LOCAL_STORAGE_CONSTANT, TEXT_CONSTANT } from '../../constants/app';
import FolderSkeleton from '../../ui-core/Skeletons/FolderSkeleton';
import { removeItem } from '../../utils/cache';
import clsx from 'clsx';
import './style.css';

const Folders = () => {
  const displayType = getDisplayType(useWindowWidth());
  const isDesktopScreen = displayType === 'desktop';
  const dispatch = useDispatch();
  const { folders, isLoading } = useSelector(state => state.folder);
  const data = folders.data ?? [];
  const paging = folders.paging || {};

  useEffect(() => {
    dispatch(doFoldersRequest());
    document.getElementById('app').scrollTo(0, 0);
    window.onbeforeunload = () => {
      removeItem(LOCAL_STORAGE_CONSTANT.FOLDER_PAGES);
    };
    return () => {
      removeItem(LOCAL_STORAGE_CONSTANT.FOLDER_PAGES);
      dispatch(resetFolders());
    };
  }, []);

  const renderFolderBlock = () => {
    const foldersOutput = data.map(folder => (
      <FolderBlocks
        key={`folder-${folder?.id}`}
        id={folder?.id}
        text={folder?.attributes?.name}
        video={`${folder?.attributes?.content_count ?? 0} videos`}
      />
    ));
    return foldersOutput;
  };
  const renderFolderSkeletons = () => {
    const folderSkeletons = Array.from({ length: 3 }).map((_, index) => (
      <FolderSkeleton key={`folder-skeleton-${index + 1}`} />
    ));
    return folderSkeletons;
  };

  const handleOpen = () =>
    dispatch(
      toggleFolderModal({
        showFolderModal: true,
        selectedModal: 'create-folder',
      }),
    );

  const handlePageChange = () => dispatch(doFoldersRequest());

  return (
    <div className="folder-page">
      {isDesktopScreen && (
        <Header
          homeClass="home"
          challengeClass="challenge"
          foldersClass="folders active"
          discoverClass="discover"
        />
      )}
      <div className="bg-floral">
        <div className="body-col-3">
          <h1 className="text-center list-page-title lg listpagetitle-gap m-0">
            {' '}
            My Folders{' '}
          </h1>
        </div>
      </div>
      <div
        className={clsx(
          'folder-wrapper body-col-3',
          !data.length && !isLoading && 'create-folder-wrapper',
        )}
      >
        {isLoading ? (
          renderFolderSkeletons()
        ) : data.length ? (
          <div className="block-lists w-100">{renderFolderBlock()}</div>
        ) : (
          <div className="folder-add-outer">
            <div className="d-flex justify-content-center flex-column align-items-lg-center folder-add">
              <h1 className="plan-title text-lg-center mt-lg-4 mt-0">
                {TEXT_CONSTANT.NO_FOLDERS_TEXT}
              </h1>
              <p className="mb-4 plan-para">
                {TEXT_CONSTANT.NO_FOLDERS_SUB_TEXT}
              </p>
              <div>
                <Button
                  class="profile-save-btn cnf w-100"
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  onClick={handleOpen}
                  text={TEXT_CONSTANT.CREATE_NEW_FOLDER}
                />
              </div>
            </div>
          </div>
        )}

        {paging?.total_pages > 1 && (
          <div className="d-flex align-items-center justify-content-center mt-5 mb-5 list-pagination">
            <PagiNation
              totalPages={paging?.total_pages}
              maxPageBlocks={paging?.total_items}
              triggerAction={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Folders.propTypes = {
  history: PropTypes.object,
};

export default Folders;
