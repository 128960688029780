import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import { Row, Col, Container } from 'react-bootstrap';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { formatCurrency, getDisplayType } from '../../../utils/helpers';
import {
  BODY_PARTS_BREAKPOINT,
  TEXT_CONSTANT,
  testimonials,
  IMAGE_CONSTANT,
  LOCAL_STORAGE_CONSTANT,
} from '../../../constants/app';
import Button from '../../../ui-core/Button/Button';
import LazyImage from '../../../ui-core/Image';
import { extractJSON } from '../../../services/jsonUtil';
import { SEGMENT_EVENTS, trackEvent } from '../../../utils/Segment';
import { doPaymentInitiationRequest } from '../../../store/action/products';

const TestimonialsGrid = ({ handleClick }) => {
  const displayType = getDisplayType(useWindowWidth());
  const isMobileScreen = displayType === 'mobile';
  const isIpadScreen = displayType === 'ipad';
  const [showMore, setShowMore] = useState(false);
  const output = showMore ? testimonials : testimonials.slice(0, 9);

  const testimonialsOutput = output.map(item => (
    <Col xs={4} key={item.name}>
      <div className="item d-flex body-card mb-4" onClick={handleClick}>
        <div className="m-auto py-2">
          <LazyImage
            alt={item.name}
            className="jen-offer-pic w-100"
            errorSrc={IMAGE_CONSTANT.placeholderImage}
            placeholderSrc={IMAGE_CONSTANT.placeholderImage}
            src={item.pictureUrl}
          />
          <p className="mb-0">{item.name}</p>
        </div>
      </div>
    </Col>
  ));
  return (
    <Row
      className={clsx('jen-offer-row px-lg-5', showMore && 'body-cards-row')}
    >
      {testimonialsOutput}
      {(isMobileScreen || isIpadScreen) && (
        <Col xs={12} className="position-relative">
          <Button
            text={showMore ? 'VIEW LESS' : 'VIEW MORE'}
            class="sign-up-button-new d-block auto w-100 white-border"
            onClick={() => setShowMore(prev => !prev)}
          />
        </Col>
      )}
    </Row>
  );
};

const Offer = ({ loading, onClick }) => {
  const displayType = getDisplayType(useWindowWidth());
  const isMobileScreen = displayType === 'mobile';
  const isIpadScreen = displayType === 'ipad';
  const dispatch = useDispatch();
  const { selectedPlan } = useSelector(state => state.product);
  const { payment } = useSelector(state => state.user);
  const rejoinMembership =
    payment?.attributes?.ends_at &&
    new Date() > new Date(payment?.attributes?.ends_at);

  const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
  const isDisabled = useMemo(() => {
    if (!user) {
      return false;
    } else {
      return (
        !selectedPlan ||
        loading ||
        (!rejoinMembership &&
          selectedPlan?.id === payment?.attributes?.product_id)
      );
    }
  }, [loading, selectedPlan, rejoinMembership]);

  const handleClick = () => {
    if (user) {
      const event = rejoinMembership
        ? SEGMENT_EVENTS.REJOIN_MEMBERSHIP
        : SEGMENT_EVENTS.START_FREE_TRIAL;
      trackEvent(event, {
        user_id: user.id,
        subscription_duration: selectedPlan.attributes.interval,
        free_trial_days: selectedPlan.attributes.free_trial_days,
        subscription_amount: formatCurrency(
          Number(selectedPlan.attributes.amount_in_cents) / 100,
          selectedPlan.attributes.currency,
        ),
        plan_name: selectedPlan.attributes.name,
        plan_id: selectedPlan.id,
      });
      if (!isDisabled) {
        dispatch(doPaymentInitiationRequest());
      }
    } else {
      if (onClick) {
        onClick();
      }
    }
  };

  const renderTestimonials = () => {
    const testimonialsOutput = testimonials?.map(item => (
      <div
        key={item.name}
        className="item d-flex cursor-pointer"
        style={{ maxHeight: '216px' }}
        onClick={handleClick}
      >
        <div className="m-auto">
          <LazyImage
            alt={item.name}
            className="jen-offer-pic w-100"
            errorSrc={IMAGE_CONSTANT.placeholderImage}
            placeholderSrc={IMAGE_CONSTANT.placeholderImage}
            src={item.pictureUrl}
          />
          <p>{item.name}</p>
        </div>
      </div>
    ));
    return testimonialsOutput;
  };

  return (
    <div className="jen-offer align-items-center text-center">
      <Container>
        <Row>
          <Col>
            <h1
              className={clsx(
                'section-title text-center mb-5 text-white ms-5 me-5',
              )}
            >
              {TEXT_CONSTANT.EXCERCISE_PLAN_MADE_FOR_YOU}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="jen-offer-para">
              {TEXT_CONSTANT.EXCERCISE_PLAN_SUB_TEXT}
            </p>
          </Col>
        </Row>
        {isMobileScreen || isIpadScreen ? (
          <TestimonialsGrid handleClick={handleClick} />
        ) : (
          <Row className={'jen-offer-row px-lg-5'}>
            <Col className="position-relative">
              <Carousel
                responsive={BODY_PARTS_BREAKPOINT}
                className="doctors-advice d_c"
                showDots={true}
                slidesToSlide={2}
                swipeable={isIpadScreen}
                draggable={isIpadScreen}
              >
                {renderTestimonials()}
              </Carousel>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

Offer.propTypes = {
  loading: PropTypes.bool,
  history: PropTypes.object,
  onClick: PropTypes.func,
  handleClick: PropTypes.func,
};

TestimonialsGrid.propTypes = {
  loading: PropTypes.bool,
  history: PropTypes.object,
  onClick: PropTypes.func,
  handleClick: PropTypes.func,
};

export default withRouter(Offer);
