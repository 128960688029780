export const BLOG_SET_IS_LOADING = 'BLOG_SET_IS_LOADING';
export const BLOG_DETAIL_SET_IS_LOADING = 'BLOG_DETAIL_SET_IS_LOADING';
export const BLOG_BY_CATEGORY_IS_LOADING = 'BLOG_BY_CATEGORY_IS_LOADING';

export const DO_FETCH_BLOG_REQUEST = 'DO_FETCH_BLOG_REQUEST';
export const DO_FETCH_BLOG_REQUEST_SUCCESS = 'DO_FETCH_BLOG_REQUEST_SUCCESS';
export const DO_FETCH_BLOG_META_REQUEST_SUCCESS =
  'DO_FETCH_BLOG_META_REQUEST_SUCCESS';
export const DO_FETCH_BLOG_FAILURE = 'DO_FETCH_BLOG_FAILURE';
export const DO_FETCH_BLOG_SPOTLIGHT_REQUEST_SUCCESS =
  'DO_FETCH_BLOG_SPOTLIGHT_REQUEST_SUCCESS';

export const DO_FETCH_BLOG_CATEGORY_REQUEST = 'DO_FETCH_BLOG_CATEGORY_REQUEST';
export const DO_FETCH_BLOG_CATEGORY_REQUEST_SUCCESS =
  'DO_FETCH_BLOG_CATEGORY_REQUEST_SUCCESS';
export const DO_FETCH_BLOG_CATEGORY_FAILURE = 'DO_FETCH_BLOG_CATEGORY_FAILURE';
export const DO_FETCH_DETAIL_BLOG_REQUEST = 'DO_FETCH_DETAIL_BLOG_REQUEST';
export const DO_FETCH_DETAIL_BLOG_REQUEST_SUCCESS =
  'DO_FETCH_DETAIL_BLOG_REQUEST_SUCCESS';

export const DO_FETCH_BLOG_BY_CATEGORY_REQUEST =
  'DO_FETCH_BLOG_BY_CATEGORY_REQUEST';

export const DO_FETCH_BLOG_BY_CATEGORY_REQUEST_SUCCESS =
  'DO_FETCH_BLOG_BY_CATEGORY_REQUEST_SUCCESS';

export const SEARCH_STRING_REQUEST = 'SEARCH_STRING_REQUEST';

export const SEARCH_STRING_REQUEST_SUCCESS = 'SEARCH_STRING_REQUEST_SUCCESS';
