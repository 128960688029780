import React from 'react';
import PropTypes from 'prop-types';
import { Image, Modal } from 'react-bootstrap';
import { IMAGE_CONSTANT } from '../../constants/app';
import '../Modal/style.css';

const ModalBox = props => {
  const {
    backButton,
    backdrop,
    bsPrefix,
    closeButton,
    customBackIcon,
    dialogClassName = 'auth-modal my-0 py-4',
    footer,
    handleGoBack,
    handleClose,
    head,
    show,
    size,
  } = props;

  const renderHeader = () => {
    if (backButton) {
      return (
        <div className="d-flex justify-content-center align-items-center w-100">
          <Image
            className="cursor-pointer modal-back-button create-folder-back-btn"
            onClick={handleGoBack}
            src={customBackIcon || IMAGE_CONSTANT.backArrow}
            alt="back"
          />
          {head}
        </div>
      );
    } else {
      return head;
    }
  };

  return (
    <Modal
      backdrop={backdrop}
      bsPrefix={`${bsPrefix ? bsPrefix + ' ' : ''}modal`}
      dialogClassName={dialogClassName}
      keyboard={false}
      onHide={handleClose}
      show={show}
      size={size}
      animation={false}
    >
      {!props.hideHeader && (
        <Modal.Header closeButton={closeButton}>{renderHeader()}</Modal.Header>
      )}
      <Modal.Body className="Modal-body">{props.children}</Modal.Body>
      {footer}
    </Modal>
  );
};

ModalBox.propTypes = {
  backdrop: PropTypes.oneOf([PropTypes.bool, 'static']),
  backButton: PropTypes.bool,
  bsPrefix: PropTypes.string,
  children: PropTypes.any,
  closeButton: PropTypes.bool,
  customBackIcon: PropTypes.string,
  dialogClassName: PropTypes.string,
  handleClose: PropTypes.func,
  handleGoBack: PropTypes.func,
  head: PropTypes.any,
  hideHeader: PropTypes.bool,
  footer: PropTypes.node,
  show: PropTypes.bool,
  size: PropTypes.string,
};

export default ModalBox;
