import { IMAGE_CONSTANT } from '../../constants/app';
import { getPromptLink } from '../../utils/helpers';
import {
  image1,
  image1Low,
  image1Webp,
  image2,
  image2Low,
  image2Webp,
  image3,
  image3Low,
  image3Webp,
  image4,
  image4Low,
  image4Webp,
  image5,
  image5Low,
  image5Webp,
  image6,
  image6Low,
  image6Webp,
  image7,
  image7Low,
  image7Webp,
  referFriend,
  vivoBarefootShoes,
} from './images';

export const moveToImproveTestimonials = [
  {
    testimonial:
      '"I have to tell you that I did the spring challenge and <b>I continued it for five months</b>, and these just changed everything for me. This was the <b>first time</b> that I had been able to consistently workout for many many years. Thank you so so much for your programs and your knowledge. <b>They\'ve made a huge difference in my life</b> 🙏💕"',
    name: 'Athena',
  },
  {
    testimonial:
      "\"<b>I've seen more results in the last month than in 6 months of HIIT</b>, so I'm very thankful for this. I've gone from three elevated push ups to 6!! I also cringe when I see people doing wrong push up form now on IG 😆\"",
    name: 'Shelley',
  },
  {
    testimonial:
      '"After suffering a hip injury in a car accident several years ago, everything tightened up... fear of re-injury and not doing enough. But <b>your program is the best thing I\'ve ever done to promote healing</b>. I had tried so much PT, along with dry needling and <b>nothing gave me relief until I began your program</b>." ',
    name: 'Bonnie',
  },
  {
    testimonial:
      '"I had pain around my back (ribs area), weakness everywhere. I feel like I\'m starting to feel normal again. The pain lessens with consistency. <b>She gives you details of how the movement should and should not feel</b>. This was game changer for me. We can all go thru the motions but until you understand and connect with your body... you cannot start the healing process."',
    name: 'Nicole',
  },
  {
    testimonial:
      '"This is the first time ever in my life I\'ve joined a challenge like this. <b>I love everything I\'ve learned, unlearned and relearned from the challenge</b>. The program was thoughtfully put together and so <b>accessible to all levels</b>. I love how each week builds up for the next and we also get to repeat certain exercises to <b>help rewire those new pathways into our bodies</b>. Your cues are one of the easiest to understand, even for parts of body that are difficult to imagine, like pelvic floor muscles."',
    name: 'Cherry',
  },
  {
    testimonial:
      '"As I started following some of your tips on IG and then the challenge, (no kidding) <b>I felt like I learned much more in one month than what I\'d been looking for in years!</b> My shoulders feel more flexible, I\'ve done spine mobility exercises routinely to relieve some stress after working in a classroom all day (I made it part of my bedtime routine), and <b>I truly feel in my best shape ever!</b>"',
    name: 'Daniela',
  },
];

export const pastChallengers = [
  {
    testimonial:
      '"Thank you for the challenge. <b>I learned so much</b>. The muscle activation before going for a run was <b>mind blowing</b>. It goes so much smoother."',
    name: 'Stefanie',
  },
  {
    testimonial:
      '"Seriously this program has changed so much of the way I think about movement. <b>I feel so much more in tune with my body</b> after listening to you explain stuff. My hips thank you!" ',
    name: 'Jasha',
  },
  {
    testimonial:
      '"My favorite was <b>core work</b> involving my pelvic floor. <b>Made planks WAY harder than before!</b> I\'ve noticed <b>less lower back tightness</b> after running after adding these types of mobility stretches. You put on a great program.  "',
    name: 'Sean',
  },
  {
    testimonial:
      '"Thank you for creating this challenge, it\'s really helping me understand my body and <b>how to move better with scoliosis!</b> I just completed the second core and <b>I have never felt my lower abs</b> the way I do now!"',
    name: 'Julia',
  },
];

export const winningPrizes = [
  {
    title: 'Prizes for those who post 3x per week on their Instagram feed',
    items: [
      {
        title: 'Cash Prize Winner',
        priceDisc: 'of $500',
        image: IMAGE_CONSTANT.cashPrizeWinnerIcon,
      },
      {
        title: 'Refer Your Friends',
        priceDisc: 'Bonus Prize <br/>(Up To $300 Value)',
        image: referFriend,
      },
      {
        title: 'Vivo Barefoot Shoes',
        priceDisc: '(Up To $240 Value)',
        image: vivoBarefootShoes,
      },
      {
        title: 'The Optimal Body Therapy Kit',
        priceDisc: '($60 Value)',
        image: IMAGE_CONSTANT.bodyTherapyIcon,
      },
      {
        title: 'Free 1 Year of <br/>Jen Health',
        priceDisc: '($300 Value)',
        image: IMAGE_CONSTANT.jenLowImapceIcon2,
      },
    ],
  },
];

export const moveToImproveThirtyDays = [
  {
    name: '5 Self Assessments',
    description: `
      <p>It's difficult to know <b>where to start</b> without understanding <b>what your body needs</b> first. We will use <b>five specific self-assessments</b> to begin this challenge on "Day 0" and then re-assess at the end of the 30 days to see (or feel) a difference!</p>
      <h5 class="my-3">Value of 1 Physical Therapy Appointment for Assessment = $75</h5>
    `,
    image: image1,
    placeholderImage: image1Low,
    webpImage: image1Webp,
    reverseOrder: false,
  },
  {
    name: '10 Mobility Flows',
    description: `
      <p>Mobility is crucial to a healthy body. It is key to providing the <b>foundation</b> from which your body improves upon and moves freely. Learn how to <b>properly assess</b> your restrictions and do the mobility exercises that will help to <b>improve range of motion</b> and <b>relieve tension!</b> Learn how to use mobility as a warm-up, cool-down or on it's own! We will be going through full body flows, upper body flows, lower body flows and flows specific for the spine.</p>
      <h5 class="my-3">Value of 10 Full Classes by a Physical Therapist= $100 (at minimum!)</h5>
    `,
    image: image2,
    placeholderImage: image2Low,
    webpImage: image2Webp,
    reverseOrder: true,
  },
  {
    name: '7 Functional & Educational Strength Workouts',
    description: `
      <p>Strength is imperative to function, <b>pain control</b>, and overall <b>resiliency</b> within the body. These workouts are meant for every level, beginning with exercises to <b>activate</b> and <b>stimulate</b> the muscle groups we will be using, into either a <b>full body workout, upper body or lower body workout</b>. We will then end with a muscle burnout finisher. It is your choice to add weights to increase resistance or just follow along with your own body weight. I will be doing the full workout with you to maximize education, cueing and understanding throughout the workouts.</p>
      <h5 class="my-3">Value of 7 Educational Classes by a Physical Therapist = $70</h5>
    `,
    image: image3,
    placeholderImage: image3Low,
    webpImage: image3Webp,
    reverseOrder: false,
  },
  {
    name: '4 Core Stability Workouts',
    description: `
      <p>Core stability? It's not the "6 pack" you might think. It's WAY more. And if we actually want to build a <b>strong foundation</b> that supports our back, hips, knees and neck you are going to want to start learning here. I'll provide you with the tools to begin building that strong core power from the <b>pelvic floor</b> to <b>scapular control</b>. We will progress through these next 30 days safely and intentionally to truly build a solid foundation.</p>
      <h5 class="my-3">Value of 4 Core/Pelvic Floor Classes by a Physical Therapist = $50</h5>
    `,
    image: image4,
    placeholderImage: image4Low,
    webpImage: image4Webp,
    reverseOrder: true,
  },
  {
    name: '5 Muscle Activations',
    description: `
      <p>What are muscle activations? Think of these as your <b>physical therapy</b> guided, <b>corrective exercises</b>. I will be demonstrating and instructing how to get into the most effective, corrective exercises so that, once and for all, you can <b>finally feel those glutes</b> fire up without any back pain or hip flexor issues. Get ready to connect to your body in ways you haven't been able to before!</p>
      <h5 class="my-3">Value of 5 Corrective Exercises by a Physical Therapist = $25</h5>
    `,
    image: image5,
    placeholderImage: image5Low,
    webpImage: image5Webp,
    reverseOrder: false,
  },
  {
    name: 'BONUS: No Equipment Needed!',
    description:
      "Workout at the gym OR in the comfort of your <b>own home</b>. We'll get creative, clean some floors, and utilize supplies from home. The intention is <b>FUNCTION</b>. This includes overall STRENGTH, BALANCE, and STABILITY. Learn the <b>WHY</b> and let's <b>OWN OUR BODY AWARENESS.</b>",
    image: image6,
    placeholderImage: image6Low,
    webpImage: image6Webp,
    reverseOrder: true,
  },
  {
    name: 'Weekly Mind-Body Pain Reset Tools + Daily Goals',
    description:
      'Our mind has the ability to control our muscles, fascia, movement, and pain. I\'ll be providing <b>tools</b> to help improve this <b>connection</b> and set you up for long-term success within your body. In addition to movement, we will have a new intention or <b>"focus of the week"</b> each week. We will move and work with some mindset tools using the intention/focus.',
    image: image7,
    placeholderImage: image7Low,
    webpImage: image7Webp,
    reverseOrder: false,
  },
];

export const moveToImproveThirtyFAQs = [
  {
    title: 'How Does the Course Work?"',
    description: [
      `The log-in you use to sign up for the course (either phone number or gmail) will be the log-in you use to access your course each time! Make sure to always use the same entry as you used to initially sign up. To easily find the link again, save this link in your browser: <a class='color-primary' data-rel="external" href="https://jen.health">https://jen.health</a>`,
      `You will also receive a Welcome Email to go over more information about your course! Please check spam, junk and promotions folders. You can also search for <a  class='color-primary' data-rel="external" href="${getPromptLink(
        'jen@jen.health',
      )}">jen@jen.health</a> through your laptop or computer email service.`,
      'Your course will include a downloadable <b>Welcome Ebook PDF</b> that you can find when you click on your 30 Days of Mobility and Strength inside <b>"Courses"</b> within the Jen Health Platform.',
      'You will have access to the <b>five self-assessment videos</b> that I strongly encourage you go through before diving into the rest of the course! I recommend slotting 30 minutes somewhere (or doing one assessment at a time) to learn about your body and chart your current starting point before you complete the course! We have provided a <b>Guided Workbook</b> at the top of your calendar, next to the Welcome Ebook, to track your progress and take notes on your self-assessments.',
      // '<b>Every Sunday before your week starts</b>,  I will send you an email with detailed information on what to expect for the week ahead and your program will unlock the week ahead online! The first Sunday you will receive an email is on March 5th. Keep in mind, you do not need an email to access your course! They are just provided as added accountability to keep you going!',
      'Every week we will be doing <b>4 mobility flows, 3 strength workouts, 2 core workouts and 1 muscle activation</b>.',
      'Yes, some of the workouts and mobility flows <b>will repeat</b> throughout the month. Remember, if we want to feel something different in the body, we must maintain consistency as we explore and learn new movements! The course will be to see how your body progresses throughout the month!',
      "And most importantly... <b>you get LIFETIME ACCESS to move through this program! I highly</b> encourage you to stay consistent for the 30 days to the best of your abilities (don't worry, we all miss a day or two throughout the week 😉) in order to truly experience what 30 consistent days of this type of movement can feel like in your body. And then feel free to go back into your program and repeat workouts as needed after to keep learning!",
    ],
  },
  {
    title: 'Who is this Course for?',
    description: [
      '<b>30 Days of Strength and Mobility is for those who are ready to <b>feel BETTER</b> in their bodies and finally start addressing those <b>consistent aches and pains</b> We will be sweating as we move through the week, but this guide goes far beyond exercise or workouts. It is for those ready to <b>learn proper movement patterns</b>, understand not only HOW to perform exercises with improved form but WHY we might change body positioning. It is for those who might be ignoring aches and pains in the body or eager to finally understand how to move through them without fearing exercise.',
      "<b>Don't have a lot of time to add an hour of yoga or mobility a week?</b> This program will teach you how to understand mobility to implement in smaller pieces throughout your day. The mobility flows each last between 8-15 minutes and can easily be implemented as a warm-up, a cool-down or on a rest day.",
      '<b>Been trying to hammer in core work to help your back pain?</b> The core goes way beyond just the abs. And unless we understand how to utilize the breath, work with your pelvic floor, back and entire abdominal region, we might not ever support the body in the most efficient and effective way! As a previous pilates instructor of 6 years and Doctor of Physical Therapy, I have been studying this "core concept" for awhile now. Understanding this foundation can help to relieve back, hip, SI and pelvic pain and dysfunction',
      '<b>Looking for more structure on how to ideally set up your week and not have to think about it?</b> This plan will provide <b>a 30 day calendar</b> that will track your progress as you complete each day. You will also receive a daily check-in graphic you are free to download and use after each day in order to hold yourself accountable! We have had thousands of people build strength in their bodies by using this course! Need more support? Post, comment, and engage with the <b>#Move2Improve2023</b> community hashtag!',
      "<b>Don't have a lot of equipment? Want to workout at home?</b> No problem! We're going to get creative using step stools, ottomans, benches, and understanding of how to change our positioning to add more load in body weight exercises! Through intention and understanding, you will be able to feel muscles burning you've never quite noticed before!",
    ],
  },
  {
    title: 'Who is this course NOT for?',
    description: [
      '<b>30 Days of Mobility and Strength/b> is not for you if you are looking for a <b>weight loss program</b>. This program is built for you to understand proper exercise execution through expert cueing and video visuals, while understanding how to implement mobility, core and muscle activations into your week. This is your opportunity to learn about your body and appreciate its ability to adapt and reduce pain as you improve strength and mobility.',
      '<b>This program is not for you if you are hoping to be diagnosed about a specific pain point or symptom you are currently experiencing.</b> This program is merely an educational tool built by a Doctor of Physical Therapy. Though I am a Doctor, I am not YOUR Doctor and without a proper assessment and personal evaluation, I cannot diagnose you nor prescribe an individual treatment plan.',
      '<b>Feel like you may be too advanced for this program?</b> We will be starting "easier" at the beginning of the month to understand foundational concepts and progress as the month goes on. In my experience (yes, even me as a therapist!), there is ALWAYS more to learn. If you are hesitant about relearning core concepts, slowing things down to ensure proper engagement, or even be challenged in your current way of movement and exercise, this program may not be for you. I want to course your idea of mobility, core and exercise execution! I am looking to move with those who are open to exploring the body in new and different ways!',
      "<b>Uncertain you will like it?</b> Unfortunately, we do not offer refunds with this program. This is a one-time, low price offer for lifetime access to 31 educational videos. I truly built this program to work for just about any person to not only move with but learn at least a few new concepts throughout! We understand the value of learning from a physical therapist and hope you will too! If you're not sure just yet, email us at jen@jen.health with any more questions!",
      '<b>Need a diet plan with your movements?</b> There will not be a meal plan included in this program. You can learn more from my friend, Julia Glanz (registered dietitian) on Instagram or her <span class="color-primary">Intermittent Fasting Guide for Women</span> Program. You can also check out <span class="color-primary">Dr. Dom\'s Kitchen Independence Cookbook</span> for healthy recipes!',
      `<b>Have additional questions?</b> Email me at <a  class='color-primary' data-rel="external" href="${getPromptLink(
        'jen@jen.health',
      )}">jen@jen.health</a>`,
    ],
  },
  // {
  //   title: 'How do the course prizes work!?',
  //   description: [
  //     "<b>Win by posting on Instagram</b> Yes, you will need a public account. If you don't feel comfortable using your current account, or if you don't have one, you can create a new one just for this course! <b>YOU DO NOT NEED AN INSTAGRAM ACCOUNT TO PARTICIPATE IN THE CHALLENGE</b>. This is merely used as an opportunity to win prizes and gain accountability through an incredible community!",
  //     'In order to be entered into winning, you must <b>post a minimum of 3 times per week</b> using the <b>hashtag #Move2Improve2023</b>, you must be <b>following both me @docjenfit AND @jen.health</b> as well as <b>tagging</b> both pages on each post for the course. This is a total of 12 posts over March 6th-April 4th.',
  //     'Instagram posts can be whatever it is that resonates with you as you move through this course OR one of the daily check-in graphics that we provide throughout the course!',
  //     '<b>BONUS</b> entries for posting <b>"I\'m In" graphic</b> and <b>referring friends!</b> Learn more about the "Refer Your Friends" program in the Welcome Email!',
  //     'Refer to the top of the page for prizes available!',
  //     '<b>WINNERS</b> will be chosen on <b>Friday, April 7th</b>',
  //   ],
  // },
];
