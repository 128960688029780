import React from 'react';
import Footer from '../components/Footer';

export const withFooter = Component => {
  // eslint-disable-next-line
  return props => (
    <React.Fragment>
      <Component {...props} />
      <Footer showFooter />
    </React.Fragment>
  );
};
