import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { APP_ROUTES } from '../../../../route';
import { SEGMENT_EVENTS, trackEvent } from '../../../../utils/Segment';
import {
  SURVEY_STATUS,
  LOCAL_STORAGE_CONSTANT,
  IMAGE_CONSTANT,
} from '../../../../constants/app';
import Button from '../../../../ui-core/Button/Button';
import LazyImage from '../../../../ui-core/Image';

import './style.css';

const WaitList = props => {
  const { user } = useSelector(state => state.auth);
  const { history } = props;

  useEffect(() => {
    trackEvent(SEGMENT_EVENTS.WAITLIST_MODAL_SCREEN, {
      // eslint-disable-line
      page: 'Home Page',
      user_id: user?.id,
      phone: user?.attributes?.mobile,
      country_code: user?.attributes?.country_code,
      has_access: user?.attributes?.has_access,
    });
  }, []);

  const handleClick = () => {
    localStorage.setItem(
      LOCAL_STORAGE_CONSTANT.SURVEY_STATUS,
      SURVEY_STATUS.PENDING,
    );
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      // eslint-disable-line
      button_title: 'Take a Quick Survey',
      page: 'Home Page',
      section_title: 'Waitlist Modal',
    });
    history.push(APP_ROUTES.SURVEY);
  };

  return (
    <div className="waitlist-content">
      {user?.attributes?.new_user ? (
        <>
          <h3 className="waitlist-h3 text-center"> Thanks for joining the </h3>
          <h2 className="waitlist-h3 text-center "> Waitlist! </h2>
        </>
      ) : (
        <h3 className="waitlist-h3 text-center">
          {' '}
          Thanks for logging in! We&apos;re working hard to bring you access to
          Jen, you&apos;ll be hearing from us soon{' '}
        </h3>
      )}

      <div className="mt-3 gift-img-box px-3 px-lg-0">
        <LazyImage
          alt="waitlist-image"
          className="gift-img img-fluid"
          placeholderSrc={IMAGE_CONSTANT.placeholderImage}
          errorSrc={IMAGE_CONSTANT.placeholderImage}
          src={
            user?.attributes?.new_user
              ? 'https://media.giphy.com/media/YnBntKOgnUSBkV7bQH/giphy.gif'
              : 'https://media.giphy.com/media/wsXVAJY8ibkqBSmjEF/giphy.gif'
          }
        />
      </div>
      {/* here we also have to add background - waitListBack.png in static folder */}
      <p className="text-center mt-3">
        Take our quick survey to get early access to Jen
        {/* and a special gift, just for you &#127873; */}
      </p>
      <Button
        onClick={() => handleClick()}
        text="TAKE A QUICK SURVEY"
        class="survey-button"
        isDisabled={false}
      />
    </div>
  );
};

WaitList.propTypes = {
  history: PropTypes.object,
};

export default withRouter(WaitList);
