import { axiosInstance, overrideHeader } from './index';

/**
 * API request
 * */

export const categoriesBySlugRequest = async slug => {
  overrideHeader();
  let endPoint = `/categories`;
  if (slug) endPoint += `/${slug}`;
  return axiosInstance.get(endPoint);
};

export const defaultVideoRequest = async () => {
  overrideHeader();
  const endPoint = `/videos/default`;
  return axiosInstance.get(endPoint);
};

export const searchV2Request = async value => {
  overrideHeader();
  const endPoint = '/videos/search?search=' + value;
  return axiosInstance.get(endPoint, {
    headers: {
      Accept: 'application/vnd.docjenfit.com; version=2',
    },
  });
};
