/*
 *
 * discover constants
 *
 * */

export const DO_CATEGORIES_SLUG_LOADING = 'DO_CATEGORIES_SLUG_LOADING';
export const DO_CATEGORIES_SLUG_REQUEST = 'DO_CATEGORIES_SLUG_REQUEST';
export const DO_CATEGORIES_SLUG_SUCCESS = 'DO_CATEGORIES_SLUG_SUCCESS';
export const DO_CATEGORIES_SLUG_FAILED = 'DO_CATEGORIES_SLUG_FAILED';

export const DO_DEFAULT_VIDEO_LOADING = 'DO_DEFAULT_VIDEO_LOADING';
export const DO_DEFAULT_VIDEO_REQUEST = 'DO_DEFAULT_VIDEO_REQUEST';
export const DO_DEFAULT_VIDEO_SUCCESS = 'DO_DEFAULT_VIDEO_SUCCESS';
export const DO_DEFAULT_VIDEO_FAILED = 'DO_DEFAULT_VIDEO_FAILED';

export const DO_SEARCH_V2_LOADING = 'DO_SEARCH_V2_LOADING';
export const DO_SEARCH_V2_REQUEST = 'DO_SEARCH_V2_REQUEST';
export const DO_SEARCH_V2_SUCCESS = 'DO_SEARCH_V2_SUCCESS';
export const DO_CLEAR_SEARCH_RECORDS = 'DO_CLEAR_SEARCH_RECORDS';

export const DO_CATEGORIES_SLUG_PAGINATION_LOADING =
  'DO_CATEGORIES_SLUG_PAGINATION_LOADING';
export const DO_CATEGORIES_SLUG_PAGINATION_REQUEST =
  'DO_CATEGORIES_SLUG_PAGINATION_REQUEST';
export const DO_CATEGORIES_SLUG_PAGINATION_SUCCESS =
  'DO_CATEGORIES_SLUG_PAGINATION_SUCCESS';
export const DO_CATEGORIES_SLUG_PAGINATION_FAILED =
  'DO_CATEGORIES_SLUG_PAGINATION_FAILED';
export const DO_CATEGORIES_SLUG_PAGINATION_RESET =
  'DO_CATEGORIES_SLUG_PAGINATION_RESET';

export const discoverNoSearchResult = [
  'Try different keywords',
  'Look for an exercise, Body part, plan or assessment',
  'Try for Mobility, Optimal body, Pain',
];

export const STORE_DISCOVER_SEARCH_TEXT = 'STORE_DISCOVER_SEARCH_TEXT';
