import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../../../ui-core/Button/Button';
import { FeatureList } from '../../../Lead/components';
import { formatCurrency } from '../../../../utils/helpers';
import { IMAGE_CONSTANT, TEXT_CONSTANT } from '../../../../constants/app';

import './style.css';

export const Features = ({ courseDetail, loading, onClick }) => {
  const { selectedPlan } = useSelector(state => state.product);

  const featuresListOne = [
    '30 Structured Days with Full Calendar to Track Progress',
    'Self-Assessment Videos',
    'Full Body Mobility Flows',
    'Functional & Educational Strength Workouts Made for Everyone',
  ];

  const featuresListTwo = [
    'Core Stability Workouts',
    'Muscle Activation Corrective Exercises',
    'Weekly Mind-Body Pain Reset Tools + Daily Goal Setting',
    'Breathwork, Affirmations, & Accountability',
    '26 Full Videos to Follow with Expert Cueing',
  ];

  const currentPrice = useMemo(() => {
    const amount = courseDetail
      ? courseDetail?.attributes?.amount
      : selectedPlan?.attributes?.amount_in_cents;
    return amount ? +amount / 100 : 0;
  }, [courseDetail, selectedPlan]);

  const perDayPrice = useMemo(() => {
    return Math.floor(currentPrice / 30);
  }, [currentPrice]);

  const headerText = useMemo(() => {
    return `When you sign up for just over ${formatCurrency(
      perDayPrice,
      courseDetail?.attributes?.currency,
      0,
    )}/day, you will receive:`;
  }, [perDayPrice]);

  const handleClick = () => {
    if (onClick) {
      onClick(TEXT_CONSTANT.JOIN_NOW, 'isSignup', headerText);
    }
  };

  return (
    <div className="features-section">
      <div className="container d-flex flex-column align-items-center">
        <FeatureList
          headerText={headerText}
          featuresListOne={featuresListOne}
          featuresListTwo={featuresListTwo}
        />
        <div className="ps-tag d-flex align-items-center">
          <img
            alt="piggy"
            className="me-3"
            src={IMAGE_CONSTANT.piggyBankIcon}
          />
          <span>{`P.S. Yes! All of this for just over ${formatCurrency(
            perDayPrice,
            courseDetail?.attributes?.currency,
            0,
          )}/DAY!! That's a ${formatCurrency(
            currentPrice,
            courseDetail?.attributes?.currency,
            0,
          )} investment into your health!`}</span>
        </div>
        <Button
          class="sign-up-button-new"
          isDisabled={loading}
          isLoading={loading}
          onClick={handleClick}
          text={TEXT_CONSTANT.JOIN_NOW}
        />
      </div>
    </div>
  );
};
Features.propTypes = {
  courseDetail: PropTypes.object,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};
