import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  analyticsReset,
  SEGMENT_EVENTS,
  trackEvent,
} from '../../utils/Segment';
import { resetStore, setSelectedFilters } from '../../store/action/common';
import { LOCAL_STORAGE_CONSTANT } from '../../constants/app';
import { removeItem } from '../../utils/cache';
import { extractJSON } from '../../services/jsonUtil';
import { doPlansSuccess } from '../../store/action/plan';
import { APP_ROUTES } from '../../route';

const LogoutButton = props => {
  const dispatch = useDispatch();
  const logOut = () => {
    const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
    trackEvent(SEGMENT_EVENTS.LOGOUT, {
      user_id: user?.id,
      free_trial_active: user?.attributes?.free_trial || false,
      subscription_active:
        user?.attributes?.subscription_details?.subscribed || false,
    });
    dispatch(resetStore());
    dispatch(setSelectedFilters({ selectedFilters: [], skipSave: true }));
    dispatch(doPlansSuccess([]));
    removeItem(LOCAL_STORAGE_CONSTANT.USER);
    removeItem(LOCAL_STORAGE_CONSTANT.SESSION);
    removeItem(LOCAL_STORAGE_CONSTANT.SELECTED_FILTERS);
    removeItem(LOCAL_STORAGE_CONSTANT.LAST_VOLUME);
    removeItem(LOCAL_STORAGE_CONSTANT.LAST_PLAYBACK_RATE);
    removeItem(LOCAL_STORAGE_CONSTANT.BODY_PARTS_OPTIONS);
    props.history.push(APP_ROUTES.ROOT);
    analyticsReset();
    window?.Cohere?.stop();
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  };
  return (
    <div className={props.className} onClick={logOut}>
      <svg
        className="feather feather-log-out"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
        <polyline points="16 17 21 12 16 7"></polyline>
        <line x1="21" y1="12" x2="9" y2="12"></line>
      </svg>
    </div>
  );
};

LogoutButton.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

export default withRouter(LogoutButton);
