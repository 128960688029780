import {
  DO_PLANS_SUCCESS,
  DO_CURATED_PLANS_SUCCESS,
  PLANS_SET_IS_LOADING,
  DO_FITNESS_OPTIONS_SUCCESS,
  RESET_CURATED_PLAN,
  SET_SHOW_PHASE_MODAL,
  PLANS_IS_LOADING,
  DO_THERAPY_PLANS_SUCCESS,
  DO_THERAPY_PLANS_RESET,
} from '../../constant/plan';
import { RESET_STORE } from '../../constant/common';

const initialState = {
  curatedPlans: {},
  fitnessOptions: [],
  myPlans: [],
  isLoading: false,
  plans: {},
  showPhaseModal: false,
  isPlansLoading: false,
  therapyPlans: { data: [], paging: {} },
  pageCount: 0,
  totalPage: 1,
};

export const planReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DO_CURATED_PLANS_SUCCESS:
      return { ...state, curatedPlans: payload };
    case DO_FITNESS_OPTIONS_SUCCESS:
      return { ...state, fitnessOptions: payload };
    case DO_PLANS_SUCCESS:
      return { ...state, plans: payload ?? [] };
    case DO_THERAPY_PLANS_SUCCESS:
      return {
        ...state,
        therapyPlans:
          {
            data: [...state?.therapyPlans.data, ...payload?.data],
            paging: payload?.paging,
            // paging: { total_pages: 100 },
          } ?? [],
        pageCount: payload?.pageCount,
        totalPage: payload?.totalPage,
      };
    case DO_THERAPY_PLANS_RESET:
      return {
        ...state,
        therapyPlans: { ...initialState.therapyPlans },
        pageCount: initialState.pageCount,
        totalPage: initialState.totalPage,
      };
    case PLANS_SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case PLANS_IS_LOADING:
      return { ...state, isPlansLoading: payload };
    case RESET_CURATED_PLAN:
      return { ...state, curatedPlans: {} };
    case RESET_STORE:
      return { ...initialState };
    case SET_SHOW_PHASE_MODAL:
      return { ...state, showPhaseModal: payload };
    default:
      return state;
  }
};
