import React from 'react';
import PropTypes from 'prop-types';
import parser from 'html-react-parser';

import { OfferWithButton } from '../OfferWithButton';
import { ShouldRender } from '../../../../ui-core/ShouldRender';
import LazyImage from '../../../../ui-core/Image';

import './style.css';

export const TextAndImageBlock = ({
  btnText,
  coupon,
  couponFirst,
  description,
  image,
  loading,
  onClick,
  sectionTitle,
  text,
  title,
}) => {
  return (
    <div className="lead-text-image-block">
      <div className="lead-text-image-div">
        <ShouldRender check={!!title}>
          <h3>{title}</h3>
        </ShouldRender>
        <div className="description">{parser(description)}</div>
        <OfferWithButton
          btnText={btnText}
          coupon={coupon}
          couponFirst={couponFirst}
          loading={loading}
          onClick={onClick}
          sectionTitle={sectionTitle}
          text={text}
        />
      </div>
      <div>
        <LazyImage
          className="w-100 h-100 img-fluid"
          alt={title}
          errorSrc={image.png}
          placeholderSrc={image.jpg}
          src={image.webp}
        />
      </div>
    </div>
  );
};

TextAndImageBlock.propTypes = {
  btnText: PropTypes.string.isRequired,
  coupon: PropTypes.string.isRequired,
  couponFirst: PropTypes.bool,
  description: PropTypes.string,
  image: PropTypes.shape({
    jpg: PropTypes.string.isRequired,
    png: PropTypes.string.isRequired,
    webp: PropTypes.string.isRequired,
  }),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  sectionTitle: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};
