import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import useWindowWidth from '../../hooks/useWindowWidth';
import {
  getDisplayType,
  getUserAttribute,
  isLoggedIn,
} from '../../utils/helpers';
import { APP_ROUTES } from '../../route';
import { IMAGE_CONSTANT, TEXT_CONSTANT } from '../../constants/app';
import { useHistory, Link } from 'react-router-dom';

import '../Footer/style.css';

const Footer = ({ showFooter }) => {
  const hideAuthenticatedRoutes = !isLoggedIn();
  const isDesktopScreen = getDisplayType(useWindowWidth()) === 'desktop';
  const showNav = getUserAttribute('onboarding_completed');
  const history = useHistory();
  if (!isDesktopScreen && !hideAuthenticatedRoutes && !showFooter) {
    return null;
  }
  const boxShadow =
    history.location.pathname === '/freetrial'
      ? { boxShadow: '0 0 0 0.25rem rgba(20, 20, 43, 0.1)' }
      : undefined;
  const handleLogoClick = () => {
    if (showNav) {
      history.push(APP_ROUTES.HOME);
    } else {
      history.push(APP_ROUTES.SELF_ASSESSMENT);
    }
  };
  return (
    <React.Fragment>
      <footer id="footer" className="footer" style={boxShadow}>
        <Container>
          <Row>
            <Col md={6} className="mb-2">
              <div className="about-jen-div">
                <div className="footer-logo">
                  <h2 className="logo-text" onClick={handleLogoClick}>
                    Jen
                  </h2>
                </div>
                <div className="sap" />
                <div className="footer-content">
                  <h3>About Jen</h3>
                  <p className="m-0">{TEXT_CONSTANT.FOOTER_INFO_TEXT}</p>
                </div>
              </div>
            </Col>

            <Col md={6}>
              <Row className="position-relative">
                <Col xs={6} md={4}>
                  <div className="ms-lg-4 ms-0">
                    <ul className="list-unstyled m-0 footer-links">
                      <li>
                        <a
                          className="cursor-pointer"
                          href={undefined}
                          onClick={handleLogoClick}
                        >
                          Home
                        </a>
                      </li>
                      {!hideAuthenticatedRoutes && (
                        <>
                          <li>
                            <Link to={APP_ROUTES.SELF_ASSESSMENT}>
                              Self Assessments
                            </Link>
                          </li>
                          <li>
                            <Link to={APP_ROUTES.FOLDERS}>My Folder</Link>
                          </li>
                          <li>
                            <Link to={APP_ROUTES.PROFILE}>My Profile</Link>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </Col>
                <Col xs={6} md={4}>
                  <div className="d-flex justify-content-md-between flex-column h-100">
                    <ul className="list-unstyled footer-links">
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            window.location.origin + APP_ROUTES.PRIVACY_POLICY
                          }
                          // href="https://docs.google.com/document/d/1ljjX_9Y2muTkNl4Ew522RG2WGJX8nQB7_2K34rkgAKM/edit"
                        >
                          Privacy policy
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://docs.google.com/document/d/1kufaBw1KZ4f6pw5vwpWwPyr8qjXy4ToHxX5tbIKoDuM/edit?usp=sharing"
                        >
                          Refund policy
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://docs.google.com/document/d/1d2nS643SoO95afqaKZtL7EHxClNoACPsiFeqevS7PGQ/edit"
                        >
                          Terms of service
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="social-media-wrapper">
                    <span>Social links:</span>
                    <ul className="list-unstyled social-media d-flex align-items-center mb-sm-0 mb-3 mt-3">
                      <li>
                        <a
                          href="https://www.facebook.com/docjenfit"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="facebook"
                        >
                          <img
                            src={IMAGE_CONSTANT.facebookIcon}
                            className="img-fluid"
                            alt="app"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/docjenfit/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="instagram"
                        >
                          <img
                            src={IMAGE_CONSTANT.instagramIcon}
                            className="img-fluid"
                            alt="app"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCknKMzugCaPXD4AI6rq3wiQ"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="youtube"
                        >
                          <img
                            src={IMAGE_CONSTANT.youtubeIcon}
                            className="img-fluid"
                            alt="app"
                          />
                        </a>
                      </li>
                    </ul>
                    <div className="d-flex gap-2 flex-md-column align-items-start mtc mt-4 app-store-foot">
                      <a
                        href="https://apps.apple.com/in/app/jen/id1601368941"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={IMAGE_CONSTANT.appStoreIcon}
                          className="img-fluid"
                          alt="app"
                        />
                      </a>

                      <a
                        href="https://play.google.com/store/apps/details?id=com.mobilitymethod.jen"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={IMAGE_CONSTANT.playStoreIcon}
                          className="img-fluid"
                          alt="app"
                        />
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <div className="footer-bottom"></div>
      </footer>
    </React.Fragment>
  );
};

Footer.propTypes = {
  showFooter: PropTypes.bool,
};

export default Footer;
