import React from 'react';
import PropTypes from 'prop-types';

import { OfferWithButton } from '../OfferWithButton';

import './style.css';

export const Banner = ({
  backgroundColor,
  btnText,
  children,
  coupon,
  couponFirst,
  loading,
  onClick,
  sectionTitle,
  text,
}) => {
  return (
    <div className="lead-price-section" style={{ background: backgroundColor }}>
      <div className="price-text-block">{children}</div>
      <OfferWithButton
        btnText={btnText}
        coupon={coupon}
        couponFirst={couponFirst}
        loading={loading}
        onClick={onClick}
        sectionTitle={sectionTitle}
        text={text}
      />
    </div>
  );
};

Banner.propTypes = {
  backgroundColor: PropTypes.string,
  btnText: PropTypes.string.isRequired,
  children: PropTypes.node,
  coupon: PropTypes.string.isRequired,
  couponFirst: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  sectionTitle: PropTypes.string,
  text: PropTypes.string,
};
