export const DO_FOLDERS_REQUEST = 'DO_FOLDERS_REQUEST';
export const DO_FOLDERS_SUCCESS = 'DO_FOLDERS_SUCCESS';

export const DO_CREATE_FOLDER_REQUEST = 'DO_CREATE_FOLDER_REQUEST';
export const DO_CREATE_FOLDER_SUCCESS = 'DO_CREATE_FOLDER_SUCCESS';

export const DO_ADD_VIDEO_TO_FOLDER_REQUEST = 'DO_ADD_VIDEO_TO_FOLDER_REQUEST';
export const DO_ADD_VIDEO_TO_FOLDER_SUCCESS = 'DO_ADD_VIDEO_TO_FOLDER_SUCCESS';

export const DO_REMOVE_VIDEO_FROM_FOLDER_REQUEST =
  'DO_REMOVE_VIDEO_FROM_FOLDER_REQUEST';
export const DO_REMOVE_VIDEO_FROM_FOLDER_SUCCESS =
  'DO_REMOVE_VIDEO_FROM_FOLDER_SUCCESS';

export const DO_FOLDER_DETAIL_REQUEST = 'DO_FOLDER_DETAIL_REQUEST';
export const DO_FOLDER_DETAIL_SUCCESS = 'DO_FOLDER_DETAIL_SUCCESS';

export const DO_FOLDER_DELETE_REQUEST = 'DO_FOLDER_DELETE_REQUEST';
export const DO_FOLDER_DELETE_SUCCESS = 'DO_FOLDER_DELETE_SUCCESS';

export const DO_FOLDER_RENAME_REQUEST = 'DO_FOLDER_RENAME_REQUEST';
export const DO_FOLDER_RENAME_SUCCESS = 'DO_FOLDER_RENAME_SUCCESS';

export const RESET_FOLDERS = 'RESET_FOLDERS';
export const RESET_DETAILED_FOLDER = 'RESET_DETAILED_FOLDER';

export const FOLDERS_SET_IS_LOADING = 'FOLDERS_SET_IS_LOADING';

export const TOGGLE_FOLDER_OVERLAY = 'TOGGLE_FOLDER_OVERLAY';

export const DO_FETCH_ALL_FOLDERS_REQUEST = 'DO_FETCH_ALL_FOLDERS_REQUEST';
export const DO_FETCH_ALL_FOLDERS_SUCCESS = 'DO_FETCH_ALL_FOLDERS_SUCCESS';
