import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMAGE_CONSTANT, TEXT_CONSTANT } from '../../constants/app';
import { doFetchProductsRequest } from '../../store/action/products';
import { doChangeSubscriptionProduct } from '../../store/action/user';
import Button from '../../ui-core/Button/Button';
import ModalBox from '../../ui-core/Modal';
import { formatCurrency, getFormattedDate } from '../../utils/helpers';
import { SEGMENT_EVENTS, trackEvent } from '../../utils/Segment';

import './style.css';

// const getRenewlDate = (interval = 'year', activeOn) => {
//   const d = new Date(activeOn);
//   const year = d.getFullYear();
//   const month = d.getMonth();
//   const day = d.getDate();
//   if (interval === 'year') {
//     return new Date(year + 1, month, day);
//   } else {
//     return new Date(year, month + 6, day);
//   }
// };

const SwitchPlan = () => {
  const dispatch = useDispatch();
  const { payment, isLoading } = useSelector(state => state.user);
  const { products } = useSelector(state => state.product);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!products) {
      dispatch(doFetchProductsRequest());
    }
  }, []);

  const subscribeTo = useMemo(() => {
    return (products || []).find(
      product =>
        product?.attributes?.interval !== payment?.attributes?.interval,
    );
  }, [products, payment]);

  const planName = subscribeTo?.attributes?.name;

  const handleChangePlan = async () => {
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      page: 'Profile page',
      section_title: 'Switch Plan Modal',
      button_title:
        TEXT_CONSTANT.UPGRADE_TO + ' ' + (planName ? ' ' + planName : ''),
    });
    if (subscribeTo?.id) {
      const currentPlanName = payment?.attributes?.name;
      const switchedPlanName = subscribeTo?.attributes?.name;
      dispatch(
        doChangeSubscriptionProduct({
          callback: () => handleClose(),
          currentPlanName,
          product_id: subscribeTo.id,
          switchedPlanName,
        }),
      );
    }
  };

  const handleOpen = () => {
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      page: 'Profile page',
      section_title: 'Payments Section',
      button_title:
        TEXT_CONSTANT.SWITCH_PLAN + (planName ? ' ' + planName : ''),
    });
    setShow(true);
  };

  const handleClose = () => setShow(false);

  // const renewalDate = getRenewlDate(
  //   subscribeTo?.attributes?.interval,
  //   payment?.attributes?.active_on,
  // );

  return (
    <React.Fragment>
      <Button
        onClick={handleOpen}
        isDisabled={isLoading || !subscribeTo || !products}
        class="cancel-payment cursor-pointer"
        text={
          <>
            <span className="color-primary">
              {TEXT_CONSTANT.SWITCH_PLAN + (planName ? ' ' + planName : '')}
            </span>
            <img src={IMAGE_CONSTANT.chevronRight} alt="right" />
          </>
        }
      />
      {show && (
        <ModalBox
          show={show}
          dialogClassName={'switch-plan-model'}
          closeButton={true}
          handleClose={handleClose}
          backdrop={'static'}
        >
          <div className="text-center">
            <h4 className="switch-h4">
              {TEXT_CONSTANT.READY_TO_SWITCH +
                ' ' +
                (planName ? ' ' + planName : '') +
                '?'}
            </h4>
            <p>
              If so, your subscription will renew for{' '}
              {formatCurrency(
                +(subscribeTo?.attributes?.amount_in_cents || 0) / 100,
                subscribeTo?.attributes?.currency,
              )}{' '}
              on{' '}
              {getFormattedDate(
                payment?.attributes?.free_trial_cancelled_at ||
                  payment?.attributes?.subscription_cancelled_at ||
                  payment?.attributes?.ends_at,
                'MM-DD-YYYY',
              )}
            </p>
            <Button
              onClick={handleChangePlan}
              isDisabled={isLoading || !subscribeTo || !products}
              isLoading={isLoading}
              class="start-free-trail-btn w-100"
              text={
                TEXT_CONSTANT.SWITCH_TO + ' ' + (planName ? ' ' + planName : '')
              }
            />
            <p
              className="m-0 color-primary text-uppercase cursor-pointer"
              onClick={handleClose}
            >
              {TEXT_CONSTANT.CANCEL}
            </p>
          </div>
        </ModalBox>
      )}
    </React.Fragment>
  );
};

export default SwitchPlan;
