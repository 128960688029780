import { axiosInstance, overrideHeader } from './index';

export const saveFiltersRequest = async bodyParts => {
  const data = {
    filters: {
      body_parts: bodyParts,
    },
  };
  overrideHeader();
  return axiosInstance.post('/users/filters', data);
};
