import React from 'react';
import Button from '../../ui-core/Button/Button';
import { IMAGE_CONSTANT, TEXT_CONSTANT } from '../../constants/app';
import { useDispatch, useSelector } from 'react-redux';
import { doUpdatePaymentMethodRequest } from '../../store/action/products';

const UpdatePayment = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.product);
  const handleClick = () =>
    dispatch(
      doUpdatePaymentMethodRequest({
        name: encodeURI('Payment method'),
        page: encodeURI('/profile/payments'),
      }),
    );
  return (
    <Button
      onClick={handleClick}
      isDisabled={isLoading}
      isLoading={isLoading}
      class="cancel-payment cursor-pointer"
      text={
        <>
          <span className="color-primary">
            {TEXT_CONSTANT.UPDATE_PAYMENT_METHOD}
          </span>
          <img src={IMAGE_CONSTANT.chevronRight} alt="right" />
        </>
      }
    />
  );
};

export default UpdatePayment;
