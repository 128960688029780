import {
  DO_GET_QUESTIONNAIRES_FAILURE,
  DO_GET_QUESTIONNAIRES_REQUEST,
  DO_GET_QUESTIONNAIRES_SUCCESS,
  DO_POST_QUESTIONNAIRES_ANSWERS_REQUEST,
  DO_POST_QUESTIONNAIRES_ANSWERS_SUCCESS,
  DO_SUBMIT_QUESTIONNAIRE_ANSWER_REQUEST,
  QUESTIONNAIRES_SET_IS_LOADING,
  RESET_QUESTIONNAIRE_STATE,
  SET_ACTIVE_QUESTION,
  SET_ACTIVITY_INPUT_COUNTS,
  SET_FILLED_ANSWERS,
  SET_QUESTIONNAIRE_IS_ALREADY_ANSWERED,
  SET_SHOW_QUESTIONNAIRE_OVERLAY,
  SET_SHOW_SUB_QUESTION_MODAL,
  TOGGLE_POSTING_RESPOSNE,
  TRIGGER_FILL_ANSWER_FOR_QUESTION,
} from '../../constant/questionnaires';

export const setIsLoading = payload => ({
  type: QUESTIONNAIRES_SET_IS_LOADING,
  payload,
});

export const doGetQuestionnairesRequest = payload => ({
  type: DO_GET_QUESTIONNAIRES_REQUEST,
  payload,
});

export const doGetQuestionnairesSuccess = payload => ({
  type: DO_GET_QUESTIONNAIRES_SUCCESS,
  payload,
});

export const doGetQuestionnairesFailure = payload => ({
  type: DO_GET_QUESTIONNAIRES_FAILURE,
  payload,
});

export const doPostQuestionnairesAnswersRequest = payload => ({
  type: DO_POST_QUESTIONNAIRES_ANSWERS_REQUEST,
  payload,
});

export const doPostQuestionnairesAnswersSuccess = payload => ({
  type: DO_POST_QUESTIONNAIRES_ANSWERS_SUCCESS,
  payload,
});

export const setPostingResponse = payload => ({
  type: TOGGLE_POSTING_RESPOSNE,
  payload,
});

export const setShowQuestionnaireOverlay = payload => ({
  type: SET_SHOW_QUESTIONNAIRE_OVERLAY,
  payload,
});

export const setActiveQuestion = payload => ({
  type: SET_ACTIVE_QUESTION,
  payload,
});

export const setFilledAnswers = payload => ({
  type: SET_FILLED_ANSWERS,
  payload,
});

export const fillAnswerForQuestion = payload => ({
  type: TRIGGER_FILL_ANSWER_FOR_QUESTION,
  payload,
});

export const setShowSubQuestionModal = payload => ({
  type: SET_SHOW_SUB_QUESTION_MODAL,
  payload,
});

export const doSubmitQuestionnaireAnswerRequest = payload => ({
  type: DO_SUBMIT_QUESTIONNAIRE_ANSWER_REQUEST,
  payload,
});

export const setactivityInputs = payload => ({
  type: SET_ACTIVITY_INPUT_COUNTS,
  payload,
});

export const resetQuestionnaireState = () => ({
  type: RESET_QUESTIONNAIRE_STATE,
});

export const setQuestionnairealreadyAnsweredQuestions = payload => ({
  type: SET_QUESTIONNAIRE_IS_ALREADY_ANSWERED,
  payload,
});
