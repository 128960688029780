import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';

const ImageCropper = props => {
  const { imageType, imageUrl, setCurrentImageURL } = props;
  const imageRef = useRef(imageUrl);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    imageRef.current = imageUrl;
  }, [imageUrl]);

  const createImage = url =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', error => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return null;
    }

    canvas.width = image.width;
    canvas.height = image.height;

    ctx.drawImage(image, 0, 0);

    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
    );

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(data, 0, 0);

    return new Promise(resolve => {
      resolve(canvas.toDataURL(imageType));
    });
  };

  const onCropComplete = async (_, croppedAreaPixels) => {
    if (
      imageRef.current &&
      croppedAreaPixels.width &&
      croppedAreaPixels.height
    ) {
      const croppedImage = await getCroppedImg(
        imageRef.current,
        croppedAreaPixels,
      );
      setCurrentImageURL(croppedImage);
    }
  };

  return (
    <Cropper
      image={imageUrl}
      crop={crop}
      zoom={zoom}
      aspect={1}
      showGrid={false}
      onCropChange={setCrop}
      onCropComplete={onCropComplete}
      onZoomChange={setZoom}
      cropShape="round"
    />
  );
};

ImageCropper.propTypes = {
  imageType: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  setCurrentImageURL: PropTypes.func.isRequired,
};

export default React.memo(ImageCropper, () => {
  return true;
});
