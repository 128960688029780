import { axiosInstance, overrideHeader } from '.';

export const getQuestionnaireRequest = id => {
  overrideHeader();
  return axiosInstance.get(`/questionnaires/${id}`);
};

export const postQuestionnaireAnswerRequest = (id, data) => {
  overrideHeader();
  return axiosInstance.post(`/questionnaires/${id}/answer`, data);
};
