import dayjs from 'dayjs';

export const DATE_FORMATS = {
  YYYY_MM_DD: 'yyyy/MM/dd',
  UNIX_EPOCH: 'UNIX_EPOCH',
  DD_MMM_YYYY: 'DD MMM YYYY',
};

export const formatDate = (format, _date = undefined) => {
  const date = _date ? dayjs(_date, format) : dayjs();
  switch (format) {
    case DATE_FORMATS.UNIX_EPOCH:
      return date.unix();
    default:
      return date.format(format);
  }
};
