import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { CHALLENGE_REDIRECT_TO } from '../constants/app';

const SpecialRoutesRedirection = ({ exact, path }) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={({ location }) => {
        const pathname = CHALLENGE_REDIRECT_TO[path];
        return (
          <Redirect
            exact={exact}
            to={{
              pathname,
              search: location.search,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

SpecialRoutesRedirection.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string,
};

export default SpecialRoutesRedirection;
