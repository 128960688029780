import {
  DO_BUY_GIFT_REQUEST,
  DO_GET_GIFT_PRODUCTS_REQUEST,
  DO_GET_GIFT_PRODUCTS_SUCCESS,
  GIFT_SET_IS_LOADING,
  SET_SELECTED_GIFT_PRODUCTS,
  TOGGLE_SHOW_GIFT_MODAL,
} from '../../constant/gift';

export const doFetchGiftProductsRequest = payload => {
  return {
    type: DO_GET_GIFT_PRODUCTS_REQUEST,
    payload,
  };
};

export const doFetchGiftProductsSuccess = payload => {
  return {
    type: DO_GET_GIFT_PRODUCTS_SUCCESS,
    payload,
  };
};

export const doBuyGiftRequest = payload => {
  return {
    type: DO_BUY_GIFT_REQUEST,
    payload,
  };
};

export const setIsLoading = payload => {
  return {
    type: GIFT_SET_IS_LOADING,
    payload,
  };
};

export const setSelectedGiftProducts = payload => ({
  type: SET_SELECTED_GIFT_PRODUCTS,
  payload,
});

export const toggleShowGiftModal = payload => ({
  type: TOGGLE_SHOW_GIFT_MODAL,
  payload,
});
