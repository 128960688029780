import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import {
  DO_MY_PLANS_REQUEST,
  DO_GET_RECOMMENDATION_REQUEST,
  DO_GET_USER_DATA_REQUEST,
  DO_UPDATE_USER_PROFILE_REQUEST,
  DO_HANDLE_CREATE_EXCERCISE_PLAN_REQUEST,
  DO_GET_PAYMENT_DETAIL_REQUEST,
  DO_CANCEL_SUBSCRIPTION_REQUEST,
  DO_PAYMENT_RESTART_REQUEST,
  DO_CHANGE_SUBSCRIPTION_PRODUCT,
  DO_REMOVE_RECOMMENDATION_REQUEST,
  DO_FETCH_ALL_CATEGORIES_REQUEST,
  DO_FETCH_BANNER_REQUEST,
} from '../../constant/user';
import {
  doFetchBannerSuccess,
  doFetchCategoriesSuccess,
  doGetPaymentDetailRequest,
  doGetRecommendationsSuccess,
  doGetUserDataSuccess,
  doMyPlansSuccess,
  doRemoveRecommendationsSuccess,
  setIsBannerLoading,
  setIsLoading,
  setPaymentDetail,
} from '../../action/user';
import {
  myPlansGetRequest,
  doGetRecommendationRequest,
  getUserRequest,
  updateUserProfileRequest,
  paymentRestartRequest,
  changeSubscriptionProductRequest,
  doRemoveRecommendationRequest,
  fetchCategoriesRequest,
  fetchBannerRequest,
} from '../../../api/user';
import {
  createNotification,
  NOTIFICATION_TYPE,
  SUCCESS_MESSAGES,
} from '../../../services/Notification';
import { extractJSON, saveJSON } from '../../../services/jsonUtil';
import { LOCAL_STORAGE_CONSTANT } from '../../../constants/app';
import { onboardingCompleteRequest } from '../../../api/auth';
import {
  errorHandler,
  formatCurrency,
  getUserAttribute,
  logOutput,
} from '../../../utils/helpers';
import { APP_ROUTES } from '../../../route';
import {
  cancelSubscriptionRequest,
  fetchPaymentDetailsRequest,
} from '../../../api/payment';
import {
  identifyUser,
  SEGMENT_EVENTS,
  trackEvent,
} from '../../../utils/Segment';

function* myPlansRequest({ payload }) {
  yield put(setIsLoading(true));
  try {
    const myPlans = extractJSON(LOCAL_STORAGE_CONSTANT.MY_PLANS);
    if (myPlans) {
      yield put(doMyPlansSuccess(myPlans));
      payload?.callback && payload?.callback(myPlans);
    } else {
      const response = yield call(myPlansGetRequest);
      yield put(doMyPlansSuccess(response.data.response.data));
      saveJSON(LOCAL_STORAGE_CONSTANT.MY_PLANS, response.data.response.data);
      payload?.callback && payload?.callback(response.data.response.data);
    }
  } catch (error) {
    createNotification(errorHandler(error));
  }
  yield put(setIsLoading(false));
}

function* getRecommendations({ payload }) {
  const { callback } = payload || {};
  try {
    const recommendations = extractJSON(
      LOCAL_STORAGE_CONSTANT.RECOMMENDED_PLANS,
    );
    if (recommendations) {
      yield put(doGetRecommendationsSuccess(recommendations));
    } else {
      const response = yield call(doGetRecommendationRequest);
      yield put(doGetRecommendationsSuccess(response.data.response.data));
      saveJSON(
        LOCAL_STORAGE_CONSTANT.RECOMMENDED_PLANS,
        response.data.response.data,
      );
    }
  } catch (error) {
    createNotification(errorHandler(error));
  } finally {
    if (callback) callback();
  }
}

function* getUser({ payload }) {
  let { userId, callback } = payload || {};
  if (!userId) {
    const jwebUser = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
    if (jwebUser?.id) {
      userId = jwebUser.id;
    } else {
      return;
    }
  }
  yield put(setIsLoading(true));
  try {
    const response = yield call(getUserRequest, userId);
    if (response?.data?.response?.data) {
      yield put(doGetUserDataSuccess(response.data.response.data));
      const userObject = response.data.response.data;
      // userObject.attributes.onboarding_completed = false;
      saveJSON(LOCAL_STORAGE_CONSTANT.USER, userObject);
      if (callback) {
        callback();
      }
    }
  } catch (error) {
    if (callback) {
      callback(error);
    }
    createNotification(errorHandler(error));
  }
  yield put(setIsLoading(false));
}

function* updateUserProfile({ payload }) {
  const { data, callback } = payload || {};
  if (data.mobile) data.mobile = data.mobile.replace(data.country_code, '');
  const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
  yield put(setIsLoading(true));
  try {
    const response = yield call(updateUserProfileRequest, user.id, data);
    if (response?.data?.response?.data) {
      const userObject = response.data.response.data;
      // userObject.attributes.onboarding_completed = false;
      saveJSON(LOCAL_STORAGE_CONSTANT.USER, userObject);
      createNotification({
        type: NOTIFICATION_TYPE.SUCCESS,
        message: data.profile_pic
          ? SUCCESS_MESSAGES.PROFILE_PIC_UPDATE_SUCCESS
          : SUCCESS_MESSAGES.USER_PROFILE_UPDATE_SUCCESS,
      });
      if (callback) callback();
      yield put(doGetUserDataSuccess(userObject));
    }
  } catch (error) {
    createNotification(errorHandler(error));
  }
  yield put(setIsLoading(false));
}

function* handleCreateExcercisePlan({ payload }) {
  const { history, callback, page } = payload || {};
  const onBoardingCompleted = getUserAttribute('onboarding_completed');
  if (onBoardingCompleted) {
    const { selectedFilters } = yield select(state => state.common);
    const { video } = yield select(state => state.video);
    callback && callback(selectedFilters, video);
    if (history) {
      history.push(APP_ROUTES.HOME);
    }
  } else {
    try {
      const response = yield call(onboardingCompleteRequest);
      if (response?.status === 204) {
        const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
        user.attributes.onboarding_completed = true;
        saveJSON(LOCAL_STORAGE_CONSTANT.USER, user);
        const { selectedFilters } = yield select(state => state.common);
        const { video } = yield select(state => state.video);
        callback && callback(selectedFilters, video);
        trackEvent(SEGMENT_EVENTS.ONBOARDING_COMPLETE, {
          page,
          user_id: user.id,
        });
        if (history) {
          history.push(APP_ROUTES.HOME);
        }
      }
    } catch (error) {
      logOutput('ERROR:', { error });
    }
  }
}

function* getPaymentDetail({ payload }) {
  const { callback } = payload || {};
  try {
    const response = yield call(fetchPaymentDetailsRequest);
    yield put(setPaymentDetail(response.data.response.data));
    callback && callback(response.data.response.data);
  } catch (error) {
    createNotification(errorHandler(error));
    callback && callback();
  }
}

function* cancelSubscription({ payload }) {
  const { callback } = payload || {};
  try {
    const response = yield call(cancelSubscriptionRequest);
    const { payment, user } = yield select(state => state.user);
    const { selectedPlan } = yield select(state => state.product);
    createNotification({
      type: NOTIFICATION_TYPE.SUCCESS,
      message: payment?.attributes?.payment_mode
        ? SUCCESS_MESSAGES.SUBSCRIPTION_CANCELLED
        : SUCCESS_MESSAGES.FREE_TRIAL_CANCELLED,
    });
    const result = response.data.response.data;
    yield put(setPaymentDetail(result));
    trackEvent(SEGMENT_EVENTS.SUBSCRIPTION_CANCELLED, {
      user_id: user.id,
      subscription_duration: selectedPlan.attributes.interval,
      free_trial_days: selectedPlan.attributes.free_trial_days,
      subscription_amount: formatCurrency(
        Number(selectedPlan.attributes.amount_in_cents) / 100,
        selectedPlan.attributes.currency,
      ),
      cancelled_at: payment?.attributes?.payment_mode
        ? result.attributes.free_trial_cancelled_at
        : result.attributes.subscription_cancelled_at,
    });
  } catch (error) {
    createNotification(errorHandler(error));
  }
  callback && callback();
}

function* restartPayment() {
  yield put(setIsLoading(true));
  try {
    const response = yield call(paymentRestartRequest);
    createNotification({
      type: NOTIFICATION_TYPE.SUCCESS,
      message: SUCCESS_MESSAGES.PAYMENT_RESTARTED,
    });
    yield put(setPaymentDetail(response.data.response.data));
  } catch (error) {
    createNotification(errorHandler(error));
  }
  yield put(setIsLoading(false));
}

function* changeSubscriptionProduct({ payload }) {
  const { callback, currentPlanName, product_id, switchedPlanName } =
    payload || {};
  yield put(setIsLoading(true));
  try {
    const response = yield call(changeSubscriptionProductRequest, product_id);
    yield put(doGetPaymentDetailRequest({ callback }));
    createNotification({
      type: NOTIFICATION_TYPE.SUCCESS,
      message: SUCCESS_MESSAGES.PLAN_SWITCHED,
    });
    trackEvent(SEGMENT_EVENTS.PLANS_SWITCH_SUCCESS, {
      current_plan: currentPlanName,
      switched_plan: switchedPlanName,
      pricing_amount: response?.data?.response?.data?.attributes?.amount,
    });
    trackEvent(SEGMENT_EVENTS.SUBSCRIPTION_PLAN_CHANGED, {
      current_plan: currentPlanName,
      switched_plan: switchedPlanName,
      pricing_amount: response?.data?.response?.data?.attributes?.amount,
    });
    const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
    const user_id = user?.id;
    if (user_id) {
      identifyUser(user_id, {
        pricing_plan: response?.data?.response?.data?.attributes?.name,
        pricing_amount: +response?.data?.response?.data?.attributes?.amount,
        user_id,
      });
    }
  } catch (error) {
    createNotification(errorHandler(error));
    trackEvent(SEGMENT_EVENTS.PLANS_SWITCH_FAILURE, {
      current_plan: currentPlanName,
      switched_plan_name: switchedPlanName,
    });
  }
  yield put(setIsLoading(false));
}

function* removeRecommendation({ payload }) {
  const { callback, planId } = payload || {};
  const { recommendations } = yield select(state => state.user);
  try {
    yield call(doRemoveRecommendationRequest, planId);
    createNotification({
      type: NOTIFICATION_TYPE.SUCCESS,
      message: SUCCESS_MESSAGES.REMOVE_SUCCESS,
    });
    yield put(
      doRemoveRecommendationsSuccess(
        recommendations.filter(item => item.id !== planId),
      ),
    );
  } catch (error) {
    createNotification(errorHandler(error));
  } finally {
    if (callback) callback();
  }
}

function* getAllCategories() {
  yield put(setIsLoading(true));
  try {
    const response = yield call(fetchCategoriesRequest);
    const data = response?.data?.response?.data || {};
    yield put(doFetchCategoriesSuccess(data));
  } catch (error) {
    // createNotification(errorHandler(error));
  }
  yield put(setIsLoading(false));
}

function* getBanner() {
  yield put(setIsBannerLoading(true));
  try {
    const response = yield call(fetchBannerRequest);
    const data = response?.data?.response?.data || {};
    yield put(doFetchBannerSuccess(data));
  } catch (error) {
    // createNotification(errorHandler(error));
  }
  yield put(setIsBannerLoading(false));
}

/**
 * Watch user actions
 * */
export default function* user() {
  yield all([
    takeLatest(DO_MY_PLANS_REQUEST, myPlansRequest),
    takeLatest(DO_GET_RECOMMENDATION_REQUEST, getRecommendations),
    takeLatest(DO_GET_USER_DATA_REQUEST, getUser),
    takeLatest(DO_UPDATE_USER_PROFILE_REQUEST, updateUserProfile),
    takeLatest(
      DO_HANDLE_CREATE_EXCERCISE_PLAN_REQUEST,
      handleCreateExcercisePlan,
    ),
    takeLatest(DO_GET_PAYMENT_DETAIL_REQUEST, getPaymentDetail),
    takeLatest(DO_CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription),
    takeLatest(DO_PAYMENT_RESTART_REQUEST, restartPayment),
    takeLatest(DO_CHANGE_SUBSCRIPTION_PRODUCT, changeSubscriptionProduct),
    takeLatest(DO_REMOVE_RECOMMENDATION_REQUEST, removeRecommendation),
    takeLatest(DO_FETCH_ALL_CATEGORIES_REQUEST, getAllCategories),
    takeLatest(DO_FETCH_BANNER_REQUEST, getBanner),
  ]);
}
