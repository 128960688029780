import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Col, Row } from 'react-bootstrap';

import Button from '../../../../ui-core/Button/Button';
import LazyImage from '../../../../ui-core/Image';
import SubscriptionSelector from '../../../../components/PaymentModal/SubscriptionSelector';

import { TEXT_CONSTANT } from '../../../../constants/app';
import { banner, bannerLow, bannerWebp } from '../../images';

import './style.css';
// import { Offer } from '../Offer';

export const JoinPlan = props => {
  const { isLoading } = props;

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(
        TEXT_CONSTANT.START_FREE_TRAIL,
        'isSignup',
        'Join the 30 Day Full Body Mobility Plan on Jen Health!',
      );
    }
  };

  return (
    <Row className="full-body-mobility row-margin-zero">
      <Col
        className="d-flex justify-content-center align-items-center px-4"
        xs={12}
        md={6}
      >
        <div className="full-body-div text-start d-flex flex-column">
          <h2 className="bold">
            Join the{' '}
            <span className="text-white">30 Day Full Body Mobility Plan</span>{' '}
            on Jen Health!
          </h2>
          <p>{parse(TEXT_CONSTANT.FULL_BODY_MOBILITY_DESCRIPTION)}</p>
          {/* <Offer coupon="MOBILITY" mode="light" /> */}
          <SubscriptionSelector />
          <Button
            onClick={handleClick}
            isDisabled={isLoading}
            isLoading={isLoading}
            text={TEXT_CONSTANT.START_FREE_TRAIL}
            class="sign-up-button-new d-inline-block mb-3 text-uppercase"
          />
        </div>
      </Col>
      <Col className="p-0 vid-wrapper" xs={12} md={6}>
        <LazyImage
          errorSrc={banner}
          placeholderSrc={bannerLow}
          src={bannerWebp}
          className="w-100 img-fluid mx-2"
        />
      </Col>
    </Row>
  );
};

JoinPlan.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};
