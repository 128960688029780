import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import './style.css';

const LazyImage = props => {
  const [loaded, setLoaded] = React.useState(false);
  const handleImageLoaded = () => {
    setLoaded(true);
  };
  const handleError = e => {
    if (e.target) {
      e.target.src = props.errorSrc;
    }
    if (props.onError) props.onError();
  };
  return (
    <React.Fragment>
      <img
        className={clsx(!loaded && 'd-none', props.className)}
        src={props.src}
        alt={props.alt}
        onClick={props.onClick}
        onError={handleError}
        onLoad={handleImageLoaded}
        data-tour-id={props.dataTourId}
      />
      {!loaded && (
        <img
          className={clsx(loaded && 'd-none', 'opacity-25', props.className)}
          src={props.placeholderSrc}
          alt={props.alt}
        />
      )}
    </React.Fragment>
  );
};

LazyImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  errorSrc: PropTypes.string.isRequired,
  onError: PropTypes.func,
  onClick: PropTypes.func,
  src: PropTypes.string.isRequired,
  placeholderSrc: PropTypes.string.isRequired,
  dataTourId: PropTypes.string,
};

export default LazyImage;
