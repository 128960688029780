import { axiosInstance, overrideHeader } from '.';
import { isNotNullOrUndefined } from '../utils/helpers';

export const getChallengeList = () => {
  overrideHeader();
  return axiosInstance.get('/challenges');
};

export const getChallengeDetail = (id, options) => {
  overrideHeader();
  const queryParams = new URLSearchParams();
  Object.entries(options).forEach(([key, val]) => {
    if (isNotNullOrUndefined(val)) queryParams.append(key, val);
  });
  return axiosInstance.get(`/challenges/${id}?${queryParams.toString()}`);
};

export const publicGetChallenges = () => {
  return axiosInstance.get('/challenges/public');
};
