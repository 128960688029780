import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import { ShouldRender } from '../../ui-core/ShouldRender';

import {
  doToggleCartModal,
  doFetchCartRequest,
} from '../../store/action/checkout';
import { SEGMENT_EVENTS, trackEvent } from '../../utils/Segment';
import { IMAGE_CONSTANT } from '../../constants/app';
import { getPageName } from '../../utils/helpers';

import './style.css';

const CheckoutCart = ({ history, showCheckoutText }) => {
  const dispatch = useDispatch();
  const { cart } = useSelector(state => state.checkout);
  const cartItems = useMemo(() => {
    if (!cart) return [];
    return cart?.attributes?.selected || [];
  }, [cart]);
  const page = useMemo(() => {
    return getPageName(history.location.pathname);
  }, [history.location.pathname]);

  const itemsCount = useMemo(() => {
    return cartItems?.length || 0;
  }, [cartItems]);

  useEffect(() => {
    if (cart === null) {
      dispatch(doFetchCartRequest());
    }
  }, []);

  const handleClick = () => {
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      button_title: 'Cart Icon',
      page,
      section_title: 'Header Bar',
    });
    dispatch(doToggleCartModal(true));
  };

  return (
    <React.Fragment>
      <Button
        className="p-2 cart-icon d-flex flex-column justify-content-between align-items-center"
        onClick={handleClick}
        variant="light"
      >
        <img alt="cart" src={IMAGE_CONSTANT.shoppingCartIcon} />
        <ShouldRender check={itemsCount > 0}>
          <span className="items-count">{itemsCount}</span>
        </ShouldRender>
        <ShouldRender check={showCheckoutText}>
          <span>Checkout</span>
        </ShouldRender>
      </Button>
    </React.Fragment>
  );
};

CheckoutCart.propTypes = {
  history: PropTypes.object,
  showCheckoutText: PropTypes.bool,
};

CheckoutCart.defaultProps = {
  showCheckoutText: false,
};

export default withRouter(CheckoutCart);
