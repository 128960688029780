import React from 'react';
import PropTypes from 'prop-types';
import '../DoctorBox/style.css';

const DoctorBox = props => {
  return (
    <div className="item">
      <div className="itemInner">
        <div>
          <div className="doctor rounded-circle">
            <picture>
              <source type="image/webp" srcSet={props.profileWebP} />
              <img
                src={props.profile}
                className="img-fluid"
                alt="Testimonial"
              />
            </picture>
          </div>
          <p className="advice">{props.text}</p>
        </div>
        <div>
          <p className="name mb-0">{props.name}</p>
          <p className="deg mb-0">{props.quali}</p>
        </div>
      </div>
    </div>
  );
};

DoctorBox.propTypes = {
  name: PropTypes.string,
  profileWebP: PropTypes.string,
  text: PropTypes.string,
  quali: PropTypes.string,
  profile: PropTypes.string,
};

export default DoctorBox;
