import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  DO_FETCH_BLOG_REQUEST,
  DO_FETCH_BLOG_CATEGORY_REQUEST,
  DO_FETCH_DETAIL_BLOG_REQUEST,
  DO_FETCH_BLOG_BY_CATEGORY_REQUEST,
} from '../../constant/blog';

import {
  requestGetAllBlogs,
  requestGetAllCategories,
  requestGetBlogDetail,
  requestGetBlogsByCategory,
} from '../../../api/strapi';

import { createNotification } from '../../../services/Notification';
import { errorHandler } from '../../../utils/helpers';

import {
  setIsLoading,
  doFetchAllBlogSuccess,
  doFetchAllSpotlightBlogSuccess,
  doFetchAllBlogcategorySuccess,
  doFetchDetailBlogSuccess,
  doFetchBlogsByCategorySuccess,
  setBlogByCategoryIsLoading,
  doFetchAllBlogMetaSuccess,
  setBlogDetailIsLoading,
} from '../../action/blog';

function* fetchAllBlog(action) {
  const { payload } = action;
  yield put(setIsLoading(true));
  try {
    const response = yield call(
      requestGetAllBlogs,
      {
        populate: '*',
      },
      payload?.page || 1,
      5,
    );
    yield put(doFetchAllBlogSuccess(response?.data));
    const response2 = yield call(requestGetAllBlogs, {
      'filters[$and][0][spotlight][$eq]': true,
      populate: '*',
    });
    yield put(doFetchAllSpotlightBlogSuccess(response2?.data));
  } catch (error) {
    console.log('ERROR:', error);
    createNotification(errorHandler(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* fetchAllBlogCategory() {
  // yield put(setIsLoading(true));
  try {
    const flag = true;
    const response = yield call(requestGetAllCategories, flag);
    yield put(doFetchAllBlogcategorySuccess(response?.data));
  } catch (error) {
    console.log('ERROR:', error);
    createNotification(errorHandler(error));
  } finally {
    // yield put(setIsLoading(false));
  }
}

function* fetchGetBlogDetail(action) {
  yield put(setBlogDetailIsLoading(true));
  try {
    const flag = true;
    const { payload } = action;
    const response = yield call(requestGetBlogDetail, payload, flag);
    yield put(
      doFetchDetailBlogSuccess(response?.data || { message: 'No blog found' }),
    );
  } catch (error) {
    console.log('ERROR:', error);
    yield put(doFetchDetailBlogSuccess({ message: 'No blog found' }));
    // createNotification(errorHandler(error));
  } finally {
    yield put(setBlogDetailIsLoading(false));
  }
}

function* fetchGetBlogsByCategory(action) {
  yield put(setBlogByCategoryIsLoading(true));
  try {
    const { payload } = action;
    const response = yield call(
      requestGetBlogsByCategory,
      payload.param,
      payload.query,
      payload?.page || 1,
      5,
      payload?.filters,
    );
    yield put(doFetchBlogsByCategorySuccess(response?.data));
    yield put(doFetchAllBlogMetaSuccess(response?.meta));
  } catch (error) {
    console.log('ERROR:', error);
    createNotification(errorHandler(error));
  } finally {
    yield put(setBlogByCategoryIsLoading(false));
  }
}

/**
 * Watch blogs actions
 * */
export default function* blogSaga() {
  yield all([
    takeLatest(DO_FETCH_BLOG_REQUEST, fetchAllBlog),
    takeLatest(DO_FETCH_BLOG_CATEGORY_REQUEST, fetchAllBlogCategory),
    takeLatest(DO_FETCH_DETAIL_BLOG_REQUEST, fetchGetBlogDetail),
    takeLatest(DO_FETCH_BLOG_BY_CATEGORY_REQUEST, fetchGetBlogsByCategory),
  ]);
}
