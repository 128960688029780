export const getUrlTitle = (value, replaceWith = '') => {
  if (!value) {
    return value;
  }
  value = value
    .replace(/ /g, '-')
    .toLowerCase()
    .replace(/[^0-9a-z-_]/gi, replaceWith);
  if (value[value.length - 1] === '-') {
    return value.slice(0, value.length - 1);
  }
  return value;
};

export const getDuration = value => {
  if (!value) {
    return value;
  }
  value = value.split(':');
  if (value.length === 3 && +value[0] === 0) {
    value = value.slice(1);
  }
  value = value.map(item => item.slice(0, 2)).join(':');
  return value;
};

export const formatPlayBackTime = seconds => {
  if (typeof seconds === 'string') return seconds;
  return new Date(seconds * 1000).toISOString().substring(11, 23);
};

export const getSecondsFromTime = time => {
  if (!time) return time;
  const arr = time.split(':');
  const seconds = +arr[0] * 60 * 60 + +arr[1] * 60 + +arr[2];
  return seconds;
};

export const getProgressBarWidth = (playbackEndedAt, duration) => {
  duration = parseInt(getSecondsFromTime(duration) ?? 0);
  playbackEndedAt = parseInt(getSecondsFromTime(playbackEndedAt) ?? 0);
  if (duration) {
    return ((playbackEndedAt / duration) * 100).toFixed(2) + '%';
  } else {
    return '0%';
  }
};
