import React from 'react';

const QuoteIcon = () => {
  return (
    <svg
      width="40"
      height="32"
      viewBox="0 0 40 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.80645 32C7.91398 32 6.36559 31.619 5.16129 30.8571C3.95699 30.0952 2.96774 29.1217 2.19355 27.9365C1.33333 26.4974 0.731183 25.0159 0.387097 23.4921C0.129032 21.8836 0 20.5714 0 19.5556C0 15.4074 1.07527 11.6402 3.22581 8.25397C5.37634 4.86773 8.73118 2.11641 13.2903 0L14.4516 2.28572C11.7849 3.38625 9.46237 5.1217 7.48387 7.49206C5.5914 9.86244 4.64516 12.2751 4.64516 14.7302C4.64516 15.746 4.77419 16.6349 5.03226 17.3968C6.4086 16.2963 8 15.746 9.80645 15.746C12.043 15.746 13.9785 16.4656 15.6129 17.9048C17.2473 19.3439 18.0645 21.3333 18.0645 23.873C18.0645 26.2434 17.2473 28.1905 15.6129 29.7143C13.9785 31.2381 12.043 32 9.80645 32ZM31.7419 32C29.8495 32 28.3011 31.619 27.0968 30.8571C25.8925 30.0952 24.9032 29.1217 24.129 27.9365C23.2688 26.4974 22.6667 25.0159 22.3226 23.4921C22.0645 21.8836 21.9355 20.5714 21.9355 19.5556C21.9355 15.4074 23.0108 11.6402 25.1613 8.25397C27.3118 4.86773 30.6667 2.11641 35.2258 0L36.3871 2.28572C33.7204 3.38625 31.3979 5.1217 29.4194 7.49206C27.5269 9.86244 26.5806 12.2751 26.5806 14.7302C26.5806 15.746 26.7097 16.6349 26.9677 17.3968C28.3441 16.2963 29.9355 15.746 31.7419 15.746C33.9785 15.746 35.914 16.4656 37.5484 17.9048C39.1828 19.3439 40 21.3333 40 23.873C40 26.2434 39.1828 28.1905 37.5484 29.7143C35.914 31.2381 33.9785 32 31.7419 32Z"
        fill="#BCC1CD"
      />
    </svg>
  );
};

export default QuoteIcon;
