import {
  DO_SELF_ASSESSMENT_REQUEST,
  DO_SELF_ASSESSMENT_SUCCESS,
  DO_SELF_ASSESSMENT_FAILURE,
  SELF_ASSESSMENT_SET_IS_LOADING,
} from '../../constant/selfAssessment';

export const doSelfAssessmentRequest = payload => {
  return {
    type: DO_SELF_ASSESSMENT_REQUEST,
    payload,
  };
};

export const doSelfAssessmentSuccess = payload => ({
  type: DO_SELF_ASSESSMENT_SUCCESS,
  payload,
});

export const doSelfAssessmentFailure = () => ({
  type: DO_SELF_ASSESSMENT_FAILURE,
});

export const setIsLoading = payload => ({
  type: SELF_ASSESSMENT_SET_IS_LOADING,
  payload,
});
