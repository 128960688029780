import { axiosInstance, overrideHeader } from '.';

export const fetchPaymentDetailsRequest = () => {
  overrideHeader();
  return axiosInstance.get('/payments/details');
};

export const cancelSubscriptionRequest = () => {
  overrideHeader();
  return axiosInstance.post('/payments/cancel');
};
