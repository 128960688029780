import { LOCAL_STORAGE_CONSTANT } from '../constants/app';
import { extractJSON } from '../services/jsonUtil';
import { logOutput } from './helpers';

const initialValue = {
  identify: () => {},
  track: () => {},
  reset: () => {},
  page: () => {},
};

export const SEGMENT_EVENTS = {
  AUTH_FLOW_INITIATED: 'Authflow_Initiated',
  BODY_PART_SELECTED: 'Body_Part_Selected',
  BUTTON_CLICKED: 'Button_Clicked',
  COLLECTION_CLICKED: 'Collection_Clicked',
  ERROR_SENDING_VERIFICATION_CODE: 'Error_Sending_Verification_Code',
  ERROR_VERIFYING_CODE: 'Error_Verifying_Code',
  FOLDER_BLOCK_CLICKED: 'Folder_Block_Clicked',
  FOLDER_CREATED: 'Folder_Created',
  FOLDER_EDITED: 'Folder_Edited',
  FREE_TRIAL_CANCELLED: 'Free_Trial_Cancelled',
  FREE_TRIAL_STARTED: 'Free_Trial_Started',
  LOGOUT: 'Logout',
  MORE_RECOMMENDATIONS: 'More_Recommendations',
  ONBOARDING_QUESTIONARE_SUBMITTED: 'Onboarding_Questionare_Completed',
  ONBOARDING_COMPLETE: 'Onboarding_Complete',
  PLAN_CLICKED: 'Plan_Clicked',
  PLAN_DAY_CLICKED: 'Plan_Day_Clicked',
  PLAN_DAY_COMPLETED: 'Plan_Day_Completed',
  PLAN_DAY_RESUMED: 'Plan_Day_Resumed',
  PLAN_DAY_STARTED: 'Plan_Day_Started',
  PROFILE_UPDATED: 'Profile_Updated',
  PRODUCT_TOUR_STEP_COMPLETED: 'Product_Tour_Step_Completed',
  REJOIN_MEMBERSHIP: 'Rejoin Membership',
  REQUESTED_VERIFICATION_CODE: 'Requested_Verification_Code',
  RESEND_VERIFICATION_CODE: 'Resend_Verification_Code',
  SELECT_PLANS: 'SELECT_PLANS',
  SHOW_EXCERCISE_PLANS: 'Show_Exercise_Plans',
  SKIP_ASSESSMENT: 'Skip_Assessment',
  START_FREE_TRIAL: 'Start_Free_Trial',
  SUBSCRIPTION_CANCELLED: 'Subscription_Cancelled',
  TRY_MORE_SELF_ASSESSMENTS: 'Try_More_Self_Assessments',
  USER_LOGIN_SUCCESSFUL: 'User_Login_Successful',
  USER_SIGNUP_SUCCESSFUL: 'User_Signup_Successful',
  VERIFY_CODE: 'Verify_Code',
  VIDEO_CLICKED: 'Video_Clicked',
  VIDEO_PLAYBACK_ENDED: 'Video_Playback_Ended',
  VIDEO_PLAYBACK_PAUSED: 'Video_Playback_Paused',
  VIDEO_PLAYBACK_PLAYING: 'Video_Playback_Playing',
  VIDEO_PLAYBACK_RESUMED: 'Video_Playback_Resumed',
  VIDEO_SAVED: 'Video_Saved',
  VIDEO_UNSAVED: 'Video_Unsaved',
  VIEW_PRICING_PLANS: 'View_Pricing_Plans',
  WAITLIST_MODAL_SCREEN: 'Waitlist_Modal_Screen',
  WAITLIST_SURVEY_SUBMITTED: 'Waitlist_Survey_Submitted',
  PLANS_SWITCH_FAILURE: 'Switch_Plan_Failure',
  PLANS_SWITCH_SUCCESS: 'Switch_Plan_Success',
  SUBSCRIPTION_PLAN_CHANGED: 'Subscription_Plan_Changed',
  CHALLENGE_CLICKED: 'Challenge_Clicked',
  CHALLENGE_DAY_CLICKED: 'Challenge_Day_Clicked',
  CHALLENGE_DAY_COMPLETED: 'Challenge_Day_Completed',
  CHALLENGE_DAY_RESUMED: 'Challenge_Day_Resumed',
  CHALLENGE_DAY_STARTED: 'Challenge_Day_Started',
  COURSE_CLICKED: 'Course_Clicked',
  COURSE_DAY_CLICKED: 'Course_Day_Clicked',
  COURSE_DAY_COMPLETED: 'Course_Day_Completed',
  COURSE_DAY_RESUMED: 'Course_Day_Resumed',
  COURSE_DAY_STARTED: 'Course_Day_Started',
  PRODUCT_ADD_TO_CART: 'Product_Add_To_Cart',
  PRODUCT_REMOVE_FROM_CART: 'Product_Remove_From_Cart',
  CREATE_ORDER: 'Create_Order',
  PURCHASE: 'Purchase',
};

const displayLogs = (eventType, props) => {
  logOutput('Segment', {
    eventType,
    ...props,
  });
};

function getCustomSegmentOptions() {
  const customOptions = {};

  const userData = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
  if (userData?.attributes) {
    const countryCode = (userData?.attributes?.country_code || '').slice(1);
    const email = userData?.attributes?.email || '';
    const phone = userData?.attributes?.mobile || '';
    const [firstName, lastName] = (userData?.attributes?.name || '').split(' ');
    customOptions.context = {
      traits: {
        firstName,
        lastName,
        phone: countryCode && phone ? countryCode + phone : '',
        email,
      },
    };
  }
  return customOptions;
}

export const trackDisplayedEvent = (title, properties) => {
  const segment = window?.analytics || initialValue;
  const customOptions = getCustomSegmentOptions();
  segment?.track(title, properties, customOptions);
  displayLogs('Segment displayed track', { title, properties, customOptions });
};

export const trackEvent = (title, properties) => {
  const segment = window?.analytics || initialValue;
  const customOptions = getCustomSegmentOptions();
  segment?.track(title, properties, customOptions);
  displayLogs('Segment track', { title, properties, customOptions });
};

export const trackSubmittedEvent = (action, properties) => {
  const segment = window?.analytics || initialValue;
  const customOptions = getCustomSegmentOptions();
  segment?.track(action, properties, customOptions);
  displayLogs('Segment submit track', { action, properties, customOptions });
};

export const analyticsReset = () => {
  const segment = window?.analytics || initialValue;
  segment?.reset();
};

export const identifyUser = (userId, properties) => {
  const segment = window?.analytics || initialValue;
  segment?.identify(userId, properties);
  displayLogs('Segment identify call', { userId, properties });
};

export const trackPage = () => {
  const segment = window?.analytics || initialValue;
  segment?.page();
};
