import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { APP_ROUTES, getRoute } from '../../route';
import { doSelfAssessmentRequest } from '../../store/action/selfAssessment';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header';
import SelfAssessmentBlock from '../../components/SelfAssessmentBlock';
import FilterSection from '../../components/FilterSection';
import { getUserAttribute, scrollToTop } from '../../utils/helpers';
import { TEXT_CONSTANT } from '../../constants/app';
import SelfAsessmentSkeleton from '../../ui-core/Skeletons/SelfAsessmentSkeleton';

import '../SelfAssessment/style.css';
import { encodeURLParams } from '../../utils/url';

const SelfAssessment = ({ history }) => {
  const dispatch = useDispatch();
  const { selfAssessment, isLoading } = useSelector(
    state => state.selfAssessment,
  );
  const { selectedFilters } = useSelector(state => state.common);
  const timeoutRef = useRef(null);
  const onBoardingCompleted = getUserAttribute('onboarding_completed');
  const renderSkeletons = selfAssessment === null || isLoading;

  useEffect(() => {
    scrollToTop();
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    timeoutRef.current = setTimeout(
      () => dispatch(doSelfAssessmentRequest()),
      100,
    );
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [selectedFilters]);

  const detailsVideoPage = (id, title) => {
    const params = {
      title,
      type: 'self-assesment',
    };
    const options = {
      pathParam: { id },
      searchParam: {
        params: encodeURLParams(params),
      },
    };
    const url = getRoute(APP_ROUTES.SELF_ASSESSMENT_DETAILED_VIDEO, options);
    history.push(url);
  };

  const renderSelfAssessment = () => {
    const selfAssessmentList = selfAssessment.map((option, index) => (
      <div className="assessment-block" key={`self-assessment-col-${index}`}>
        <SelfAssessmentBlock
          equipText="Equipment"
          head3={option.attributes.title}
          body={option.attributes.issues}
          phase="Phase 1"
          equip={option.attributes.equipments}
          src={option.attributes.thumbnail}
          duration={option.attributes.duration}
          playbackEndedAt={option.attributes.playback_ended_at}
          id={option.id}
          key={`self-assessment-box-${index}`}
          onClick={() => detailsVideoPage(option.id, option.attributes.title)}
          saved={option?.attributes?.saved}
          status={option?.attributes?.status}
          can_view={option?.attributes?.can_view || false}
          section={`self-assessment`}
          reduxKey={`self-assessment`}
          type={option?.attributes?.video_type}
          page="Self Assessment Page"
        />
      </div>
    ));
    return selfAssessmentList;
  };
  const renderSelfAsessmentSkeletons = () => {
    const selfSkeletons = Array.from({ length: 6 }).map((_, index) => (
      <div
        className="assessment-block"
        key={`self-assessment-skeleton-col-${index}`}
      >
        <SelfAsessmentSkeleton key={`self-asessment-skeleton-${index + 1}`} />
      </div>
    ));
    return selfSkeletons;
  };
  const handleGoBack = () => {
    if (onBoardingCompleted) {
      history.push(APP_ROUTES.HOME);
    } else {
      history.push(APP_ROUTES.FITNESS);
    }
  };
  return (
    <div className="self-assessment-page">
      <Header
        homeClass="home"
        challengeClass="challenge"
        foldersClass="folders"
        showBackHeader={true}
        handleGoBack={handleGoBack}
        discoverClass="discover"
      />
      <FilterSection pageName="Self Assessment Page" />
      <div className="bg-white">
        <div className="body-col-2">
          <div className="px-lg-4">
            <h1 className={'self-asess-title w-100'}>
              {' '}
              {TEXT_CONSTANT.SELF_ASSESSMENT_TOP_TEXT}{' '}
            </h1>
          </div>
        </div>
      </div>

      <div className="body-col-2">
        <div className="block-lists">
          <div className="d-flex mt-4 assessment flex-wrap">
            {renderSkeletons ? (
              renderSelfAsessmentSkeletons()
            ) : selfAssessment.length ? (
              renderSelfAssessment()
            ) : (
              <div className="d-flex justify-content-center flex-column align-items-center ms-4">
                <h4>{TEXT_CONSTANT.NO_SELF_ASSESSMENT_TEXT}</h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SelfAssessment.propTypes = {
  history: PropTypes.object,
};

export default SelfAssessment;
