import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

import '../Button/Button.css';

const Button = React.forwardRef((props, ref) => {
  return (
    <button
      ref={ref}
      className={props.class}
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
      onSubmit={props.onSubmit}
      disabled={props.isDisabled}
      type={props.type || 'submit'}
    >
      {props.isLoading ? (
        <Spinner animation="border" role="status" />
      ) : (
        props.text
      )}
    </button>
  );
});

Button.propTypes = {
  class: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onSubmit: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  text: PropTypes.node,
};

Button.displayName = 'Button';

export default Button;
