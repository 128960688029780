export const getItem = key => {
  if (localStorage) {
    if (localStorage?.getItem) {
      return localStorage.getItem(key);
    }
  }
  return null;
};

export const removeItem = key => {
  if (localStorage) {
    if (localStorage?.removeItem) {
      localStorage.removeItem(key);
    }
  }
};

export const setItem = (key, value) => {
  if (localStorage) {
    if (localStorage?.setItem) {
      localStorage.setItem(key, value);
    }
  }
};
