import React from 'react';
import PropTypes from 'prop-types';

import TimeCounter from '../../../../ui-core/TimeCounter';
import Button from '../../../../ui-core/Button/Button';

import { TEXT_CONSTANT } from '../../../../constants/app';

import './style.css';
import { ShouldRender } from '../../../../ui-core/ShouldRender';

export const TopBar = props => {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick(TEXT_CONSTANT.JOIN_NOW, 'isSignup', 'top bar');
    }
  };
  return (
    <div className="label-wrapper">
      <div className="me-1 challenge-label-text">
        <h5 className="text-header">{props.text}</h5>
        <ShouldRender check={!!props.description}>
          <span className="text-description">{props.description}</span>
        </ShouldRender>
      </div>
      <div className="timer-block">
        <ShouldRender check={!props.hideTimeCounter}>
          <TimeCounter endDate={props.endDate} />
        </ShouldRender>
        <Button
          class="join-now-button"
          isDisabled={props.loading}
          isLoading={props.loading}
          text={TEXT_CONSTANT.JOIN_NOW}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

TopBar.propTypes = {
  btnText: PropTypes.string,
  description: PropTypes.string,
  endDate: PropTypes.string,
  hideTimeCounter: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

TopBar.defaultProps = {
  btnText: TEXT_CONSTANT.JOIN_NOW,
  hideTimeCounter: false,
};
