import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import {
  IMAGE_CONSTANT,
  LOCAL_STORAGE_CONSTANT,
  TEXT_CONSTANT,
} from '../../../constants/app';
import {
  formatCurrency,
  getMonthName,
  getSubscriptionChargeDate,
} from '../../../utils/helpers';
import SubscriptionSelector from '../../../components/PaymentModal/SubscriptionSelector';
import { extractJSON } from '../../../services/jsonUtil';
import StartFreeTrail from '../../StartFreeTrial';

import './style.css';

const HowFreeTrailWorks = props => {
  const { selectedPlan } = useSelector(state => state.product);
  const { payment } = useSelector(state => state.user);
  const subscriptionChargeDate = getSubscriptionChargeDate(7);
  const [height, setHeight] = useState([]);
  const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
  const [viewAll, setViewAll] = useState(!user);
  const getEndDate =
    getMonthName(subscriptionChargeDate) +
    ' ' +
    subscriptionChargeDate.getDate();
  const rejoinMembership =
    payment?.attributes?.ends_at &&
    new Date() > new Date(payment?.attributes?.ends_at);

  useEffect(() => {
    if (rejoinMembership) {
      setViewAll(true);
    }
  }, [rejoinMembership]);

  const steps = [
    {
      title: 'Today - Free Access',
      description: 'Get instant access & see how it can change your life.',
      icon: IMAGE_CONSTANT.lockIcon,
      alt: 'lock',
      backgroundIconColor: '#F7F5EF',
    },
    {
      title: 'Day 5',
      description:
        'We’ll remind you with an email or notification that your trial is ending',
      icon: IMAGE_CONSTANT.bellIconSvg,
      alt: 'bell',
      backgroundIconColor: '#F7F5EF',
    },
    {
      title: 'Day 7',
      description: `You’ll be charged on ${getEndDate}, cancel anytime before`,
      icon: IMAGE_CONSTANT.cardIcon,
      alt: 'card',
      backgroundIconColor: '#F7F5EF',
    },
  ];

  useEffect(() => {
    const height = [];
    Array.from({ length: steps.length - 1 }).forEach((_, index) => {
      const iconOne = document.getElementById(`icon-${index + 1}`);
      const iconTwo = document.getElementById(`icon-${index + 2}`);
      if (iconOne && iconTwo) {
        height.push(
          Math.abs(iconTwo.offsetTop - iconOne.offsetTop - 47) + 'px',
        );
      }
    });
    setHeight(height);
  }, [selectedPlan]);

  const toggleViewAll = () => setViewAll(prev => !prev);

  return (
    <section className="position-relative free-trail-work">
      <Container>
        {selectedPlan && (
          <Row>
            <Col xl={12} className="d-flex align-items-center">
              <div className="free-div text-center w-100">
                {!rejoinMembership && (
                  <h3 className="text-center">
                    {TEXT_CONSTANT.HOW_FREE_TRIAL_WORKS}
                  </h3>
                )}
                {!rejoinMembership && (
                  <div className="free-trial-box">
                    <div className="free-trial-header">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <img
                            className={clsx(
                              'free-trial-icon',
                              props.hidePlanSelector && 'd-none',
                            )}
                            src={IMAGE_CONSTANT.radioIcon}
                            alt="radio"
                          />
                          <div className="d-flex flex-column">
                            <h5 className="mb-0">
                              {selectedPlan?.attributes?.name}
                            </h5>
                            {selectedPlan?.attributes?.description && (
                              <span>
                                {selectedPlan?.attributes?.description}
                              </span>
                            )}
                          </div>
                        </div>
                        <h5 className="mt-2 me-lg-3">
                          {formatCurrency(
                            +selectedPlan.attributes.amount_in_cents / 100,
                            selectedPlan.attributes.currency,
                          )}
                        </h5>
                      </div>
                      <p className="free-trial-para1 text-start mt-2 mb-0">
                        {`Unlimited free access for 7-days, then ${formatCurrency(
                          +selectedPlan.attributes.amount_in_cents / 100,
                          selectedPlan.attributes.currency,
                        )} per ${selectedPlan.attributes.interval}`}
                      </p>
                    </div>
                    <div className="free-trial-body">
                      <p className="text-start d-sm-none">
                        {TEXT_CONSTANT.HOW_FREE_TRIAL_WORKS}
                      </p>
                      {steps.map((step, index) => (
                        <div
                          className="trial-steps"
                          key={`trial-steps-${index}`}
                        >
                          <div
                            id={`icon-${index + 1}`}
                            className="step-icons"
                            style={{
                              backgroundColor:
                                step.backgroundIconColor ?? '#fff',
                            }}
                          >
                            <img src={step.icon} alt={step.alt} />
                            <div
                              style={{ height: height[index] }}
                              className={'vl'}
                            ></div>
                          </div>
                          <div className="step-text">
                            <h5>{step.title}</h5>
                            <p>{step.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {props?.hidePlanSelector ? (
                  ''
                ) : (
                  <>
                    {viewAll ? (
                      <div className="subscription-selector-wrapper">
                        <SubscriptionSelector />
                      </div>
                    ) : (
                      <p onClick={toggleViewAll} className="view-all-plans">
                        {TEXT_CONSTANT.VIEW_ALL_PLANS}
                      </p>
                    )}
                  </>
                )}
                <div id="start-free-trial-btn">
                  <StartFreeTrail {...props} />
                  <p className="free-trial-para">
                    {TEXT_CONSTANT.GUIDANCE_TEXT}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};

HowFreeTrailWorks.propTypes = {
  hidePlanSelector: PropTypes.bool,
  history: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default HowFreeTrailWorks;
