import {
  DO_MY_PLANS_SUCCESS,
  DO_GET_RECOMMENDATION_SUCCESS,
  USER_SET_IS_LOADING,
  DO_GET_USER_DATA_SUCCESS,
  SET_PAYMENT_DETAIL,
  DO_REMOVE_RECOMMENDATION_SUCCESS,
  DO_FETCH_ALL_CATEGORIES_SUCCESS,
  DO_FETCH_BANNER_SUCCESS,
  BANNER_SET_IS_LOADING,
} from '../../constant/user';
import { RESET_STORE } from '../../constant/common';

const initialState = {
  isLoading: false,
  recommendations: [],
  myPlans: [],
  user: null,
  payment: null,
  categories: [],
  banner: null,
  isBannerLoading: false,
};

export const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DO_MY_PLANS_SUCCESS:
      return { ...state, myPlans: payload };
    case DO_GET_RECOMMENDATION_SUCCESS:
    case DO_REMOVE_RECOMMENDATION_SUCCESS:
      return { ...state, recommendations: payload };
    case DO_GET_USER_DATA_SUCCESS:
      return { ...state, user: payload || {} };
    case USER_SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case BANNER_SET_IS_LOADING:
      return { ...state, isBannerLoading: payload };
    case SET_PAYMENT_DETAIL:
      return {
        ...state,
        payment: payload,
      };
    case RESET_STORE:
      return { ...initialState };
    case DO_FETCH_ALL_CATEGORIES_SUCCESS:
      return { ...state, categories: payload };
    case DO_FETCH_BANNER_SUCCESS:
      return { ...state, banner: payload };
    default:
      return state;
  }
};
