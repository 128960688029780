import {
  DO_TRACK_VIDEO_EVENT_REQUEST,
  DO_VIDEO_FAILURE,
  DO_VIDEO_REQUEST,
  DO_VIDEO_SUCCESS,
  RESET_VIDEO_STATE,
  UPDATE_PLAYBACK_TIME,
} from '../../constant/video';

export const doVideoRequest = payload => {
  return {
    type: DO_VIDEO_REQUEST,
    payload,
  };
};

export const doVideoSuccess = payload => ({
  type: DO_VIDEO_SUCCESS,
  payload,
});

export const doVideoFailure = payload => ({
  type: DO_VIDEO_FAILURE,
  payload,
});

export const resetVideoState = payload => ({
  type: RESET_VIDEO_STATE,
  payload,
});

export const doTrackVideoEvent = payload => ({
  type: DO_TRACK_VIDEO_EVENT_REQUEST,
  payload,
});

export const doUpdatePlaybackTimeRequest = payload => ({
  type: UPDATE_PLAYBACK_TIME,
  payload,
});
