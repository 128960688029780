// Import necessary dependencies
import React, { useEffect, useRef, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from '../../route';
import PropTypes from 'prop-types';
import {
  TOUR_TARGET_ELEMENT,
  IMAGE_CONSTANT,
  LOCAL_STORAGE_CONSTANT,
} from '../../constants/app';
import { getUserAttribute, isLoggedIn } from '../../utils/helpers';
import {
  SEGMENT_EVENTS,
  analyticsReset,
  trackEvent,
} from '../../utils/Segment';
import { useDispatch } from 'react-redux';
import { removeItem } from '../../utils/cache';
import { doPlansSuccess } from '../../store/action/plan';
import { resetStore, setSelectedFilters } from '../../store/action/common';
import LazyImage from '../../ui-core/Image';
import './index.css';
import { extractJSON } from '../../services/jsonUtil';
import Hamburger from 'hamburger-react';

// Create the functional component
const HamburgerMenu = props => {
  // State to manage the menu visibility
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const history = useHistory();
  const profilePic = getUserAttribute('profile_pic');
  const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
  const dispatch = useDispatch();
  const drawerRef = useRef();

  const logOut = () => {
    trackEvent(SEGMENT_EVENTS.LOGOUT, {
      user_id: user?.id,
      free_trial_active: user?.attributes?.free_trial || false,
      subscription_active:
        user?.attributes?.subscription_details?.subscribed || false,
    });
    dispatch(resetStore());
    dispatch(setSelectedFilters({ selectedFilters: [], skipSave: true }));
    dispatch(doPlansSuccess([]));
    removeItem(LOCAL_STORAGE_CONSTANT.USER);
    removeItem(LOCAL_STORAGE_CONSTANT.SESSION);
    removeItem(LOCAL_STORAGE_CONSTANT.SELECTED_FILTERS);
    removeItem(LOCAL_STORAGE_CONSTANT.LAST_VOLUME);
    removeItem(LOCAL_STORAGE_CONSTANT.LAST_PLAYBACK_RATE);
    removeItem(LOCAL_STORAGE_CONSTANT.BODY_PARTS_OPTIONS);
    history.push(APP_ROUTES.ROOT);
    analyticsReset();
    window?.Cohere?.stop();
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        drawerRef?.current &&
        !drawerRef?.current?.contains(event.target) &&
        !event?.target?.classList?.contains('hamburger-react') &&
        !event?.target?.parentElement?.classList?.contains('hamburger-react')
      ) {
        setIsMenuOpen(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [drawerRef?.current]);
  return (
    <>
      {/* <Navbar.Toggle
        type="button"
        aria-controls="basic-navbar-nav"
        onClick={ev => {
          console.log('ev', ev);
          ev.preventDefault();
          // toggleMenu();
        }}
      /> */}
      {/* <button
        type="button"
        aria-controls="basic-navbar-nav"
        className={isMenuOpen ? 'navbar-toggler open' : 'navbar-toggler'}
        onClick={() => {
          toggleMenu();
        }}
      >
        <span className="navbar-toggler-icon"></span>
      </button> */}
      <Hamburger
        size={28}
        color="rgba(0,0,0,.55)"
        toggled={isMenuOpen}
        toggle={setIsMenuOpen}
      />

      {/* Collapsible menu items */}
      <Navbar.Collapse
        ref={drawerRef}
        id="basic-navbar-nav"
        className={isMenuOpen ? 'show' : ''}
      >
        <Nav className="ml-auto">
          {/* Your navigation links */}
          {!isLoggedIn() ? (
            <Nav.Link
              className="login-menu"
              onClick={() => {
                props?.onLoginClick();
              }}
            >
              <img
                src={IMAGE_CONSTANT.userMenu}
                alt={IMAGE_CONSTANT.userMenu}
                className="me-3"
              />
              Login
            </Nav.Link>
          ) : (
            <Nav.Link
              className="login-menu"
              onClick={() => history.push(APP_ROUTES.PROFILE)}
            >
              <LazyImage
                alt="face"
                className={`me-3 ${
                  user?.attributes?.subscription_details?.subscribed
                    ? 'pro-user'
                    : ''
                }`}
                errorSrc={IMAGE_CONSTANT.defaultProfileIcon}
                placeholderSrc={IMAGE_CONSTANT.personIcon}
                src={
                  user?.attributes?.profile_pic ||
                  profilePic ||
                  IMAGE_CONSTANT.defaultProfileIcon
                }
              />
              {user?.attributes?.name}
            </Nav.Link>
          )}

          <Nav.Link
            onClick={() => {
              setIsMenuOpen(false);
              setTimeout(() => {
                history.push(APP_ROUTES.PODCASTS);
              }, 200);
            }}
            data-tour-id={TOUR_TARGET_ELEMENT.podcast}
            className={`podcasts-mobile ${props.podcastClass}`}
          >
            Podcast
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              setIsMenuOpen(false);
              setTimeout(() => {
                history.push(APP_ROUTES.BLOGS);
              }, 400);
            }}
            data-tour-id={TOUR_TARGET_ELEMENT.blog}
            className={`blogs-mobile ${props.blogClass}`}
          >
            Blog
          </Nav.Link>
          <Nav.Link
            target="_blank"
            rel="noopener noreferrer"
            href={window.location.origin + APP_ROUTES.PRIVACY_POLICY}
            // href="https://docs.google.com/document/d/1ljjX_9Y2muTkNl4Ew522RG2WGJX8nQB7_2K34rkgAKM/edit"
            data-tour-id={TOUR_TARGET_ELEMENT.privacy_policy}
            className={`privacy-policy-mobile`}
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            Privacy Policy
          </Nav.Link>
          <Nav.Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.google.com/document/d/1kufaBw1KZ4f6pw5vwpWwPyr8qjXy4ToHxX5tbIKoDuM/edit?usp=sharing"
            data-tour-id={TOUR_TARGET_ELEMENT.privacy_policy}
            className={`refund-policy-mobile`}
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            Refund Policy
          </Nav.Link>
          <Nav.Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.google.com/document/d/1d2nS643SoO95afqaKZtL7EHxClNoACPsiFeqevS7PGQ/edit"
            data-tour-id={TOUR_TARGET_ELEMENT.privacy_policy}
            className={`refund-policy-mobile`}
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            Terms & Conditions
          </Nav.Link>
          {/* Add more Nav.Link elements as needed */}
        </Nav>

        <div className="bttm-nav-btn border-top">
          {!isLoggedIn() && (
            <button
              type="button"
              className="stroke-btn"
              onClick={() => {
                props?.onFreeTrialClick();
              }}
            >
              get started for free
            </button>
          )}
          {isLoggedIn() && (
            <a
              href="/logout"
              onClick={ev => {
                ev.preventDefault();
                setIsMenuOpen(false);
                logOut();
              }}
              className="logout"
            >
              Logout
            </a>
          )}
        </div>
      </Navbar.Collapse>
    </>
  );
};

HamburgerMenu.propTypes = {
  blogClass: PropTypes.string,
  podcastClass: PropTypes.string,
  onFreeTrialClick: PropTypes.func,
  onLoginClick: PropTypes.func,
};

export default HamburgerMenu;
