import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getUserAttribute } from '../../utils/helpers';
import Button from '../../ui-core/Button/Button';
import '../FilterSection/style.css';

const FilterMobile = props => {
  const {
    // handleClear,
    selectedOptions,
    handleFilterApply,
    renderBodyPart,
    show,
    handleCancel,
    renderFilterOptionsSkeletons,
  } = props;
  const { user } = useSelector(state => state.user);
  const name = user?.attributes?.name ?? getUserAttribute('name');
  let firstName = '';
  if (name && name?.split) {
    firstName = name.split(' ')[0];
  }
  const { isLoading } = useSelector(state => state.common);

  return (
    <div id="body-filter-model" className="ms-xl-5 ps-xl-5 me-xl-5 pe-xl-5">
      {show && (
        <React.Fragment>
          <div onClick={handleCancel} className="back-drop"></div>
          <div className="filter-options-wrapper">
            {firstName && (
              <div className="filter-name">
                <h4 className="mt-5 mb-0">{`Hey ${firstName},`}</h4>
              </div>
            )}
            <div className="filter-options-wrapper_inner">
              {<h2 className="fitness-h2_">What can we help you with?</h2>}

              <div className="d-flex justify-content-between align-items-start">
                <div className="select-text">
                  <h4 className="title- mb-0">Select a body part</h4>
                </div>
                {/* <div className="clear-all-btn">
                  <Button
                    text="Clear All"
                    class="d-block bg-transparent border-0 text-decoration-underline non-styled clearAll"
                    isDisabled={false}
                    onClick={handleClear}
                  />
                </div> */}
              </div>

              <div className="d-flex flex-wrap mt-4">
                <div className="options-checkbox">
                  <Form className="options-checkbox">
                    {!isLoading
                      ? renderBodyPart()
                      : renderFilterOptionsSkeletons()}
                  </Form>
                </div>
                <div className="w-100 pb-5">
                  <Button
                    text="APPLY"
                    isDisabled={!selectedOptions.length}
                    class="sign-up-button large d-block"
                    onClick={handleFilterApply}
                  />
                  <div className="mt-4 mb-5 d-flex mt-4 mb-4 justify-content-sm-start justify-content-center align-items-center">
                    <Button
                      text="CANCEL"
                      isDisabled={!selectedOptions.length}
                      class="d-block bg-transparent border-0 text-decoration-underline fs-5 non-styled b-5"
                      onClick={handleCancel}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

FilterMobile.propTypes = {
  handleClear: PropTypes.func,
  handleFilterApply: PropTypes.func,
  renderBodyPart: PropTypes.func,
  selectedOptions: PropTypes.array,
  show: PropTypes.bool,
  handleCancel: PropTypes.func,
  renderFilterOptionsSkeletons: PropTypes.func,
};

export default FilterMobile;
