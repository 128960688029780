import React from 'react';
import PropTypes from 'prop-types';
import parser from 'html-react-parser';
import { useDispatch } from 'react-redux';

import LazyImage from '../../../../ui-core/Image';
import { ShouldRender } from '../../../../ui-core/ShouldRender';

import { formatCurrency } from '../../../../utils/helpers';
import { doRemoveFromCartRequest } from '../../../../store/action/checkout';
import { IMAGE_CONSTANT } from '../../../../constants/app';
import { SEGMENT_EVENTS, trackEvent } from '../../../../utils/Segment';

import './style.css';

const CartItems = ({ page, selectedProducts, viewOnly }) => {
  const dispatch = useDispatch();

  const handleRemove = product => {
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      button_title: 'REMOVE ICON',
      page,
      section_title: 'Cart Items',
    });
    if (!viewOnly) {
      dispatch(doRemoveFromCartRequest({ product }));
    }
  };
  return (
    <React.Fragment>
      <ShouldRender check={!viewOnly}>
        {selectedProducts.map(item => (
          <div className="checkout-card mb-4 pb-4" key={item.id}>
            <LazyImage
              alt={item?.title}
              className={!item?.thumbnail ? 'jen-logo' : undefined}
              errorSrc={IMAGE_CONSTANT.placeholderImage}
              placeholderSrc={IMAGE_CONSTANT.placeholderImage}
              src={item?.thumbnail || '/Logo.png'}
            />
            <div className="card-detail">
              <div className="d-flex justify-content-between">
                <h4>{item?.name}</h4>
              </div>
              <ShouldRender check={!!item?.description}>
                <p>{parser(item?.description || '')}</p>
              </ShouldRender>
              <div className="d-flex mt-2 gap-2">
                <ShouldRender check={!!item?.discount}>
                  <div className="price-cut">
                    {formatCurrency(
                      +item?.total_amount / 100,
                      item?.currency,
                      2,
                    )}
                  </div>
                </ShouldRender>
                <div className="currency-amt">
                  {formatCurrency(+item?.amount / 100, item?.currency, 2)}
                </div>
              </div>
            </div>
            <div className="remove-deal" onClick={() => handleRemove(item)}>
              <img src={IMAGE_CONSTANT.deleteIcon} alt="remove deal" />
            </div>
          </div>
        ))}
      </ShouldRender>
      <ShouldRender check={!!viewOnly}>
        <ul className="mb-0">
          {selectedProducts.map(item => (
            <li key={item.id}>
              <p className="mb-0">{item?.name}</p>
              <ShouldRender check={!!item?.description}>
                <p>{parser(item?.description || '')}</p>
              </ShouldRender>
            </li>
          ))}
        </ul>
      </ShouldRender>
    </React.Fragment>
  );
};

CartItems.propTypes = {
  page: PropTypes.string,
  selectedProducts: PropTypes.array,
  viewOnly: PropTypes.bool,
};

CartItems.defaultProps = {
  page: 'Bundle Deals Page',
  selectedProducts: [],
  viewOnly: false,
};

export default CartItems;
