import {
  GET_PLAYBACK_HISTORY,
  SET_LOADING_STATE,
  SET_SELECTED_FITLERS,
  SET_ACTIVE_PAGE,
  RESET_STORE,
  TOGGLE_TRAIL_MODAL,
  SET_ARE_VALUES_CHANGED,
  CHECK_FOR_VALUES_CHANGE,
  FETCH_ONBOARDING_QUESTIONS_FAILURE,
  FETCH_ONBOARDING_QUESTIONS_REQUEST,
  FETCH_ONBOARDING_QUESTIONS_SUCCESS,
  TOGGLE_EMAIL_MODEL,
  SET_PAYMENT_IS_IN_PROGRESS,
} from '../../constant/common';

export const setLoadingState = payload => ({
  type: SET_LOADING_STATE,
  payload,
});

export const setSelectedFilters = payload => ({
  type: SET_SELECTED_FITLERS,
  payload,
});

export const doPlaybackHistoryRequest = payload => ({
  type: GET_PLAYBACK_HISTORY,
  payload,
});

export const setActivePage = payload => ({
  type: SET_ACTIVE_PAGE,
  payload,
});

export const resetStore = () => ({
  type: RESET_STORE,
});

export const toggleTrialModal = payload => ({
  type: TOGGLE_TRAIL_MODAL,
  payload,
});

export const setValuesAreChanged = payload => ({
  type: SET_ARE_VALUES_CHANGED,
  payload,
});

export const checkForChange = payload => ({
  type: CHECK_FOR_VALUES_CHANGE,
  payload,
});

export const doFetchOnboardingQuestionRequest = payload => ({
  type: FETCH_ONBOARDING_QUESTIONS_REQUEST,
  payload,
});

export const doFetchOnboardingQuestionSuccess = payload => ({
  type: FETCH_ONBOARDING_QUESTIONS_SUCCESS,
  payload,
});

export const doFetchOnboardingQuestionFailure = () => ({
  type: FETCH_ONBOARDING_QUESTIONS_FAILURE,
});

export const doToggleEmailModel = payload => ({
  type: TOGGLE_EMAIL_MODEL,
  payload,
});

export const setPaymentIsInProgress = payload => ({
  type: SET_PAYMENT_IS_IN_PROGRESS,
  payload,
});
