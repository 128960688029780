import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

export const Video = ({ headerText, videoURL }) => {
  return (
    <div className="lead-video-section">
      <h2 className="title-text">{headerText}</h2>
      <iframe
        title={headerText}
        className="video-iframe"
        src={videoURL}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

Video.propTypes = {
  headerText: PropTypes.string.isRequired,
  videoURL: PropTypes.string,
};

Video.defaultProps = {
  videoURL: 'https://www.youtube.com/embed/z6gUxkY_9Vc',
};
