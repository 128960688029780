import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Button from '../../../../ui-core/Button/Button';
import TimeCounter from '../../../../ui-core/TimeCounter';
import { ShouldRender } from '../../../../ui-core/ShouldRender';
import { TEXT_CONSTANT } from '../../../../constants/app';
import { formatCurrency } from '../../../../utils/helpers';

import './style.css';

export const JenHealthChallenge = ({
  backgroundColor,
  btnClass,
  btnText,
  courseDetail,
  description,
  hideTimeCounter,
  loading,
  onClick,
  startsAt,
  title,
}) => {
  const endDate = useMemo(() => {
    return dayjs(startsAt).format('YYYY-MM-DD');
  }, [startsAt]);

  const handleClick = () => {
    if (onClick) {
      onClick(btnText, 'isSignup', title);
    }
  };

  const currentPrice = useMemo(() => {
    return courseDetail?.attributes?.amount
      ? +courseDetail?.attributes?.amount / 100
      : 0;
  }, [courseDetail?.attributes?.amount]);

  const originalPrice = useMemo(() => {
    return currentPrice + (courseDetail?.attributes?.discount || 0);
  }, [currentPrice, courseDetail?.attributes?.discount]);

  return (
    <div className="jen-health-challenge-section" style={{ backgroundColor }}>
      <h1 className="mb-4">{title}</h1>
      {courseDetail?.attributes?.amount > 0 && (
        <div className="d-flex align-items-center">
          {Boolean(courseDetail?.attributes?.discount) && (
            <p className="jen-health-price-cut">
              {formatCurrency(
                originalPrice,
                courseDetail?.attributes?.currency,
                2,
              )}
            </p>
          )}
          <p className="jen-health-price">
            {formatCurrency(
              currentPrice,
              courseDetail?.attributes?.currency,
              2,
            )}
          </p>
          {courseDetail?.attributes?.discount > 0 ? (
            <div className="jen-health-para-div">
              <p className="jen-health-para">For a limited time</p>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      <p>{description}</p>
      <ShouldRender check={!hideTimeCounter}>
        <TimeCounter endDate={endDate} />
      </ShouldRender>
      <Button
        isDisabled={loading}
        isLoading={loading}
        class={btnClass}
        text={btnText}
        onClick={handleClick}
      />
    </div>
  );
};

JenHealthChallenge.propTypes = {
  backgroundColor: PropTypes.string,
  btnClass: PropTypes.string,
  btnText: PropTypes.string,
  courseDetail: PropTypes.object,
  description: PropTypes.string,
  hideTimeCounter: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  startsAt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

JenHealthChallenge.defaultProps = {
  btnClass: 'singup-btn bg-transparent',
  btnText: TEXT_CONSTANT.SIGNUP_NOW,
  description: TEXT_CONSTANT.REGISTER_NOW,
  hideTimeCounter: false,
};
