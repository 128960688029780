import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import CartItems from '../../containers/BundleDeals/components/CartItems';
import Button from '../../ui-core/Button/Button';
import { ShouldRender } from '../../ui-core/ShouldRender';
import {
  doCreateOrderRequest,
  doToggleCartModal,
} from '../../store/action/checkout';
import { APP_ROUTES } from '../../route';
import { IMAGE_CONSTANT } from '../../constants/app';
import { SEGMENT_EVENTS, trackEvent } from '../../utils/Segment';
import { getPageName } from '../../utils/helpers';

import './style.css';

const CartOffCanvas = ({ history }) => {
  const dispatch = useDispatch();
  const { cart, showCartModal, isLoading } = useSelector(
    state => state.checkout,
  );
  const page = useMemo(() => {
    return getPageName(history.location.pathname);
  }, [history.location.pathname]);

  const cartItems = useMemo(() => {
    if (!cart) return [];
    return cart?.attributes?.selected || [];
  }, [cart]);

  const itemsCount = useMemo(() => {
    return cartItems?.length || 0;
  }, [cartItems]);

  const handleClose = () => dispatch(doToggleCartModal(false));
  const handleClick = () => {
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      button_title: 'Cart Icon',
      page,
      section_title: 'Cart Offcanvas Header',
    });
    handleClose();
    if (history.location.pathname !== APP_ROUTES.BUNDLE_DEALS) {
      history.push(APP_ROUTES.BUNDLE_DEALS);
    }
  };
  const handleCheckout = () => {
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      button_title: 'CHECKOUT',
      page,
      section_title: 'Cart Offcanvas Footer',
    });
    dispatch(doCreateOrderRequest());
  };
  return (
    <ShouldRender check={showCartModal}>
      <div className="off-canvas">
        <div className="off-canvas-backdrop" onClick={handleClose}></div>
        <div className="off-canvas-body">
          <div className="image-cross">
            <img
              alt="closeIcon"
              src={IMAGE_CONSTANT.crossIcon}
              onClick={handleClose}
            />
          </div>
          <div className="header">
            <img
              className="back-icon"
              onClick={handleClose}
              src={IMAGE_CONSTANT.backIcon}
            />
            <div className="off-canvas-title">Your Cart</div>
            <div className="cart-icon">
              <img
                alt="cart"
                className="cursor-pointer"
                src={IMAGE_CONSTANT.shoppingCartIcon}
                onClick={handleClick}
              />
              <ShouldRender check={itemsCount > 0}>
                <span className="items-count">{itemsCount}</span>
              </ShouldRender>
            </div>
          </div>
          <ShouldRender check={itemsCount === 0}>
            <div className="off-canvas-container">
              <img
                src={IMAGE_CONSTANT.emptyCartIcon}
                alt="empty cart"
                className="mb-4"
              />
              <p>There are no items in your cart</p>
              <Button
                text="ADD ITEMS"
                onClick={handleClick}
                class="sign-up-button-new text-uppercase mt-2 w-auto px-3"
              />
            </div>
          </ShouldRender>
          <ShouldRender check={itemsCount > 0}>
            <div className="cart-items-wrapper">
              <CartItems page={page} selectedProducts={cartItems} />
            </div>
            <div className="checkout-wrapper mt-2">
              <Button
                text={`View Cart${itemsCount ? ' (' + itemsCount + ')' : ''} `}
                onClick={handleClick}
                class="view-button"
              />
              <Button
                text="CHECKOUT"
                onClick={handleCheckout}
                class="sign-up-button-new"
                isLoading={isLoading}
                isDisabled={isLoading}
              />
            </div>
          </ShouldRender>
        </div>
      </div>
    </ShouldRender>
  );
};

CartOffCanvas.propTypes = {
  history: PropTypes.object,
};

export default withRouter(CartOffCanvas);
