import React from 'react';
import PropTypes from 'prop-types';
import parser from 'html-react-parser';

import { ShouldRender } from '../../../../ui-core/ShouldRender';

import './style.css';

export const FeatureList = ({
  description,
  featuresListOne,
  featuresListTwo,
  headerText,
}) => {
  return (
    <div className="lead-features-list">
      <h4>{headerText}</h4>
      <ShouldRender check={!!description}>
        <p className="description">{description}</p>
      </ShouldRender>
      <div className="features-list-row">
        <div>
          <ul>
            {featuresListOne.map(feature => (
              <li className="mb-2" key={feature}>
                <p>{parser(feature)}</p>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <ul>
            {featuresListTwo.map(feature => (
              <li className="mb-2" key={feature}>
                <p>{parser(feature)}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

FeatureList.propTypes = {
  description: PropTypes.string,
  featuresListOne: PropTypes.array,
  featuresListTwo: PropTypes.array,
  headerText: PropTypes.string,
};
