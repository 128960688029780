export const CHALLENGES_AND_COURSES_SET_IS_LOADING =
  'CHALLENGES_AND_COURSES_SET_IS_LOADING';

export const DO_GET_CHALLENGES_LIST_REQUEST = 'DO_GET_CHALLENGES_LIST_REQUEST';
export const DO_GET_CHALLENGES_LIST_SUCCESS = 'DO_GET_CHALLENGES_LIST_SUCCESS';

export const DO_GET_CHALLENGE_DETAILS_REQUEST =
  'DO_GET_CHALLENGE_DETAILS_REQUEST';
export const DO_GET_CHALLENGE_DETAILS_SUCCESS =
  'DO_GET_CHALLENGE_DETAILS_SUCCESS';
export const DO_GET_CHALLENGE_DETAILS_FAILURE =
  'DO_GET_CHALLENGE_DETAILS_FAILURE';
export const RESET_CHALLENGE_DETAIL = 'RESET_CHALLENGE_DETAIL';

export const DO_GET_ALL_COURSES_REQUEST = 'DO_GET_ALL_COURSES_REQUEST';
export const DO_GET_ALL_COURSES_SUCCESS = 'DO_GETALL__COURSES_SUCCESS';

export const DO_GET_COURSE_DETAILS_REQUEST = 'DO_GET_COURSE_DETAILS_REQUEST';
export const DO_GET_COURSE_DETAILS_SUCCESS = 'DO_GET_COURSE_DETAILS_SUCCESS';
export const DO_GET_COURSE_DETAILS_FAILURE = 'DO_GET_COURSE_DETAILS_FAILURE';
export const RESET_COURSE_DETAIL = 'RESET_COURSE_DETAIL';

export const DO_GET_ALL_CHALLENGES_COURSES_REQUEST =
  'DO_GET_ALL_CHALLENGES_COURSES_REQUEST';

export const DO_GET_PUBLIC_PLANS_REQUEST = 'DO_GET_PUBLIC_PLANS_REQUEST';
export const DO_GET_PUBLIC_PLANS_SUCCESS = 'DO_GET_PUBLIC_PLANS_SUCCESS';
export const DO_GET_PUBLIC_PLANS_FAILURE = 'DO_GET_PUBLIC_PLANS_FAILURE';

export const DO_FETCH_PUBLIC_COURSES_REQUEST =
  'DO_FETCH_PUBLIC_COURSES_REQUEST';
export const DO_FETCH_PUBLIC_COURSES_SUCCESS =
  'DO_FETCH_PUBLIC_COURSES_SUCCESS';
