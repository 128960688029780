import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Card from './components/Card';
import Display from './components/Display';
// import FAQs from './components/FAQs';
// import Spend from './components/Spend';
import HowFreeTrailWorks from './components/HowFreeTrialWorks';
import WhyJen from '../NewHome/components/WhyJen';
import { TopBar } from '../MoveToImprove/sections';
import dayjs from 'dayjs';
import Modal from '../NewHome/components/Modal';
import Login from '../NewHome/components/Login';
import SignUp from '../NewHome/components/SignUp';
import OtpModal from '../NewHome/components/OtpModal';
import WaitList from '../NewHome/components/WaitList';
import SuccessModal from '../NewHome/components/SuccessModal';
import { Wrapper } from '../NewHome';

import { getDisplayType, logOutput, scrollToTop } from '../../utils/helpers';
import useWindowWidth from '../../hooks/useWindowWidth';
import { useDispatch } from 'react-redux';
import { doFetchProductsRequest } from '../../store/action/products';
import { doGetPaymentDetailRequest } from '../../store/action/user';
import { extractJSON } from '../../services/jsonUtil';
import {
  analyticsReset,
  SEGMENT_EVENTS,
  trackEvent,
} from '../../utils/Segment';
import {
  LOCAL_STORAGE_CONSTANT,
  MODAL_TYPE,
  SURVEY_STATUS,
  TEXT_CONSTANT,
} from '../../constants/app';
import { getItem, removeItem } from '../../utils/cache';
import axios from 'axios';
import { APP_ROUTES } from '../../route';
import Steps from '../NewHome/components/Steps';
import Offer from '../NewHome/components/Offer';
import Calender from '../NewHome/components/Calender';
import Community from '../NewHome/components/Community';
import NeckPain from '../NewHome/components/NeckPain';
import Testimonial from '../NewHome/components/testimonial';
import Header from '../NewHome/components/Header';
import LoginHeader from '../../components/Header';
import StartFreeTrail from '../StartFreeTrial';
import SeoTags from '../../components/SeoComponent';
import { getCanonicalLink, SeoTexts } from '../../services/SEO';
import { withFooter } from '../../hoc/withFooter';
// import Banner from './components/Banner';

const Payment = ({ history }) => {
  const width = useWindowWidth();
  const dispatch = useDispatch();
  const isMobileScreen = getDisplayType(width) === 'mobile';
  const scrollRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState(null);
  const [countryCode, setCountryCode] = useState('us');
  const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);

  const dialogClassName = (() => {
    switch (modalState) {
      case MODAL_TYPE.SUCCESS:
        return 'success-modal';
      case MODAL_TYPE.WAITLIST:
        return 'survey-modal';
      default:
        return undefined;
    }
  })();

  useEffect(() => {
    scrollToTop();
    if (getItem(LOCAL_STORAGE_CONSTANT.SURVEY_STATUS) === SURVEY_STATUS.DONE) {
      setModalState(MODAL_TYPE.SUCCESS);
      analyticsReset();
    }
    removeItem(LOCAL_STORAGE_CONSTANT.SURVEY_SESSION);
    removeItem(LOCAL_STORAGE_CONSTANT.SURVEY_STATUS);
    axios
      .get('https://ipapi.co/json/')
      .then(response => {
        const data = response.data;
        setCountryCode(data.country_code.toLowerCase());
      })
      .catch(error => {
        logOutput(error);
      });
    dispatch(doFetchProductsRequest());
    if (user) {
      dispatch(
        doGetPaymentDetailRequest({
          callback: () => setLoading(false),
        }),
      );
    } else {
      setLoading(false);
      const route = APP_ROUTES.FREE_TRAIL;
      history.replace(route, {
        payment: true,
      });
    }
  }, []);

  // logic to make "Start Free Trail" button sticky on mobile devices
  const handleScroll = event => {
    const ele = document.getElementById('start-free-trial-btn');
    if (!ele || !isMobileScreen) return;
    const currentPosition = ele.getBoundingClientRect().top;
    const visibleAreaHeight =
      window.innerHeight - (ele.getBoundingClientRect().height + 80);
    const scrollTop = event.target.scrollTop;
    if (currentPosition < visibleAreaHeight) {
      ele.classList.add('sticky-free-trial-button');
      if (!scrollRef.current) scrollRef.current = scrollTop;
    } else if (scrollRef.current && scrollRef.current > scrollTop) {
      ele.classList.remove('sticky-free-trial-button');
      scrollRef.current = null;
    }
  };

  const handleClose = () => {
    removeItem(LOCAL_STORAGE_CONSTANT.SURVEY_SESSION);
    removeItem(LOCAL_STORAGE_CONSTANT.SURVEY_STATUS);
    setModalState(false);
  };

  const handleModalState = modalData => setModalState(modalData);

  const handleModalEvent = modalType => setModalState(modalType);

  const handleAuthFlow = (buttonTitle, eventType, section) => {
    handleModalEvent(eventType);
    trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
      button_title: buttonTitle,
      page: 'Free Trial Page',
      section_title: `${section}`,
    });
    trackEvent(SEGMENT_EVENTS.AUTH_FLOW_INITIATED, {
      page: 'Free Trial Page',
      section_title: `${section}`,
    });
    if (isMobileScreen) {
      scrollToTop();
    }
  };

  const renderModalContent = () => {
    switch (modalState) {
      case MODAL_TYPE.LOGIN:
        return (
          <Wrapper type={modalState} handleGoBack={() => setModalState(null)}>
            <Login
              page={'Payment Page'}
              countryCode={countryCode}
              handleModalState={handleModalState}
              handleModalEvent={handleModalEvent}
            />
          </Wrapper>
        );
      case MODAL_TYPE.OTP:
        return (
          <Wrapper type={modalState} handleGoBack={() => setModalState(null)}>
            <OtpModal
              page={'Payment Page'}
              handleModalState={handleModalState}
              handleModalEvent={handleModalEvent}
            />
          </Wrapper>
        );
      case MODAL_TYPE.SIGNUP:
        return (
          <Wrapper type={modalState} handleGoBack={() => setModalState(null)}>
            <SignUp
              page={'Payment Page'}
              countryCode={countryCode}
              handleModalState={handleModalState}
              handleModalEvent={handleModalEvent}
            />
          </Wrapper>
        );
      case MODAL_TYPE.SUCCESS:
        return <SuccessModal />;
      case MODAL_TYPE.WAITLIST:
        return <WaitList />;
      default:
        return null;
    }
  };

  // const redirectToPaymentPage = section => {
  //   trackEvent(SEGMENT_EVENTS.BUTTON_CLICKED, {
  //     button_title: TEXT_CONSTANT.GET_STARTED_FOR_FREE,
  //     page: 'Home Page',
  //     section_title: section,
  //   });
  //   history.push(APP_ROUTES.FREE_TRAIL);
  // };

  const renderTopBar = () => {
    const currentDate = dayjs();
    const endDate = dayjs('2024-03-30T11:59:59');
    // const extendedEarlyBidEndDate = dayjs('2024-01-28T23:59:59');
    // const renderEndDateTimer =
    //   currentDate >= endDate ? endDate : extendedEarlyBidEndDate;
    const renderTopBar = dayjs('2024-03-26T11:59:59');
    return (
      <>
        {currentDate > endDate ? (
          ''
        ) : currentDate > renderTopBar ? (
          <TopBar
            description="Sign up & start relieving your pain now!"
            endDate={endDate.toString()}
            // loading={isLoading}
            onClick={handleAuthFlow}
            text="47% OFF Yearly Sale until Friday 29th March 2024!"
            hideTimeCounter={false}
          />
        ) : (
          ''
        )}
      </>
    );
  };

  const renderPageContent = () => (
    <React.Fragment>
      {renderTopBar()}
      {user ? (
        <LoginHeader
          homeClass="home"
          challengeClass="challenge"
          foldersClass="folders"
        />
      ) : (
        <Header onClick={handleAuthFlow} freeTrialPage={true} />
      )}
      {/* <Banner
        loading={loading}
        onClick={() =>
          handleAuthFlow(
            'START MY FREE WEEK',
            'isSignup',
            'Annual membership offer',
          )
        }
      /> */}
      <Display
        loading={loading}
        onClick={() =>
          handleAuthFlow(
            'START MY FREE WEEK',
            'isSignup',
            'How does your free trial work?',
          )
        }
      />
      <Steps freeTrialPage={true} />
      <HowFreeTrailWorks
        loading={loading}
        onClick={() =>
          handleAuthFlow(
            'START MY FREE WEEK',
            'isSignup',
            'How does your free trial work?',
          )
        }
      />
      <WhyJen
        loading={loading}
        onClick={() =>
          handleAuthFlow('START MY FREE WEEK', 'isSignup', 'Why Jen?')
        }
        freetrialPage={true}
      />
      <Card />
      <Offer
        loading={loading}
        onClick={() =>
          handleAuthFlow(
            'START MY FREE WEEK',
            'isSignup',
            TEXT_CONSTANT.EXCERCISE_PLAN_MADE_FOR_YOU,
          )
        }
      />
      <Calender />
      <Community
        freetrialPage={true}
        loading={loading}
        onClick={() =>
          handleAuthFlow(
            'START MY FREE WEEK',
            'isSignup',
            'Be part of a community that supports you',
          )
        }
      />
      <NeckPain />
      <Testimonial />
      <footer className="bg-OysterBay text-center px-3 home-footer">
        <h3>{TEXT_CONSTANT.FOOTER_HEADER_TEXT}</h3>
        <p>{TEXT_CONSTANT.FOOTER_SUB_TEXT}</p>
        <StartFreeTrail
          onClick={() =>
            handleAuthFlow(
              'START MY FREE WEEK',
              'isSignup',
              'Be part of a community that supports you',
            )
          }
          loading={loading}
        />
      </footer>
    </React.Fragment>
  );

  const renderContentMobile = () => {
    if (modalState) {
      return renderModalContent();
    } else {
      return renderPageContent();
    }
  };

  return (
    <div
      onScroll={handleScroll}
      className={clsx(
        'main payment-page',
        isMobileScreen && 'h-100',
        !user && 'public-view',
      )}
    >
      {isMobileScreen &&
      ![MODAL_TYPE.SUCCESS, MODAL_TYPE.WAITLIST].includes(modalState)
        ? renderContentMobile()
        : renderPageContent()}
      {!isMobileScreen && (
        <Modal
          dialogClassName={dialogClassName}
          show={Boolean(modalState)}
          handleClose={handleClose}
        >
          {renderModalContent()}
        </Modal>
      )}
      <SeoTags
        title={SeoTexts.JEN_WEB_TITLE}
        metaDescription={SeoTexts.FREE_TRAIL_DESCRIPTION}
        canonicalLink={getCanonicalLink(APP_ROUTES.FREE_TRAIL)}
        ogImgUrl={SeoTexts.JEN_WEB_OG_IMAGE_URL}
        twitterImgUrl={SeoTexts.JEN_WEB_TWITTER_IMAGE_URL}
      />
    </div>
  );
};

Payment.propTypes = {
  history: PropTypes.object,
};

export default withFooter(Payment);
