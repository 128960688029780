import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import LazyImage from '../../../../ui-core/Image';
import Button from '../../../../ui-core/Button/Button';
import { MODAL_TYPE, TEXT_CONSTANT } from '../../../../constants/app';
import { hero, heroLow, heroWebp } from '../../images';
import { formatCurrency } from '../../../../utils/helpers';

import './style.css';

export const Hero = ({ loading, onClick, courseDetail }) => {
  const currentPrice = useMemo(() => {
    return courseDetail?.attributes?.amount
      ? +courseDetail?.attributes?.amount / 100
      : 0;
  }, [courseDetail?.attributes?.amount]);

  const handleClick = () => {
    if (onClick) {
      onClick(
        TEXT_CONSTANT.JOIN_NOW,
        MODAL_TYPE.LOGIN,
        '30 Days of Strength and Mobility',
      );
    }
  };
  return (
    <Row className="hero-section row-margin-zero">
      <Col
        xs={12}
        md={6}
        className="hero-text-block d-flex justify-content-center align-items-center px-4"
      >
        <div className="here-banner text-start d-flex flex-column">
          <h2>
            30 Days of Strength and Mobility
            <span>Move Stronger and Smarter</span>
          </h2>
          <p>
            Learn the tools to{' '}
            <strong>Improve Mobility, Relieve Consistent Aches & Pains,</strong>{' '}
            and <strong>Move SMARTER</strong>
          </p>
          <p className="mb-1">
            <b className="bold">{TEXT_CONSTANT.GET_STARTED_NOW}</b>
          </p>
          {Boolean(courseDetail?.attributes?.amount) && (
            <div className="d-flex">
              <p className="full-body-price">
                {formatCurrency(
                  currentPrice,
                  courseDetail?.attributes?.currency,
                  2,
                )}
              </p>
            </div>
          )}
          <Button
            class="sign-up-button-new"
            isDisabled={loading}
            isLoading={loading}
            onClick={handleClick}
            text={TEXT_CONSTANT.JOIN_NOW}
          />
        </div>
      </Col>
      <Col xs={12} md={6} className="p-0 vid-wrapper">
        <LazyImage
          alt="Hero Section image"
          className="w-100"
          errorSrc={hero}
          placeholderSrc={heroLow}
          src={heroWebp}
        />
      </Col>
    </Row>
  );
};

Hero.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  courseDetail: PropTypes.object,
};
