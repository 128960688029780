import { RESET_STORE } from '../../constant/common';
import {
  DO_GET_GIFT_PRODUCTS_SUCCESS,
  GIFT_SET_IS_LOADING,
  SET_SELECTED_GIFT_PRODUCTS,
  TOGGLE_SHOW_GIFT_MODAL,
} from '../../constant/gift';

const initialState = {
  gifts: [],
  isLoading: false,
  selectedGiftProducts: null,
  showGiftModal: false,
};

export const giftReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DO_GET_GIFT_PRODUCTS_SUCCESS:
      return { ...state, gifts: payload };
    case GIFT_SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case RESET_STORE:
      return { ...initialState };
    case SET_SELECTED_GIFT_PRODUCTS:
      return { ...state, selectedGiftProducts: payload };
    case TOGGLE_SHOW_GIFT_MODAL:
      return { ...state, showGiftModal: payload };
    default:
      return state;
  }
};
