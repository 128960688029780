import { useEffect } from 'react';
import { setItem } from '../utils/cache';
import { LOCAL_STORAGE_CONSTANT } from '../constants/app';

const useUserRef = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userRef = urlParams.get('ref');
    const utmSource = urlParams.get('utm_source');
    const utmMedium = urlParams.get('utm_medium');
    const utmCampaign = urlParams.get('utm_campaign');
    const adId = urlParams.get('ad_id');
    const utmContent = urlParams.get('utm_content');
    const fbClid = urlParams.get('fbclid');
    const pixelId = urlParams.get('pixel');
    if (userRef) {
      setItem(LOCAL_STORAGE_CONSTANT.USER_REF_ATTRIBUTE, userRef);
    }
    if (utmSource) {
      setItem(LOCAL_STORAGE_CONSTANT.UTM_SOURCE_ATTRIBUTE, utmSource);
    }
    if (utmMedium) {
      setItem(LOCAL_STORAGE_CONSTANT.UTM_MEDIUM_ATTRIBUTE, utmMedium);
    }
    if (utmCampaign) {
      setItem(LOCAL_STORAGE_CONSTANT.UTM_CAMPAIGN_ATTRIBUTE, utmCampaign);
    }
    if (adId) {
      setItem(LOCAL_STORAGE_CONSTANT.UTM_AD_ID, adId);
    }
    if (pixelId) {
      setItem(LOCAL_STORAGE_CONSTANT.UTM_PIXEL_ID, pixelId);
    }
    if (utmContent) {
      setItem(LOCAL_STORAGE_CONSTANT.UTM_CONTENT_ATTRIBUTE, utmContent);
    }
    if (fbClid) {
      const fbc = `fb.1.${Date.now()}.${fbClid}`;
      setItem(LOCAL_STORAGE_CONSTANT.FB_CLID, fbc);
    }
  }, []);
};

export default useUserRef;
