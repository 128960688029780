import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../ui-core/Button/Button';
// import { Offer } from '../Offer';

import './style.css';

export const VideoSection = props => {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props.btnText, 'isSignup', props.title);
    }
  };

  return (
    <div className="video-wrapper align-items-center">
      <h2 className="full-body-video-h2 text-center">{props.title}</h2>
      <iframe
        className="video-iframe"
        src="https://www.youtube.com/embed/Tm263cCyB7Q"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <div className="offer-mob-div">
        {/* <Offer coupon="MOBILITY" mode="dark" /> */}
        <Button
          onClick={handleClick}
          text={props.btnText}
          class="sign-up-button-new text-uppercase w-auto"
        />
      </div>
    </div>
  );
};

VideoSection.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
};
