import { toast } from 'react-toastify';
import parse from 'html-react-parser';

export const NOTIFICATION_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const createNotification = ({ type, message }) => {
  switch (type) {
    case NOTIFICATION_TYPE.SUCCESS:
      toast.success(message);
      break;
    case NOTIFICATION_TYPE.ERROR:
      toast.error(message);
      break;
    default:
      toast(parse(message), {
        style: {
          background: '#f00',
        },
        type: NOTIFICATION_TYPE.ERROR,
      });
      break;
  }
};

export const SUCCESS_MESSAGES = {
  FOLDER_CREATE_SUCCESS: 'Folder Created Successfully!',
  FOLDER_DELETE_SUCCESS: 'Folder Deleted Successfully!',
  FOLDER_RENAME_SUCCESS: 'Folder Edited Successfully!',
  VIDEO_ADD_SUCCESS: 'Video Saved',
  VIDEO_REMOVED_SUCCESS: 'Video Removed',
  PROFILE_PIC_UPDATE_SUCCESS: 'Profile Picture updated successfully',
  USER_PROFILE_UPDATE_SUCCESS: 'Profile Updated Successfully',
  SUBSCRIPTION_CANCELLED: 'Subscription Cancelled Successfully',
  FREE_TRIAL_CANCELLED: 'Free Trial Cancelled Successfully',
  PAYMENT_RESTARTED: 'Membership Restarted Successfully',
  PLAN_SWITCHED: 'Switched Plan Successfully',
  TEXT_COPY_SUCCESS: 'Copied text to clipboard',
  REMOVE_SUCCESS: 'Removed Successfully',
};
