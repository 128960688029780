export const DO_GET_GIFT_PRODUCTS_REQUEST = 'DO_GET_GIFT_PRODUCTS_REQUEST';
export const DO_GET_GIFT_PRODUCTS_SUCCESS = 'DO_GET_GIFT_PRODUCTS_SUCCESS';
// export const DO_GET_GIFT_PRODUCTS_FAILURE = 'DO_GET_GIFT_PRODUCTS_FAILURE';

export const DO_BUY_GIFT_REQUEST = 'DO_BUY_GIFT_REQUEST';
export const DO_BUY_GIFT_SUCCESS = 'DO_BUY_GIFT_SUCCESS';

export const GIFT_SET_IS_LOADING = 'GIFT_SET_IS_LOADING';

export const SET_SELECTED_GIFT_PRODUCTS = 'SET_SELECTED_GIFT_PRODUCTS';

export const TOGGLE_SHOW_GIFT_MODAL = 'TOGGLE_SHOW_GIFT_MODAL';
