export const DO_GET_COLLECTION_REQUEST = 'DO_GET_COLLECTION_REQUEST';
export const DO_GET_COLLECTION_SUCCESS = 'DO_GET_COLLECTION_SUCCESS';
export const DO_GET_COLLECTION_FAILURE = 'DO_GET_COLLECTION_FAILURE';

export const DO_GET_COLLECTION_DETAILS_REQUEST =
  'DO_GET_COLLECTION_DETAILS_REQUEST';
export const DO_GET_COLLECTION_DETAILS_SUCCESS =
  'DO_GET_COLLECTION_DETAILS_SUCCESS';
export const DO_GET_COLLECTION_DETAILS_FAILURE =
  'DO_GET_COLLECTION_DETAILS_FAILURE';
export const RESET_COLLECTION_DETAILS = 'RESET_COLLECTION_DETAILS';

export const COLLECTION_SET_IS_LOADING = 'COLLECTION_SET_IS_LOADING';
