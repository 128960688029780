export const CHECKOUT_SET_IS_LOADING = 'CHECKOUT_SET_IS_LOADING';
export const SET_CART_IS_LOADING = 'SET_CART_IS_LOADING';

export const DO_FETCH_CART_REQUEST = 'DO_FETCH_CART_REQUEST';
export const DO_FETCH_CART_SUCCESS = 'DO_FETCH_CART_SUCCESS';
export const DO_FETCH_CART_FAILURE = 'DO_FETCH_CART_FAILURE';

export const DO_ADD_TO_CART_REQUEST = 'DO_ADD_TO_CART_REQUEST';
export const DO_ADD_TO_CART_SUCCESS = 'DO_ADD_TO_CART_SUCCESS';

export const DO_REMOVE_FROM_CART_REQUEST = 'DO_REMOVE_FROM_CART_REQUEST';
export const DO_REMOVE_FROM_CART_SUCCESS = 'DO_REMOVE_FROM_CART_SUCCESS';

export const DO_CREATE_ORDER_REQUEST = 'DO_CREATE_ORDER_REQUEST';
export const DO_CREATE_ORDER_SUCCESS = 'DO_CREATE_ORDER_SUCCESS';

export const DO_TOGGLE_CART_MODAL = 'DO_TOGGLE_CART_MODAL';
