export const DO_PLANS_REQUEST = 'DO_PLANS_REQUEST';
export const DO_PLANS_SUCCESS = 'DO_PLANS_SUCCESS';

export const DO_CURATED_PLANS_REQUEST = 'DO_CURATED_PLANS_REQUEST';
export const DO_CURATED_PLANS_SUCCESS = 'DO_CURATED_PLANS_SUCCESS';

export const DO_FITNESS_OPTIONS_REQUEST = 'DO_FITNESS_OPTIONS_REQUEST';
export const DO_FITNESS_OPTIONS_SUCCESS = 'DO_FITNESS_OPTIONS_SUCCESS';

export const RESET_CURATED_PLAN = 'RESET_CURATED_PLAN';

export const PLANS_SET_IS_LOADING = 'PLANS_SET_IS_LOADING';
export const PLANS_IS_LOADING = 'PLANS_IS_LOADING';

export const SET_SHOW_PHASE_MODAL = 'SET_SHOW_PHASE_MODAL';

export const DO_THERAPY_PLANS_REQUEST = 'DO_THERAPY_PLANS_REQUEST';
export const DO_THERAPY_PLANS_SUCCESS = 'DO_THERAPY_PLANS_SUCCESS';
export const DO_THERAPY_PLANS_RESET = 'DO_THERAPY_PLANS_RESET';
