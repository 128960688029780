import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import {
  DO_PLANS_REQUEST,
  DO_CURATED_PLANS_REQUEST,
  DO_FITNESS_OPTIONS_REQUEST,
  DO_THERAPY_PLANS_REQUEST,
} from '../../constant/plan';
import {
  doPlansSuccess,
  doCuratedPlansSuccess,
  doFitnessOptionsSuccess,
  setIsLoading,
  setIsPlanLoading,
  doTherapyPlansSuccess,
} from '../../action/plan';
import { plansGetRequest } from '../../../api/plans';
import { curatedPlansGetRequest } from '../../../api/curatedPlans';
import { createNotification } from '../../../services/Notification';
import { getFiltersParams } from '../../../services/api';
import { fitnessOptionsGetRequest } from '../../../api/fitness';
import { setLoadingState, setSelectedFilters } from '../../action/common';
import { setItem } from '../../../utils/cache';
import { LOCAL_STORAGE_CONSTANT } from '../../../constants/app';
import { extractJSON } from '../../../services/jsonUtil';
import { errorHandler } from '../../../utils/helpers';

function* plansRequest() {
  const { selectedFilters } = yield select(state => state.common);
  const params = getFiltersParams(selectedFilters);
  yield put(setIsLoading(true));
  try {
    const response = yield call(plansGetRequest, params);
    yield put(
      doPlansSuccess({
        data: response.data.response.data,
        paging: response.data.paging,
      }),
    );
  } catch (e) {
    createNotification(errorHandler(e));
  }
  yield put(setIsLoading(false));
}

function* therapyPlansRequest({ payload }) {
  const { pageCount, totalPage } = yield select(state => state.plan);
  const count = pageCount + 1;
  if (totalPage < count) return;
  const params = `?page=${count}`;
  try {
    yield put(setIsPlanLoading(true));
    const response = yield call(plansGetRequest, params);
    yield put(
      doTherapyPlansSuccess({
        data: response.data.response.data,
        paging: response.data.paging,
        pageCount: count,
        totalPage: response?.data?.paging?.total_pages,
      }),
    );
  } catch (e) {
    createNotification(errorHandler(e));
  }
  yield put(setIsPlanLoading(false));
}

function* curatedPlansRequest({ payload }) {
  const { planId, selectedDay, selectedPhase, errorCallback } = payload;
  yield put(setIsLoading(true));
  const options = {
    day: selectedDay,
    phase: selectedPhase,
  };
  try {
    const response = yield call(curatedPlansGetRequest, planId, options);
    yield put(doCuratedPlansSuccess(response.data.response.data));
  } catch (e) {
    createNotification(errorHandler(e));
    if (errorCallback) errorCallback();
  }
  yield put(setIsLoading(false));
}

function* fitnessOptionsRequest({ payload }) {
  const { setFilters } = payload || {};
  yield put(setLoadingState(true));
  let options = [];
  try {
    const cachedOptions = extractJSON(
      LOCAL_STORAGE_CONSTANT.BODY_PARTS_OPTIONS,
    );
    if (cachedOptions) {
      options = cachedOptions;
    } else {
      const response = yield call(fitnessOptionsGetRequest);
      if (response?.data?.response?.data) {
        options = response.data.response.data;
        setItem(
          LOCAL_STORAGE_CONSTANT.BODY_PARTS_OPTIONS,
          JSON.stringify(options),
        );
      }
    }
    if (setFilters) {
      const userObject = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
      const bodyParts = userObject?.attributes?.filters?.body_parts ?? [];
      const selectedFilters = options
        .filter(item => bodyParts.includes(item.id))
        .map(item => ({
          id: item.id,
          name: item.attributes.name,
        }));
      yield put(setSelectedFilters({ selectedFilters }));
      setItem(
        LOCAL_STORAGE_CONSTANT.SELECTED_FILTERS,
        JSON.stringify(selectedFilters),
      );
    }
    yield put(doFitnessOptionsSuccess(options));
  } catch (e) {
    createNotification(errorHandler(e));
  }
  yield put(setLoadingState(false));
}

/**
 * Watch plan actions
 * */
export default function* plan() {
  yield all([
    takeLatest(DO_CURATED_PLANS_REQUEST, curatedPlansRequest),
    takeLatest(DO_FITNESS_OPTIONS_REQUEST, fitnessOptionsRequest),
    takeLatest(DO_PLANS_REQUEST, plansRequest),
    takeLatest(DO_THERAPY_PLANS_REQUEST, therapyPlansRequest),
  ]);
}
