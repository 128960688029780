import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { searchVideosRequest } from '../../../api/video';
import { getTourSteps } from '../../../components/Tour';
import { LOCAL_STORAGE_CONSTANT } from '../../../constants/app';
import { extractJSON } from '../../../services/jsonUtil';
import { createNotification } from '../../../services/Notification';
import { errorHandler, getUserAttribute } from '../../../utils/helpers';
import { trackEvent } from '../../../utils/Segment';
import {
  doClearSearchResults,
  setIsLoading,
  setSearchResults,
} from '../../action/search';

import {
  DO_SEARCH_REQUEST_FOR_NEXT_PAGE,
  DO_SEARCH_REQUEST,
} from '../../constant/search';

function* handleSearch({ payload }) {
  const keyword = payload?.keyword?.trim();
  const { page, setIsOpen, setSteps, userOnboarding } = payload || {};
  if (keyword.length) {
    yield put(doClearSearchResults());
    yield put(setIsLoading(true));
    try {
      const response = yield call(searchVideosRequest, keyword, page);
      const paging = response?.data?.paging?.paging || {};
      const data = response?.data?.response?.data || [];
      yield put(setSearchResults({ data, paging }));
      const freeTrial = getUserAttribute('free_trial');
      const subscriptionDetails = getUserAttribute('subscription_details');
      const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
      if (userOnboarding) {
        setTimeout(() => {
          const steps = getTourSteps(data);
          setSteps(steps);
          setIsOpen(true);
        }, 300);
      }
      trackEvent('User_Search', {
        user_id: user.id,
        search_phrase: keyword,
        free_trial_active: freeTrial || false,
        subscription_active: subscriptionDetails?.subscribed || false,
      });
    } catch (error) {
      createNotification(errorHandler(error));
    }
    yield put(setIsLoading(false));
  }
}

function* handleSearchPageChange({ payload }) {
  const { callback } = payload || {};
  const { activePage } = yield select(state => state.common);
  // const { searchInput } = yield select(state => state.search);
  callback && callback(activePage || 1);
  // yield put(setIsLoading(true));
  // try {
  //   const response = yield call(
  //     searchVideosRequest,
  //     searchInput,
  //     activePage || 1,
  //   );
  //   const paging = response?.data?.paging?.paging || {};
  //   const data = response?.data?.response?.data || [];
  //   yield put(setSearchResults({ data, paging }));
  //   callback && callback(activePage || 1);
  // } catch (error) {
  //   createNotification({
  //     type: NOTIFICATION_TYPE.ERROR,
  //     message: `${error?.message}`,
  //   });
  // }
  // yield put(setIsLoading(false));
}

/**
 * Watch selfAssessment actions
 * */
export default function* searchSaga() {
  yield all([
    takeLatest(DO_SEARCH_REQUEST, handleSearch),
    takeLatest(DO_SEARCH_REQUEST_FOR_NEXT_PAGE, handleSearchPageChange),
  ]);
}
