import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { checkoutReducer } from './checkout';
import { collectionReducer } from './collection';
import { commonReducer } from './common';
import { folderReducer } from './folders';
import { giftReducer } from './gifts';
import { planReducer } from './plan';
import { productsReducer } from './products';
import { questionnairesReducer } from './questionnaires';
import { searchReducer } from './search';
import { selfAssessmentReducer } from './selfAssessment';
import { userReducer } from './user';
import { challengesAndCoursesReducer } from './challengesAndCourses';
import { videoReducer } from './video';
import { blogReducer } from './blog';
import { podcastReducer } from './podcast';
import { discoverReducer } from './discover';

const appReducer = combineReducers({
  auth: authReducer,
  blogs: blogReducer,
  checkout: checkoutReducer,
  common: commonReducer,
  collection: collectionReducer,
  folder: folderReducer,
  gift: giftReducer,
  plan: planReducer,
  product: productsReducer,
  questionnaires: questionnairesReducer,
  search: searchReducer,
  selfAssessment: selfAssessmentReducer,
  user: userReducer,
  challengesAndCourses: challengesAndCoursesReducer,
  video: videoReducer,
  podcasts: podcastReducer,
  discover: discoverReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
