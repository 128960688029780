import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
// import Cohere from 'cohere-js';
import { IMAGE_CONSTANT } from '../../constants/app';
// import { doLogoutRequest } from '../../store/action/auth';
import LazyImage from '../../ui-core/Image';
import PhotoUploadModel from '../../containers/Profile/PhotoUploadModel';
import useWindowWidth from '../../hooks/useWindowWidth';
import { getDisplayType, getUserAttribute } from '../../utils/helpers';

import '../SideBar/style.css';

const SideBar = ({
  profilePicture,
  userName,
  handleUpload,
  handleClick,
  selectedOption,
}) => {
  const [show, setShow] = useState(false);
  const [userImage, setUserImage] = useState(profilePicture);
  const isDesktopScreen = getDisplayType(useWindowWidth()) === 'desktop';
  const onBoardingCompleted = getUserAttribute('onboarding_completed');

  const options = [
    {
      label: 'Edit Profile',
      image: IMAGE_CONSTANT.userIcon,
      value: 'edit-profile',
      class: 'user',
    },
    {
      label: 'Payments',
      image: IMAGE_CONSTANT.crownIcon,
      value: 'payments',
      class: 'mail',
    },
    {
      label: 'Help & Support',
      image: IMAGE_CONSTANT.mailIcon,
      value: 'help-support',
      class: 'mail',
    },
    {
      label: 'My Orders',
      image: IMAGE_CONSTANT.myOrdersIcon,
      value: 'my-orders',
      class: 'my-orders',
    },
    // {
    //   label: 'About Jen',
    //   image: IMAGE_CONSTANT.aboutJenIcon,
    //   value: 'about-jen',
    //   class: 'about-jen',
    //   addSeparator: true,
    // },
    {
      label: 'Logout',
      image: IMAGE_CONSTANT.unlockIcon,
      value: 'logout',
      className: 'logout',
      class: 'unlock',
    },
  ];

  useEffect(() => {
    if (profilePicture) {
      setUserImage(profilePicture);
    }
  }, [profilePicture]);

  function handlePhotoUpload(profilePic) {
    const data = {
      name: userName,
      profile_pic: profilePic,
    };
    handleUpload(data, () => {
      setUserImage(profilePic?.content);
      setShow(false);
    });
  }

  return (
    <div className="profile-sidebar-div h-100">
      <div className="profile-sidebar-subdiv">
        <div className="position-relative">
          <h3 className="text-center profile-title">My Profile</h3>
          <div className="avator-outer">
            <button className="edit-btn" onClick={() => setShow(true)}>
              <img src={IMAGE_CONSTANT.editIcon1} className="edit" alt="edit" />
            </button>
            <div className="avator">
              <LazyImage
                alt="face"
                className="img-fluid"
                errorSrc={IMAGE_CONSTANT.defaultProfileIcon}
                placeholderSrc={IMAGE_CONSTANT.personIcon}
                src={userImage || IMAGE_CONSTANT.defaultProfileIcon}
              />
            </div>
          </div>
          <h3 className="text-center profile-name mb-lg-5">
            {' '}
            {userName ?? ''}{' '}
          </h3>
        </div>
      </div>
      <div className="profile-sidebar-subdiv-2">
        <Nav defaultActiveKey="/home" className="flex-column">
          {options.map(option => (
            <React.Fragment key={option.value}>
              {option.addSeparator && <div className="sidemenu-separator" />}
              <Nav.Link
                onClick={() => handleClick(option.value)}
                disabled={!onBoardingCompleted && option.value !== 'logout'}
                className={clsx(
                  'sidebar-link-text',
                  selectedOption === option.value &&
                    isDesktopScreen &&
                    'current',
                  option.className,
                )}
              >
                <span className={option.class}>
                  <img src={option.image} className="img-fluid" alt="" />
                </span>
                {option.label}
              </Nav.Link>
            </React.Fragment>
          ))}
        </Nav>
      </div>
      <PhotoUploadModel
        show={show}
        handleClose={() => setShow(false)}
        callback={profilePic => {
          handlePhotoUpload.bind(this, userName);
          handlePhotoUpload(profilePic);
        }}
      />
    </div>
  );
};

SideBar.propTypes = {
  handleUpload: PropTypes.func,
  handleClick: PropTypes.func,
  profilePicture: PropTypes.string,
  selectedOption: PropTypes.string,
  userName: PropTypes.string,
};

export default withRouter(SideBar);
