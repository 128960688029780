import { axiosInstance, overrideHeader } from './index';

/**
 * API request
 * */

export const selfAssessmentGetRequest = params => {
  overrideHeader();
  let endPoint = '/categories/self-assessments';
  if (params) endPoint += params;
  return axiosInstance.get(endPoint);
};
