import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import LazyImage from '../../../../ui-core/Image';
import Button from '../../../../ui-core/Button/Button';

import { formatCurrency } from '../../../../utils/helpers';
import { MODAL_TYPE, TEXT_CONSTANT } from '../../../../constants/app';
import { sample, sampleLow, sampleWebp } from '../../images';

import './style.css';

export const Sample = ({ courseDetail, loading, onClick }) => {
  const { selectedPlan } = useSelector(state => state.product);

  const currentPrice = useMemo(() => {
    const amount = courseDetail
      ? courseDetail?.attributes?.amount
      : selectedPlan?.attributes?.amount_in_cents;
    return amount ? +amount / 100 : 0;
  }, [courseDetail, selectedPlan]);

  const perDayPrice = useMemo(() => {
    return Math.floor(currentPrice / 30);
  }, [currentPrice]);

  const headerText = useMemo(() => {
    return `All of this for just over ${formatCurrency(
      perDayPrice,
      courseDetail?.attributes?.currency,
      0,
    )}/day, when you sign up!`;
  }, [perDayPrice]);

  const subHeaderText = useMemo(() => {
    return `A $320 value at minimum and we are giving it away for ${formatCurrency(
      currentPrice,
      courseDetail?.attributes?.currency,
      0,
    )}!!`;
  }, [perDayPrice]);

  const handleClick = () => {
    if (onClick) {
      onClick(TEXT_CONSTANT.START_FREE_TRIAL, MODAL_TYPE.LOGIN, headerText);
    }
  };
  return (
    <Row className="sample-section row-margin-zero">
      <p>{subHeaderText}</p>
      <h2>{headerText}</h2>
      <Col
        xs={12}
        md={6}
        className="sample-text-block d-flex justify-content-center align-items-center px-4"
      >
        <div className="here-banner text-start d-flex flex-column">
          <ul>
            <li>30-Day Calender</li>
            <li>Automatically Tracks Progress</li>
            <li>Daily Accountability Check-Ins</li>
          </ul>
          {/* <div className="my-3">
            <img src={earlyBirdPricing} alt="early-pricing" />
          </div> */}
          <Button
            class="sign-up-button-new"
            isDisabled={loading}
            isLoading={loading}
            onClick={handleClick}
            text={TEXT_CONSTANT.JOIN_TO_START_LEARING}
          />
        </div>
      </Col>
      <Col xs={12} md={6} className="p-0 vid-wrapper">
        <LazyImage
          alt="sample Section image"
          className="w-100"
          errorSrc={sample}
          placeholderSrc={sampleLow}
          src={sampleWebp}
        />
      </Col>
    </Row>
  );
};

Sample.propTypes = {
  courseDetail: PropTypes.object,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};
