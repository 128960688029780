import { axiosInstance, overrideHeader } from '.';

export const addToCartRequest = product_id => {
  const data = {
    product_id,
  };
  overrideHeader();
  return axiosInstance.post('/carts/add', data);
};

export const removeToCartRequest = product_id => {
  const body = {
    data: {
      product_id,
    },
  };
  overrideHeader();
  return axiosInstance.delete('/carts/remove', body);
};

export const getCartInfoRequest = () => {
  overrideHeader();
  return axiosInstance.get('/carts/show');
};

export const createOrderRequest = params => {
  overrideHeader();
  return axiosInstance.post('/orders', undefined, {
    params,
  });
};

export const getOrderDetailsRequest = id => {
  overrideHeader();
  return axiosInstance.get('/orders/' + id);
};
