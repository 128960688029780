import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';
import { setActivePage } from '../../store/action/common';

const PagiNation = props => {
  const dispatch = useDispatch();
  let {
    defaultPage,
    totalPages,
    maxPageBlocks,
    maxPagesToRender,
    triggerAction,
  } = props;
  if (!maxPageBlocks) maxPageBlocks = 12;
  if (!maxPagesToRender) maxPagesToRender = 5;
  const selectedPage = useSelector(state => state.common.activePage);
  const activePage = selectedPage ?? 1;
  const startPageIndex = useRef(activePage);
  const pageToShow =
    maxPagesToRender < totalPages ? maxPagesToRender : totalPages;

  useEffect(() => {
    if (defaultPage && !selectedPage) {
      dispatch(setActivePage(+defaultPage));
    }
  }, [defaultPage, selectedPage]);

  useEffect(() => {
    return () => {
      dispatch(setActivePage(undefined));
    };
  }, []);

  const updatedActivePage = newPage => {
    dispatch(setActivePage(newPage));
    triggerAction && triggerAction();
  };

  const handlePageChange = action => {
    let newPage = activePage;
    if (action === 'next' && activePage + 1 <= totalPages) {
      newPage += 1;
      if (newPage > pageToShow && newPage <= totalPages)
        startPageIndex.current += 1;
    } else if (action === 'previous' && activePage - 1 > 0) {
      newPage -= 1;
      if (newPage === startPageIndex.current - 1) startPageIndex.current -= 1;
    }
    updatedActivePage(newPage);
  };

  const renderPages = () => {
    const pages = Array.from({ length: pageToShow }).map(
      (_, index) => startPageIndex.current + index,
    );
    const pageOutput = pages.map(pageNumber => (
      <Pagination.Item
        key={`page-${pageNumber}`}
        active={pageNumber === activePage}
        onClick={() => updatedActivePage(pageNumber)}
      >
        {pageNumber}
      </Pagination.Item>
    ));
    if (totalPages > maxPagesToRender) {
      if (pages.includes(totalPages)) {
        pageOutput.unshift(
          <Pagination.Item
            key={'first-page'}
            active={activePage === 1}
            onClick={() => {
              startPageIndex.current = 1;
              updatedActivePage(1);
            }}
          >
            {1}
          </Pagination.Item>,
          <Pagination.Ellipsis key="ellipsis" />,
        );
      } else {
        pageOutput.push(
          <Pagination.Ellipsis key="ellipsis" />,
          <Pagination.Item
            key={'last-page'}
            active={totalPages === activePage}
            onClick={() => {
              startPageIndex.current = totalPages - maxPagesToRender + 1;
              updatedActivePage(totalPages);
            }}
          >
            {totalPages}
          </Pagination.Item>,
        );
      }
    }
    return pageOutput;
  };

  return (
    <Pagination>
      <Pagination.Prev
        disabled={activePage === 1}
        onClick={() => handlePageChange('previous')}
      />
      {renderPages()}
      <Pagination.Next
        disabled={activePage === totalPages}
        onClick={() => handlePageChange('next')}
      />
    </Pagination>
  );
};

PagiNation.propTypes = {
  defaultPage: PropTypes.string,
  totalPages: PropTypes.number,
  maxPageBlocks: PropTypes.number,
  maxPagesToRender: PropTypes.number,
  triggerAction: PropTypes.func,
};

export default PagiNation;
