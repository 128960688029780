import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SelfAssessment from './containers/SelfAssessment';
import PublicRoute from './hoc/PublicRoute';
import PrivateRoute from './hoc/PrivateRoute';
import SpecialRoutesRedirection from './hoc/SpecialRoutesRedirection';
import SurveyRoute from './hoc/SurveyRoute';

// components
import Loader from './ui-core/Loader';

// constants and css
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import MyFolders from './containers/Folders';
import Profile from './containers/Profile';
import Payment from './containers/Payment';
import NewHome from './containers/NewHome';
import PaymentProgressIndicator from './components/PaymentProgressIndicator';
import CartOffCanvas from './components/CartOffCanvas';

import { isLoggedIn } from './utils/helpers';
import { APP_ROUTES, COURSES_SLUGS } from './route';
import useUserRef from './hooks/useUserRef';
import { useDispatch } from 'react-redux';
import { extractJSON } from './services/jsonUtil';
import { LOCAL_STORAGE_CONSTANT } from './constants/app';
import { doGetUserDataRequest } from './store/action/user';

// lazy loaded components
const CallbackController = React.lazy(() =>
  import('./containers/CallbackController'),
);
const Collection = React.lazy(() => import('./containers/Collection'));
const CollectionDetail = React.lazy(() =>
  import('./containers/CollectionDetail'),
);
const CuratedPlans = React.lazy(() => import('./containers/CuratedPlans'));
const DeatiledFolder = React.lazy(() => import('./containers/DetailedFolder'));
const DeatiledVideo = React.lazy(() => import('./containers/DetailedVideo'));
const FitnessOptions = React.lazy(() => import('./containers/FitnessOptions'));
// const MyFolders = React.lazy(() => import('./containers/Folders'));
const NewDashboard = React.lazy(() => import('./containers/NewDashboard'));
const HomeV2 = React.lazy(() => import('./containers/V2/Home'));
const CategoriesV2 = React.lazy(() => import('./containers/V2/Categories'));
const ThankYou = React.lazy(() => import('./containers/NewDashboard/ThankYou'));
// const NewHome = React.lazy(() => import('./containers/NewHome'));
const NoPageFound = React.lazy(() => import('./containers/NoPageFound'));
// const Payment = React.lazy(() => import('./containers/Payment'));

const PaymentAffiliation = React.lazy(() =>
  import('./containers/PaymentAffiliation'),
);
const PersonalDetails = React.lazy(() =>
  import('./containers/Profile/PersonalDetails'),
);
// const Profile = React.lazy(() => import('./containers/Profile'));
const QuickTips = React.lazy(() => import('./containers/QuickTips'));
const Search = React.lazy(() => import('./containers/Search'));
// const SelfAssessment = React.lazy(() => import('./containers/SelfAssessment'));
const Survey = React.lazy(() => import('./containers/Survey'));
const Challenge = React.lazy(() => import('./containers/Challenge'));
const Challenges = React.lazy(() => import('./containers/Challenges'));
const BodyParts = React.lazy(() => import('./containers/BodyParts'));
const FullBodyLowImpact = React.lazy(() =>
  import('./containers/FullBodyLowImpact'),
);
const BareFootMiniCourse = React.lazy(() =>
  import('./containers/BareFootMiniCourse'),
);
const ChallengeDetail = React.lazy(() =>
  import('./containers/CuratedPlans/ChallengeDetail'),
);
const DetailedCategories = React.lazy(() =>
  import('./containers/DetailedCategories'),
);
const GratefulForBody = React.lazy(() =>
  import('./containers/GratefulForBody'),
);
const Gifts = React.lazy(() => import('./containers/Gifts'));
const FullBodyMobility = React.lazy(() =>
  import('./containers/FullBodyMobility'),
);
const MoveToImprove = React.lazy(() => import('./containers/MoveToImprove'));
const Move2Improve = React.lazy(() => import('./containers/Move2Improve'));
const WarmupCoolDown = React.lazy(() => import('./containers/WarmupCoolDown'));
const PostureRedefined = React.lazy(() =>
  import('./containers/PostureRedefined'),
);

// Lead Pages
const Barefoot = React.lazy(() => import('./containers/Lead/Barefoot'));
const HipMobility = React.lazy(() => import('./containers/Lead/HipMobility'));
const KneeHealth = React.lazy(() => import('./containers/Lead/KneeHealth'));
const LowBackPain = React.lazy(() => import('./containers/Lead/LowBackPain'));
const ReleaseAndRecovery = React.lazy(() =>
  import('./containers/Lead/Release & Recover'),
);
const UpperBackAndNeck = React.lazy(() =>
  import('./containers/UpperBackAndNeck'),
);
const BundleDeals = React.lazy(() => import('./containers/BundleDeals'));
const OrderAffiliation = React.lazy(() =>
  import('./containers/OrderAffiliation'),
);
const PelvicFloor = React.lazy(() => import('./containers/PelvicFloor'));
const Blogs = React.lazy(() => import('./containers/Blogs'));
const BlogsListing = React.lazy(() => import('./containers/Blogs/listingPage'));
const BlogDetail = React.lazy(() => import('./containers/Blogs/detailPage'));
const Podcasts = React.lazy(() => import('./containers/Podcasts'));
const Podcast = React.lazy(() => import('./containers/Podcasts/detailPage'));
const PodcastListing = React.lazy(() =>
  import('./containers/Podcasts/listingPage'),
);
const PrivacyPolicy = React.lazy(() => import('./containers/PrivacyPolicy'));
const TherapyPlans = React.lazy(() => import('./containers/V2/TherapyPlans'));
const PremiumCourses = React.lazy(() =>
  import('./containers/V2/PremiumCourses'),
);

const MobilityFoundations = React.lazy(() =>
  import('./containers/MobilityFoundations'),
);
const DiscoverPage = React.lazy(() => import('./containers/DiscoverPage'));

const LowBack = React.lazy(() => import('./containers/LowBack'));

function App() {
  function handleScroll(event) {
    const app = event.target;
    const header = document.getElementById('header-section');
    const mainBody = document.getElementById('main-body');
    if (window.location.pathname !== APP_ROUTES.SELF_ASSESSMENT) {
      if (app.scrollTop >= 80) {
        if (header) header.classList.add('sticky-header');
        if (mainBody) mainBody.classList.add('main-body-pd');
      } else {
        if (header) header.classList.remove('sticky-header');
        if (mainBody) mainBody.classList.remove('main-body-pd');
      }
    }
  }

  const dispatch = useDispatch();
  const getUserData = () => {
    const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
    if (user?.id) {
      const userId = user.id;
      dispatch(doGetUserDataRequest({ userId }));
    }
  };
  useEffect(() => {
    if (
      Object.values(COURSES_SLUGS).find(
        slugKey => window.location.pathname === `/${slugKey}`,
      )
    ) {
      getUserData();
    }
  }, []);

  useUserRef();
  return (
    <div id="app" className="App" onScroll={handleScroll}>
      <Suspense fallback={<Loader isLoading={true} />}>
        {/* <Router> */}
        <Switch>
          <PublicRoute exact path={APP_ROUTES.ROOT} component={NewHome} />
          <PublicRoute exact path={APP_ROUTES.BLOGS} component={Blogs} />
          <PublicRoute exact path={APP_ROUTES.PODCASTS} component={Podcasts} />
          <PublicRoute path={APP_ROUTES.BLOG} component={BlogDetail} />
          <PublicRoute
            exact
            path={APP_ROUTES.BLOGS_LISTING}
            component={BlogsListing}
          />
          <PublicRoute
            exact
            path={APP_ROUTES.BLOGS_LISTINGS}
            component={BlogsListing}
          />
          <PublicRoute exact path={APP_ROUTES.PODCAST} component={Podcast} />
          <PublicRoute
            exact
            path={APP_ROUTES.PODCAST_DUPLICATE}
            component={Podcast}
          />
          <PublicRoute
            exact
            path={APP_ROUTES.PODCASTS_LISTING}
            component={PodcastListing}
          />
          <PublicRoute
            exact
            path={APP_ROUTES.PODCASTS_LISTINGS}
            component={PodcastListing}
          />
          <Route
            exact
            path={APP_ROUTES.PRIVACY_POLICY}
            component={PrivacyPolicy}
          />
          <Route exact path={APP_ROUTES.GIFTS} component={Gifts} />
          <PrivateRoute
            exact
            path={APP_ROUTES.FITNESS}
            component={FitnessOptions}
            hideFooter={true}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.HOME_OLD}
            component={NewDashboard}
          />
          <PrivateRoute exact path={APP_ROUTES.HOME} component={HomeV2} />
          <PrivateRoute
            exact
            path={APP_ROUTES.CATEGORIES}
            component={CategoriesV2}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.THERAPY_PLANS}
            component={TherapyPlans}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.THERAPY_PLANS_DETAILS}
            component={CuratedPlans}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.DISCOVER_THERAPY_PLANS_DETAILED}
            component={CuratedPlans}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.THERAPY_PLANS_DETAILED_VIDEO}
            component={DeatiledVideo}
            enableAutoPlay={true}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.DISCOVER_THERAPY_PLANS_DETAILED_VIDEO}
            component={DeatiledVideo}
            enableAutoPlay={true}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.PREMIUM_COURSES}
            component={PremiumCourses}
          />
          <PublicRoute exact path={APP_ROUTES.THANK_YOU} component={ThankYou} />
          <PrivateRoute exact path={APP_ROUTES.PROFILE} component={Profile} />
          <PrivateRoute
            exact
            path={APP_ROUTES.PROFILE_WITH_SUB_ROUTES}
            component={Profile}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.COLLECTION}
            component={Collection}
          />
          <PrivateRoute exact path={APP_ROUTES.SEARCH} component={Search} />
          <PrivateRoute
            exact
            path={APP_ROUTES.COLLECTION_DETAIL}
            component={CollectionDetail}
            activeSection="Explore"
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.MOST_POPULAR_DETAILED}
            component={CollectionDetail}
          />
          <PrivateRoute exact path={APP_ROUTES.FOLDERS} component={MyFolders} />
          <PrivateRoute
            exact
            path={APP_ROUTES.COURSES}
            component={Challenges}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.DISCOVER}
            component={DiscoverPage}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.DISCOVER_CATEGORIES_DETAILED}
            component={DetailedCategories}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.CATEGORIES_DETAILED}
            component={DetailedCategories}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.DETAILED_FOLDER}
            component={DeatiledFolder}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.QUICKTIPS}
            component={QuickTips}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.DISCOVER_QUICKTIPS_VIDEO}
            component={QuickTips}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.MOST_POPULAR_DETAILED_QUICK_TIPS_VIDEO}
            component={QuickTips}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.SELF_ASSESSMENT}
            component={SelfAssessment}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.CURATED_PLANS}
            component={CuratedPlans}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.HOME_SUB_SECTION}
            component={CuratedPlans}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.COLLECTION_DETAILED_VIDEO}
            component={DeatiledVideo}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.MOST_POPULAR_DETAILED_VIDEO}
            component={DeatiledVideo}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.CATEGORIES_DETAILED_VIDEO}
            component={DeatiledVideo}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.DISCOVER_DETAILED_VIDEO}
            component={DeatiledVideo}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.SELF_ASSESSMENT_DETAILED_VIDEO}
            component={DeatiledVideo}
            enableAutoPlay={true}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.PLANS_MADE_FOR_YOU_DETAILED_VIDEO}
            component={DeatiledVideo}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.HOME_SUB_SECTION_DETAILED_VIDEO}
            component={DeatiledVideo}
            enableAutoPlay={true}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.FOLDERS_DETAILED_VIDEO}
            component={DeatiledVideo}
            enableAutoPlay={true}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.PERSONAL_DETAILS}
            component={PersonalDetails}
          />
          <PrivateRoute
            exact
            path={APP_ROUTES.BUNDLE_DEALS}
            component={BundleDeals}
          />
          <Route exact path={APP_ROUTES.FREE_TRAIL} component={Payment} />
          <Route
            exact
            path={APP_ROUTES.SUCCESS}
            component={PaymentAffiliation}
          />
          <Route
            exact
            path={APP_ROUTES.CANCEL}
            component={PaymentAffiliation}
          />
          <Route
            exact
            path={APP_ROUTES.ORDER_CONFIRMATION}
            component={OrderAffiliation}
          />
          <SurveyRoute exact path={APP_ROUTES.SURVEY} component={Survey} />
          <Route
            path={APP_ROUTES.LOGIN_CALLBACK}
            component={CallbackController}
          />
          <PublicRoute path={APP_ROUTES.CHALLENGE} component={Challenge} />
          <PublicRoute path={APP_ROUTES.BODY_PARTS} component={BodyParts} />
          {/* <PrivateRoute
            exact
            path={APP_ROUTES.CHALLENGE_DETAIL}
            component={ChallengeDetail}
          /> */}
          <PrivateRoute
            exact
            path={APP_ROUTES.PREMIUM_COURSES_DETAILS}
            component={ChallengeDetail}
          />
          {/* <PrivateRoute
            exact
            path={APP_ROUTES.CHALLENGE_TYPE_DETAILED_VIDEO}
            component={DeatiledVideo}
            enableAutoPlay={true}
          /> */}
          <PrivateRoute
            exact
            path={APP_ROUTES.PREMIUM_COURSES_DETAILED_VIDEO}
            component={DeatiledVideo}
            enableAutoPlay={true}
          />
          <SpecialRoutesRedirection
            exact
            path={APP_ROUTES.BARE_FOOT_MINI_COURSE}
          />
          <SpecialRoutesRedirection exact path={APP_ROUTES.GRATEFUL_FOR_BODY} />
          <SpecialRoutesRedirection
            exact
            path={APP_ROUTES.FULL_BODY_MOBILITY}
          />
          <SpecialRoutesRedirection
            exact
            path={APP_ROUTES.OLD_GRATEFUL_FOR_BODY}
          />
          <Route
            exact
            path={APP_ROUTES.PAGE_NOT_FOUND}
            component={NoPageFound}
          />
          <Route
            path={APP_ROUTES.COURSE_SLUG}
            exact
            render={props => {
              // eslint-disable-next-line
              const { slug } = props.match.params;
              switch (slug) {
                case COURSES_SLUGS.BARE_FOOT:
                  return <BareFootMiniCourse {...props} />;
                case COURSES_SLUGS.FULL_BODY:
                  return <FullBodyLowImpact {...props} />;
                case COURSES_SLUGS.LowBack:
                  return <LowBack {...props} />;
                case COURSES_SLUGS.GRATEFUL_FOR_BODY:
                  return <GratefulForBody {...props} />;
                case COURSES_SLUGS.FULL_BODY_MOBILITY:
                  return <FullBodyMobility {...props} />;
                case COURSES_SLUGS.UPPER_BACK_AND_NECK:
                  return <UpperBackAndNeck {...props} />;
                case COURSES_SLUGS.MOVE_TO_IMPROVE:
                  return <MoveToImprove {...props} />;
                case COURSES_SLUGS.PELVIC_FLOOR:
                  return <PelvicFloor {...props} />;
                case COURSES_SLUGS.MOBILITY_FOUNDATIONS:
                  return <MobilityFoundations {...props} />;
                case COURSES_SLUGS.MOVE_2_IMRPOVE:
                  return <Move2Improve {...props} />;
                case COURSES_SLUGS.WARMUP_COOL_DOWN:
                  return <WarmupCoolDown {...props} />;
                case COURSES_SLUGS.POSTURE_REDEFINED:
                  return <PostureRedefined {...props} />;
                default:
                  return (
                    <Redirect
                      exact
                      to={{
                        pathname: isLoggedIn()
                          ? APP_ROUTES.PREMIUM_COURSES
                          : APP_ROUTES.PAGE_NOT_FOUND,
                      }}
                    />
                  );
              }
            }}
          />
          <Route
            path={APP_ROUTES.LEAD_PAGES}
            exact
            render={props => {
              // eslint-disable-next-line
              const { slug } = props.match.params;
              switch (slug) {
                case COURSES_SLUGS.BARE_FOOT:
                  return <Barefoot {...props} />;
                case COURSES_SLUGS.HIP_MOBILITY:
                  return <HipMobility {...props} />;
                case COURSES_SLUGS.KNEE_HEALTH:
                  return <KneeHealth {...props} />;
                case COURSES_SLUGS.LOW_BACK_PAIN:
                  return <LowBackPain {...props} />;
                case COURSES_SLUGS.GRATEFUL_FOR_BODY:
                  return <ReleaseAndRecovery {...props} />;
                default:
                  return (
                    <Redirect
                      exact
                      to={{
                        pathname: isLoggedIn()
                          ? APP_ROUTES.HOME
                          : APP_ROUTES.PAGE_NOT_FOUND,
                      }}
                    />
                  );
              }
            }}
          />
          <SpecialRoutesRedirection
            exact
            path={APP_ROUTES.OLD_COURSES_BARE_FOOT}
          />
          <SpecialRoutesRedirection
            exact
            path={APP_ROUTES.OLD_COURSES_FULL_BODY_LOW_IMPACT}
          />
          <SpecialRoutesRedirection
            exact
            path={APP_ROUTES.OLD_COURSES_GRATEFUL_FOR_BODY}
          />
          <SpecialRoutesRedirection
            exact
            path={APP_ROUTES.OLD_CHALLENGES_BARE_FOOT}
          />
          <SpecialRoutesRedirection
            exact
            path={APP_ROUTES.OLD_CHALLENGES_FULL_BODY_LOW_IMPACT}
          />
          <SpecialRoutesRedirection
            exact
            path={APP_ROUTES.OLD_CHALLENGES_GRATEFUL_FOR_BODY}
          />
          <Redirect
            exact
            to={{
              pathname: APP_ROUTES.PAGE_NOT_FOUND,
              state: { from: window.location.pathname },
            }}
          />
        </Switch>
      </Suspense>
      <PaymentProgressIndicator />
      <CartOffCanvas />
    </div>
  );
}

export default App;
