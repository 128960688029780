import { axiosInstance, overrideHeader } from './index';

export const myPlansGetRequest = () => {
  overrideHeader();
  return axiosInstance.get('/users/plans');
};

export const doGetRecommendationRequest = () => {
  overrideHeader();
  return axiosInstance.get('/users/recommendations');
};

export const getUserRequest = id => {
  overrideHeader();
  return axiosInstance.get(`/users/${id}`);
};

export const updateUserProfileRequest = (id, data) => {
  overrideHeader();
  return axiosInstance.patch(`/users/${id}`, data);
};

export const checkForUniqueness = data => {
  overrideHeader();
  return axiosInstance.post('/users/unique', data);
};

export const fetchProductsRequest = () => {
  overrideHeader();
  return axiosInstance.get('/products');
};

export const initiatePaymentRequest = data => {
  overrideHeader();
  return axiosInstance.post('/orders', data);
};

export const paymentRestartRequest = () => {
  overrideHeader();
  return axiosInstance.patch('/payments/restart');
};

export const changeSubscriptionProductRequest = product_id => {
  overrideHeader();
  return axiosInstance.post('/payments/product/change', {
    product_id,
  });
};

export const fetchProductsDetailRequest = id => {
  overrideHeader();
  return axiosInstance.get('/products/' + id + window?.location?.search);
};

export const updatePaymentMethodRequest = data => {
  overrideHeader();
  return axiosInstance.post('/payments/initiate', data);
};

export const doRemoveRecommendationRequest = plan_id => {
  overrideHeader();
  const body = {
    data: {
      plan_id,
    },
  };
  return axiosInstance.delete('/users/recommendations/remove', body);
};

export const fetchCategoriesRequest = () => {
  overrideHeader();
  return axiosInstance.get('/categories');
};

export const fetchBannerRequest = () => {
  overrideHeader();
  return axiosInstance.get('/configs/banner');
};
