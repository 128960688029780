import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const InputField = props => {
  const {
    autocomplete,
    className,
    errorMessage,
    handleChange,
    label,
    maxLength,
    name,
    onBlur,
    placeholder,
    required,
    type,
    value,
  } = props;
  return (
    <Form.Group className={className} controlId="formBasicName">
      <Form.Label className="label-text">{label}</Form.Label>
      {required && label && (
        <Form.Label className="label-text text-danger">*</Form.Label>
      )}
      <Form.Control
        type={type}
        name={name}
        required={required}
        defaultValue={value}
        placeholder={placeholder}
        maxLength={maxLength}
        style={{ border: '1px solid #14142b' }}
        onBlur={onBlur}
        onChange={handleChange}
        className="form-control-survey"
        autoComplete={autocomplete}
      />
      {errorMessage && (
        <Form.Label className="error-text position-static text-danger">
          {errorMessage}
        </Form.Label>
      )}
    </Form.Group>
  );
};

InputField.propTypes = {
  autocomplete: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default InputField;
