import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Button from '../../../ui-core/Button/Button';
import { TEXT_CONSTANT, VIDEO_CONSTANT } from '../../../constants/app';
import Video from '../../../ui-core/Video';
import StartFreeTrail from '../../StartFreeTrial';

const Community = props => {
  return (
    <section className="bg-Alabaster pb-5 pt-5">
      <Container className="pe-lg-5">
        <Row className="px-lg-2 align-items-center">
          <Col md={6} className="pe-lg-5">
            <div className="d-flex community-image-wrapper">
              <Video
                mp4={VIDEO_CONSTANT.communityVideoMp4}
                removeIntersectionObserver
                webm={VIDEO_CONSTANT.communityVideoWebm}
              />
            </div>
          </Col>
          <Col
            md={6}
            lg={5}
            className="d-flex  flex-column justify-content-center"
          >
            <div className="px-lg-0 px-2 py-md-5">
              <h1 className="h-3 sec-head">
                {TEXT_CONSTANT.COMMUNITY_HEADER_TEXT}
              </h1>
              <p className="section-para pe-3">
                {TEXT_CONSTANT.COMMUNITY_SUB_TEXT}
              </p>
              <div className="w-100 mt-4 py-2">
                {props.freetrialPage ? (
                  <StartFreeTrail
                    onClick={props.onClick}
                    loading={props.loading}
                  />
                ) : (
                  <Button
                    onClick={props.onClick}
                    text="JOIN NOW"
                    class="sign-up-button-new d-block auto w-216"
                    isDisabled={false}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

Community.propTypes = {
  onClick: PropTypes.func,
  freetrialPage: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Community;
