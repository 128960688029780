import {
  CLEAR_SEARCH_RESULTS,
  SEARCH_SET_IS_LOADING,
  SEARCH_INPUT_CHANGE,
  SET_SEARCH_RESULTS,
} from '../../constant/search';
import { RESET_STORE } from '../../constant/common';

const initialState = {
  isLoading: true,
  searchInput: '',
  searchResults: null,
};

export const searchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case SET_SEARCH_RESULTS:
      return { ...state, searchResults: payload };
    case CLEAR_SEARCH_RESULTS:
      return { ...state, searchResults: null };
    case RESET_STORE:
      return { ...initialState };
    case SEARCH_INPUT_CHANGE:
      return { ...state, searchInput: payload };
    default:
      return state;
  }
};
