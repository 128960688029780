import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Button from '../../../../ui-core/Button/Button';
import { IMAGE_CONSTANT, TEXT_CONSTANT } from '../../../../constants/app';
import { formatCurrency } from '../../../../utils/helpers';

import './style.css';

export const Features = ({ courseDetail, onClick }) => {
  const featuresListOne = [
    '30 Structured Days with Full Calendar',
    'Access to 10 Self-Assessment Videos',
    '17 Unique Mobility Exercises',
    '8 Different Mobility Flows to address everything from the neck down to the ankles',
  ];
  const featuresListTwo = [
    'Education from a Doctor of Physical Therapy to understand the "why" behind each exercise',
    'Modifications provided for any level',
    'Community & Accountability',
    '25 Full Videos to Follow with Expert Cueing',
  ];

  const currentPrice = useMemo(() => {
    return courseDetail?.attributes?.amount
      ? +courseDetail?.attributes?.amount / 100
      : 0;
  }, [courseDetail?.attributes?.amount]);

  const perDayPrice = useMemo(() => {
    return Math.ceil(currentPrice / 30);
  }, [currentPrice]);

  const headerText = `When you sign up for less than ${formatCurrency(
    perDayPrice,
    courseDetail?.attributes?.currency,
    0,
  )}/day, you will receive`;

  const handleClick = () => {
    if (onClick) {
      onClick(TEXT_CONSTANT.JOIN_NOW, 'isSignup', headerText);
    }
  };

  return (
    <div className="features-section-mobility ">
      <h4>{headerText}</h4>
      <Row>
        <Col>
          <ul>
            {featuresListOne.map(feature => (
              <li className="mb-2" key={feature}>
                <p>{feature}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col>
          <ul>
            {featuresListTwo.map(feature => (
              <li className="mb-2" key={feature}>
                <p>{feature}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <div className="ps-tag d-flex align-items-center">
        <img alt="piggy" className="me-3" src={IMAGE_CONSTANT.piggyBankIcon} />
        <span>{`P.S. Yup... less than ${formatCurrency(
          perDayPrice,
          courseDetail?.attributes?.currency,
          0,
        )}/DAY!! That's a ${formatCurrency(
          currentPrice - currentPrice * 0.2,
          courseDetail?.attributes?.currency,
          0,
        )} investment into your health!`}</span>
      </div>
      <Button
        onClick={handleClick}
        text={TEXT_CONSTANT.JOIN_NOW}
        class="sign-up-button-new d-inline-block mx-auto mb-3"
      />
    </div>
  );
};

Features.propTypes = {
  courseDetail: PropTypes.object,
  onClick: PropTypes.func,
};
