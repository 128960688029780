import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import './style.css';

const SkeletonElements = ({ className, component, type, style }) => {
  const classes = clsx(`${component} ${type}`, className);
  return <div className={classes} style={style}></div>;
};

SkeletonElements.propTypes = {
  className: PropTypes.string,
  component: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
};

export default SkeletonElements;
