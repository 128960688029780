import React from 'react';
import {
  EXCERCISE_FREQUENCY_OPTIONS,
  GOALS_OPTIONS,
  LOCAL_STORAGE_CONSTANT,
  MONTHS,
  PAYMENT_INTERVAL,
  PREFFERED_PART_OF_DAY_OPTIONS,
  STATUS,
  TIME_PER_DAY_OPTIONS,
  TRIED_SOLUTION_OPTIONS,
} from '../constants/app';
import { getItem, removeItem } from './cache';
import { extractJSON } from '../services/jsonUtil';
import { APP_ROUTES, COURSES_SLUGS } from '../route';
import StarIcon from '../ui-core/Star';
import { NOTIFICATION_TYPE } from '../services/Notification';
import dayjs from 'dayjs';

export const setInLocalStorage = (name, data) => {
  localStorage.setItem(name, data);
};

export const retrieveFromLocalStorage = name => {
  const retrievedData = localStorage.getItem(name);
  return retrievedData;
};

export const removeFromLocalStorage = name => {
  localStorage.removeItem(name);
};

export const getUserAttribute = key => {
  const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
  return user?.attributes ? user?.attributes[key] : undefined;
};

export const sliceString = (string, index, length) => {
  if (string) {
    const finalString = string.slice(index, length);
    return finalString;
  }
  return string;
};

export const sortObjectArray = (a, b) => {
  const nameFirst = a.label ? a.label?.toUpperCase() : a.text?.toUpperCase();
  const nameSecond = b.label ? b.label?.toUpperCase() : b.text?.toUpperCase();

  let comparison = 0;
  if (nameFirst > nameSecond) {
    comparison = 1;
  } else if (nameFirst < nameSecond) {
    comparison = -1;
  }
  return comparison;
};

export const movePointerOnTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

export const checkImageFormateIsWebp = image => {
  let imageType = image;
  if (image && /\.(gif|jpe?g|tiff|png|bmp)$/i.test(image)) {
    imageType = image.replace(/\.(gif|jpe?g|tiff|png|bmp)$/i, '.webp');
  }
  return imageType;
};

export const openUrlOnNewTab = path => {
  const win = window.open(path, '_blank');
  win.focus();
};

export const getInitialsProfileImage = (firstName, lastName) => {
  let initials = '';
  if (firstName && lastName) {
    initials = `${firstName.charAt(0)?.toUpperCase()}${lastName
      .charAt(0)
      ?.toUpperCase()}`;
  } else if (firstName) {
    initials = `${firstName.charAt(0)?.toUpperCase()}`;
  }
  return <div className="user-initials">{initials}</div>;
};

export const getErrorMessage = (error, msg) => {
  let message = msg;
  if (error?.response?.data?.error) {
    message = error.response.data.error;
  }
  return message;
};

export const checkAnyTrueObjectValue = objData => {
  const isTrue = Object.values(objData).some(item => item);
  return isTrue;
};

// Get first true value from object
export const getTrueKey = obj => {
  for (const key in obj) {
    if (obj[key]) return key;
  }
  return undefined;
};

export const isEmpty = objectToCheck =>
  Object.entries(objectToCheck).length === 0;

export const getLodash = (value, path, defaultValue) =>
  String(path)
    .split('.')
    .reduce((acc, v) => {
      try {
        acc = acc[v] !== undefined && acc[v] !== null ? acc[v] : defaultValue;
      } catch (e) {
        return defaultValue;
      }
      return acc;
    }, value);

export const splitArrayAsUNiqueCombination = dataset => {
  const result = dataset.reduce((acc, item) => {
    if (!acc[item.codeIataCity]) {
      acc[item.codeIataCity] = [];
    }

    acc[item.codeIataCity].push(item);
    return acc;
  }, {});
  return result;
};

// export const convertDateFormat = (date) => {
//   const newDate = moment.unix(date/1000, 'YYYY-MM-DD')
//   return moment(newDate).format('YYYY-MM-DD')
// }

export const isLoggedIn = () => {
  const authObject = getItem(LOCAL_STORAGE_CONSTANT.SESSION);
  return Boolean(authObject);
};

export const isSurveySession = () => {
  const authObject = getItem(LOCAL_STORAGE_CONSTANT.SURVEY_SESSION);
  return Boolean(authObject);
};

export const getCountryCode = () => {
  return navigator.language.split('-')[1].toLowerCase();
};

export const isMobileSafari = () => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
  return iOSSafari;
};

export const rangeOfYears = (start, end) =>
  Array(end - start + 1)
    .fill(start)
    .map((year, index) => year + index);

export const isValidateEmail = email => {
  const regx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regx.test(String(email).toLowerCase());
};

export const isWidthUp = (width, currentWidth) => {
  switch (width) {
    case 'xs':
      return currentWidth > 0;
    case 'sm':
      return currentWidth > 600;
    case 'md':
      return currentWidth > 990;
    case 'lg':
      return currentWidth > 1280;
    case 'xl':
      return currentWidth > 1920;
    default:
      return false;
  }
};

export const getDisplayType = currentWidth => {
  if (currentWidth > 1024) {
    return 'desktop';
  } else if (currentWidth > 767) {
    return 'ipad';
  } else {
    return 'mobile';
  }
};

export const getStatusClass = status => {
  switch (status) {
    case STATUS.COMPLETED:
      return 'status-completed';
    case STATUS.NEW:
    case STATUS.IN_PROGRESS:
      return 'status-in-progress';
    case STATUS.NOT_STARTED:
      return 'status-not-started';
    default:
      return '';
  }
};

export const redirectTo = redirectionLink => {
  const url = `${window.location.origin}${redirectionLink}`;
  const linkElement = document.createElement('a');
  linkElement.href = url;
  linkElement.click();
};

export function iOS() {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function isDeviceIPhone() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
}

export function isTouchEnabled() {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

export const getPromptLink = (email, subject, body) => {
  let href = `mailto: ${email}?`;
  if (subject) {
    href += `subject=${encodeURI(subject)},`;
  }
  if (body) {
    href += `body=${encodeURI(body)},`;
  }
  return href;
};

export const scrollToTop = () => {
  const app = document.getElementById('app');
  // const header = document.getElementById('header-section');
  // const mainBody = document.getElementById('main-body');
  // if (header?.classList?.remove) mainBody.classList.remove('sticky-header');
  // if (mainBody?.classList?.remove) mainBody.classList.remove('main-body-pd');
  if (app && app.scrollTo) {
    document.getElementById('app').scrollTo(0, 0);
  }
};

export const formatCurrency = (value, currency, decimal = 2) => {
  return value.toLocaleString('en', {
    style: 'currency',
    currency: currency || 'USD',
    maximumFractionDigits: decimal,
    minimumFractionDigits: decimal,
  });
};

export const getSubscriptionChargeDate = numberOfDays => {
  const someDate = new Date();
  const result = someDate.setDate(someDate.getDate() + numberOfDays);
  return new Date(result);
};

export const getMonthName = d => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return monthNames[d.getMonth()];
};

export const getFormattedDate = (value, format = 'DD-MMM-YYYY') => {
  if (value) {
    const date = new Date(value);
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const months = Object.keys(MONTHS);
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    switch (format) {
      case 'MM-DD-YYYY':
        return month + ' ' + day + ', ' + year;
      default:
        return day + ' ' + month.substring(0, 3) + ' ' + year;
    }
  }
  return '';
};

export const getPercentageSaved = products => {
  const monthlyPrice = products?.find(
    product => product?.attributes?.interval === PAYMENT_INTERVAL.MONTHLY,
  )?.attributes?.amount_in_cents;
  const yearlyPrice = products?.find(
    product => product?.attributes?.interval === PAYMENT_INTERVAL.YEARLY,
  )?.attributes?.amount_in_cents;
  if (monthlyPrice && yearlyPrice) {
    const annualCostForMonthly = 12 * monthlyPrice;
    const percentageOff =
      ((annualCostForMonthly - yearlyPrice) / annualCostForMonthly) * 100;
    return percentageOff.toFixed(0);
  }
  return 0;
};

export function clearDataAndRedirectToLogin() {
  setTimeout(() => {
    removeItem(LOCAL_STORAGE_CONSTANT.USER);
    removeItem(LOCAL_STORAGE_CONSTANT.SESSION);
    removeItem(LOCAL_STORAGE_CONSTANT.SELECTED_FILTERS);
    // redirectTo(APP_ROUTES.ROOT);
    window.location.replace(window.location.origin);
  }, 500);
}

export function daysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

const getDefaultOptions = key => {
  switch (key) {
    case 'goal':
      return GOALS_OPTIONS;
    case 'tried_solutions':
      return TRIED_SOLUTION_OPTIONS;
    case 'exercise_frequency':
      return EXCERCISE_FREQUENCY_OPTIONS;
    case 'time_per_day':
      return TIME_PER_DAY_OPTIONS;
    case 'preferred_part_of_day':
      return PREFFERED_PART_OF_DAY_OPTIONS;
    default:
      return [];
  }
};

export const extractOptions = (key, optionsArray = []) => {
  const options = optionsArray.find(item => item.name === key);
  if (options) {
    return options.options;
  }
  return getDefaultOptions(key);
};

export const logOutput = (...args) => {
  if (
    process.env.REACT_APP_ENV === 'dev' ||
    process.env.REACT_APP_ENV === 'staging'
  ) {
    console.log(...args);
  }
};

export const openLink = (url, samewindow = false) => {
  const newWindow = window.open(
    url,
    samewindow ? '_self' : '_blank',
    'noopener,noreferrer',
  );
  if (newWindow) newWindow.opener = null;
};

export const getPageName = path => {
  switch (path) {
    case APP_ROUTES.ROOT:
      return 'Landing Page';
    case APP_ROUTES.CANCEL:
      return 'Payment Failed Page';
    case APP_ROUTES.COLLECTION:
      return 'Collection Page';
    case APP_ROUTES.COLLECTION_DETAIL:
      return 'Collection Detail Page';
    case APP_ROUTES.COLLECTION_DETAILED_VIDEO:
    case APP_ROUTES.COLLECTION_DETAILED_VIDEO_QUICK_TIP:
    case APP_ROUTES.FOLDERS_DETAILED_VIDEO:
    case APP_ROUTES.PLANS_MADE_FOR_YOU_DETAILED_VIDEO:
    case APP_ROUTES.SELF_ASSESSMENT_DETAILED_VIDEO:
    case APP_ROUTES.THERAPY_PLANS_DETAILED_VIDEO:
    case APP_ROUTES.HOME_SUB_SECTION_DETAILED_VIDEO:
    case APP_ROUTES.DISCOVER_QUICKTIPS_VIDEO:
    case APP_ROUTES.CATEGORIES_DETAILED_VIDEO:
    case APP_ROUTES.DISCOVER_THERAPY_PLANS_DETAILED_VIDEO:
    case APP_ROUTES.MOST_POPULAR_DETAILED_VIDEO:
    case APP_ROUTES.MOST_POPULAR_DETAILED_QUICK_TIPS_VIDEO:
    case APP_ROUTES.PREMIUM_COURSES_DETAILED_VIDEO:
      return 'Video Detail Page';
    case APP_ROUTES.CATEGORIES:
      return 'Categories Page';
    case APP_ROUTES.CATEGORIES_DETAILED:
    case APP_ROUTES.DISCOVER_CATEGORIES_DETAILED:
      return 'Category Detail Page';
    case APP_ROUTES.THERAPY_PLANS:
      return 'Therapy Plans Page';
    case APP_ROUTES.THERAPY_PLANS_DETAILS:
    case APP_ROUTES.DISCOVER_THERAPY_PLANS_DETAILED:
      return 'Therapy Plans Detail Page';
    case APP_ROUTES.PREMIUM_COURSES:
      return 'Premium Courses Page';
    case APP_ROUTES.PREMIUM_COURSES_DETAILS:
      return 'Premium Courses Detail Page';
    case APP_ROUTES.MOST_POPULAR_DETAILED:
      return 'Most Popular Detail Page';
    case APP_ROUTES.CURATED_PLANS:
      return 'Plan Detail Page';
    case APP_ROUTES.DASHBOARD:
      return 'Dashboard Page';
    case APP_ROUTES.DETAILED_FOLDER:
      return 'Folder Detail Page';
    case APP_ROUTES.DETAILED_QUICK_TIPS:
      return 'Quick Tips Detail Page';
    case APP_ROUTES.FITNESS:
      return 'Onboarding Page';
    case APP_ROUTES.FOLDERS:
      return 'Folders Page';
    case APP_ROUTES.FREE_TRAIL:
      return 'Free Trial Page';
    case APP_ROUTES.HOME:
      return 'Home Page';
    case APP_ROUTES.DISCOVER:
      return 'Discover Page';
    case APP_ROUTES.HOME_SUB_SECTION:
      return 'Plan Detail Page';
    case APP_ROUTES.QUICKTIPS:
      return 'Quicktips Page';
    case APP_ROUTES.SELF_ASSESSMENT:
      return 'Self Assessment Page';
    case APP_ROUTES.SEARCH:
      return 'Search Page';
    case APP_ROUTES.PERSONAL_DETAILS:
      return 'Personal Details Page';
    case APP_ROUTES.PROFILE:
      return 'Profile Page';
    case APP_ROUTES.BODY_PARTS:
      return 'Body Parts Page';
    case APP_ROUTES.CHALLENGE:
      return 'Challenge Page';
    case APP_ROUTES.FULL_BODY_LOW_IMPACT:
      return 'Full Body Low Impact Page';
    case APP_ROUTES.BARE_FOOT_MINI_COURSE:
      return 'Bare-Foot Mini Course';
    case APP_ROUTES.GIFTS:
      return 'Gifts';
    case APP_ROUTES.FULL_BODY_MOBILITY:
      return 'Full Body Mobility';
    case APP_ROUTES.BUNDLE_DEALS:
      return 'Bundle Deals';
    case `/${COURSES_SLUGS.BARE_FOOT}`:
      return COURSES_SLUGS.BARE_FOOT;
    case `/${COURSES_SLUGS.FULL_BODY}`:
      return 'Full Body Low Impact';
    case `/${COURSES_SLUGS.FULL_BODY_MOBILITY}`:
      return COURSES_SLUGS.FULL_BODY_MOBILITY;
    case `/${COURSES_SLUGS.GRATEFUL_FOR_BODY}`:
      return COURSES_SLUGS.GRATEFUL_FOR_BODY;
    case `/${COURSES_SLUGS.HIP_MOBILITY}`:
      return 'Hip Mobility';
    case `/${COURSES_SLUGS.KNEE_HEALTH}`:
      return 'Hip Mobility';
    case `/${COURSES_SLUGS.LOW_BACK_PAIN}`:
      return 'Low Back Pain';
    case `/${COURSES_SLUGS.MOVE_TO_IMPROVE}`:
      return 'Move To Improve';
    case `/${COURSES_SLUGS.UPPER_BACK_AND_NECK}`:
      return 'Upper Back & Neck Plan';
    default:
      return '';
  }
};

export const getStarsFromRating = ratings => {
  const maxStars = 5;
  const fullyFilledStars = Math.floor(ratings);
  const partialFilledStars = Math.ceil(maxStars - ratings);
  const stars = [];
  Array.from({ length: fullyFilledStars }).forEach(_ => {
    stars.push([
      { color: '#FB8200', offset: '0%' },
      { color: '#FB8200', offset: '100%' },
    ]);
  });
  Array.from({ length: partialFilledStars }).forEach((_, index) => {
    const leftOffset = ((ratings - fullyFilledStars) * 100).toFixed(0) + '%';
    if (index === 0) {
      stars.push([
        { color: '#FB8200', offset: '0%' },
        { color: '#FB8200', offset: leftOffset },
        { color: 'white', offset: leftOffset },
        { color: 'white', offset: '100%' },
      ]);
    } else {
      stars.push([
        { color: 'white', offset: '0%' },
        { color: 'white', offset: '100%' },
      ]);
    }
  });
  return stars;
};

export const renderReviewStars = ratings => {
  const stars = getStarsFromRating(ratings);
  return stars.map((gradiants, index) => {
    const id = 'grad' + (index + 1);
    return <StarIcon key={`star-${index + 1}`} gradiants={gradiants} id={id} />;
  });
};

export function localeCompare(a, b) {
  return (a || '').localeCompare(b || '');
}

export function extractVideoSources(video) {
  const sources = [];
  if (video) {
    Object.entries(video).forEach(([type, src]) => {
      sources.push({
        src,
        type: `video/${type}`,
      });
    });
  }
  return sources;
}

export const errorHandler = error => {
  const errorArray = error?.response?.data?.response?.errors;
  if (Array.isArray(errorArray)) {
    return {
      type: NOTIFICATION_TYPE.ERROR,
      message: error?.response?.data?.response?.errors[0],
    };
  } else if (typeof errorArray === 'string') {
    return {
      type: NOTIFICATION_TYPE.ERROR,
      message: errorArray,
    };
  }
  const errorMessage = error?.response?.data;
  if (errorMessage)
    return {
      message: errorMessage,
    };
  return {
    type: NOTIFICATION_TYPE.ERROR,
    message: error?.message || 'Somenting went wrong',
  };
};

export const isNotNullOrUndefined = val => val !== undefined && val !== null;

export const addAosAnimateClassObserver = () => {
  return new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (!entry.target.classList.contains('aos-animate')) {
            entry.target.classList.add('aos-animate');
          }
        }
      });
    },
    { rootMargin: '0px', threshold: 0.2 },
  );
};

export const blogImageUrl = 'https://crm.jen.health';

export const blogDateConversion = date => {
  // const inputDate = new Date(date);
  // const options = { day: 'numeric', month: 'short', year: '2-digit' };
  // const formattedDate = inputDate.toLocaleDateString('en-GB', options);
  // return formattedDate;
  return dayjs(date).format('MMM D, YYYY');
};

export const getFormatterContent = content => {
  if (content) {
    // Define a regular expression pattern to match img tags with src attribute
    const imgSrcRegex = /<img[^>]*\ssrc="([^"]+)"/g;
    // console.log('11111', content);
    // Replace the src attribute in all img tags
    let modifiedString = content.replace(imgSrcRegex, (match, src) => {
      const newSrc = `https://crm.jen.health${src}`;
      return `<img src="${newSrc}"`;
    });

    // Use a regular expression to find the oembed element in the HTML string
    const oembedRegex = /<oembed[^>]*>/g;
    const oembedMatch = modifiedString.match(oembedRegex);

    // If an oembed element was found, convert it to an iframe element
    if (oembedMatch) {
      let oembedUrl = oembedMatch[0].match(/url="([^"]*)"/)[1];
      if (oembedUrl?.includes('youtube')) {
        try {
          const iframeUrl = new URL(oembedUrl);
          const embedParam = iframeUrl.searchParams.get('v');
          oembedUrl = `https://www.youtube.com/embed/${embedParam}`;
        } catch (error) {
          console.error(error);
        }
      }

      const iframeElement = `<iframe src="${oembedUrl}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      modifiedString = modifiedString.replace(oembedRegex, iframeElement);
    }

    return modifiedString;
  }
  return '';
};

export const blogContentParser = content => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');

  return doc.body.textContent;
};

export function debounce(callback, timeout = 300) {
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      callback.apply(this, args);
    }, timeout);
  };
}
