import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useInView from '../../hooks/useInView';

const Video = React.forwardRef(
  ({ webm, mp4, videoClassName, removeIntersectionObserver, ...rest }, ref) => {
    const videoClass = videoClassName ? `${videoClassName}` : 'w-100';
    const videoRef = useRef(null);
    const inView = useInView(videoRef.current);

    return (
      <div ref={videoRef}>
        {inView || removeIntersectionObserver ? (
          <video
            autoPlay
            className={videoClass}
            loop
            muted
            playsInline
            ref={ref}
            {...rest}
          >
            {webm && <source src={webm} type="video/webm" />}
            <source src={mp4} type="video/mp4" />
          </video>
        ) : null}
      </div>
    );
  },
);

Video.displayName = 'Video';

Video.propTypes = {
  controls: PropTypes.bool,
  mp4: PropTypes.string.isRequired,
  muted: PropTypes.bool,
  removeIntersectionObserver: PropTypes.bool,
  videoClassName: PropTypes.string,
  webm: PropTypes.string,
};

export default Video;
