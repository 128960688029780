import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { capitalCase } from 'change-case';
import { getStatusClass } from '../../utils/helpers';

const StatusBadge = props => {
  const statusClass = getStatusClass(props.status);
  return (
    <span
      className={clsx('completed d-block text-white rounded px-1', statusClass)}
    >
      {props.status ? capitalCase(props.status) : 'NA'}
    </span>
  );
};

StatusBadge.propTypes = {
  status: PropTypes.string,
};

export default StatusBadge;
