import { axiosInstance, overrideHeader } from '.';

export const getGiftProductsRequest = () => {
  overrideHeader();
  return axiosInstance.get('/gifts/products');
};

export const buyGiftRequest = body => {
  overrideHeader();
  return axiosInstance.post('/gifts', body);
};
