import {
  CLEAR_SEARCH_RESULTS,
  DO_SEARCH_REQUEST,
  DO_SEARCH_REQUEST_FOR_NEXT_PAGE,
  SEARCH_INPUT_CHANGE,
  SEARCH_SET_IS_LOADING,
  SET_SEARCH_RESULTS,
} from '../../constant/search';

export const setIsLoading = payload => ({
  type: SEARCH_SET_IS_LOADING,
  payload,
});

export const setSearchInput = payload => ({
  type: SEARCH_INPUT_CHANGE,
  payload,
});

export const setSearchResults = payload => ({
  type: SET_SEARCH_RESULTS,
  payload,
});

export const doClearSearchResults = () => ({
  type: CLEAR_SEARCH_RESULTS,
});

export const doSearchRequestForNextPage = payload => ({
  type: DO_SEARCH_REQUEST_FOR_NEXT_PAGE,
  payload,
});

export const doSearchRequest = payload => ({
  type: DO_SEARCH_REQUEST,
  payload,
});
