import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import SkeletonElements from '../Skeletons';

const SelfAsessmentSkeleton = ({ theme }) => {
  const themeClass = theme || 'light';
  return (
    <div className={`skeleton-wrapper ${themeClass}`}>
      <div className="pb-lg-3 pb-2">
        <Row>
          <Col>
            <SkeletonElements
              type="self-thumbnail skeleton"
              component="self-skeleton"
            />
            <SkeletonElements
              type="self-title skeleton"
              component="self-skeleton"
            />
            <SkeletonElements
              type="self-text skeleton"
              component="self-skeleton"
            />
            <SkeletonElements
              type="self-text skeleton"
              component="self-skeleton"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

SelfAsessmentSkeleton.propTypes = {
  theme: PropTypes.string,
};

export default SelfAsessmentSkeleton;
