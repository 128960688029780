// import { all } from 'redux-saga/effects';
import authSaga from './auth';
import checkoutSaga from './checkout';
import collectionSaga from './collection';
import commonSaga from './common';
import foldersSaga from './folders';
import planSaga from './plan';
import searchSaga from './search';
import selfAssessmentSaga from './selfAssessment';
import userSaga from './user';
import productsSaga from './products';
import challengesAndCoursesSaga from './challengesAndCourses';
import videoSaga from './video';
import giftSaga from './gifts';
import questionnairesSaga from './questionnaires';
import blogSaga from './blog';
import podcastSaga from './podcast';
import discoverSaga from './discover';

export default [
  authSaga,
  checkoutSaga,
  blogSaga,
  podcastSaga,
  collectionSaga,
  commonSaga,
  foldersSaga,
  planSaga,
  searchSaga,
  selfAssessmentSaga,
  userSaga,
  productsSaga,
  challengesAndCoursesSaga,
  videoSaga,
  giftSaga,
  questionnairesSaga,
  discoverSaga,
];
