import { LOCAL_STORAGE_CONSTANT } from '../constants/app';
import { axiosInstance, overrideHeader } from './index';

/**
 * API request
 * */

export const waitlistSurveyGetRequest = async () => {
  await overrideHeader(LOCAL_STORAGE_CONSTANT.SURVEY_SESSION);
  return axiosInstance.get('/surveys/onboarding');
};
