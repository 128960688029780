export const PODCAST_SET_IS_LOADING = 'PODCAST_SET_IS_LOADING';
export const PODCAST_DETAIL_SET_IS_LOADING = 'PODCAST_DETAIL_SET_IS_LOADING';
export const PODCAST_BY_CATEGORY_IS_LOADING = 'PODCAST_BY_CATEGORY_IS_LOADING';

export const DO_FETCH_PODCAST_REQUEST = 'DO_FETCH_PODCAST_REQUEST';
export const DO_FETCH_PODCAST_REQUEST_SUCCESS =
  'DO_FETCH_PODCAST_REQUEST_SUCCESS';
export const DO_FETCH_PODCAST_META_REQUEST_SUCCESS =
  'DO_FETCH_PODCAST_META_REQUEST_SUCCESS';
export const DO_FETCH_PODCAST_CATEGORIES_META_REQUEST_SUCCESS =
  'DO_FETCH_PODCAST_CATEGORIES_META_REQUEST_SUCCESS';
export const DO_FETCH_PODCAST_FAILURE = 'DO_FETCH_PODCAST_FAILURE';
export const DO_FETCH_PODCAST_SPOTLIGHT_REQUEST_SUCCESS =
  'DO_FETCH_PODCAST_SPOTLIGHT_REQUEST_SUCCESS';

export const DO_FETCH_PODCAST_CATEGORY_REQUEST =
  'DO_FETCH_PODCAST_CATEGORY_REQUEST';
export const DO_FETCH_PODCAST_CATEGORY_REQUEST_SUCCESS =
  'DO_FETCH_PODCAST_CATEGORY_REQUEST_SUCCESS';
export const DO_FETCH_PODCAST_CATEGORY_FAILURE =
  'DO_FETCH_PODCAST_CATEGORY_FAILURE';
export const DO_FETCH_DETAIL_PODCAST_REQUEST =
  'DO_FETCH_DETAIL_PODCAST_REQUEST';
export const DO_FETCH_DETAIL_PODCAST_REQUEST_SUCCESS =
  'DO_FETCH_DETAIL_PODCAST_REQUEST_SUCCESS';

export const DO_FETCH_PODCAST_BY_CATEGORY_REQUEST =
  'DO_FETCH_PODCAST_BY_CATEGORY_REQUEST';

export const DO_FETCH_PODCAST_BY_CATEGORY_REQUEST_SUCCESS =
  'DO_FETCH_PODCAST_BY_CATEGORY_REQUEST_SUCCESS';

export const SEARCH_PODCAST_STRING_REQUEST = 'SEARCH_PODCAST_STRING_REQUEST';

export const SEARCH_PODCAST_STRING_REQUEST_SUCCESS =
  'SEARCH_PODCAST_STRING_REQUEST_SUCCESS';
