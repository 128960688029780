import React from 'react';
import PropTypes from 'prop-types';
import '../Ref/style.css';

const Ref = props => {
  return (
    <a className={props.class} onClick={props.onClick}>
      {props.text}
    </a>
  );
};

Ref.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  class: PropTypes.string,
};

export default Ref;
