import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import {
  DO_ADD_TO_CART_REQUEST,
  DO_CREATE_ORDER_REQUEST,
  DO_FETCH_CART_REQUEST,
  DO_REMOVE_FROM_CART_REQUEST,
} from '../../constant/checkout';
import {
  addToCartRequest,
  createOrderRequest,
  getCartInfoRequest,
  removeToCartRequest,
} from '../../../api/checkout';
import { createNotification } from '../../../services/Notification';
import { errorHandler } from '../../../utils/helpers';
import { doFetchCartSuccess, setIsLoading } from '../../action/checkout';
import { setPaymentIsInProgress } from '../../action/common';
import { SEGMENT_EVENTS, trackEvent } from '../../../utils/Segment';
import { LOCAL_STORAGE_CONSTANT } from '../../../constants/app';
import { getItem } from '../../../utils/cache';

function* addToCart({ payload }) {
  const { product } = payload || {};
  trackEvent(SEGMENT_EVENTS.PRODUCT_ADD_TO_CART, {
    product_id: product.id,
    product_name: product.name,
    amount: product.amount,
  });
  yield put(setIsLoading(true));
  try {
    const response = yield call(addToCartRequest, product.id);
    // const response = yield call(getCartInfoRequest);
    yield put(doFetchCartSuccess(response?.data?.response?.data));
  } catch (error) {
    console.log('ERROR:', error);
    createNotification(errorHandler(error));
  }
  yield put(setIsLoading(false));
}

function* removeFromCart({ payload }) {
  const { product } = payload || {};
  trackEvent(SEGMENT_EVENTS.PRODUCT_REMOVE_FROM_CART, {
    product_id: product.id,
    product_name: product.name,
    amount: product.amount,
  });
  yield put(setIsLoading(true));
  try {
    const response = yield call(removeToCartRequest, product.id);
    // const response = yield call(getCartInfoRequest);
    yield put(doFetchCartSuccess(response?.data?.response?.data));
  } catch (error) {
    console.log('ERROR:', error);
    createNotification(errorHandler(error));
  }
  yield put(setIsLoading(false));
}

function* fetchCart() {
  yield put(setIsLoading(true));
  try {
    const response = yield call(getCartInfoRequest);
    yield put(doFetchCartSuccess(response?.data?.response?.data));
  } catch (error) {
    console.log('ERROR:', error);
    createNotification(errorHandler(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* createOrder() {
  yield put(setPaymentIsInProgress(true));
  trackEvent(SEGMENT_EVENTS.CREATE_ORDER);
  try {
    let params;
    if (getItem(LOCAL_STORAGE_CONSTANT.FB_CLID)) {
      params = {
        fbc: getItem(LOCAL_STORAGE_CONSTANT.FB_CLID),
      };
    }
    const response = yield call(createOrderRequest, params);
    const url = response?.data?.response?.data?.url || response?.data?.response;
    if (url) {
      window.location.assign(url);
    }
  } catch (error) {
    console.log('ERROR:', error);
    yield put(setPaymentIsInProgress(false));
    createNotification(errorHandler(error));
  }
}

/**
 * Watch checkout actions
 * */
export default function* checkoutSaga() {
  yield all([
    takeLeading(DO_ADD_TO_CART_REQUEST, addToCart),
    takeLeading(DO_REMOVE_FROM_CART_REQUEST, removeFromCart),
    takeLatest(DO_FETCH_CART_REQUEST, fetchCart),
    takeLeading(DO_CREATE_ORDER_REQUEST, createOrder),
  ]);
}
