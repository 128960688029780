import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getCollectionDetails, getCollections } from '../../../api/collection';
import { createNotification } from '../../../services/Notification';
import { errorHandler } from '../../../utils/helpers';
import {
  doGetCollectionDetailsFailure,
  doGetCollectionDetailsSuccess,
  doGetCollectionsFailure,
  doGetCollectionsSuccess,
  setIsLoading,
} from '../../action/collection';
import {
  DO_GET_COLLECTION_DETAILS_REQUEST,
  DO_GET_COLLECTION_REQUEST,
} from '../../constant/collection';

function* collectionRequest() {
  yield put(setIsLoading(true));
  try {
    const response = yield call(getCollections);
    const paging = response.data.paging;
    const data = response.data.response.data;
    yield put(doGetCollectionsSuccess({ data, paging }));
  } catch (error) {
    yield put(doGetCollectionsFailure());
    createNotification(errorHandler(error));
  }
  yield put(setIsLoading(false));
}

function* collectionDetailsRequest({ payload }) {
  const { id } = payload || {};
  yield put(setIsLoading(true));
  try {
    const response = yield call(getCollectionDetails, id);
    yield put(doGetCollectionDetailsSuccess(response.data.response.data));
  } catch (error) {
    yield put(doGetCollectionDetailsFailure());
    createNotification(errorHandler(error));
  }
  yield put(setIsLoading(false));
}

/**
 * Watch selfAssessment actions
 * */
export default function* collectionSaga() {
  yield all([
    takeLatest(DO_GET_COLLECTION_REQUEST, collectionRequest),
    takeLatest(DO_GET_COLLECTION_DETAILS_REQUEST, collectionDetailsRequest),
  ]);
}
