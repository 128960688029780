import React from 'react';
import SkeletonElements from '../Skeletons';

const FiltersSkeletons = () => {
  return (
    <div className="filter-box-wrapper">
      <SkeletonElements
        type="filter-box skeleton"
        component="filter-skeleton"
      />
    </div>
  );
};

export default FiltersSkeletons;
