import {
  CHALLENGES_AND_COURSES_SET_IS_LOADING,
  DO_GET_ALL_COURSES_SUCCESS,
  DO_GET_CHALLENGES_LIST_SUCCESS,
  DO_GET_CHALLENGE_DETAILS_FAILURE,
  DO_GET_CHALLENGE_DETAILS_SUCCESS,
  DO_GET_COURSE_DETAILS_FAILURE,
  DO_GET_COURSE_DETAILS_SUCCESS,
  DO_GET_PUBLIC_PLANS_FAILURE,
  DO_GET_PUBLIC_PLANS_REQUEST,
  DO_GET_PUBLIC_PLANS_SUCCESS,
  RESET_CHALLENGE_DETAIL,
  RESET_COURSE_DETAIL,
  DO_FETCH_PUBLIC_COURSES_SUCCESS,
} from '../../constant/challengesAndCourses';

const initialState = {
  isLoading: true,
  challenges: [],
  challengeDetail: null,
  courseDetail: null,
  courses: [],
  publicPlansLoading: false,
  publicPlans: [],
  publicCourses: [],
};

export const challengesAndCoursesReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case CHALLENGES_AND_COURSES_SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case DO_GET_CHALLENGES_LIST_SUCCESS:
      return { ...state, challenges: payload };
    case DO_GET_ALL_COURSES_SUCCESS:
      return { ...state, courses: payload };
    case DO_GET_CHALLENGE_DETAILS_SUCCESS:
    case DO_GET_CHALLENGE_DETAILS_FAILURE:
      return {
        ...state,
        challengeDetail: payload,
      };
    case DO_GET_COURSE_DETAILS_SUCCESS:
    case DO_GET_COURSE_DETAILS_FAILURE:
      return {
        ...state,
        courseDetail: payload,
      };
    case RESET_CHALLENGE_DETAIL:
      return {
        ...state,
        challengeDetail: null,
        isLoading: true,
      };
    case RESET_COURSE_DETAIL:
      return {
        ...state,
        courseDetail: null,
        isLoading: true,
      };
    case DO_GET_PUBLIC_PLANS_REQUEST:
      return {
        ...state,
        publicPlansLoading: true,
      };
    case DO_GET_PUBLIC_PLANS_SUCCESS:
      return {
        ...state,
        publicPlansLoading: false,
        publicPlans: payload,
      };
    case DO_GET_PUBLIC_PLANS_FAILURE:
      return {
        ...state,
        publicPlansLoading: false,
        publicPlans: [],
      };

    case DO_FETCH_PUBLIC_COURSES_SUCCESS:
      return {
        ...state,
        publicPlansLoading: false,
        publicCourses: payload,
      };
    default:
      return state;
  }
};
