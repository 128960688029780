import { LOCAL_STORAGE_CONSTANT } from '../constants/app';
import { axiosInstance, overrideHeader } from './index';

/**
 * API request
 * */
export const signupPostRequest = data =>
  axiosInstance.post('/auth', { ...data });

export const loginPostRequest = data =>
  axiosInstance.post('/auth', { ...data });

export const otpPostRequest = (data, plan_id) =>
  axiosInstance.post(`/auth/login${plan_id ? '?plan_id=' + plan_id : ''}`, {
    ...data,
  });

export const otpResendPostRequest = data => {
  axiosInstance.post('/otps/demand', { ...data });
};

export const surveyPostRequest = async data => {
  await overrideHeader(LOCAL_STORAGE_CONSTANT.SURVEY_SESSION);
  axiosInstance.post('/surveys/onboarding', { ...data });
};

export const onboardingCompleteRequest = () => {
  overrideHeader();
  return axiosInstance.post('/onboarding/complete', {
    onboarding_complete: true,
  });
};

export const appleSignInRequest = (code, identity_token, name) => {
  const data = {
    code,
    identity_token,
    name,
  };
  return axiosInstance.post('/auth/apple/login', data);
};
