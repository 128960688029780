import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import clsx from 'clsx';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { getDisplayType } from '../../../utils/helpers';
import { TEXT_CONSTANT, VIDEO_CONSTANT } from '../../../constants/app';
import Video from '../../../ui-core/Video';

const Calender = () => {
  const displayType = getDisplayType(useWindowWidth());
  const isMobileScreen = displayType === 'mobile';
  return (
    <section className="bg-FloralWhite home-row">
      <Container>
        <Row className="align-items-center px-lg-5 px-2">
          <Col md="6" className="position-relative px-lg-4 ps-4 order-md-2">
            <div className="cal video-1">
              <div className="position-relative">
                <Video
                  mp4={VIDEO_CONSTANT.stepByStepVideoMp4}
                  removeIntersectionObserver
                  webm={VIDEO_CONSTANT.stepByStepVideoWebm}
                />
              </div>
            </div>
          </Col>
          <Col
            md="6"
            className={clsx(
              isMobileScreen ? 'pe-lg-5 mt-md-0 mt-92' : 'pe-lg-5 mt-md-0 mt-5',
            )}
          >
            <div className="px-lg-0 px-2">
              <h3 className="h-3 sec-head">
                {TEXT_CONSTANT.CALENDAR_HEADER_TEXT}
              </h3>
              <p className="section-para mb-0">
                {TEXT_CONSTANT.CALENDAR_SUB_TEXT}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Calender;
