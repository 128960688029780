import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  requestGetAllPodcastCategories,
  requestGetAllPodcasts,
  requestGetPodcastDetail,
  requestGetPodcastsByCategory,
} from '../../../api/strapi';

import { createNotification } from '../../../services/Notification';
import { errorHandler } from '../../../utils/helpers';

import {
  DO_FETCH_DETAIL_PODCAST_REQUEST,
  DO_FETCH_PODCAST_BY_CATEGORY_REQUEST,
  DO_FETCH_PODCAST_CATEGORY_REQUEST,
  DO_FETCH_PODCAST_REQUEST,
} from '../../constant/podcast';
import {
  doFetchAllPodcastMetaCategoriesSuccess,
  doFetchAllPodcastMetaSuccess,
  doFetchAllPodcastSuccess,
  doFetchAllPodcastcategorySuccess,
  doFetchAllSpotlightPodcastSuccess,
  doFetchDetailPodcastSuccess,
  doFetchPodcastsByCategorySuccess,
  setIsLoading,
  setPodcastByCategoryIsLoading,
  setPodcastDetailIsLoading,
} from '../../action/podcast';

function* fetchAllPodcast(action) {
  const { payload } = action;
  yield put(setIsLoading(true));
  try {
    const response = yield call(
      requestGetAllPodcasts,
      {
        populate: '*',
      },
      payload?.page || 1,
      5,
      payload?.filters,
    );
    yield put(doFetchAllPodcastSuccess(response?.data));
    yield put(doFetchAllPodcastMetaSuccess(response?.meta));
    const response2 = yield call(
      requestGetAllPodcasts,
      {
        'filters[$and][0][spotlight][$eq]': true,
        populate: '*',
      },
      1,
      5,
    );
    // doFetchAllSpotlightBlogSuccess
    yield put(doFetchAllSpotlightPodcastSuccess(response2?.data));
  } catch (error) {
    console.log('ERROR:', error);
    createNotification(errorHandler(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* fetchAllPodcastCategory() {
  // yield put(setIsLoading(true));
  try {
    const flag = true;
    const response = yield call(requestGetAllPodcastCategories, flag);
    yield put(doFetchAllPodcastcategorySuccess(response?.data));
  } catch (error) {
    console.log('ERROR:', error);
    createNotification(errorHandler(error));
  } finally {
    // yield put(setIsLoading(false));
  }
}

function* fetchGetPodcastDetail(action) {
  yield put(setPodcastDetailIsLoading(true));
  try {
    const flag = true;
    const { payload } = action;
    const response = yield call(requestGetPodcastDetail, payload, flag);
    yield put(
      doFetchDetailPodcastSuccess(
        response?.data?.length
          ? response?.data[0]
          : { message: 'No podcast found' },
      ),
    );
  } catch (error) {
    console.log('ERROR:', error);
    createNotification(errorHandler(error));
  } finally {
    yield put(setPodcastDetailIsLoading(false));
  }
}

function* fetchGetPodcastsByCategory(action) {
  yield put(setPodcastByCategoryIsLoading(true));
  try {
    const { payload } = action;
    const response = yield call(
      requestGetPodcastsByCategory,
      payload.param,
      payload.query,
      payload?.page || 1,
      5,
      payload?.filters,
    );
    yield put(doFetchPodcastsByCategorySuccess(response?.data));
    yield put(doFetchAllPodcastMetaCategoriesSuccess(response?.meta));
  } catch (error) {
    console.log('ERROR:', error);
    createNotification(errorHandler(error));
  } finally {
    yield put(setPodcastByCategoryIsLoading(false));
  }
}

/**
 * Watch blogs actions
 * */
export default function* podcastSaga() {
  yield all([
    takeLatest(DO_FETCH_PODCAST_REQUEST, fetchAllPodcast),
    takeLatest(DO_FETCH_PODCAST_CATEGORY_REQUEST, fetchAllPodcastCategory),
    takeLatest(DO_FETCH_DETAIL_PODCAST_REQUEST, fetchGetPodcastDetail),
    takeLatest(
      DO_FETCH_PODCAST_BY_CATEGORY_REQUEST,
      fetchGetPodcastsByCategory,
    ),
  ]);
}
