import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import Button from '../../../ui-core/Button/Button';
import { TEXT_CONSTANT, VIDEO_CONSTANT } from '../../../constants/app';
import Video from '../../../ui-core/Video';
import StartFreeTrail from '../../StartFreeTrial';

const WhyJen = props => (
  <section className="why-jen">
    <Container>
      <Row className="px-lg-5 px-2 justify-content-center">
        <Col lg={12}>
          <Row>
            <Col>
              <h1 className="section-title text-center mb-5">Why Jen?</h1>
              <p className="text-center mb-4">{TEXT_CONSTANT.WHY_JEN}</p>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <Video
                mp4={VIDEO_CONSTANT.whyJenVideoMp4}
                removeIntersectionObserver
                webm={VIDEO_CONSTANT.whyJenVideoWebm}
              />
            </Col>
            <Col md={6}>
              <ul className="list-unstyled w-j-p pe-lg-2 mt-5">
                <li>
                  <p>
                    Improve <b>mobility</b>, find relief from <b>pain</b> and
                    maintain <b>long term health.</b>
                  </p>
                </li>
                <li>
                  <p>
                    Gain <b>knowledge for life</b> and discover what works for
                    your body.
                  </p>
                </li>
                <li>
                  <p>
                    Save up to <b>thousands of dollars annually</b> in overall
                    healthcare costs
                  </p>
                </li>
                <li>
                  <p>
                    <b>No more waiting for weeks&nbsp;</b>
                    to get the guidance you need.
                  </p>
                </li>
                <li>
                  <p>
                    Spend <b>more time with friends &amp; family</b> instead of
                    spending them at clinics &amp; hospitals
                  </p>
                </li>
              </ul>
              {props.freetrialPage ? (
                <StartFreeTrail
                  onClick={props.onClick}
                  loading={props.loading}
                />
              ) : (
                <Button
                  onClick={props.onClick}
                  text={TEXT_CONSTANT.GET_STARTED_FOR_FREE}
                  class="sign-up-button-new d-inline-block auto mb-3"
                  isDisabled={false}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </section>
);

WhyJen.propTypes = {
  onClick: PropTypes.func.isRequired,
  freetrialPage: PropTypes.bool,
  loading: PropTypes.bool,
};

export default WhyJen;
