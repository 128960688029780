import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '../../ui-core/Button/Button';
import { LOCAL_STORAGE_CONSTANT, TEXT_CONSTANT } from '../../constants/app';
import { extractJSON } from '../../services/jsonUtil';
import { doPaymentInitiationRequest } from '../../store/action/products';
import { SEGMENT_EVENTS, trackEvent } from '../../utils/Segment';
import { formatCurrency } from '../../utils/helpers';
import { encodeBase64Value } from '../../utils/url';
import { APP_ROUTES } from '../../route';

const StartFreeTrail = ({ loading, onClick, className, display, text }) => {
  const dispatch = useDispatch();
  const { selectedPlan } = useSelector(state => state.product);
  const { payment } = useSelector(state => state.user);
  const rejoinMembership =
    payment?.attributes?.ends_at &&
    new Date() > new Date(payment?.attributes?.ends_at);

  const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
  const isDisabled = useMemo(() => {
    if (!user) {
      return false;
    } else {
      return (
        !selectedPlan ||
        loading ||
        (!rejoinMembership &&
          selectedPlan?.id === payment?.attributes?.product_id)
      );
    }
  }, [loading, selectedPlan, rejoinMembership]);

  const handleSubmit = () => {
    if (user) {
      const user = extractJSON(LOCAL_STORAGE_CONSTANT.USER);
      const event = rejoinMembership
        ? SEGMENT_EVENTS.REJOIN_MEMBERSHIP
        : SEGMENT_EVENTS.START_FREE_TRIAL;
      trackEvent(event, {
        user_id: user?.id,
        subscription_duration: selectedPlan?.attributes?.interval,
        free_trial_days: selectedPlan?.attributes?.free_trial_days,
        subscription_amount: formatCurrency(
          Number(selectedPlan?.attributes?.amount_in_cents || 0) / 100,
          selectedPlan?.attributes?.currency,
        ),
        plan_name: selectedPlan?.attributes?.name,
        plan_id: selectedPlan?.id,
      });
      dispatch(
        doPaymentInitiationRequest({
          page: encodeBase64Value(APP_ROUTES.FREE_TRAIL),
        }),
      );
    } else {
      if (onClick) {
        onClick();
      }
    }
  };

  return (
    <Button
      text={
        rejoinMembership
          ? TEXT_CONSTANT.REJOIN_MEMBERSHIP
          : text || TEXT_CONSTANT.START_FREE_TRAIL
      }
      isDisabled={isDisabled}
      isLoading={!selectedPlan || loading}
      class={clsx(
        'sign-up-button-new d-block text-uppercase',
        display ? 'ml-0' : 'mx-auto',
        className,
      )}
      onClick={handleSubmit}
    />
  );
};

StartFreeTrail.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  display: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default StartFreeTrail;
