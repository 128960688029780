import { all, call, put, takeLatest } from 'redux-saga/effects';
import { buyGiftRequest, getGiftProductsRequest } from '../../../api/gift';
import { doFetchGiftProductsSuccess, setIsLoading } from '../../action/gifts';
import { setPaymentIsInProgress } from '../../action/common';
import { createNotification } from '../../../services/Notification';

import {
  DO_BUY_GIFT_REQUEST,
  DO_GET_GIFT_PRODUCTS_REQUEST,
} from '../../constant/gift';
import { errorHandler } from '../../../utils/helpers';

function* getGiftProducts() {
  yield put(setIsLoading(true));
  try {
    const response = yield call(getGiftProductsRequest);
    const data = response?.data?.response?.data || [];
    yield put(doFetchGiftProductsSuccess(data));
  } catch (e) {
    createNotification(errorHandler(e));
  }
  yield put(setIsLoading(false));
}

function* buyGift({ payload }) {
  const { callback, values } = payload;
  yield put(setIsLoading(true));
  yield put(setPaymentIsInProgress(true));
  try {
    const response = yield call(buyGiftRequest, values);
    const { url } = response?.data?.response?.data;
    if (callback) callback();
    window.location.assign(url);
  } catch (e) {
    createNotification(errorHandler(e));
  }
  yield put(setPaymentIsInProgress(false));
  yield put(setIsLoading(false));
}

/**
 * Watch plan actions
 * */
export default function* gift() {
  yield all([
    takeLatest(DO_GET_GIFT_PRODUCTS_REQUEST, getGiftProducts),
    takeLatest(DO_BUY_GIFT_REQUEST, buyGift),
  ]);
}
