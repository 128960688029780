import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  categoriesBySlugRequest,
  defaultVideoRequest,
  searchV2Request,
} from '../../../api/discover';
import {
  DO_CATEGORIES_SLUG_PAGINATION_REQUEST,
  DO_CATEGORIES_SLUG_REQUEST,
  DO_DEFAULT_VIDEO_REQUEST,
  DO_SEARCH_V2_REQUEST,
} from '../../constant/discover';
import {
  catSlugPaginationSuccess,
  categoriesBySlugSuccess,
  categoriesBySlugfailed,
  clearSearchRecords,
  doV2SearchSuccess,
  setCatSlugPaginationLoading,
  setCategoriesSlugLoading,
  setDefaultVideoLoading,
  setV2SearchLoading,
} from '../../action/discover';
import { createNotification } from '../../../services/Notification';
import { errorHandler } from '../../../utils/helpers';

function* categoriesSlugRequest({ payload: { slug, callback } }) {
  yield put(setCategoriesSlugLoading(true));
  try {
    const response = yield call(categoriesBySlugRequest, slug);
    const data = response?.data?.response?.data;
    yield put(
      categoriesBySlugSuccess({
        data: data,
      }),
    );
    if (callback) {
      callback({ listByCategory: data });
    }
  } catch (e) {
    categoriesBySlugfailed({
      error: e,
    });
  } finally {
    yield put(setCategoriesSlugLoading(false));
  }
}

function* doDefaultVideoRequest({ payload: { callback } }) {
  yield put(setDefaultVideoLoading(true));
  try {
    const response = yield call(defaultVideoRequest);
    const data = response?.data?.response?.data;
    yield put(
      categoriesBySlugSuccess({
        data: data,
      }),
    );
    if (callback) {
      callback({ records: data });
    }
  } catch (e) {
    categoriesBySlugfailed({
      error: e,
    });
  } finally {
    yield put(setDefaultVideoLoading(false));
  }
}

function* doSearchV2Request({ payload: { search, callback } }) {
  yield put(setV2SearchLoading(true));
  try {
    const response = yield call(searchV2Request, search);
    const data = response?.data?.response?.data;
    yield put(
      doV2SearchSuccess({
        data: data,
      }),
    );
    if (callback) {
      callback({ records: data });
    }
  } catch (e) {
    createNotification(errorHandler(e));
    yield put(clearSearchRecords());
  } finally {
    yield put(setV2SearchLoading(false));
  }
}

function* categoriesSlugPaginationRequest({
  payload: { slug, callback, query },
}) {
  const { pageCount, totalPage } = yield select(state => state.discover);
  const count = pageCount + 1;
  if (totalPage < count) return;
  yield put(setCatSlugPaginationLoading(true));
  try {
    let url = slug;
    let extension = '?';
    if (totalPage > 1) {
      url = `${slug}?page=${count}`;
      extension = '&';
    }
    if (query) {
      url = `${url}${extension}search=${query}`;
    }

    const response = yield call(categoriesBySlugRequest, url);
    const data = response?.data;
    yield put(
      catSlugPaginationSuccess({
        data: {
          totalPage: data?.paging?.total_pages,
          records: data?.response?.data || [],
          pageCount: count,
        },
      }),
    );
    if (callback) {
      callback({ data: data });
    }
  } catch (e) {
    categoriesBySlugfailed({
      error: e,
    });
  } finally {
    yield put(setCatSlugPaginationLoading(false));
  }
}

/**
 * Watch discover actions
 * */
export default function* authSaga() {
  yield all([takeLatest(DO_CATEGORIES_SLUG_REQUEST, categoriesSlugRequest)]);
  yield all([takeLatest(DO_DEFAULT_VIDEO_REQUEST, doDefaultVideoRequest)]);
  yield all([takeLatest(DO_SEARCH_V2_REQUEST, doSearchV2Request)]);
  yield all([
    takeLatest(
      DO_CATEGORIES_SLUG_PAGINATION_REQUEST,
      categoriesSlugPaginationRequest,
    ),
  ]);
}
