import {
  CHECKOUT_SET_IS_LOADING,
  DO_FETCH_CART_SUCCESS,
  DO_TOGGLE_CART_MODAL,
  SET_CART_IS_LOADING,
} from '../../constant/checkout';
import { RESET_STORE } from '../../constant/common';

const initialState = {
  cart: null,
  isLoading: true,
  cartLoading: true,
  showCartModal: false,
};

export const checkoutReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHECKOUT_SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_CART_IS_LOADING:
      return {
        ...state,
        cartLoading: payload,
      };
    case DO_FETCH_CART_SUCCESS:
      return {
        ...state,
        cart: payload,
      };
    case DO_TOGGLE_CART_MODAL:
      return {
        ...state,
        showCartModal: payload,
      };
    case RESET_STORE:
      return { ...initialState };
    default:
      return state;
  }
};
