import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import Phone from '../../ui-core/Phone';
import { useFormik } from 'formik';
import {
  COUNTRY_CODE,
  GENDER_OPTIONS,
  PERSONAL_DETAIL_FIELDS as fieldArray,
} from '../../constants/app';
import Dropdown from '../../ui-core/Dropdown';
import InputField from '../../ui-core/InputField';
import Button from '../../ui-core/Button/Button';
import { checkForUniqueness } from '../../api/user';
import { setValuesAreChanged } from '../../store/action/common';

function getInitialValue(user) {
  const initialValues = {
    name: '',
    email: '',
    mobile: '',
    gender: '',
    countryCode: '',
  };
  initialValues.name = user?.attributes?.name || '';
  initialValues.mobile =
    (user?.attributes?.country_code || '') + (user?.attributes?.mobile || '');
  initialValues.gender = user?.attributes?.gender || '';
  initialValues.email = user?.attributes?.email || '';
  initialValues.countryCode = user?.attributes?.country_code || '';
  return initialValues;
}

export const checkUniqueEmail = async email => {
  try {
    const data = {
      type: 'email',
      email,
    };
    const response = await checkForUniqueness(data);
    return response.data;
  } catch (error) {
    return error?.response?.data?.response?.errors[0] || error?.message;
  }
};

const validate = async values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

const PersonalDetails = props => {
  const { user, handleSubmit } = props;
  const { isLoading } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const noMobileNumber = !user.attributes.mobile;

  const formik = useFormik({
    initialValues: getInitialValue(user),
    validate,
    onSubmit: async values => {
      const error = await checkUniqueEmail(values.email);
      if (!error) {
        handleSubmit({
          name: values.name,
          mobile: values.mobile,
          gender: values.gender,
          email: values.email,
          country_code: values.countryCode,
        });
      } else {
        formik.setErrors({
          ...formik.errors,
          email: error,
        });
      }
    },
  });

  // check for changes in values
  const areValuesChanged = () => {
    let change = false;
    const initialValues = getInitialValue(user);
    const updatedValues = formik.values;
    if (initialValues.name !== updatedValues.name) {
      change = true;
    } else if (initialValues.email !== updatedValues.email) {
      change = true;
    } else if (initialValues.gender !== updatedValues.gender) {
      change = true;
    }

    return change;
  };

  const handleChange = (event, name) => {
    const value = event.target.value;
    formik.setFieldValue(name, value);
    const isChanged = areValuesChanged();
    dispatch(setValuesAreChanged(isChanged));
  };

  const handleMobileNumberChange = (mobile, countryCode) => {
    formik.setFieldValue('countryCode', countryCode);
    formik.setFieldValue('mobile', countryCode + mobile);
  };
  const renderField = field => {
    const maxLength = field.name === 'name' ? 30 : undefined;
    switch (field.name) {
      case 'name':
      case 'email':
        return (
          <InputField
            key={field.name}
            label={field.label}
            maxLength={maxLength}
            name={field.name}
            placeholder={field.placeholder}
            handleChange={event => handleChange(event, field.name)}
            value={formik.values[field.name]}
            errorMessage={formik.errors[field.name]}
          />
        );
      case 'mobile':
        return (
          <Form.Group
            className={clsx(noMobileNumber && 'd-none')}
            key={field.name}
          >
            <Form.Label className="label-text">Mobile Number</Form.Label>
            <Phone
              disabled={true}
              containerClass={'phone-input-group'}
              value={formik.values[field.name]}
              countryCode={COUNTRY_CODE[formik.values.countryCode]}
              handleOnChange={handleMobileNumberChange}
            />
            {formik.errors[field.name] && (
              <Form.Label className="error-text position-static text-danger">
                {formik.errors[field.name]}
              </Form.Label>
            )}
          </Form.Group>
        );
      case 'gender':
        return (
          <Dropdown
            key={field.name}
            name={field.name}
            label={field.label}
            value={formik.values[field.name]}
            options={GENDER_OPTIONS}
            onChange={event => handleChange(event, field.name)}
            className="gender"
            placeholder="Select gender"
          />
        );
      default:
        return null;
    }
  };

  const handleKeyDown = event => {
    if (
      event.keyCode === 13 &&
      !isLoading &&
      areValuesChanged() &&
      formik.isValid
    ) {
      formik.handleSubmit();
    }
  };

  return (
    <div className="profile-form-div-outer">
      <div className="profile-form-div">
        <Form onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown}>
          <div className="mb-4 d-flex justify-content-end">
            <Button
              class="profile-save-btn mobile-view"
              isDisabled={isLoading || !areValuesChanged() || !formik.isValid}
              isLoading={isLoading}
              type="submit"
              text={'Save'}
            />
          </div>
          {fieldArray.map(renderField)}
        </Form>
      </div>
    </div>
  );
};

PersonalDetails.propTypes = {
  user: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default PersonalDetails;
