import React from 'react';
import { Redirect, Route } from 'react-router';
import PropTypes from 'prop-types';

import { APP_ROUTES } from '../route';
import { isSurveySession } from '../utils/helpers';

const SurveyRoute = props => {
  const { component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={props =>
        isSurveySession() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: APP_ROUTES.ROOT,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

SurveyRoute.propTypes = {
  // rest: PropTypes.object,
  rest: PropTypes.shape({
    exact: PropTypes.bool,
    path: PropTypes.string,
    location: PropTypes.object,
  }),
  location: PropTypes.object,
  component: PropTypes.any,
};

export default SurveyRoute;
