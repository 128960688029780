import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Footer from '../components/Footer';
import SeoTags from '../components/SeoComponent';
import { getCanonicalLink, SeoTexts } from '../services/SEO';
import { getHomeRoute } from '../route';
import { getUserAttribute, isLoggedIn } from '../utils/helpers';
import { PUBLIC_PRIVATE_COMBINED } from '../constants/app';
import NavFooter from '../components/NavFooter';

const PublicRoute = props => {
  const { component: Component, ...rest } = props;
  const location = useLocation();
  const containsPattern = (arr, pattern) => {
    return arr.some(item => pattern.includes(item));
  };
  const onBoardingCompleted = getUserAttribute('onboarding_completed');
  let hideFooter = props.hideFooter;
  if (!onBoardingCompleted) {
    hideFooter = true;
  }
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn() &&
        !containsPattern(PUBLIC_PRIVATE_COMBINED, location.pathname) ? (
          <Redirect
            to={{
              pathname: getHomeRoute(),
              state: { from: props.location },
            }}
          />
        ) : (
          <React.Fragment>
            <Component {...props} />
            <Footer />
            {!hideFooter && <NavFooter {...props} />}
            <SeoTags
              title={SeoTexts.JEN_WEB_TITLE}
              canonicalLink={getCanonicalLink()}
              metaDescription={SeoTexts.JEN_WEB_DESCRIPTION}
              ogImgUrl={SeoTexts.JEN_WEB_OG_IMAGE_URL}
              twitterImgUrl={SeoTexts.JEN_WEB_TWITTER_IMAGE_URL}
            />
          </React.Fragment>
        )
      }
    />
  );
};

PublicRoute.propTypes = {
  // rest: PropTypes.object,
  rest: PropTypes.shape({
    exact: PropTypes.bool,
    path: PropTypes.string,
    location: PropTypes.object,
  }),
  location: PropTypes.object,
  component: PropTypes.any,
  hideFooter: PropTypes.bool,
};

export default PublicRoute;
