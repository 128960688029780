import axios from 'axios';

/**
 * Configure core axios instance
 * */
export const baseURL = 'https://crm.jen.health/api/';

const ACCESS_TOKEN =
  'cdef947ab83b8293b0e043d595283bfe6dd44c5fcec92780ad2429489bb59af0ef3328b2768488e3e5649d47b73e645e2bca69fa9b743f987bfb7c7aa9b0247b8e512f74bb6b18cfea9813535193015e92dad8647ba69b5a50eb311441e4ba8449edc076c5e0c50f34289fa777333d801feec1b6494474320b8ac89291eb38af';

export function createAxiosInstace() {
  return axios.create({
    baseURL,
    headers: {
      common: {
        Authorization: `Bearer ${ACCESS_TOKEN}`,
      },
    },
  });
}

export const axiosInstance = createAxiosInstace();

export const requestGetAllBlogs = async (
  options = {},
  page = 1,
  pageSize = 10,
) => {
  const queryParams = new URLSearchParams();
  Object.entries(options).forEach(([key, value]) => {
    queryParams.set(key, value);
  });
  const response = await axiosInstance.get(
    `/blogs?${queryParams.toString()}&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=blogLiveDate:DESC`,
  );
  return response.data;
};

export const requestGetBlogDetail = async (blogId, withPopulate = false) => {
  let endpoint = `/blogs/${blogId}`;
  if (withPopulate) {
    endpoint += '?populate=*';
  }
  const response = await axiosInstance.get(endpoint);

  return response.data;
};

export const requestGetAllCategories = async (withPopulate = false) => {
  let endpoint = '/categories';
  if (withPopulate) {
    endpoint += '?populate=*';
  }
  const response = await axiosInstance.get(endpoint);
  return response.data;
};

export const requestGetAllPodcastCategories = async (withPopulate = false) => {
  let endpoint = '/podcast-categories';
  if (withPopulate) {
    endpoint += '?populate=*';
  }
  const response = await axiosInstance.get(endpoint);
  return response.data;
};

export const requestGetBlogsByCategory = async (
  categoryName = '',
  query = '',
  page = 1,
  pageSize = 10,
  filters,
) => {
  let filterString = '';
  if (filters?.length) {
    filters.forEach(filterItem => {
      filterString = `${filterString}&filters[$and][0][categories][name][$eq]=${filterItem}`;
    });
  }
  let endpoint = categoryName
    ? `/blogs?&populate=*&filters[$and][0][categories][name][$containsi]=${categoryName}&&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=blogLiveDate:DESC${filterString}`
    : `/blogs?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=blogLiveDate:DESC${filterString}`;
  if (query) {
    endpoint += `&_q=${query}`;
  }
  const response = await axiosInstance.get(endpoint);
  return response.data;
};

export const requestGetAllPodcasts = async (
  options = {},
  page = 1,
  pageSize = 10,
  filters,
) => {
  const queryParams = new URLSearchParams();
  let filterString = '';
  if (filters?.length) {
    filters.forEach(filterItem => {
      filterString = `${filterString}&filters[$and][0][podcast_categories][name][$eq]=${filterItem}`;
    });
  }
  Object.entries(options).forEach(([key, value]) => {
    queryParams.set(key, value);
  });
  const response = await axiosInstance.get(
    `/podcasts?${queryParams.toString()}&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=episode:DESC${filterString}`,
  );
  return response.data;
};

export const requestGetPodcastDetail = async (
  podcastId,
  withPopulate = false,
) => {
  let endpoint = `/podcasts?filters[episode][$eq]=${podcastId}&sort=episode:DESC`;
  if (withPopulate) {
    endpoint += '&populate=*&sort=episode:DESC';
  }
  const response = await axiosInstance.get(endpoint);

  return response.data;
};

export const requestGetPodcastDetailAuthor = async (
  podcastId,
  withPopulate = false,
) => {
  let endpoint = `/podcasts?filters[episode][$eq]=${podcastId}&sort=episode:DESC`;
  if (withPopulate) {
    endpoint += '&populate[author][populate][0]=image';
  }
  const response = await axiosInstance.get(endpoint);

  return response.data;
};

export const requestGetPodcastsByCategory = async (
  categoryName = '',
  query = '',
  page = 1,
  pageSize = 10,
  filters,
) => {
  let filterString = '';
  if (filters?.length) {
    filters.forEach(filterItem => {
      filterString = `${filterString}&filters[$and][0][podcast_categories][name][$eq]=${filterItem}`;
    });
  }
  let endpoint = categoryName
    ? `/podcasts?&populate=*&filters[$and][0][podcast_categories][name][$containsi]=${categoryName}&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=episode:DESC${filterString}`
    : `/podcasts?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=episode:DESC${filterString}`;
  if (query) {
    endpoint += `&_q=${query}`;
  }
  const response = await axiosInstance.get(endpoint);
  return response.data;
};
