export const COURSES_SLUGS = {
  BARE_FOOT: 'Barefoot Mini Course',
  FULL_BODY: 'lowimpact',
  LowBack: 'lowback',
  GRATEFUL_FOR_BODY: '30 Days of Release And Recovery',
  FULL_BODY_MOBILITY: 'Full Body Mobility Plan',
  LOW_BACK_PAIN: 'LowBackFB',
  HIP_MOBILITY: 'HipFB',
  KNEE_HEALTH: 'KneeAndITFB',
  UPPER_BACK_AND_NECK: 'UpperBackAndNeckFB',
  MOVE_TO_IMPROVE: 'move',
  PELVIC_FLOOR: 'pelvicfloor',
  MOBILITY_FOUNDATIONS: 'foundations',
  MOVE_2_IMRPOVE: 'strong',
  WARMUP_COOL_DOWN: 'prep',
  POSTURE_REDEFINED: 'posture',
};

export const APP_ROUTES = {
  ROOT: '/',
  HOME_OLD: '/home-old',
  HOME: '/home',
  HOME_SUB_SECTION: '/home/:id',
  FITNESS: '/onboarding',
  COLLECTION: '/collection',
  FOLDERS: '/my-folders',
  QUICKTIPS: '/collection/quick-tips/:id',

  COLLECTION_DETAILED_VIDEO: '/collection/video/:id',
  SELF_ASSESSMENT_DETAILED_VIDEO: '/self-assessment/:id',
  PLANS_MADE_FOR_YOU_DETAILED_VIDEO: '/collection/plans/:id',
  HOME_SUB_SECTION_DETAILED_VIDEO: '/home/plans/:id',
  FOLDERS_DETAILED_VIDEO: '/my-folders/video/:id',

  CURATED_PLANS: '/collection/plans-made-for-you/:id',
  PROFILE: '/profile',
  FREE_TRAIL: '/freetrial',
  PROFILE_WITH_SUB_ROUTES: '/profile/:section',
  PERSONAL_DETAILS: '/personal-details',
  DETAILED_FOLDER: '/my-folders/:id',
  COLLECTION_DETAIL: '/collection/:id',
  SURVEY: '/survey',
  SEARCH: '/search/:keyword',
  SUCCESS: '/success',
  CANCEL: '/cancel',
  LOGIN_CALLBACK: '/login/callback',
  PAGE_NOT_FOUND: '/page-not-found',
  CHALLENGE: '/challenge',
  BODY_PARTS: '/body-parts',
  FULL_BODY_LOW_IMPACT: '/lowimpact',
  BARE_FOOT_MINI_COURSE: '/barefoot',
  COURSE_SLUG: '/:slug',
  CHALLENGES: '/challenges',
  COURSES: '/courses',
  GRATEFUL_FOR_BODY: '/grateful',
  OLD_COURSES_BARE_FOOT: `/courses/${COURSES_SLUGS.BARE_FOOT}`,
  OLD_COURSES_FULL_BODY_LOW_IMPACT: `/courses/${COURSES_SLUGS.FULL_BODY}`,
  OLD_COURSES_GRATEFUL_FOR_BODY: `/courses/${COURSES_SLUGS.GRATEFUL_FOR_BODY}`,
  OLD_CHALLENGES_BARE_FOOT: `/challenges/${COURSES_SLUGS.BARE_FOOT}`,
  OLD_CHALLENGES_FULL_BODY_LOW_IMPACT: `/challenges/${COURSES_SLUGS.FULL_BODY}`,
  OLD_CHALLENGES_GRATEFUL_FOR_BODY: `/challenges/${COURSES_SLUGS.GRATEFUL_FOR_BODY}`,
  OLD_GRATEFUL_FOR_BODY: '/Grateful For My Body Challenge',
  GIFTS: '/gifts',
  FULL_BODY_MOBILITY: '/mobility',
  LEAD_PAGES: `/lead/:slug`,

  BUNDLE_DEALS: '/bundle-deals',
  ORDER_CONFIRMATION: '/orders/:result',

  QUESTIONNAIRE: '/questionnaire/:planId',
  THANK_YOU: '/thank-you',
  // BLOG: '/blog',
  BLOGS: '/blogs',
  BLOGS_LISTING: '/blogs-listing/:name',
  BLOGS_LISTINGS: '/blogs-listing',
  BLOG: '/blog/:id',

  PODCASTS: '/podcasts',
  PODCAST: '/podcast/:name/:title',
  PODCAST_DUPLICATE: '/podcast/:name',
  PODCASTS_LISTING: '/podcasts-listing/:name',
  PODCASTS_LISTINGS: '/podcasts-listing',
  PRIVACY_POLICY: '/privacypolicy',
  DISCOVER: '/discover',
  DISCOVER_DETAILED_VIDEO: '/discover/video/:id',
  DISCOVER_QUICKTIPS_VIDEO: '/discover/quick-tips/video/:id',
  CATEGORIES_DETAILED: '/home/categories/:category',
  CATEGORIES_DETAILED_VIDEO: '/home/categories/video/:id',
  DISCOVER_CATEGORIES_DETAILED: '/discover/:category',
  DISCOVER_THERAPY_PLANS_DETAILED: '/discover/therapy-plans/:id',
  DISCOVER_THERAPY_PLANS_DETAILED_VIDEO: '/discover/therapy-plan/video/:id',
  MOST_POPULAR_DETAILED: '/discover/most-popular/:id',
  MOST_POPULAR_DETAILED_VIDEO: '/discover/most-popular/video/:id',
  MOST_POPULAR_DETAILED_QUICK_TIPS_VIDEO:
    '/discover/most-popular/quick-tips/video/:id',

  // CHALLENGE_DETAIL: '/courses/detail/:id',
  // CHALLENGE_TYPE_DETAILED_VIDEO: '/courses/detail/video/:id',
  SELF_ASSESSMENT: '/home/self-assessment',
  CATEGORIES: '/home/categories',
  THERAPY_PLANS: '/home/therapy-plans',
  THERAPY_PLANS_DETAILS: '/home/therapy-plans/:id',
  THERAPY_PLANS_DETAILED_VIDEO: '/home/therapy-plans/video/:id',
  PREMIUM_COURSES: '/home/premium-courses',
  PREMIUM_COURSES_DETAILS: '/home/premium-courses/:id',
  PREMIUM_COURSES_DETAILED_VIDEO: '/home/premium-courses/video/:id',
};

export const getRoute = (route, options) => {
  const { pathParam, searchParam } = options;
  if (pathParam) {
    Object.keys(pathParam).forEach(idenitifer => {
      route = route.replace(':' + idenitifer, pathParam[idenitifer]);
    });
  }
  if (searchParam) {
    const queryParams = Object.entries(searchParam)
      .map(item => item[0] + '=' + item[1])
      .join('&');
    route = route + '?' + queryParams;
  }
  return route;
};

export const getHomeRoute = (section = 'my-plans') => {
  const option = {
    pathParam: {
      section,
    },
  };
  return getRoute(APP_ROUTES.HOME, option);
};

export const pushHomeRoute = (
  history,
  section,
  replace = false,
  statePayload,
) => {
  const path = getHomeRoute(section);
  if (replace) {
    if (statePayload) {
      history.replace(path, statePayload);
    } else {
      history.replace(path);
    }
  } else {
    if (statePayload) {
      history.push(path, statePayload);
    } else {
      history.push(path);
    }
  }
};
