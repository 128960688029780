import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../ui-core/Button/Button';
import { planBegins } from '../../images';
import { MODAL_TYPE, TEXT_CONSTANT } from '../../../../constants/app';

import './style.css';

export const PlanBegins = ({ loading, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick(TEXT_CONSTANT.JOIN_NOW, MODAL_TYPE.LOGIN, 'Start Getting...');
    }
  };
  return (
    <div className="plan-begins">
      <div className="pb-head">
        <p>
          Start <span className="ms-4">Getting...</span>
        </p>
        <h3>
          Stronger
          <img alt="jen" src={planBegins} />
          <span>Today</span>
        </h3>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <Button
          class="sign-up-button-new"
          text={TEXT_CONSTANT.JOIN_NOW}
          isDisabled={loading}
          isLoading={loading}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

PlanBegins.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};
