import {
  DO_SELF_ASSESSMENT_SUCCESS,
  DO_SELF_ASSESSMENT_FAILURE,
  SELF_ASSESSMENT_SET_IS_LOADING,
} from '../../constant/selfAssessment';
import { RESET_STORE } from '../../constant/common';

const initialState = {
  isLoading: false,
  selfAssessment: null,
};

export const selfAssessmentReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case DO_SELF_ASSESSMENT_SUCCESS:
      return { ...state, selfAssessment: payload };
    case DO_SELF_ASSESSMENT_FAILURE:
      return { ...state, selfAssessment: [] };
    case SELF_ASSESSMENT_SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case RESET_STORE:
      return { ...initialState };
    default:
      return state;
  }
};
