import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  doGetPaymentDetailRequest,
  doPaymentRestartRequest,
} from '../../store/action/user';
import {
  IMAGE_CONSTANT,
  SUBSCRIPTION_STATUSES,
  TEXT_CONSTANT,
} from '../../constants/app';
import { Spinner } from 'react-bootstrap';
import {
  getUserAttribute,
  formatCurrency,
  getFormattedDate,
} from '../../utils/helpers';
import CancelSubscription from './CancelSubscription';
import Button from '../../ui-core/Button/Button';
import {
  doFetchProductsRequest,
  doPaymentInitiationRequest,
} from '../../store/action/products';
import { withRouter } from 'react-router-dom';
import { toggleTrialModal } from '../../store/action/common';
import StartFreeTrial from '../../components/PaymentModal/StartFreeTrial';
import SwitchPlan from '../../components/SwitchPlan';
import UpdatePayment from '../../components/UpdatePayment';
import { encodeBase64Value } from '../../utils/url';

const Payments = props => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const { payment } = useSelector(state => state.user);
  const { isLoading, selectedPlan } = useSelector(state => state.product);
  const { openTrialModal } = useSelector(state => state.common);
  const { viewAll } = openTrialModal || {};
  const pendingRetries = payment?.attributes?.pending_retries;
  const hasTobAccess = getUserAttribute('tob_member');

  const isSubscriptionCancelled =
    payment?.attributes?.provider === 'stripe' &&
    payment?.attributes?.original_status === 'cancelled' &&
    payment?.attributes?.status === 'active';

  const showUpdatePaymentMethod =
    payment?.attributes?.provider === 'stripe' &&
    (payment?.attributes?.status === 'active' ||
      payment?.attributes?.status === 'expired');

  const isSubscriptionExpired =
    payment?.attributes?.ends_at &&
    new Date() > new Date(payment?.attributes?.ends_at);

  useEffect(() => {
    dispatch(
      doGetPaymentDetailRequest({
        callback: () => setLoading(false),
      }),
    );
  }, []);

  useEffect(() => {
    if (isSubscriptionCancelled || !payment) {
      dispatch(doFetchProductsRequest());
    }
  }, [isSubscriptionCancelled, payment]);

  const paymentMode = useMemo(() => {
    return payment?.attributes?.payment_mode || null;
  }, [payment]);

  const showCancelButton =
    payment?.attributes?.provider === 'stripe' &&
    payment?.attributes?.status === 'active';

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleSubmit = () =>
    dispatch(
      doPaymentInitiationRequest({
        page: encodeBase64Value(props.history.location.pathname),
      }),
    );

  const cardImage = useMemo(() => {
    switch (paymentMode?.brand) {
      case 'visa':
        return IMAGE_CONSTANT.visaLogo;
      default:
        return null;
    }
  }, [paymentMode]);

  const subscriptionStatus = useMemo(() => {
    const isSubscribed =
      payment?.attributes?.payment_mode !== null ||
      payment?.attributes?.provider === 'ios';
    if (isSubscribed) {
      if (payment?.attributes?.subscription_cancelled_at) {
        return SUBSCRIPTION_STATUSES.SUBSCRIPTION_CANCELLED;
      } else if (new Date() > new Date(payment?.attributes?.ends_at)) {
        return SUBSCRIPTION_STATUSES.SUBSCRIPTION_ENDED;
      } else {
        return SUBSCRIPTION_STATUSES.SUBSCRIPTION_ACTIVE;
      }
    } else if (payment?.attributes?.free_trial_cancelled_at) {
      return SUBSCRIPTION_STATUSES.FREE_TRAIL_CANCELLED;
    } else if (new Date() > new Date(payment?.attributes?.ends_at)) {
      return SUBSCRIPTION_STATUSES.FREE_TRAIL_ENDED;
    } else {
      return SUBSCRIPTION_STATUSES.FREE_TRAIL_ACTIVE;
    }
  }, [payment]);

  const styleObject = useMemo(() => {
    const isSubscribed = payment?.attributes?.payment_mode !== null;
    if (
      (isSubscribed && payment?.attributes?.subscription_cancelled_at) ||
      (payment?.attributes?.free_trial_cancelled_at && !isSubscribed) ||
      new Date() > new Date(payment?.attributes?.ends_at)
    ) {
      return {
        backgroundColor: '#fff3f3',
        border: '1px solid #f7d2d2',
        color: '#FF0000',
      };
    } else {
      return {
        color:
          payment?.attributes?.free_trial && !isSubscribed
            ? '#00A3ED'
            : '#13BFB8',
        backgroundColor: '#F1FDFF',
        border: '1px solid #8ECCE9',
      };
    }
  }, [payment]);

  const restartMembership = () => dispatch(doPaymentRestartRequest());

  // const redirectToPayment = () => props.history.push(APP_ROUTES.FREE_TRAIL);

  const openPaymentModal = () =>
    dispatch(
      toggleTrialModal({ open: true, hideBackButton: true, viewAll: !viewAll }),
    );

  const toggleViewAll = () =>
    dispatch(toggleTrialModal({ open: true, viewAll: !viewAll }));

  return loading ? (
    <div className="d-flex w-100 h-100 justify-content-center align-items-center">
      Retrieving details
      <Spinner className="mx-2" animation="border" />
    </div>
  ) : hasTobAccess ? (
    <div className="d-flex w-100 h-100 payment-block justify-content-center align-items-center">
      <div className="payment-block_inner">
        <h1>You have an active Optimal Body Membership!</h1>
      </div>
    </div>
  ) : payment ? (
    <div className="payment-block">
      <div className="payment-block_inner">
        <h4 className="plan_txt fw-medium">
          {TEXT_CONSTANT.SUBSCRIPTION_DETAILS}
        </h4>
        <div style={styleObject} className="subscription-details-block">
          <div className="payment-info d-flex justify-content-between align-items-center">
            <div>
              <h3 className="fz17">{subscriptionStatus}</h3>
              <h5 className="fz24bold">{payment?.attributes?.name}</h5>
            </div>
            <h5 className="billing-amt">
              {formatCurrency(
                +payment?.attributes?.amount / 100,
                payment?.attributes?.currency,
              )}
            </h5>
          </div>
          <div style={{ color: 'black' }} className="subscription-info">
            <div className="d-flex justify-content-between mb-3">
              <h3 className="fz17 color-black-russian m-0 lgt-font">
                {TEXT_CONSTANT.ACTIVE_ON}
              </h3>
              <h3 className="billing-date">
                {getFormattedDate(payment?.attributes?.active_on)}
              </h3>
            </div>
            <div className="d-flex justify-content-between">
              <h3 className="fz17 color-black-russian m-0 lgt-font">
                {isSubscriptionCancelled
                  ? TEXT_CONSTANT.CANCELLED_DATE
                  : TEXT_CONSTANT.NEXT_BILLING_DATE}
              </h3>
              <h3 className="billing-date">
                {getFormattedDate(
                  payment?.attributes?.free_trial_cancelled_at ||
                    payment?.attributes?.subscription_cancelled_at ||
                    payment?.attributes?.ends_at,
                )}
              </h3>
            </div>
            {paymentMode && (
              <>
                <div className="card-breaker my-4"></div>
                <div className="d-flex justify-content-between mb-2">
                  <h3 className="fz16 lgt-font">
                    {TEXT_CONSTANT.PAYMENT_MODE}
                  </h3>
                </div>
                <div className="d-flex justify-content-between align-items-center position-relative">
                  <h5 className="card-number fz20medium m-0">{`XXXX XXXX XXXX ${paymentMode?.number}`}</h5>
                  <span className="card__">
                    {cardImage ? (
                      <img src={cardImage} alt="card" className="img-fluid" />
                    ) : (
                      <h3 className="text-uppercase mb-0">
                        {paymentMode?.brand}
                      </h3>
                    )}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        {showCancelButton && !isSubscriptionCancelled && <SwitchPlan />}
        {showUpdatePaymentMethod && <UpdatePayment />}
        {showCancelButton && !isSubscriptionCancelled && (
          <div onClick={handleOpen} className="cancel-payment cursor-pointer">
            <span>
              {payment?.attributes?.free_trial
                ? TEXT_CONSTANT.CANCEL_FREE_TRAIL
                : TEXT_CONSTANT.CANCEL_SUBSCRIPTION}
            </span>
            <img src={IMAGE_CONSTANT.chevronRight} alt="right" />
          </div>
        )}
        {isSubscriptionCancelled && (
          <div className="subs-cancelled-text">
            {payment?.attributes?.free_trial_cancelled_at && (
              <h5>{TEXT_CONSTANT.RENEW_TEXT}</h5>
            )}
            <Button
              class="start-free-trail-btn mt-4 w-100"
              isDisabled={!selectedPlan}
              isLoading={isLoading}
              onClick={restartMembership}
              text={TEXT_CONSTANT.RESTART_MEMBERSHIP}
            />
          </div>
        )}
        {isSubscriptionExpired && (
          <div className="subs-cancelled-text">
            <Button
              class="start-free-trail-btn mt-4 w-100"
              isLoading={isLoading}
              onClick={openPaymentModal}
              text={TEXT_CONSTANT.REJOIN_MEMBERSHIP}
            />
          </div>
        )}
        {!Number.isNaN(pendingRetries) && pendingRetries > 0 && (
          <div className="text-center mt-4">
            <div className="payment-note my-4">
              <img src={IMAGE_CONSTANT.alertCircle} alt="alert-icon" />
              <span className="bold me-1">Note:</span>
              <span>{TEXT_CONSTANT.PAYMENT_FAILED_NOTE}</span>
            </div>
            <Button
              class="start-free-trail-btn mt-4 w-100"
              isDisabled={!selectedPlan}
              isLoading={isLoading}
              onClick={handleSubmit}
              text={TEXT_CONSTANT.UPDATE_PAYMENT_METHOD}
            />
          </div>
        )}
        <CancelSubscription show={open} handleClose={handleClose} />
      </div>
    </div>
  ) : (
    <div className="d-flex w-100 h-100 justify-content-center align-items-center flex-column text-center payment-block">
      <h2 className="mx-auto payment-modal-title">
        {'How your free trial works?'}
      </h2>
      <StartFreeTrial showSubscription={true} toggleViewAll={toggleViewAll} />
      <div className="mx-auto w-100 text-center">
        <Button
          class="start-free-trail-btn mt-3"
          isDisabled={!selectedPlan}
          isLoading={isLoading}
          onClick={handleSubmit}
          text={TEXT_CONSTANT.START_FREE_TRAIL}
        />
      </div>
    </div>
  );
};

Payments.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Payments);
