import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

import { getPurchaseList } from '../../api/orders';
import {
  NOTIFICATION_TYPE,
  createNotification,
} from '../../services/Notification';
import {
  errorHandler,
  formatCurrency,
  openUrlOnNewTab,
} from '../../utils/helpers';
import LazyImage from '../../ui-core/Image';
import { IMAGE_CONSTANT, TEXT_CONSTANT } from '../../constants/app';
import { ShouldRender } from '../../ui-core/ShouldRender';
import Button from '../../ui-core/Button/Button';
import { encodeURLParams } from '../../utils/url';
import { APP_ROUTES, getRoute } from '../../route';

const MyOrders = ({ handleBundleDealsRedirection }) => {
  const [loading, setLoading] = useState(true);
  const [purchases, setPurchases] = useState([]);

  useEffect(() => {
    const fetchPurchaseList = async () => {
      try {
        const response = await getPurchaseList();
        const purchases = response?.data?.response || [];
        setPurchases(purchases);
      } catch (error) {
        createNotification({
          type: NOTIFICATION_TYPE.ERROR,
          message: errorHandler(error),
        });
      } finally {
        setLoading(false);
      }
    };
    fetchPurchaseList();
  }, []);

  const handleClick = item => {
    const { plan_id, title, type } = item?.attributes;
    if (plan_id) {
      const params = {
        plan_type: type,
        title,
      };
      const options = {
        pathParam: {
          id: plan_id,
        },
        searchParam: {
          params: encodeURLParams(params),
        },
      };
      const url =
        type === 'plan'
          ? APP_ROUTES.HOME_SUB_SECTION
          : APP_ROUTES.PREMIUM_COURSES_DETAILS;
      const route = getRoute(url, options);
      openUrlOnNewTab(route);
    } else {
      const options = {
        pathParam: {
          section: 'payments',
        },
      };
      const url = getRoute(APP_ROUTES.PROFILE_WITH_SUB_ROUTES, options);
      openUrlOnNewTab(url);
    }
  };

  return loading ? (
    <div className="d-flex w-100 h-100 justify-content-center align-items-center">
      Retrieving details
      <Spinner className="mx-2" animation="border" />
    </div>
  ) : (
    <div className="my-orders-wrapper">
      <ShouldRender check={purchases.length === 0}>
        <div className="m-auto d-flex flex-column align-items-center">
          <div className="no-deals-block d-flex align-items-center flex-column gap-4 mb-4">
            <img src={IMAGE_CONSTANT.boxIcon} alt="box" />
            <div className="cart-full">
              <p className="mb-0">You haven&apos;t made any purchase yet.</p>
            </div>
          </div>
          <Button
            text="Explore Bundle Deals"
            class="sign-up-button-new text-uppercase mt-2 w-auto px-3"
            onClick={handleBundleDealsRedirection}
          />
        </div>
      </ShouldRender>
      <ShouldRender check={purchases.length > 0}>
        {purchases.map(purchase => {
          const { amount, created_at, thumbnail, title, transaction_id } =
            purchase?.attributes || {};
          return (
            <div key={purchase.id} className="my-orders-card">
              <div className="transaction-header">
                <span>{created_at}</span>
                <span>
                  Transaction ID: <b>{transaction_id}</b>
                </span>
              </div>
              <div className="transaction-body">
                <LazyImage
                  alt={title}
                  className="order-image"
                  errorSrc={IMAGE_CONSTANT.placeholderImage}
                  placeholderSrc={IMAGE_CONSTANT.placeholderImage}
                  src={thumbnail || '/Logo.png'}
                />
                <div className="info-block">
                  <p className="mb-2">{title}</p>
                  <p>Total {formatCurrency(+amount / 100, undefined, 2)}</p>
                </div>
                <div className="d-flex align-items-center">
                  <Button
                    text={TEXT_CONSTANT.VIEW}
                    class="sign-up-button-new text-uppercase mt-2 w-auto px-3"
                    onClick={() => handleClick(purchase)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </ShouldRender>
    </div>
  );
};

MyOrders.propTypes = {
  handleBundleDealsRedirection: PropTypes.func.isRequired,
};

export default MyOrders;
