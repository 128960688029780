import {
  DO_FETCH_BLOG_REQUEST_SUCCESS,
  DO_FETCH_BLOG_CATEGORY_REQUEST_SUCCESS,
  BLOG_SET_IS_LOADING,
  DO_FETCH_BLOG_SPOTLIGHT_REQUEST_SUCCESS,
  DO_FETCH_DETAIL_BLOG_REQUEST_SUCCESS,
  DO_FETCH_BLOG_BY_CATEGORY_REQUEST_SUCCESS,
  BLOG_BY_CATEGORY_IS_LOADING,
  DO_FETCH_BLOG_META_REQUEST_SUCCESS,
  BLOG_DETAIL_SET_IS_LOADING,
} from '../../constant/blog';

const initialState = {
  blogs: null,
  blog: null,
  spotLight: null,
  blogsCategory: null,
  isLoading: true,
  detailBlog: null,
  blogsByCategory: [],
  blogsByCategoryIsLoading: false,
  isBlogDetailLoading: false,
};

export const blogReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case BLOG_SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case BLOG_DETAIL_SET_IS_LOADING:
      return {
        ...state,
        isBlogDetailLoading: payload,
      };

    case DO_FETCH_BLOG_REQUEST_SUCCESS:
      return {
        ...state,
        blogs: payload,
      };

    case DO_FETCH_BLOG_CATEGORY_REQUEST_SUCCESS:
      return {
        ...state,
        blogsCategory: payload,
      };

    case DO_FETCH_BLOG_SPOTLIGHT_REQUEST_SUCCESS:
      return {
        ...state,
        spotLight: payload,
      };

    case DO_FETCH_DETAIL_BLOG_REQUEST_SUCCESS:
      return {
        ...state,
        detailBlog: payload,
      };
    case DO_FETCH_BLOG_META_REQUEST_SUCCESS:
      return {
        ...state,
        meta: payload,
      };
    case DO_FETCH_BLOG_BY_CATEGORY_REQUEST_SUCCESS:
      return {
        ...state,
        blogsByCategory: payload,
      };
    case BLOG_BY_CATEGORY_IS_LOADING:
      return {
        ...state,
        blogsByCategoryIsLoading: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
