import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useCountdown } from '../../hooks/useCountDown';
import { TEXT_CONSTANT } from '../../constants/app';

import './style.css';

const TimeCounter = ({ endDate = dayjs().format('YYYY-MM-DD'), liveNow }) => {
  const [timer, timerOver] = useCountdown(endDate);

  const getLabel = index => {
    switch (index) {
      case 0:
        return 'Days';
      case 1:
        return 'Hours';
      case 2:
        return 'Mins';
      case 3:
        return 'Secs';
      default:
        break;
    }
  };

  return (
    <div className="d-flex align-items-center">
      {timerOver || liveNow ? (
        <div className="text-live">
          <div className="dot"></div>
          <div>{TEXT_CONSTANT.LIVE_NOW}</div>
        </div>
      ) : (
        timer.map((value, index) => {
          if (value < 10) value = '0' + value;
          const label = getLabel(index);
          return (
            <div key={label} className="time-bubble">
              <span className="timer-value">{value}</span>
              <span className="timer-unit">{label}</span>
            </div>
          );
        })
      )}
    </div>
  );
};

TimeCounter.propTypes = {
  endDate: PropTypes.string,
  liveNow: PropTypes.bool,
};

export default TimeCounter;
