import {
  DO_CREATE_FOLDER_SUCCESS,
  DO_FOLDERS_SUCCESS,
  DO_FOLDER_DETAIL_SUCCESS,
  DO_FOLDER_RENAME_SUCCESS,
  DO_FOLDER_DELETE_SUCCESS,
  FOLDERS_SET_IS_LOADING,
  TOGGLE_FOLDER_OVERLAY,
  RESET_FOLDERS,
  RESET_DETAILED_FOLDER,
  DO_FETCH_ALL_FOLDERS_SUCCESS,
} from '../../constant/folders';
import { RESET_STORE } from '../../constant/common';

const initialState = {
  isLoading: false,
  selectedModal: null,
  folderModalOptions: {},
  showFolderModal: false,
  selectedFolder: {},
  folders: {},
  allFolders: [],
};

export const folderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DO_FOLDERS_SUCCESS:
    case DO_CREATE_FOLDER_SUCCESS:
    case DO_FOLDER_RENAME_SUCCESS:
    case DO_FOLDER_DELETE_SUCCESS:
      return { ...state, folders: payload };
    case DO_FOLDER_DETAIL_SUCCESS:
      return { ...state, selectedFolder: payload };
    case TOGGLE_FOLDER_OVERLAY:
      return { ...state, ...payload };
    case FOLDERS_SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case RESET_FOLDERS:
      return { ...state, folders: {} };
    case RESET_DETAILED_FOLDER:
      return { ...state, selectedFolder: {} };
    case RESET_STORE:
      return { ...initialState };
    case DO_FETCH_ALL_FOLDERS_SUCCESS:
      return { ...state, allFolders: payload };
    default:
      return state;
  }
};
