import React from 'react';
import Testimonial from '../../../FullBodyLowImpact/Testimonials';
import { pastChallengers } from '../../static';

import './style.css';

export const PastChallengers = () => {
  return (
    <div className="past-challengers">
      <Testimonial
        headerText="Hear from some of our past challengers..."
        testimonials={pastChallengers}
      />
    </div>
  );
};
