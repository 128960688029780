export const DO_MY_PLANS_REQUEST = 'DO_MY_PLANS_REQUEST';
export const DO_MY_PLANS_SUCCESS = 'DO_MY_PLANS_SUCCESS';

export const DO_GET_RECOMMENDATION_REQUEST = 'DO_GET_RECOMMENDATION_REQUEST';
export const DO_GET_RECOMMENDATION_SUCCESS = 'DO_GET_RECOMMENDATION_SUCCESS';

export const DO_GET_USER_DATA_REQUEST = 'DO_GET_USER_DATA_REQUEST';
export const DO_GET_USER_DATA_SUCCESS = 'DO_GET_USER_DATA_SUCCESS';

export const DO_UPDATE_USER_PROFILE_REQUEST = 'DO_UPDATE_USER_PROFILE_REQUEST';
export const DO_UPDATE_USER_PROFILE_SUCCESS = 'DO_UPDATE_USER_PROFILE_SUCCESS';

export const USER_SET_IS_LOADING = 'USER_SET_IS_LOADING';
export const BANNER_SET_IS_LOADING = 'BANNER_SET_IS_LOADING';

export const DO_HANDLE_CREATE_EXCERCISE_PLAN_REQUEST =
  'DO_HANDLE_CREATE_EXCERCISE_PLAN_REQUEST';

export const DO_GET_PAYMENT_DETAIL_REQUEST = 'DO_GET_PAYMENT_DETAIL_REQUEST';

export const SET_PAYMENT_DETAIL = 'SET_PAYMENT_DETAIL';

export const DO_CANCEL_SUBSCRIPTION_REQUEST = 'DO_CANCEL_SUBSCRIPTION_REQUEST';

export const DO_PAYMENT_RESTART_REQUEST = 'DO_PAYMENT_RESTART_REQUEST';

export const DO_CHANGE_SUBSCRIPTION_PRODUCT = 'DO_CHANGE_SUBSCRIPTION_PRODUCT';

export const DO_REMOVE_RECOMMENDATION_REQUEST =
  'DO_REMOVE_RECOMMENDATION_REQUEST';
export const DO_REMOVE_RECOMMENDATION_SUCCESS =
  'DO_REMOVE_RECOMMENDATION_SUCCESS';

export const DO_FETCH_ALL_CATEGORIES_REQUEST =
  'DO_FETCH_ALL_CATEGORIES_REQUEST';
export const DO_FETCH_ALL_CATEGORIES_SUCCESS =
  'DO_FETCH_ALL_CATEGORIES_SUCCESS';

export const DO_FETCH_BANNER_REQUEST = 'DO_FETCH_BANNER_REQUEST';
export const DO_FETCH_BANNER_SUCCESS = 'DO_FETCH_BANNER_SUCCESS';
