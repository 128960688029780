import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

export const useCountdown = targetDate => {
  const countDownDate = dayjs(targetDate).valueOf();
  const [countDown, setCountDown] = useState(() => {
    const countDown = countDownDate - dayjs().valueOf();
    if (countDown < 0) return 0;
    return countDown;
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const countDown = countDownDate - dayjs().valueOf();
      if (countDown >= 0) {
        setCountDown(countDown);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return [getReturnValues(countDown), countDown === 0];
};

const getReturnValues = countDown => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
  return [days, hours, minutes, seconds];
};

export default useCountdown;
