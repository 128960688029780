import React from 'react';
import PropTypes from 'prop-types';

const StarIcon = ({ gradiants, id }) => {
  return (
    <svg height="20" viewBox="0 0 24 24" width="19">
      <defs>
        <linearGradient gradientUnits="objectBoundingBox" id={id}>
          {gradiants.map(({ color, offset }) => (
            <stop key={color + offset} offset={offset} stopColor={color} />
          ))}
        </linearGradient>
      </defs>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill={`url(#${id})`}
        stroke="#FB8200"
        strokeWidth="1.5"
        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
};

StarIcon.propTypes = {
  gradiants: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
};

export default StarIcon;
