import {
  DO_CATEGORIES_SLUG_LOADING,
  DO_CATEGORIES_SLUG_PAGINATION_LOADING,
  DO_CATEGORIES_SLUG_PAGINATION_RESET,
  DO_CATEGORIES_SLUG_PAGINATION_SUCCESS,
  DO_DEFAULT_VIDEO_LOADING,
  DO_CLEAR_SEARCH_RECORDS,
  DO_SEARCH_V2_LOADING,
  DO_SEARCH_V2_SUCCESS,
  STORE_DISCOVER_SEARCH_TEXT,
} from '../../constant/discover';

const initialState = {
  categoriesBySlugLoading: false,
  defaultVideoLoading: false,
  searchV2Loading: false,
  searchV2Records: [],
  catSlugPaginationLoading: false,
  catPaginationList: [],
  pageCount: 0,
  totalPage: 1,
  searchTextDiscover: '',
};

export const discoverReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DO_CATEGORIES_SLUG_LOADING:
      return {
        ...state,
        categoriesBySlugLoading: payload,
      };
    case DO_DEFAULT_VIDEO_LOADING:
      return {
        ...state,
        defaultVideoLoading: payload,
      };
    case DO_SEARCH_V2_LOADING:
      return {
        ...state,
        searchV2Loading: payload,
      };
    case DO_SEARCH_V2_SUCCESS:
      return {
        ...state,
        searchV2Records: payload,
      };
    case DO_CLEAR_SEARCH_RECORDS:
      return {
        ...state,
        searchV2Records: [],
      };
    case DO_CATEGORIES_SLUG_PAGINATION_LOADING:
      return {
        ...state,
        catSlugPaginationLoading: payload,
      };
    case DO_CATEGORIES_SLUG_PAGINATION_SUCCESS:
      return {
        ...state,
        catSlugPaginationLoading: payload,
        catPaginationList: [
          ...state.catPaginationList,
          ...(payload?.records || []),
        ],
        pageCount: payload?.pageCount,
        totalPage: payload?.totalPage,
      };
    case DO_CATEGORIES_SLUG_PAGINATION_RESET:
      return {
        ...state,
        catSlugPaginationLoading: false,
        catPaginationList: [],
        pageCount: 0,
        totalPage: 1,
      };
    case STORE_DISCOVER_SEARCH_TEXT:
      return {
        ...state,
        searchTextDiscover: payload?.text,
      };
    default:
      return {
        ...state,
      };
  }
};
