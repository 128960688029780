import React from 'react';
import CookieConsent from 'react-cookie-consent';
import './CookieModel.css';
import { LOCAL_STORAGE_CONSTANT } from '../../constants/app';
import { getItem } from '../../utils/cache';
import Cookies from '../../utils/cookies';
import { Nav } from 'react-bootstrap';
import { APP_ROUTES } from '../../route';

const CookieModel = () => {
  const sessionRef = getItem(LOCAL_STORAGE_CONSTANT.SESSION) || '';
  const OnAcceptCookie = () => {
    if (sessionRef) {
      Cookies.set('session', sessionRef);
    }
  };
  return (
    <CookieConsent
      location="bottom"
      declineButtonText="Decline"
      buttonText="Accept"
      enableDeclineButton="true"
      cookieName="cookie"
      containerClasses="cookieConsent"
      buttonWrapperClasses="Wrapper_btn"
      buttonClasses="cookie_btn"
      declineButtonClasses="cookie_btn"
      buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
      expires={365}
      onAccept={OnAcceptCookie}
    >
      <b>Cookie Notice</b>
      <br></br>
      <span>
        {`We use cookies to enhance the browsing experience, deliver personalized ads and content, and analyze traffic. By clicking "Accept" you agree to our use of cookies.`}
      </span>
      <Nav.Link
        className="ps-0 cursor-pointer text-decoration-underline"
        href={window.location.origin + APP_ROUTES.PRIVACY_POLICY}
        target="_blank"
      >
        Learn More
      </Nav.Link>
    </CookieConsent>
  );
};

export default CookieModel;
