import React from 'react';
import { TEXT_CONSTANT } from '../constants/app';
import { APP_ROUTES } from '../route';

const baseURL = window.location.origin;
const LogoFull = 'Logo.png';
const gratefulLogo = 'grateful-banner.png';
const barefootLogo = 'who it is for.png';
const docJenLogo = 'Meet DocJenFit.png';
const mobilityLogo = 'mobility.png';
const foundationsBanner = 'main-banner.png';
const move2ImroveBanner = 'move-2-improve-banner.png';
const peliveFloorBanner = 'pelvic-floor-main-banner.png';
const workoutAndRecovery = 'workout-and-recovery-banner.png';

export const SeoTexts = {
  FULL_BODY_LOW_IMPACT_TITLE: 'Full Body Low Impact Plan',
  FULL_BODY_LOW_IMPACT_DESCRIPTION:
    'Learn how to improve mobility, relieve consistent aches & pains, and move smarter with a doctor of physical therapy to guide you! Join our Jen Health Community.',
  FULL_BODY_LOW_IMPACT_OG_IMAGE_URL: `${baseURL}/${gratefulLogo}`,
  FULL_BODY_LOW_IMPACT_TWITTER_IMAGE_URL: `${baseURL}/${gratefulLogo}`,

  FULL_BODY_LOW_MOBILITY_TITLE: 'Full Body Mobility Plan',
  FULL_BODY_LOW_MOBILITY_DESCRIPTION:
    'Join our 30 Day Full Body Mobility Plan on Jen Health beginning January 1st!',
  FULL_BODY_LOW_MOBILITY_OG_IMAGE_URL: `${baseURL}/${mobilityLogo}`,
  FULL_BODY_LOW_MOBILITY_TWITTER_IMAGE_URL: `${baseURL}/${mobilityLogo}`,

  BARE_FOOT_MINI_COURSE_TITLE: 'Bare Foot Mini Course',
  BARE_FOOT_MINI_COURSE_DESCRIPTION:
    TEXT_CONSTANT.BARE_FOOT_MINI_COURSE_DESCRIPTION,
  BARE_FOOT_MINI_COURSE_OG_IMAGE_URL: `${baseURL}/${barefootLogo}`,
  BARE_FOOT_MINI_COURSE_TWITTER_IMAGE_URL: `${baseURL}/${gratefulLogo}`,

  BLOGS_PAGE_TITLE: 'Blogs',
  BLOGS_PAGE_DESCRIPTION: 'Blogs',
  BLOGS_PAGE_OG_IMAGE_URL: `${baseURL}/${LogoFull}`,
  BLOGS_PAGE_TWITTER_IMAGE_URL: `${baseURL}/${LogoFull}`,

  PDOCASTS_PAGE_TITLE: 'Podcasts',
  PDOCASTS_PAGE_DESCRIPTION: 'Podcasts',
  PDOCASTS_PAGE_OG_IMAGE_URL: `${baseURL}/${LogoFull}`,
  PDOCASTS_PAGE_TWITTER_IMAGE_URL: `${baseURL}/${LogoFull}`,

  JEN_WEB_TITLE: 'Jen',
  FREE_TRAIL_DESCRIPTION:
    'Improve mobility, find relief from pain and maintain long term health. Gain knowledge for life and discover what works best for your body. Save up to thousands of dollars annually in overall healthcare costs. No more waiting for weeks to get the guidance and support you need. Start your free week now!',
  JEN_WEB_DESCRIPTION:
    'Improve mobility, find relief from pain and maintain long term health. Gain knowledge for life and discover what works best for your body. Save up to thousands of dollars annually in overall healthcare costs. No more waiting for weeks to get the guidance and support you need. Start exploring Jen Health now!',
  JEN_WEB_OG_IMAGE_URL: `${baseURL}/${LogoFull}`,
  JEN_WEB_TWITTER_IMAGE_URL: `${baseURL}/${LogoFull}`,

  GRATEFUL_FOR_MY_BODY_TITLE: '30 Days of Release & Recovery',
  GRATEFUL_FOR_MY_BODY_DESCRIPTION:
    'It’s time to release restrictions, relieve aches and pains, and reset the body from the inside out. Join the challenge.',
  GRATEFUL_FOR_MY_BODY_OG_IMAGE_URL: `${baseURL}/${docJenLogo}`,
  GRATEFUL_FOR_MY_BODY_TWITTER_IMAGE_URL: `${baseURL}/${docJenLogo}`,

  GIFTS_TITLE: 'Gift Health',
  GIFTS_DESCRIPTION:
    'Give the gift of health! Jen Health provides your physical therapy needs in your back pocket. Choose from our membership or courses to help those in pain!',
  GIFTS_OG_IMAGE_URL: `${baseURL}/${LogoFull}`,
  GIFTS_TWITTER_IMAGE_URL: `${baseURL}/${LogoFull}`,

  PRIVACY_POLICY_TITLE: 'Privacy Policy',
  PRIVACY_POLICY_DESCRIPTION: 'Privacy Policy Jen Health',
  PRIVACY_POLICY_OG_IMAGE_URL: `${baseURL}/${LogoFull}`,
  PRIVACY_POLICY_TWITTER_IMAGE_URL: `${baseURL}/${LogoFull}`,

  LOW_BACK_TITLE: 'Low Back Plan',
  LOW_BACK_DESCRIPTION:
    'Improving mobility, core function, and strength to reduce back pain and tension and increase movement capacity.',
  LOW_BACK_OG_IMAGE_URL: `${baseURL}/${LogoFull}`,
  LOW_BACK_TWITTER_IMAGE_URL: `${baseURL}/${LogoFull}`,

  HIP_MOBILITY_TITLE: 'Hip Mobility',
  HIP_MOBILITY_DESCRIPTION:
    'Improving mobility hip range of motion and reducing aches and pains by improving strength, control and stability.',
  HIP_MOBILITY_OG_IMAGE_URL: `${baseURL}/${LogoFull}`,
  HIP_MOBILITY_TWITTER_IMAGE_URL: `${baseURL}/${LogoFull}`,

  KNEE_HEALTH_TITLE: 'Knee & IT Band Plan - 4 Weeks',
  KNEE_HEALTH_DESCRIPTION:
    'Improving mobility in surrounding joints to reduce pressure and tension on the knee, while building hip/core strength, control and awareness to increase knee strength and resiliency.',
  KNEE_HEALTH_OG_IMAGE_URL: `${baseURL}/${LogoFull}`,
  KNEE_HEALTH_TWITTER_IMAGE_URL: `${baseURL}/${LogoFull}`,

  UPPER_BACK_AND_NECK_TITLE: 'Neck & Upper Back Plan For You - 2 Weeks',
  UPPER_BACK_AND_NECK_DESCRIPTION:
    'Grab some therapy balls, melt into those suboccipitals with slow exhales and feel the release after the week.<br>Your body (and head) will thank you.',
  UPPER_BACK_AND_NECK_OG_IMAGE_URL: `${baseURL}/${LogoFull}`,
  UPPER_BACK_AND_NECK_TWITTER_IMAGE_URL: `${baseURL}/${LogoFull}`,

  MOVE_TO_IMPROVE_TITLE: '30 Days of Mobility + Strength!',
  MOVE_TO_IMPROVE_DESCRIPTION:
    'Join us for these 30 days to develop foundational strength, improve baseline mobility, and improve your overall movement.',
  MOVE_TO_IMPROVE_OG_IMAGE_URL: `${baseURL}/${LogoFull}`,
  MOVE_TO_IMPROVE_TWITTER_IMAGE_URL: `${baseURL}/${LogoFull}`,
  WARMUP_COOL_DOWN_DESCRIPTION:
    'Building a consistent workout routine but need quick, effective warm-ups and cool-downs to better prepare your body and improve your mobility along the way?',
  PELVIC_FLOOR_TITLE: 'Pelvic Floor',
  PELVIC_FLOOR_DESCRIPTION:
    'Developing integrated pelvic floor awareness through assessment, education, and movement.',
  PELVIC_FLOOR_OG_IMAGE_URL: `${baseURL}/${peliveFloorBanner}`,
  PELVIC_FLOOR_TWITTER_IMAGE_URL: `${baseURL}/${peliveFloorBanner}`,

  MOBILITY_FOUNDATIONS_TITLE: 'Mobility Foundations',
  MOBILITY_FOUNDATIONS_DESCRIPTION:
    'Join our 30 Day Mobility Foundations on Jen Health beginning October 27th!',
  MOBILITY_FOUNDATIONS_OG_IMAGE_URL: `${baseURL}/${foundationsBanner}`,
  MOBILITY_FOUNDATIONS_TWITTER_IMAGE_URL: `${baseURL}/${foundationsBanner}`,
  MOVE_2_IMPROVE_TITLE: 'Strong Healthy Joints',
  MOVE_2_IMPROVE_DESCRIPTION:
    'Join our 30 Day Move 2 Improve:Strong Healthy Joints! 30 Days of Mobility, Strength & Power to Relieve Pain & Reduce Injury',
  MOVE_2_IMPROVE_OG_IMAGE_URL: `${baseURL}/${move2ImroveBanner}`,
  MOVE_2_IMPROVE_TWITTER_IMAGE_URL: `${baseURL}/${move2ImroveBanner}`,
  WARMUP_COOL_DOWN_TITLE: 'Workout Prep & Recovery',
  WARMUP_COOL_DOWN_OG_IMAGEURL: `${baseURL}/${workoutAndRecovery}`,
  WARMUP_COOL_DOWN_TWITTER_IMAGE_URL: `${baseURL}/${workoutAndRecovery}`,
};

export const getCanonicalLink = (path = '') => {
  const baseURL = window.location.origin;
  return baseURL + path;
};

export const lowImpactSnippet = (
  <script type="application/ld+json">
    {`{
      "@context": "http://schema.org",
      "@type": "WebPage",
      "name": "Full Body Low Impact",
      "url": "${baseURL}/${APP_ROUTES.FULL_BODY_LOW_IMPACT}", 
      "description": "${TEXT_CONSTANT.FULL_BODY_PLAN_DESCRIPTION}"
      }`}
  </script>
);

export const bareFootSnippet = (
  <script type="application/ld+json">
    {`{
      "@context": "http://schema.org",
      "@type": "WebPage",
      "name": "BARE FOOT MINI COURSE",
      "url": "${baseURL}/${APP_ROUTES.BARE_FOOT_MINI_COURSE}", 
      "description": "${TEXT_CONSTANT.BARE_FOOT_MINI_COURSE_DESCRIPTION}"
      }`}
  </script>
);
