import React from 'react';
import PropTypes from 'prop-types';
import ModalBox from '../../../ui-core/Modal';

const Modal = props => {
  return (
    <ModalBox
      show={props.show}
      handleClose={props.handleClose}
      backdrop={'static'}
      bsPrefix={'onboarding-modal'}
      dialogClassName={props.dialogClassName}
      closeButton
    >
      {props.children}
    </ModalBox>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  dialogClassName: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default Modal;
