import React from 'react';
import PropTypes from 'prop-types';
import parser from 'html-react-parser';

import { ShouldRender } from '../../../../ui-core/ShouldRender';
import { getPromptLink } from '../../../../utils/helpers';

import './style.css';

export const Greetings = ({
  backgroundImage,
  description,
  headerText1,
  headerText2,
  keyword,
  title,
}) => {
  return (
    <div
      className="greetings-section"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div>
        <h2>Jen</h2>
        <div>
          <h3>
            {parser(headerText1)}
            <span>{keyword}</span> {parser(headerText2)}
          </h3>
        </div>
        <ShouldRender check={!title}>
          <div>
            <h5>
              Make sure to check your email and search for{' '}
              <a
                className="color-primary"
                data-rel="external"
                href={getPromptLink('jen@jen.health')}
              >
                jen@jen.health
              </a>
              <br />
              to find the pain education emails we will be delivering you over
              the next week!
            </h5>
          </div>
        </ShouldRender>
        <div>
          {title && <h4>{parser(title)}</h4>}
          <p>{parser(description)}</p>
        </div>
        <ShouldRender check={!!title}>
          <div>
            <h5>
              Look out for an email from{' '}
              <a
                className="color-primary"
                data-rel="external"
                href={getPromptLink('jen@jen.health')}
              >
                jen@jen.health
              </a>{' '}
              for details.
            </h5>
            <p>(PS. Check your spam folder if you don&apos;t see it!)</p>
          </div>
        </ShouldRender>
      </div>
    </div>
  );
};

Greetings.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  headerText1: PropTypes.string.isRequired,
  headerText2: PropTypes.string.isRequired,
  keyword: PropTypes.string.isRequired,
  title: PropTypes.string,
};
